import { Global } from '@emotion/react'

const GlobalStyles = () => {
  return (
    <Global
      styles={{
        body: {
          display: 'block !important',
        },
    }}
    />
  )
}

export { GlobalStyles }

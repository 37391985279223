export const programList = [
  'Nils Frahm',
  'Lingua Ignota',
  'The Kid Laroi',
  'Triptykon',
  'Spiritualized',
  'Injury Reserve',
  'Emma Ruth Rundle',
  'Robert Aiki Aubrey Lowe',
  'Boris',
  'Kim Gordon',
  'Perfume Genius',
  'Baxter Dury',
  'Moses Sumney',
  'Cate Le Bon',
  'Deafheaven',
  'Chelsea Wolfe',
  'TSO',
  'Hand Habits',
  'Lotic',
  'yeule',
  'Kučka',
  'Raja Kirik',
  'Rinuwat',
  'Vladislav Delay',
  'AGF',
  'Andrea Belfi',
  'Klara Lewis',
  'Nik Void',
  'Pedro Maia',
  'Claire Rousay',
  'Heather Leigh',
  'Norman Westberg',
  'Penelope Trappes',
  'David Watson',
  'Christina Vantzou',
  'Katatonia',
  'Conjurer',
  'King Yosef',
  'Diploid',
  'Black Sheep Wall',
  'YLVA',
  'Triumph of Death',
  'Chthe’ilist',
  'Krypts',
  'Darkestrah',
  'Pope Alice',
  'COBRAH',
  'Tygapaw',
  'Cakes da Killa',
  'Schacke',
  'Nathan Fake',
  'Ninajirachi',
  '1300',
  'Skeleten',
  'Rebel Yell',
  'Y-DRA',
  'Juno Mamba',
  'Jazmine Nikitta',
  'Lupa J',
  'Jesswar',
  'Elsy Wameyo',
  'Arunya Lee Olive',
  'Mama de Leche',
  'Bill Viola',
  'Michele Rizzo',
  'Hildur Guðnadóttir',
  'Chris Watson',
  'Sam Slater',
  'Matthias Schack-Arnott',
  'Hiromi Tango',
  'Sabio',
  'Michael Candy',
  'Georgie Mattingley',
  'AGF Hydra',
  'Loren Kronemyer',
  'Nathan Coley',
  'Jeremy Shaw',
  'Fiona Hall',
  'AJ King',
  'Luana Towney',
  'Robert Andrew',
  'Jónsi',
  'Meiro Koizumi',
  'Doug Aitken',
  'AES + F',
  'Marianna Simnett',
  'Joel Crosswell',
  'Hannah Brontë',
  'Callum Cusick',
  'Joe Hamilton',
  'Nadege Philippe-Janon',
  'Jacob Leary',
  'Mary Maggic',
  'Grace Gamage',
  'Matt Warren',
  'Gail Priest',
  'Sean Bacon',
  'Abbie Calvert',
  'Alex Podger',
  'Reclamation Walk',
  'Winter Feast',
  'Memorial',
  'Ogoh-ogoh',
  'The Burning',
  'Nude Solstice Swim',
]

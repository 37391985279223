/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import { useDarkContext } from 'components/context/DarkContext'
import { Button } from 'components/buttons/Button'

const NoEvents = ({ elem, index }) => {
  const { clearFilters } = useDarkContext()

  return (
    <Box
      sx={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        pt: '10rem',
        alignSelf:'center'
      }}
    >
      <Text
        variant="copyCapsWide"
        sx={{
          color: 'grey',
          textAlign:'center',
        }}
      >
        No matches
      </Text>
      <Text
        variant="copyCapsWide"
        sx={{
          color: 'red',
          textAlign:'center',
          cursor: 'pointer',
        }}
        onClick={() => clearFilters()}
      >
        Show all
      </Text>
    </Box>
  )
}

export { NoEvents }

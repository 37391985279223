/** @jsx jsx */
import { jsx } from 'theme-ui'

const TASPortsLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 162.76"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <g>
          <path d="M204.74,53.39h-19.66v-5.73h45.92v5.73h-19.66v60.9h-6.59V53.39z" />
          <path
            d="M249.23,106.47h-0.19c-2.67,5.82-9.45,8.97-15.46,8.97c-13.84,0-16.04-9.35-16.04-13.75
			c0-16.32,17.37-17.09,29.97-17.09h1.15v-2.48c0-8.31-2.96-12.51-11.07-12.51c-5.06,0-9.83,1.15-14.32,4.01v-5.82
			c3.72-1.81,10.02-3.34,14.32-3.34c12.03,0,17.09,5.44,17.09,18.14v21.48c0,3.91,0,6.87,0.48,10.22h-5.92V106.47z M248.65,89.76
			h-1.72c-10.41,0-22.81,1.05-22.81,11.74c0,6.4,4.58,8.78,10.12,8.78c14.13,0,14.41-12.31,14.41-17.56V89.76z"
          />
          <path
            d="M261.45,107.23c3.63,1.81,8.02,3.06,12.69,3.06c5.73,0,10.79-3.15,10.79-8.69c0-11.55-23.39-9.74-23.39-23.86
			c0-9.64,7.83-13.27,15.85-13.27c2.58,0,7.73,0.57,12.03,2.19l-0.57,5.25c-3.15-1.43-7.45-2.29-10.79-2.29
			c-6.2,0-10.5,1.91-10.5,8.12c0,9.07,23.96,7.92,23.96,23.86c0,10.31-9.64,13.84-16.99,13.84c-4.68,0-9.35-0.57-13.65-2.29
			L261.45,107.23z"
          />
          <path
            d="M301.25,47.67h17.47c13.65,0,25.01,4.77,25.01,19.95c0,14.8-11.55,20.05-23.58,20.05h-9.35v26.63h-9.55V47.67
			z M310.8,79.26h9.45c5.92,0,13.37-2.96,13.37-11.74c0-8.4-8.78-11.45-14.7-11.45h-8.11V79.26z"
          />
          <path
            d="M369.41,64.47c14.8,0,24.72,10.69,24.72,25.49c0,13.94-10.12,25.49-24.72,25.49
			c-14.7,0-24.82-11.55-24.82-25.49C344.59,75.16,354.52,64.47,369.41,64.47z M369.41,108.19c10.5,0,15.18-9.55,15.18-18.23
			c0-9.26-5.63-18.23-15.18-18.23c-9.64,0-15.27,8.97-15.27,18.23C354.14,98.64,358.81,108.19,369.41,108.19z"
          />
          <path
            d="M399.96,65.61h8.4v7.54h0.19c2.39-5.06,7.54-8.69,12.7-8.69c2.67,0,4.01,0.28,5.92,0.67v8.02
			c-1.62-0.67-3.44-0.86-4.96-0.86c-8.02,0-13.27,7.64-13.27,19.86v22.15h-8.97V65.61z"
          />
          <path
            d="M463.92,72.87h-12.89V99.5c0,5.63,3.44,8.69,7.54,8.69c2.77,0,4.77-0.86,6.11-1.72v7.64
			c-2,0.57-4.77,1.34-7.64,1.34c-9.26,0-14.99-4.39-14.99-14.13V72.87h-10.98v-7.26h10.98V54.35l8.97-2.86v14.13h12.89V72.87z"
          />
          <path
            d="M470.12,104.75c3.53,2.29,8.59,3.44,11.17,3.44c4.1,0,9.16-1.72,9.16-6.97c0-8.88-21.1-8.11-21.1-21.76
			c0-10.12,7.54-14.99,17.18-14.99c4.2,0,7.64,0.86,11.17,1.91l-0.76,7.83c-2-1.24-7.35-2.48-9.45-2.48c-4.58,0-8.59,1.91-8.59,5.92
			c0,9.93,21.1,7.06,21.1,22.63c0,10.4-8.31,15.18-16.99,15.18c-4.58,0-9.26-0.48-13.36-2.67L470.12,104.75z"
          />
        </g>
        <path
          d="M81.54,32.17c-27.28,0-49.4,22.12-49.4,49.4c0,27.28,22.12,49.4,49.4,49.4s49.4-22.12,49.4-49.4
		C130.94,54.29,108.82,32.17,81.54,32.17z M93.42,116.91c0,0,8.99-7.96-31.12-64.2c0,0,40.98,18.18,46.36,3.51
		C108.66,56.22,133.13,91.23,93.42,116.91z"
        />
        <g>
          <path d="M78.61,24.79c0,0,11.86-8.57,23.08-24.79c0,0,1.74,17.64,8.91,32.75C110.6,32.75,96.68,23.74,78.61,24.79z" />
          <path
            d="M138.8,78.55c0,0,8.57,11.86,24.78,23.08c0,0-17.64,1.75-32.74,8.91C130.84,110.54,139.84,96.62,138.8,78.55z
			"
          />
          <path
            d="M84.15,137.98c0,0-11.86,8.57-23.08,24.78c0,0-1.75-17.64-8.91-32.74C52.16,130.02,66.08,139.02,84.15,137.98
			z"
          />
          <path d="M24.78,84.16c0,0-8.57-11.86-24.78-23.08c0,0,17.64-1.75,32.75-8.91C32.75,52.17,23.74,66.09,24.78,84.16z" />
        </g>
      </g>
    </svg>
  )
}

export { TASPortsLogo }

export const wordsFromChildren = (children) => {
  if (typeof children === 'string') {
    return children.split(' ')
  }

  if (children instanceof Array) {
    return children.flatMap(wordsFromChildren)
  }

  throw new TypeError(`Cannot explode text for children of type ${typeof children}`)
}

/* eslint-disable complexity */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Hls from 'hls.js'

import omit from 'lodash/omit'
import getPosterSrc from './util/getPosterSrc'
import ExplodedLine from 'components/utils/ExplodedLine'
import FlexCentre from 'components/utils/FlexCentre'

const propTypes = {
  assetDocument: PropTypes.object.isRequired,
  autoload: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  showControls: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  poster: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  onPlay: PropTypes.func,
  onFineTimeUpdate: PropTypes.func,
  tracks: PropTypes.arrayOf(PropTypes.node),
  videoRef: PropTypes.object
}

const handledPropNames = [
  'assetDocument',
  'autoload',
  'autoplay',
  'muted',
  'showControls',
  'style',
  'className',
  'poster',
  'onClick',
  'onPlay',
  'onFineTimeUpdate',
  'children',
  'tracks',
  'videoRef',
]

class SanityPlayer extends Component {
  state = {
    posterUrl: null,
    source: null,
    isLoading: true,
    error: null,
  }

  static defaultProps = {
    autoload: true,
    autoplay: false,
    className: '',
    height: '',
    loop: false,
    muted: false,
    showControls: true,
    style: { width: '100%', height: 'auto' },
    width: '100%',
    poster: false,
  }

  videoContainer = React.createRef()
  hls = null
  timeRequest = null

  // eslint-disable-next-line complexity
  static getDerivedStateFromProps(nextProps) {
    let source = null
    let posterUrl = null
    let isLoading = true
    const { assetDocument, poster } = nextProps
    if (assetDocument && assetDocument.status === 'preparing') {
      isLoading = 'MUX is processing the video'
    }
    if (assetDocument && assetDocument.status === 'ready') {
      isLoading = false
    }
    if (assetDocument && assetDocument.playbackId) {
      source = `https://stream.mux.com/${assetDocument.playbackId}.m3u8`
      // Load video poster only if explictly requested.
      if (poster === true) {
        posterUrl = getPosterSrc(assetDocument.playbackId, {
          time: assetDocument.thumbTime || 1,
          fitMode: 'preserve',
        })
      }
    }
    if (assetDocument && typeof assetDocument.status === 'undefined') {
      isLoading = false
    }
    if (typeof poster === 'string') {
      posterUrl = poster
    }
    return { isLoading, source, posterUrl }
  }

  componentDidMount() {
    this.video = this.props.videoRef || React.createRef()
    this.raf = React.createRef()
    this.setState(SanityPlayer.getDerivedStateFromProps(this.props))
  }

  componentDidUpdate(prevProps, prevState) {
    // play video callback
    if (this.props.onPlay && !this.raf.current) {
      const video = this.video.current
      const checkTime = () => {
        if (video.currentTime >= 0.01) {
          // fire onPlay prop
          this.props.onPlay(video)
        } else {
          this.raf.current = requestAnimationFrame(checkTime)
        }
      }
      this.raf.current = requestAnimationFrame(checkTime)
    }

    if (
      this.state.source !== null &&
      this.video.current &&
      !this.video.current.src
    ) {
      if (this.state.error) {
        this.setState({ error: null })
      }

      this.attachVideo()
    }

    if (this.state.source !== null && this.state.source !== prevState.source) {
      if (this.state.error || this.state.showControls) {
        this.setState({ error: null, showControls: false })
      }

      if (this.hls) {
        this.hls.destroy()
      }

      this.attachVideo()
    }

    // Just continually call the fine time check — rather than handle paused / seek / ended / error
    requestAnimationFrame(this.fineTimeUpdate)
  }

  getVideoElement() {
    return this.video && this.video.current
  }

  attachVideo() {
    const { autoload } = this.props
    if (Hls.isSupported()) {
      this.hls = new Hls({ autoStartLoad: autoload })
      this.hls.loadSource(this.state.source)
      this.hls.attachMedia(this.video.current)
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (this.videoContainer?.current?.style) {
          this.videoContainer.current.style.display = 'block'
        }
      })
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            if (this.videoContainer?.current?.style) {
              this.videoContainer.current.style.display = 'none'
            }
            this.setState({ error: data })
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            // Don't output anything visible as these mostly are non-fatal
            break
          default:
            if (this.videoContainer?.current?.style) {
              this.videoContainer.current.style.display = 'none'
            }
            this.setState({ error: data })
        }
        console.error(data) // eslint-disable-line no-console
      })
    } else if (
      this.video.current.canPlayType('application/vnd.apple.mpegurl')
    ) {
      this.video.current.src = this.state.source
      this.video.current.addEventListener('loadedmetadata', () => {
        if (this.videoContainer?.current?.style) {
          this.videoContainer.current.style.display = 'block'
        }
      })
      this.video.current.addEventListener('error', () => {
        if (this.videoContainer?.current?.style) {
          this.videoContainer.current.style.display = 'none'
        }
        this.setState({
          error: {
            type: `${this.video.current.error.constructor.name} code ${this.video.current.error.code}`,
          },
        })
        console.error(this.video.current.error) // eslint-disable-line no-console
      })
    }
  }

  handleVideoClick = event => {
    const { autoload } = this.props
    if (!autoload) {
      this.setState({ showControls: true })
      if (this.hls) {
        this.hls.startLoad(0)
      }
    }
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  fineTimeUpdate = () => {
    if (this.props.onFineTimeUpdate && this.video.current) {
      this.props.onFineTimeUpdate(this.video.current.currentTime)
    }

    // Continue to check / update time indefinitely
    requestAnimationFrame(this.fineTimeUpdate)
  }

  render() {
    const { isLoading, error } = this.state
    const {
      assetDocument,
      autoload,
      style,
      tracks,
      showControls = autoload || this.state.showControls,
    } = this.props

    if (!assetDocument || !assetDocument.status) {
      return null
    }

    if (isLoading) {
      return (
        <FlexCentre sx={{
          zIndex: 66,
          position: 'absolute',
        }}>
          <ExplodedLine
            variant="splashError"
          >
            Inception
          </ExplodedLine>
        </FlexCentre>
      )
    }

    const videoProps = omit(this.props, handledPropNames)

    return (
      <>
        <video
          onClick={this.handleVideoClick}
          controls={showControls}
          muted={this.props.muted} // Force mute if autoplay (or it might not even work at all)
          autoPlay={this.props.autoplay}
          ref={this.video}
          {...videoProps}
          style={style}
          playsInline
          preload="auto"
        >
          {tracks}
        </video>
        {error && (
          <FlexCentre sx={{
            zIndex: 66,
            position: 'absolute',
          }}>
            <ExplodedLine
              variant="splashError"
            >
              Error
            </ExplodedLine>
          </FlexCentre>
        )}
      </>
    )
  }
}

SanityPlayer.propTypes = propTypes

export {
  SanityPlayer,
  SanityPlayer as default,
}

/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const Line = ({
  color = 'black',
  hrHeight = '1px',
  ...props
}) => {
  return (
    <Box
      as="hr"
      sx={{
        height: hrHeight,
        border: '0 none',
        backgroundColor: color,
      }}
      {...props}
    />
  )
}

export { Line }

import { PreviewAlert } from 'components/PreviewAlert'

export default function Index({ preview }) {
  return (
    <PreviewAlert preview={preview} />
  )
}

export async function getStaticProps({ preview = false }) {
  return {
    props: { preview },
  }
}

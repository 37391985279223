/**
 * Return a string with the correct background gradient prefix for the header
 *
 * @param {*} page which page is invoking the header
 * @param {*} colorMode dark or lightmode
 * @param {*} isBackgroundInvisible for event page, to switch between backgrounds
 * @param {*} filterByOpen for program page, change gradient if filter is open
 * @returns
 */
function backgroundHeader(
  page,
  colourMode,
  isBackgroundInvisible,
  filterByOpen,
) {
  if (
    page === 'event' &&
    (isBackgroundInvisible || colourMode === 'light')
  ) {
    return 'none';
  }

  const rgb = rgbPageMode(page, colourMode);

  if (filterByOpen && page === 'program') {
    return `linear-gradient(to top, ${withAlpha(rgb, 0)}, ${withAlpha(rgb, 1)}, ${withAlpha(rgb, 1)}, ${withAlpha(rgb, 1)})`;
  }

  return `linear-gradient(to top, ${withAlpha(rgb, 0)}, ${withAlpha(rgb, 0.8)}, ${withAlpha(rgb, 0.9)}, ${withAlpha(rgb, 1)})`;
}

function rgbPageMode(page, colourMode) {
  const dark = colourMode === 'dark';

  if (dark) {
    switch (page) {
      case 'menu':
      case 'form':
        return [160,160,160];
      case 'event':
        return [253,1,0];
      case 'program':
      default:
        return [0,0,0];
    }
  }

  // i.e. Light mode
  return [255,255,255];
}

function withAlpha(rgb, a) {
  return `rgba(${[...rgb, a].join(',')})`;
}

export { backgroundHeader }

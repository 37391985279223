// export function linkByType(type, slug, sub, nested = false) {
//   if (type === 'event') {
//     return linkByType('program')
//   }

//   if (nested) {

//   }

//   return `/${type}/${slug}`
// }

export function mapLinkTypes(type) {
  if (type === 'event') {
    return 'program'
  }

  return type
}

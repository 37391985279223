import { NoEvents } from "components/program/NoEvents";

export const crossSize = [
  '52px',
  null,
  '72px',
  '84px',
  '94px',
]

const config = {
  // Do NOT respect the user's 'prefers-color-scheme' media query, always be
  // 'dark' by default
  useColorSchemeMediaQuery: false,
  useCustomProperties: true,
  useLocalStorage: false,
  initialColorModeName: 'dark',
  initialColorMode: 'dark',
}

export default {
  // Use 'dark' as name for default theme instead of 'default'
  ...config,
  config,
  layout: {
    container: {
      maxWidth: '2000px',
      px: [3, 4, 6, 6],
    },
    noPaddingContainer: {
      maxWidth: '2000px',
      px: '0px',
    },
    mapContainer: {
      variant: 'layout.container',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      pt: [2],
      pb: ['16px', null, 6, 6, 6],
    },
  },
  grids: {
    default: {
      gridColumnGap: [2, 3],
    },
  },
  breakpoints: ['640px', '768px', '1024px', '1600px', '2000px', '3200px'], // em * 16px
  space: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96],
  colors: {
    white: '#fff',
    black: '#000',
    red: '#fd0100',
    grey: '#A0A0A0',
    invertedBlack: '#fd0100',
    invertedWhite: '#fff',
    invertedRed: '#000',
    imageRed: '#fd0100',
    lightGrey: '#A0A0A0',
    lightRed: '#fd0100',
    alwaysBlack: '#000',
    modes: {
      light: {
        white: '#fd0100',
        black: '#fff',
        red: '#000',
        grey: '#000',
        invertedBlack: '#000',
        invertedWhite: '#000',
        invertedRed: '#fd0100',
        imageRed: '#A0A0A0',
        lightGrey: '#fff',
        lightRed: '#fff',
        alwaysBlack: '#000',
      },
    },
  },
  fonts: {
    body: 'RegolaNeue, Avenir, Century-Gothic, Futura, sans-serif',
  },
  fontSizes: [
    /* small */
    20,
    28,
    33,
    0,
    /* copy */
    14,
    17, // late override wont make sense in array but must happen
    22,
    /* header */
    21,
    44,
    '4.09vw',
    66,
  ],
  lineHeights: [0, 0, '1.5em', 0, 0, 0, '1.153em', 0, 0, '0.783em'],
  letterSpacing: ['0.06em', '0.02em', '-0.015em'],
  text: {
    largeHeader: {
      fontFamily: 'body',
      display: 'inline-block',
      fontSize: [7, null, 8, 9, 10],
      lineHeight: '0.783em',
      fontWeight: 'bold',
      fontStyle: 'normal',
      color: 'grey',
      textTransform: 'uppercase',
      textAlign: 'justify',
      textAlignLast: 'justify',
      letterSpacing:'0.33em',
      textDecoration: 'none',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      userSelect: 'none',
      whiteSpace: 'pre-wrap',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    largeHeaderBlack: {
      variant: 'text.largeHeader',
      color: 'alwaysBlack',
    },
    largeHeaderReverse: {
      variant: 'text.largeHeader',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    splashHeader: {
      variant: 'text.largeHeader',
      color: 'red',
      lineHeight: '2em',
      fontSize: [
        '3.444vw',
        null,
        null,
        null,
        null,
        '66px',
      ],
    },
    splashError: {
      variant: 'text.splashHeader',
      color: 'grey',
    },
    splashCredit: {
      fontFamily: 'body',
      color: 'red',
      textTransform: 'uppercase',
      letterSpacing: '.333em',
      lineHeight: '1.666em',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    smallHeader: {
      fontFamily: 'body',
      display: 'inline-block',
      fontSize: [4, null, 5, 6, 7],
      lineHeight: '0.783em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: 'grey',
      textTransform: 'capitalize',
      textAlign: 'left',
      textAlignLast: 'left',
      textDecoration: 'none',
      padding: 0,
      margin: '0.55em 0 0.55em 0',
      background: 'none',
      outline: 'none',
      border: 'none',
      userSelect: 'none',
      whiteSpace: 'pre-wrap',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallHeaderReverse: {
      variant: 'text.smallHeader',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallHeaderReverseCaps: {
      variant: 'text.smallHeader',
      color: 'alwaysBlack',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '0.33em',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    copy: {
      fontFamily: 'body',
      fontSize: [5, null, null, 5, 6],
      lineHeight: '1.5em',
      fontVariantNumeric: 'oldstyle-nums',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: 'alwaysBlack',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    copyCaps: {
      variant: 'text.copy',
      fontVariantNumeric: 'default',
      textTransform: 'uppercase',
    },
    // copyCapsWide: {
    //   variant: 'text.copy',
    //   fontVariantNumeric: 'default',
    //   textTransform: 'uppercase',
    //   letterSpacing: '0.33em',
    //   fontWeight: 'bold',
    // },
    copyCapsEventListing: {
      variant: 'text.largeCopyCaps',
      lineHeight: '1.5em',
      color: 'alwaysBlack',
      fontWeight: 'bold',
      lineHeight: '1.5em',
      margin: '1em 0',
      letterSpacing:'0.33em',
    },
    copyReverse: {
      variant: 'text.copy',
      color: 'alwaysBlack',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    copyCapsReverse: {
      variant: 'text.copyReverse',
      fontVariantNumeric: 'default',
      textTransform: 'uppercase',
    },
    copyCapsReverseWide: {
      variant: 'text.copyReverse',
      fontVariantNumeric: 'default',
      textTransform: 'uppercase',
      letterSpacing:'0.33em',
    },
    copyTitleReverse: {
      variant: 'text.copyReverse',
      fontVariantNumeric: 'default',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    copySmallCaps: {
      variant: 'text.copy',
      fontVariant: 'small-caps',
      textTransform: 'lowercase',
    },
    copySmallCapsReverse: {
      variant: 'text.copySmallCaps',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    copyCapsWide: {
      variant: 'text.copyCaps',
      letterSpacing: '0.33em',
      lineHeight: '2em',
      color: 'alwaysBlack',
    },
    smallCopy: {
      fontFamily: 'body',
      fontSize: [0, null, null, 1, 2],
      lineHeight: '1.5em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallAccessCopy: {
      fontSize: [4, 4, 5, 5, 5],
      lineHeight: '1.5em',
      fontWeight: 'normal',
      fontStyle: 'normal',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallAccessCopyCaps: {
      fontSize: [4, 4, 5, 5, 5],
      lineHeight: '1.5em',
      fontWeight: 'bold',
      fontStyle: 'normal',
      letterSpacing:'0',
      textTransform:'capitalize',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallAccessCopyReverse: {
      variant: 'text.smallAccessCopy',
      color: 'alwaysBlack',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallCopyCaps: {
      variant: 'text.smallCopy',
      fontVariant: 'initial',
      fontVariantNumeric: 'initial',
      textTransform: 'uppercase',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallCopyReverse: {
      variant: 'text.smallCopy',
      color: 'alwaysBlack',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallCopyBlack: {
      variant: 'text.smallAccessCopy',
      color: 'alwaysBlack',
      letterSpacing: '0.33em',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    smallCopySmallCaps: {
      variant: 'text.smallCopy',
      fontSize: [0, null, null, 0, 0],
      fontVariant: 'small-caps',
      textTransform: 'uppercase',
      letterSpacing:'0.33em',
    },
    smallCopySmallCapsWide: {
      variant: 'text.smallCopy',
      fontSize: ["10px", null, null, 4, 4],
      fontVariant: 'small-caps',
      textTransform: 'uppercase',
      letterSpacing: '0.33em',
      color: 'grey',
    },
    smallCopyCapsWide: {
      variant: 'text.smallCopy',
      fontSize: [0, null, null, 0, 0],
      textTransform: 'uppercase',
      letterSpacing: '0.33em',
      color: 'grey',
    },
    smallCopySmallCapsReverse: {
      variant: 'text.smallCopySmallCaps',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    largeCopy: {
      fontSize: [0, null, null, 1, 2],
      lineHeight: '1.5em',
      fontVariantNumeric: 'oldstyle-nums',
      fontWeight: 'normal',
      fontStyle: 'bold',
      padding: 0,
      margin: 0,
      background: 'none',
      outline: 'none',
      border: 'none',
      textTransform: 'uppercase',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
    largeCopyReverse: {
      variant: 'text.largeCopy',
      color: 'alwaysBlack',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    largeCopyCaps: {
      variant: 'text.largeCopy',
      fontVariant: 'initial',
      fontVariantNumeric: 'initial',
      textTransform: 'uppercase',
    },
    largeCopyCapsWide: {
      variant: 'text.largeCopyCaps',
      letterSpacing: '0.33em',
      lineHeight: '2em',
      color: 'alwaysBlack',
    },
    copyPttr: {
      variant: 'text.smallCopy',
      textIndent: '-1.15em',
      pl: '1.15em',
    },
    copyPttrReverse: {
      variant: 'text.copyPttr',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    winterFeastArtists: {
      variant: 'text.copy',
      textIndent: '-1.15em',
      pl: '1.15em',
    },
    winterFeastArtistsReverse: {
      variant: 'text.winterFeastArtists',
      color: 'invertedRed',
      '::selection': { backgroundColor: 'red', color: 'black' },
    },
    screenReader: {
      fontSize: 0,
      lineHeight: 0,
      display: 'block',
      height: 0,
      width: 0,
      overflow: 'hidden',
      textIndent: '-9999px',
      '::selection': { backgroundColor: 'black', color: 'red' },
    },
  },
  input: {
    border: 'none',
    background: 'none',
  },
  buttons: {
    primary: {
      display: 'inline-block',
      variant: 'text.copy',
      border: '1px solid',
      borderColor: 'red',
      pt: '0.36em',
      pb: '0.36em',
      px: '0.6em',
      mr: '0.3em',
      textTransform: 'uppercase',
      textDecoration: 'none',
      // lineheight - padding bottom of bottom - 2px for borders top and bottom = x2 line heights or 2em
      mb: ['calc(1.153em - 0.72em - 2px)', 'calc(1.153em - 0.72em - 4px)'],
      // '&:hover': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:active': {
      //   color: 'currentColor',
      // },
      '::active': {
        color: 'black',
      },
    },
    primaryReverse: {
      variant: 'buttons.primary',
      color: 'alwaysBlack',
      borderColor: 'alwaysBlack',
      '::active': {
        color: 'black',
      },
    },
    secondary: {
      display: 'inline-block',
      variant: 'text.copyCapsWide',
      border: '1px solid',
      borderColor: 'red',
      pt: '0.7em',
      pb: '0.9em',
      px: '0.6em',
      mr: '0.3em',
      // lineheight - padding bottom of bottom - 2px for borders top and bottom = x2 line heights or 2em
      mb: ['calc(1.153em - 0.72em - 2px)', 'calc(1.153em - 0.72em - 4px)'],
      // '&:hover': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:focus': {
      //   boxShadow: '0px 0px 15px #0000001f, 0px 0px 17px #00000047',
      // },
      // '&:active': {
      //   color: 'currentColor',
      // },
      color: 'alwaysBlack',
      '::active': {
        color: 'black',
      },
    },
    secondaryReverse: {
      variant: 'buttons.secondary',
      color: 'lightRed',
      borderColor: 'alwaysBlack',
      color: 'alwaysBlack',
      backgroundColor: 'none',
      padding: '0',
      '::active': {
        color: 'black',
      },
    },
    smallReverse: {
      display: 'inline-block',
      variant: 'text.smallCopySmallCapsWide',
      border: '1px solid',
      borderColor: 'grey',
      pt: '0.7em',
      pb: '0.9em',
      px: '0.6em',
      mr: '0.3em',
      mb: ['calc(1.153em - 0.72em - 2px)', 'calc(1.153em - 0.72em - 4px)'],
      color: 'grey',
      backgroundColor: 'none',
      padding: '0',
    },
    clear: {
      display: 'inline-block',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'alwaysBlack',
      '::active': {
        color: 'black',
      },
    },
    copyClear: {
      display: 'inline-block',
      variant: 'text.copy',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'red',
      '::active': {
        color: 'black',
      },
      '::selection': {
        backgroundColor: 'red',
        color: 'black',
      },
    },
    copyClearReverse: {
      display: 'inline-block',
      variant: 'text.copyReverse',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedRed',
      '::active': {
        color: 'invertedRed',
      },
    },
    copyCapsClear: {
      display: 'inline-block',
      variant: 'text.copy',
      textTransform: 'uppercase',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'alwaysBlack',
      '::active': {
        color: 'black',
      },
    },
    copyCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.copyReverse',
      textTransform: 'uppercase',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedRed',
      '::active': {
        color: 'invertedRed',
      },
    },
    copySmallCapsClear: {
      display: 'inline-block',
      variant: 'text.copySmallCaps',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'alwaysBlack',
      '::active': {
        color: 'black',
      },
    },
    copySmallCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.copySmallCapsReverse',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedRed',
      '::active': {
        color: 'invertedRed',
      },
    },
    smallCopySmallCapsClear: {
      display: 'inline-block',
      variant: 'text.smallCopySmallCapsWide',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'red',
      letterSpacing: '0.33em',
      '::active': {
        color: 'red',
      },
    },
    smallCopySmallCapsClearReverse: {
      display: 'inline-block',
      variant: 'text.smallCopySmallCapsWide',
      outline: 'none',
      m: 0,
      p: 0,
      textAlign: 'left',
      background: 'none',
      border: 'none',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      color: 'invertedRed',
      '::active': {
        color: 'invertedRed',
      },
    },
  },
  input: {
    text: {
      variant: 'text.copy',
      appearance: 'none',
      display: 'flex',
      textAlign: 'left',
      width: '100%',
      textDecoration: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      borderBottom: '1px solid',
      borderColor: 'red',
      borderRadius: 0,
      lineHeight:'2.2em',
      mb: '0',
      padding: '0.33em 0 0.66em',
      '::selection': {
        backgroundColor: 'red',
        color: 'black',
      },
      '::placeholder': {
        color: 'red',
      },
      ':-webkit-autofill': {
        WebkitTextFillColor: '#000', // theme colors dont work here
        WebkitBoxShadow: '0 0 0px 1000px #fff inset',
        transition: 'background-color 5000s ease-in-out 0s',
        background: 'none !important',
      },
      // '&:-webkit-autofill::first-line': {
      //   fontFamily: 'Times, "Times New Roman", serif !important',
      //   fontSize: '40px',
      // },
    },
    search: {
      variant: 'text.largeHeaderReverse',
      appearance: 'none',
      background: 'none !important',
      width: '100%',
      padding: '0 1rem',
      border: 'none !important',
      outline: 'none',
      textAlign: 'center',
      textAlignLast: 'center',
      '::placeholder': {
        color: 'alwaysBlack',
      },
      ':focus::placeholder': {
        color: 'transparent',
      },
    },
    tinySearch: {
      variant: 'text.smallCopySmallCapsWide',
      background: 'none',
      color: 'grey',
      width: '100%',
      border: 'none',
      outline: 'none',
      padding: '0px',
      textAlign: 'left',
      '::placeholder': {
        color: 'grey',
      },
      '::selection': {
        backgroundColor: 'invertedWhite',
        color: 'black',
      },
    },
    tinySearchMobile: {
      variant: 'text.smallCopySmallCapsWide',
      background: 'none',
      color: 'grey',
      width: '100%',
      border: 'none',
      outline: 'none',
      padding: '0px',
      textAlign: 'center',
      '::placeholder': {
        color: 'grey',
      },
      '::selection': {
        backgroundColor: 'invertedWhite',
        color: 'black',
      },
    },
    select: {
      variant: 'input.text', // inherit text
      appearance: 'none',
      WebkitAppearance: 'none',
      display: 'flex',
      textAlign: 'left',
      width: '100%',
      textDecoration: 'none',
      color: 'red',
      borderRadius: '0px',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '::placeholder': {
        color: 'red',
      },
      ':-webkit-autofill': {
        WebkitTextFillColor: 'red',
        WebkitBoxShadow: '0 0 0px 1000px black inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
      '::-ms-expand': {
        display: 'none',
      },
    },
  },
  label: {
    hidden: {
      display: 'block',
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
    visible: {
      variant: 'text.copyCaps',
      fontStyle: 'normal',
      fontWeight: 'normal',
      userSelect: 'none',
      cursor: 'pointer',
    },
    checkbox: {
      variant: 'text.copy',
      display: 'block',
      cursor: 'pointer',
      a: {
        /*backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: '0 1.05em',
        backgroundSize: '1px 1px',*/
        borderBottom: '1px solid currentColor',
      },
      p: {
        margin: 0,
        fontSize: 'inherit',
        display: 'contents',
      },
    },
    checkboxFilter: {
      variant: 'text.smallCopyCaps',
      display: 'block',
      cursor: 'pointer',
      letterSpacing: '0.33em',
      a: {
        /*backgroundImage: `linear-gradient(currentColor 0%, currentColor 100%)`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: '0 1.05em',
        backgroundSize: '1px 1px',*/
        borderBottom: '1px solid currentColor',
      },
      p: {
        margin: 0,
        fontSize: 'inherit',
        display: 'contents',
      },
    },
  },
  page: {
    position: 'relative',
    height: '100%',
  },
  styles: {
    root: {
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: [5, null, null, 6],
      color: 'red',
      bg: 'black',
      height: '100%',
      a: {
        color: 'black',
        cursor: 'pointer',
      },
      button: {
        cursor: 'pointer',
      },
      'a:focus-visible, button:focus-visible': {
        outline: theme => `2px solid ${theme.colors.white}`,
      },
      label: {
        userSelect: 'none',
        cursor: 'pointer',
      },
    },
    html: {
      height: '100%',
    },
    body: {
      margin: 0,
      maxWidth: '2000px',
      float: 'none',
      height: '100%',
    },
    document: {
      backgroundColor: 'black',
    },
  },
}

/** @jsx jsx */
import { PreviewAlert } from 'components/PreviewAlert'
import { useDarkContext } from 'components/context/DarkContext'
import Meta from 'components/Meta'
import { Events } from 'components/program/Events'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { useRouter } from 'next/router'
import { Container, jsx } from 'theme-ui'
import { LockedWord } from 'components/LockedWord'
import Link from 'next/link'
import { CrossSVG } from './Cross'

export default function GlobalLayout({ preview, children }) {
  const {
    global: { metaFields },
  } = useGlobalStaticData()

  const router = useRouter()
  const { menuDisplay } = useDarkContext()
  const isMain =
    router.query.slug !== undefined &&
    router.query.slug[0] === 'program' &&
    !menuDisplay

  // TODO: dynamic main if root domain use main otherwise swap to aside?
  return (
    <>
      <Meta metaFields={metaFields} />
      <PreviewAlert preview={preview} />
      <Container
        as={isMain ? 'main' : 'aside'}
        sx={{
          minHeight:'100vh',
          display: 'flex',
          flexDirection:'column',
          justifyContent:'space-between',
        }}
      >
        <Events />
        <Container sx={{
          mt: '33vh',
        }}>
          {/* Cross */}
          <Link href='/program' scroll={false} sx={{ paddingBottom: '0px', }}>
            <div
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              sx={{
                zIndex: 1000,
                maxHeight: 'fill-available',
                height: [
                  'calc(38px + 14px)',
                  null,
                  'calc(54px + 18px)',
                  'calc(64px + 20px)',
                  'calc(74px + 20px)',
                ],
                paddingBottom: '0px',
                cursor: 'pointer',
              }}
            >
              <CrossSVG title="To top"/>
            </div>
          </Link>
        </Container>
      </Container>
      {children}
    </>
  )
}

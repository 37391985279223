const { Flex } = require("theme-ui")

const FlexCentre = ({
  children,
  sx = {},
}) => {
  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}

export default FlexCentre

/** @jsx jsx */
import { FavouriteButton } from 'components/buttons/FavouriteButton'
import { Line } from 'components/Line'
import Link from 'next/link'
import { jsx, Flex, Box, Text, Grid, useColorMode } from 'theme-ui'
import { useDarkContext } from 'components/context/DarkContext'
import { EventHeader } from 'components/program/EventHeader'
import { ExclusiveIcon } from 'components/global/icons/ExclusiveIcon'
import { JointExclusiveIcon } from 'components/global/icons/JointExclusiveIcon'
import { LockedWord } from 'components/LockedWord'
import ExplodedLine from 'components/utils/ExplodedLine'


const ListEvent = ({ elem, index }) => {
  const { tagFilterBy, dateFilters } = useDarkContext()
  const [colorMode] = useColorMode()
  const inlineSubtitle = elem.subTitle?.replace(/\s?\([^)]*\)\s?/g, '').replace(/\s+/g, ' ').replace(/\s?,\s?/g, ' • ').replace(/\s\+\s/g, ' + ').replace(/\s\/\s/g, ' / ')

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      {/* Event Dates */}
      <Flex
        sx={{
          justifyContent: ['space-between'],
          margin: '1rem 0 0 0',
          height: '1.5em',
        }}>
        <Text as="span" variant="smallCopySmallCapsWide" color="grey">
          {elem.listEventDates}
        </Text>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: ['flex-end'],
            gap: '1rem',
            alignContent: 'flex-start',
          }}
        >
          {elem.exclusive &&
            <ExclusiveIcon />
          }
          {elem.jointExclusive &&
            <JointExclusiveIcon />
          }
          {/* Bookmark button */}
          <FavouriteButton
            id={elem._id}
            title={elem.title}
            index={index + 1}
            elem={elem}
            tnew={elem.tnewProductionSeason}
            color='grey'
          />
        </Flex>
      </Flex>
      <Flex
        sx={{
          //overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          mb: ['7px', null, '11px', '12px', '15px'],
        }}
        onClick={() => {
          const filterToString = Object.keys(tagFilterBy)
            //get only true values
            .map((value, i) => (tagFilterBy[value] ? `${value}` : null))
            //filter for non null
            .filter((v, i) => v)
            //join string together
            .join('_')

          const dates = dateFilters
            .map((v, i) => {
              const d = new Date(v)
              return d.getDate()
            })
            .join('_')

          const searchTerm = filterToString
            ? dates
              ? `${filterToString}_${dates}`
              : filterToString
            : dates

          dataLayer.push({
            event: 'custom.search.link.click',
            parentGroup: 'Filter',
            label: elem.title,
            info: {
              url: `/program/${elem?.slug?.current}`,
              position: index + 1,
            },
            data: {
              value: searchTerm,
              mode: colorMode,
            },
          })
        }}
      >
        <Link href={`/program/${elem?.slug?.current}`} passHref scroll={false}>
            <Text
              as="a"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifySelf: 'stretch',
                alignContent: 'stretch',
                width: '100%',
                margin: '0 0 1rem',
              }}
            >
              <EventHeader title={elem.title} />
            </Text>
            </Link>
            {elem.subTitleOnMainPage &&
              <ExplodedLine
              variant="smallCopySmallCapsWide"
              wordGap="1em"
            >
              {inlineSubtitle}
            </ExplodedLine>
            }
      </Flex>
      <Line hrHeight='1px' color={'red'} />
    </Flex>
  )
}

export { ListEvent }

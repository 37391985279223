/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { Line } from 'components/Line'
import { RenderIf } from 'components/RenderIf'
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useState, useEffect, useRef } from 'react'
import { jsx, Text, Box } from 'theme-ui'
import { pageMatchesRoute, pageMatchesURL } from 'components/global/menu/helpers/menuRoutes'
import { LockedWord } from 'components/LockedWord'
import { useBreakpointIndex } from '@theme-ui/match-media'

const MenuPage = ({ item, i, parent }) => {
  const buttonRef = useRef()
  const titleRef = useRef()
  const router = useRouter()
  const bp = useBreakpointIndex({ defaultIndex: 2 })

  const regExp = new RegExp(`\/${item.slug.current}($|\/){1}`);

  const isOpen = pageMatchesRoute(router, regExp)

  const handleClick = () => {
    if (!isOpen) {
      console.log(item?.slug?.current)
      router.push(
        `/${item?.slug?.current}`,
        undefined,
        {
          scroll: false,
          shallow: true,
        }
      )
    } else {
      router.push(parent, undefined, { scroll: false, shallow: true })
    }
  }

  const { setMenuDisplay } = useDarkContext()

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (pageMatchesURL(url, regExp)) {
        setMenuDisplay(true)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, item?.slug, router.events, setMenuDisplay])

  return (
    <Box ref={buttonRef}>
      <Button
        aria-expanded={isOpen}
        ref={titleRef}
        sx={{
          width: '100%',
          py: ['8px', null, '12px', '15px', 3],
          textAlign: 'justify',
          textAlignLast: 'justify',
          textJustify: 'inter-character',
          letterSpacing: '100%',
        }}
        id={`accordion${i}`}
        onClick={() => handleClick()}
      >
        <LockedWord title={item.title} fontVariant='largeHeader' lineHeight='1em' margin='1.5rem 0 1.5rem 0' textColor='alwaysBlack' wordGap='1em' charGap='0.5em' />
      </Button>
      {item.content[0]._type === 'accordionBlock' && <Line color="alwaysBlack" hrHeight="1px" />}
      <RenderIf show={isOpen}>
        <Box
          sx={{
            width: item.content[0]._type === 'block' && bp > 1 ? '50%' : '100%',
            py: item.content[0]._type === 'formBlock' || item.content[0]._type === 'block' ? '2em' : 0,
            overflow: 'hidden',
          }}
        >
          <DefaultBlocks
            parentRoute={item?.slug?.current}
            blocks={item.content}
            reverseColor={true}
          />
          <Text as="p" variant="copyReverse"></Text>
        </Box>
      </RenderIf>
      {(item.content[0]._type === 'formBlock' || item.content[0]._type === 'block') && <Line color="alwaysBlack" hrHeight="1px" />}
    </Box>
  )
}

export { MenuPage }

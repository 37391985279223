import { ExplodedWord } from './ExplodedWord'
import { ExplodedWords } from './ExplodedWords'

const ExplodedLine = ({
  children,
  ...props
}) => {
  if (children.indexOf(' ') > 0) {
    return (
      <ExplodedWords
        {...props}
      >
        {children}
      </ExplodedWords>
    )
  }
  if(children.indexOf(' ') > 0) {
    <ExplodedWords
      {...props}
    >
      {children.replace(/ /g, ' ')}
    </ExplodedWords>
  }
  return (
    <ExplodedWord
      {...props}
    >
      {children}
    </ExplodedWord>
  )
}

export {
  ExplodedLine,
  ExplodedLine as default,
}

/** @jsx jsx */
import { forwardRef } from 'react'
import { jsx, Text } from 'theme-ui'

const Button = forwardRef(
  (
    { children, variant = 'smallCopySmallCapsClear', as = 'button', ...props },
    ref,
  ) => {
    return (
      <Text
        {...props}
        sx={{
          variant: `buttons.${variant}`,
        }}
        as={as}
        ref={ref}
      >
        {children}
      </Text>
    )
  },
)

export { Button }

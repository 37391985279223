/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { jsx, Text } from 'theme-ui'

const ProgramButton = () => {
  const {
    toggleMenu,
    menuDisplay,
    setMenuDisplay,
  } = useDarkContext()

  const router = useRouter()


  const word = "program"
  return (
    <Link href="/program" scroll={false} sx={{ textDecoration: 'none' }}>
      <Text
        as="h3"
        variant='largeHeader'
        sx={{
          flexGrow: word.length,
          display: 'flex',
          justifyContent: 'space-between',
          letterSpacing: '0',
          gap: '0.83em',
          lineHeight: '1.5em',
          margin: '2.5rem 0 1.5rem 0',
          color: 'alwaysBlack',
          '&:hover': {
            cursor: 'pointer',
          }
        }}
        onClick={() => {
          if (menuDisplay) {
            setMenuDisplay(false)
          }
        }}
      >
        {word.split('').map((char, index) => (
          <span key={index}>{char}</span>)
        )}
      </Text>
    </Link>
  )
}

export { ProgramButton }

const COOKIE_RESURRECTED = 'resurrected'
const RESURRECT_SEARCH = 'resurrect'

function resurrectionCycleTime() {
  var date = new Date()
  date.setDate(date.getDate() - date.getDay())

  return date.toDateString()
}

export function resurrect() {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(COOKIE_RESURRECTED, resurrectionCycleTime())
  }
}

export function resurrected() {
  if (typeof window !== 'undefined') {
    if ((new URLSearchParams(window?.location?.search)).has(RESURRECT_SEARCH)) {
      return true
    }

    return window.localStorage.getItem(COOKIE_RESURRECTED) === resurrectionCycleTime()
  }

  return false
}

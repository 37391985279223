/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, useColorMode, Text, Box, Container, Flex } from 'theme-ui'
import DatePicker from './DatePicker'
import { Button } from 'components/buttons/Button'
import { FilterCheck } from './FilterCheck'
import { useBreakpointIndex } from '@theme-ui/match-media'
import InputSwitchboard from 'components/form/InputSwitchBoard'
import { LockedWord } from 'components/LockedWord'
import { Line } from 'components/Line'
import { useForm } from 'react-hook-form'
import { useRef, useState } from 'react'

const HeaderFilterByMobile = ({ id }) => {
  const {
    filterNames,
    dateFilters,
    tagFilterBy,
    setTagFilterBy,
    clearFilters,
    filterByOpen,
    setFilterByOpen,
    searchValue,
    setSearchValue,
    filteredEvents,
  } = useDarkContext()
  const [colorMode] = useColorMode()

  const updateFilters = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    dataLayer.push({
      event: `custom.filter.${value ? 'on' : 'off'}`,
      action: `Article Filter`,
      parentLabel: 'Filter By',
      label: name,
      data: {
        mode: colorMode,
      },
    })

    if (name === 'ticketed' && value && tagFilterBy['free']) {
      tagFilterBy['free'] = false
    }
    if (name === 'free' && value && tagFilterBy['ticketed']) {
      tagFilterBy['ticketed'] = false
    }
    const updatedFilters = {
      ...tagFilterBy,
      [name]: value,
    }
    // console.log(name, value)
    setTagFilterBy(updatedFilters)
  }

  const input = {
    _key: '0',
    _type: 'searchTextInput',
    title: 'Keyword Search',
    value: searchValue === ''? '' : searchValue,
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    trigger,
    errors,
  } = useForm({ mode: 'onChange' })

  const errorAnchor = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [started, setStarted] = useState(false)

  const inputProps = {
    control,
    watch,
    register,
    getValues,
    trigger,
    errors,
    errorAnchor,
    isSubmitting,
    isComplete,
  }

  const tagFilterKeysArray = Object.keys(tagFilterBy).filter(name => {
    return (name !== 'saved' ? !!tagFilterBy[name] : false)
  })
  const showClear = dateFilters.length > 0 || tagFilterKeysArray.length > 0 || searchValue.length > 0

  const bp = useBreakpointIndex({ defaultIndex: 2 })

  return (
    <Container
      variant="noPaddingContainer"
      sx={{
        height: '100vh',
        backgroundColor: 'black',
        zIndex: '999',
      }}
    >
      <LockedWord title="filter" fontVariant='largeHeader' lineHeight='1.5em' margin='1.5rem 0 1.5rem 0' textColor='grey' wordGap='0' charGap='0.5em' />
      <Line color="grey" hrHeight="1px" />
      <Flex
        sx={{
          justifyContent: "space-between",
          padding: "1.5rem 0",
        }}
      >
        {filterNames.map(filterName => (
          <div
            sx={{
              display: 'flex',
            }}
            key={filterName}
          >
            <FilterCheck
              title={filterName}
              type={'checkbox'}
              checked={tagFilterBy[filterName]}
              onChange={updateFilters}
              id={`filter-${filterName}`}
            />
          </div>
        ))}
      </Flex>
      <Line color="grey" hrHeight="1px" />
      <Flex
        sx={{
          alignSelf: "center",
        }}
      >
        <form
          // onSubmit={handleSubmit(onSubmit, onError)}
          autoComplete="off"
          onSubmit={() => {
            setFilterByOpen(!filterByOpen)
          //   event.preventDefault()
          }}
          onChange={() => {
            if (getValues(input._key).length >= 3) {
              setSearchValue(getValues(input._key))
            }
            if (getValues(input._key).length === 0) {
              setSearchValue("")
            }
          }}
          sx={{
            padding: "1.5rem 0",
            width: '100%',
          }}
        >
          <Box
          sx={{
            width: '100%',
          }}>
            <InputSwitchboard
              input={input}
              isSubmitting='true'
              uniqueId='123456'
              variant='tinySearchMobile'
              color='grey'
              {...inputProps}
            />
          </Box>
        </form>
      </Flex>
      <Line color="grey" hrHeight="1px" />
      <DatePicker />
      <Flex
        sx={{
          justifyContent: "space-evenly",
          padding: "1.5rem 0",
        }}
      >
        <Box
        >
          <Button
            variant="smallReverse"
            sx={{
              width: '100%',
              mr: 0,
              textAlign: 'center',
              color: 'grey',
              fontWeight: 'bold',
            }}
            onClick={() => { clearFilters(); setFilterByOpen(!filterByOpen) }}
          >
            {showClear ? "Clear" : "Close"}
          </Button>
        </Box>
        <Box
        >
          <Button
            variant="smallReverse"
            disabled={!showClear}
            sx={{
              cursor: !showClear || filteredEvents === 0 ? 'not-allowed' : 'pointer',
              textDecoration: !showClear || filteredEvents === 0 ? 'line-through' : 'none',
              // border:'1px solid grey',
              width: '100%',
              mr: 0,
              textAlign: 'center',
              // color: 'grey',
              // fontWeight: 'bold',
            }}
            onClick={() => setFilterByOpen(!filterByOpen)}
          >
            {"Apply" + (showClear ? (" (" + filteredEvents + ")") : '')}
          </Button>
        </Box>
      </Flex>
    </Container>
  )
}

export { HeaderFilterByMobile }

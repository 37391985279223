/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { DateEvent } from 'components/program/DateEvent'
import { ListEvent } from 'components/program/ListEvent'
import { NoEvents } from 'components/program/NoEvents'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { Fragment, useEffect, useState } from 'react'
import { jsx, Grid, Flex, Text } from 'theme-ui'
import { useRouter } from 'next/router'
import { Line } from 'components/Line'
import { normalizeSearch, searchTerm } from 'components/global/helpers/searchTerm'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { hobartTime } from 'lib/util/time'
// TODO: Determine if timezone is required

const Events = () => {
  const {
    home: { events },
  } = useGlobalStaticData()

  const {
    viewBy,
    tagFilterBy,
    dateFilters,
    setDateFilters,
    favourites,
    viewByOpen,
    filterByOpen,
    setFilterByOpen,
    searchValue,
    setFilteredEvents,
  } = useDarkContext()
  const bp = useBreakpointIndex(2)

  // console.log(searchValue)
  // Create array of filters
  const tagFilterKeysArray = Object.keys(tagFilterBy)
  // filter down tagfilters relative to selected checkboxes
  const tagFilters = tagFilterKeysArray.filter(tag => {
    return tagFilterBy[tag]
  })

  const router = useRouter()
  const date = router.query.date
  if (date) {
    const today = hobartTime().format('YYYY-MM-DD')
    if (date === 'today') {
      if (today >= "2022-06-08" && today <= "2022-06-22") {
        setDateFilters([today])
      }
    }
    else if (date === 'threedays') {
      const tomorrow = hobartTime().add(1, 'day').format('YYYY-MM-DD')
      const twodays = hobartTime().add(2, 'day').format('YYYY-MM-DD')
      if (today >= "2022-06-06" && today <= "2022-06-22") {
        setDateFilters([today, tomorrow, twodays])
      }
    }
    router.query.date = ""
    if (bp > 1) {
      setFilterByOpen(true)
    }
  }

  const [isProgram, setIsProgram] = useState(false)

  useEffect(() => {
    setIsProgram(
      router.query.slug &&
      (router.query.slug === 'program' || router.query.slug[0] === 'program'),
    )
  }, [router.query])

  // Filter events from date and tag input
  const filteredEvents = events.filter(event => {
    const dateFiltered =
      dateFilters.length > 0 && !!event.eventDates
        ? event.eventDates.some(date => {

          const startDate = hobartTime(date.eventStart).format('YYYY-MM-DD')
          const endDate = hobartTime(date.eventEnd).format('YYYY-MM-DD')

          return dateFilters.some(filter => {
            const filterDate = hobartTime(filter).format('YYYY-MM-DD')

            return (startDate <= filterDate && endDate >= filterDate)
          })
          // return dateFilters.includes(eventDate)
        })
        : true

    const searchEvent = searchTerm(event)
    const searchFiltered =
      searchValue.length > 0
        ? searchValue.split(' ').every(search => {
          return searchEvent.includes(normalizeSearch(search))
        })
        : true

    const tagFiltered =
      tagFilters.length > 0
        ? tagFilters.every(tag => {
          // Show items favourited/saved by the user (user data, not item tag)
          if (tag === 'saved') {
            return !!favourites[event._id]
          }

          // Show exclusive items (item attribute, not item tag)
          if (tag === 'exclusives') {
            return !!event.exclusive || !!event.jointExclusive
          }

          // Show ticketed items (item attribute, not item tag)
          if (tag === 'ticketed') {
            return !!event.ticketedEvent
          }

          if (tag === 'free') {
            return !event.ticketedEvent
          }

          if (tag === 'world premiere') {
            return !!event.worldPremiere
          }

          // Otherwise check that event tag includes the selected tag
          return !event.tags
            ? false
            : event.tags.some(
              tagoption =>
                tagoption.value.replace(' ', '').toLowerCase() ===
                tag.toLowerCase(),
            )
        })
        : true

    // If both are true then keep event in array
    return tagFiltered && dateFiltered && searchFiltered
  })
  setFilteredEvents(filteredEvents.length)
  const eventsByDate = {} // Object with date labels and time-grouped event ID arrays
  const allDates = [] // Dates with events, sorted
  const eventsById = {} // Events accessible by ID


  return (
    <Grid
      columns={1}
      variant="default"
      gap={[0, 0]}
      // inert={!isProgram || filterByOpen || viewByOpen ? 'true' : undefined}//
      aria-live="polite"
    >
      {viewBy === 'lineup' ? (
        filteredEvents?.length > 0 ? (
          filteredEvents.map((elem, index) => {
            return <ListEvent key={elem._id} elem={elem} index={index + 1} />
          })
        ) : (
          <NoEvents />
        )
      ) : allDates?.length > 0 ? (
        allDates.map(date => {
          return (
            <Fragment key={date}>
              <Flex
                sx={{
                  justifyContent: 'center',
                  pt: 1,
                  pb: '5px',
                }}
              >
                <Text as="h3" variant="copyCaps">
                  {eventsByDate[date].label}
                </Text>
              </Flex>
              <Line hrHeight='1px' color="black" />
              {Object.keys(eventsByDate[date].times)
                .sort()
                .map(time => {
                  return eventsByDate[date].times[time].map(id => {
                    return (
                      <DateEvent
                        key={date + '-' + time + '-' + id}
                        elem={eventsById[id]}
                        eventTime={eventsByDate[date].timeLabels[id]}
                        eventDate={eventsByDate[date].day}
                      />
                    )
                  })
                })}
            </Fragment>
          )
        })
      ) : (
        <NoEvents />
      )}
    </Grid>
  )
}

export { Events }

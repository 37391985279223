/** @jsx jsx */
import { useBreakpointIndex } from '@theme-ui/match-media'
import { ColormodeIcon } from 'components/global/icons/ColormodeIcon'
import { Flex, jsx, useColorMode } from 'theme-ui'

const ColorModeButton = ({
  color,
}) => {
  const [colorMode, setColorMode] = useColorMode()
  const bp = useBreakpointIndex({ defaultIndex: 2 })
  return (
    <Flex>
      {bp > 1 ?
        <button
          onClick={() => {
            /* eslint-disable no-undef */
            dataLayer.push({
              event: 'custom.content.background',
              group: 'Background',
              label: `${colorMode === 'dark' ? 'light' : 'dark'}`,
              data: {
                mode: `${colorMode === 'dark' ? 'dark' : 'light'}`,
              },
            })
            /* eslint-enable no-undef */
            setColorMode(colorMode === 'dark' ? 'light' : 'dark')
          }}
          sx={{
            variant: 'buttons.smallCopySmallCapsClear',
            color: color,
            padding: '0px',
            margin: '0px',
          }}
        >
          <Flex>
            {colorMode === 'dark' ? 'Dark' : 'Light'}
          </Flex>
        </button>
        :
        <button
          onClick={() => {              
            /* eslint-disable no-undef */
            dataLayer.push({
              event: 'custom.content.background',
              group: 'Background',
              label: `${colorMode === 'dark' ? 'light' : 'dark'}`,
              data: {
                mode: `${colorMode === 'dark' ? 'dark' : 'light'}`,
              },
            })
            /* eslint-enable no-undef */
            setColorMode(colorMode === 'dark' ? 'light' : 'dark')
          }}
          sx={{
            variant: 'buttons.smallCopySmallCapsClear',
            color: color,
            padding: '0px',
            margin: '0px',
          }}
        >
          <ColormodeIcon size="2em" iconColor={color} />
        </button>
      }
    </Flex>
  )
}

export { ColorModeButton }

/** @jsx jsx */
import { toSentenceCase } from 'lib/general/toSentenceCase'
import { useRef } from 'react'
import { jsx, Text, Input, useColorMode } from 'theme-ui'

const FilterCheck = ({ title, checked, onChange, type, id, color = 'grey', checkedColor = 'red', variant = 'smallCopySmallCapsWide' }) => {
  const { current: filterTitle } = useRef(toSentenceCase(title))
  const [colorMode] = useColorMode()

  return (
    <div
      sx={{
        position: 'relative',
      }}
    >
      <label
        htmlFor={id}
        sx={{
          variant: 'label.checkboxFilter',
          padding:'0px',
        }}
      >
        <Text variant={variant}
          sx={{
            color: colorMode === 'dark' && checked ? 'red' : (checked ? 'black' : color),
            backgroundColor: colorMode === 'light' && checked ? 'grey' : 'none',
            fontWeight: 'normal',
          }}
        >
          {filterTitle}
        </Text>
        <Input
          type={type}
          id={id}
          name={title}
          value={title}
          checked={checked}
          onChange={onChange}
          sx={{
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
            height: 0,
            width: 0,
            left: 0,
            top: 0,
            padding: 0,
            fontWeight: 'normal',
          }}
        />
      </label>
    </div>
  )
}

export { FilterCheck }

/** @jsx jsx */
import { Button, jsx } from 'theme-ui'

const SavedIcon = ({
  saved = false,
  size = '1em',
  iconColor = 'black',
  fillColor = 'red',
  show = 'default',
  ...props
}) => {
  return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      align="center"
      width="1em"
      height="1em"
      viewBox="0 0 105 105"
      sx={{
        display: 'inline-block',
        fontSize: size,
        fill: saved ? fillColor : 'none',
        stroke: saved ? fillColor : iconColor,
      }}
    >
      <polygon
        points="50,15 89,85 11,85"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
    
  )
}

export { SavedIcon }

/** @jsx jsx */
import { jsx } from 'theme-ui'

const RiverleeLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 80.78"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <g>
          <path
            d="M119.69,60.21l-8.56-12.77h-6.9v12.77h-8.78V20.29h18.25c9.41,0,15.05,4.96,15.05,13.17v0.11
			c0,6.44-3.48,10.49-8.55,12.37l9.75,14.26H119.69z M119.85,33.92c0-3.76-2.62-5.71-6.9-5.71h-8.73v11.46h8.9
			c4.28,0,6.73-2.28,6.73-5.65V33.92z"
          />
        </g>
        <rect x="154.17" y="20.29" width="8.78" height="39.92" />
        <polygon points="210.51,60.49 202.76,60.49 186.62,20.29 196.31,20.29 206.75,48.4 217.18,20.29 226.65,20.29 	" />
        <polygon
          points="249.9,60.21 249.9,20.29 280.01,20.29 280.01,28.1 258.63,28.1 258.63,36.2 277.45,36.2 277.45,44.01 
		258.63,44.01 258.63,52.39 280.3,52.39 280.3,60.21 	"
        />
        <g>
          <path
            d="M329.45,60.21l-8.55-12.77h-6.9v12.77h-8.78V20.29h18.25c9.41,0,15.06,4.96,15.06,13.17v0.11
			c0,6.44-3.48,10.49-8.55,12.37l9.75,14.26H329.45z M329.62,33.92c0-3.76-2.62-5.71-6.9-5.71h-8.73v11.46h8.9
			c4.28,0,6.73-2.28,6.73-5.65V33.92z"
          />
        </g>
        <polygon points="363.54,60.21 363.54,20.29 372.32,20.29 372.32,52.22 392.22,52.22 392.22,60.21 	" />
        <polygon
          points="415.94,60.21 415.94,20.29 446.05,20.29 446.05,28.1 424.66,28.1 424.66,36.2 443.48,36.2 
		443.48,44.01 424.66,44.01 424.66,52.39 446.33,52.39 446.33,60.21 	"
        />
        <polygon
          points="469.61,60.21 469.61,20.29 499.71,20.29 499.71,28.1 478.33,28.1 478.33,36.2 497.15,36.2 
		497.15,44.01 478.33,44.01 478.33,52.39 500,52.39 500,60.21 	"
        />
        <rect y="35.33" width="32.83" height="2.69" />
        <rect x="4.21" y="40.51" width="28.62" height="2.69" />
        <rect x="8" y="45.7" width="24.83" height="2.69" />
        <rect x="11.78" y="50.88" width="21.04" height="2.69" />
        <rect x="15.57" y="56.07" width="17.26" height="2.69" />
        <rect x="19.36" y="61.25" width="13.47" height="2.69" />
        <rect x="30.14" y="66.44" width="2.69" height="14.34" />
        <rect x="30.13" width="2.69" height="32.83" />
        <rect x="24.95" y="4.21" width="2.69" height="28.62" />
        <rect x="19.76" y="8" width="2.69" height="24.83" />
        <rect x="14.58" y="11.78" width="2.69" height="21.04" />
        <rect x="9.39" y="15.57" width="2.69" height="17.26" />
        <rect x="4.21" y="19.36" width="2.69" height="13.47" />
        <rect x="35.33" y="30.14" width="32.83" height="2.69" />
        <rect x="35.33" y="24.95" width="28.62" height="2.69" />
        <rect x="35.33" y="19.76" width="24.83" height="2.69" />
        <rect x="35.33" y="14.58" width="21.04" height="2.69" />
        <rect x="35.33" y="9.39" width="17.26" height="2.69" />
        <rect x="35.33" y="4.21" width="13.47" height="2.69" />
        <rect x="35.33" y="35.33" width="2.69" height="45.46" />
        <rect x="40.52" y="35.33" width="2.69" height="28.62" />
        <rect x="45.7" y="35.33" width="2.69" height="24.83" />
        <rect x="50.89" y="35.33" width="2.69" height="21.04" />
        <rect x="56.07" y="35.33" width="2.69" height="17.26" />
        <rect x="61.26" y="35.33" width="2.69" height="13.47" />
      </g>
    </svg>
  )
}

export { RiverleeLogo }

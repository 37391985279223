/** @jsx jsx */
import { jsx } from 'theme-ui'

const CityOfHobartLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 345.8"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <g>
          <path
            d="M0,309.8c0-14.7,10.8-24.9,24.2-24.9c4.8,0,8.8,1.1,11.9,2.7l2.9-3.1h0.7v16.1h-0.9
          c-3.6-9.3-8.4-14.5-15.5-14.5c-7.9,0-14.6,7.1-14.6,22.9c0,15.5,8.3,21.8,16.3,21.8c7.6,0,12.9-4.8,15.9-11.1l1.1,0.6
          c-2.8,6.5-8.7,13.1-19.4,13.1C9.1,333.3,0,324.1,0,309.8"
          />
          <path
            d="M49.7,291.5c0-2.7,1.7-4.6,4.3-4.6c2.6,0,4.4,1.7,4.4,4.4s-1.7,4.5-4.4,4.5C51.4,295.8,49.7,294.1,49.7,291.5
           M46,331.4c3.9-0.1,4.8-0.5,4.8-2.5v-22.8c0-2-1-3.2-4.8-3.3v-0.8l11.8-0.5v27.4c0,2,0.7,2.5,4.8,2.5v0.8H46V331.4z"
          />
          <path
            d="M109,301.9v0.8c4.8,0.7,5.7,2.2,3.6,7.6l-5.9,15.1l-7.8-18.5c-1.1-2.5-0.6-3.9,3.4-4.2v-0.8H90.6h-4.8H77
          v-11.1l-7,2.5v8.4l-6.2,1.5v0.9H70v20c0,6.1,2.8,8.8,8,8.8c4.2,0,7.1-2.3,9.2-6.6l-0.8-0.5c-1.7,3.1-3.6,4.4-5.6,4.4
          c-2.7,0-3.8-1.5-3.8-5.5v-20.7h9.3c3.3,0,4.4,1.5,6,4.9l11.1,24.3l-0.4,0.9c-3.2,8-5.8,9.9-8.4,10.2c0.9-0.7,1.5-1.9,1.5-3.3
          c0-2.3-1.7-4-3.7-4c-2.5,0-3.8,2-3.8,4c0,2.6,2,4.7,5.2,4.7c3.8,0,7-2.4,10.5-10.9l10.9-26.5c1.5-3.5,3.6-5.4,5.9-5.7v-0.8H109z"
          />
          <path
            d="M154.2,310.1c0-5-1.6-7.7-5.2-7.7c-7.3,0-11.2,14.4-11.2,21.4c0,5,1.7,7.6,5.2,7.6
          C150.3,331.5,154.2,317.1,154.2,310.1 M160.8,313.9c0,8.9-6.6,18.9-17.7,18.9c-7.1,0-11.9-5-11.9-12.7c0-9,6.6-18.9,17.8-18.9
          C156.1,301.2,160.8,306.2,160.8,313.9"
          />
          <path
            d="M179.6,303.3l-4.8,22.5c-3.2,15.1-7.2,19.7-15.1,19.7c-4.7,0-7.4-1.9-7.4-4.9c0-2.1,1.5-3.8,3.8-3.8
          c2.3,0,3.6,1.7,3.6,3.6c0,1.4-0.8,2.7-1.8,3.3c0.5,0.3,1.1,0.6,2.1,0.6c2.6,0,4.4-1.5,6.2-10l6.8-31h-7.5l0.5-1.5h7.2l0.7-2.9
          c2.5-10.2,7.8-14.7,14.3-14.7c5,0,8.1,2.3,8.1,5.8c0,2.3-1.3,4.1-3.9,4.1c-2.3,0-3.8-1.5-3.8-3.9c0-1.3,0.7-2.9,1.6-3.7
          c-0.5-0.7-1.5-1.1-2.4-1.1c-2.9,0-5,2.6-6.7,9.7l-1.5,6.6h8l-0.5,1.5H179.6z"
          />
        </g>
        <polygon
          points="209.8,285.8 220,285.8 220,304.1 238.8,304.1 238.8,285.8 249,285.8 249,332.2 238.8,332.2 
        238.8,313.6 220,313.6 220,332.2 209.8,332.2 	"
        />
        <g>
          <path
            d="M296.8,309.1V309c0-8-5.8-14.6-14.1-14.6c-8.2,0-13.9,6.5-13.9,14.5v0.1c0,8,5.8,14.6,14.1,14.6
          C291.1,323.6,296.8,317.1,296.8,309.1 M258.1,309.1V309c0-13.2,10.4-24,24.7-24c14.3,0,24.6,10.7,24.6,23.9v0.1
          c0,13.2-10.4,24-24.7,24C268.4,333,258.1,322.3,258.1,309.1"
          />
          <path
            d="M338.3,313h-11.8v10.2h12.1c4.5,0,7.2-1.6,7.2-5v-0.1C345.9,315,343.6,313,338.3,313 M343.3,299.5
          c0-3.1-2.4-4.8-6.7-4.8h-10.1v9.8h9.4c4.5,0,7.4-1.5,7.4-4.9V299.5z M316.6,285.8h21.6c5.3,0,9.5,1.5,12.1,4.1
          c2.1,2.1,3.2,4.7,3.2,7.9v0.1c0,5.2-2.8,8.2-6.1,10c5.4,2.1,8.7,5.2,8.7,11.4v0.1c0,8.5-6.9,12.7-17.4,12.7h-22.1V285.8z"
          />
        </g>
        <path
          d="M391.1,312.8l-6.2-15.1l-6.2,15.1H391.1z M380.4,285.4h9.4l19.9,46.8H399l-4.2-10.4h-19.6l-4.2,10.4h-10.4
        L380.4,285.4z"
        />
        <g>
          <path
            d="M437.4,308.3c5,0,7.8-2.7,7.8-6.6v-0.1c0-4.4-3-6.6-8-6.6h-10.2v13.3H437.4z M416.9,285.8h21.2
          c5.9,0,10.5,1.7,13.5,4.7c2.6,2.6,4,6.2,4,10.6v0.1c0,7.5-4,12.2-9.9,14.4l11.3,16.6h-11.9l-10-14.9h-8v14.9h-10.2V285.8z"
          />
        </g>
        <polygon points="461.5,285.8 461.5,295.2 475.7,295.2 475.7,332.2 485.9,332.2 485.9,295.2 500,295.2 500,285.8 	" />
        <g>
          <path d="M127,122.5c17.3,4.5,26,5.4,43.3,6v43.3c-17.3-0.6-26-1.5-43.3-6V122.5z" />
          <path d="M228.4,125.3c43-5.6,86.5-12.8,129.8-5.9v43.3c-43.3-6.9-86.8,0.3-129.8,5.9V125.3z" />
          <path d="M177.7,128.6c17.3,0.1,26-0.7,43.3-2.6v97.6c-17.3,1.9-26,2.7-43.3,2.6V128.6z" />
          <path d="M177.7,2.6c17.3,0.1,26-0.7,43.3-2.6v68c-17.3,1.9-26,2.7-43.3,2.6C177.7,44.2,177.7,29,177.7,2.6" />
          <path d="M329.7,65.8c17.3,0.6,25.9,1.5,43.3,6v43.3c-17.3-4.5-26-5.4-43.3-6V65.8z" />
          <path d="M141.8,75c43.3,6.9,86.9-0.3,129.8-5.9v43.3c-43,5.6-86.6,12.8-129.8,5.9V75z" />
          <path d="M279,169.6c17.3-1.9,26-2.7,43.2-2.6v68c-17.3-0.1-25.9,0.7-43.2,2.6V169.6z" />
          <path
            d="M279,13.9c17.3-1.9,26-2.7,43.2-2.6c0,36.3,0,61.4,0,97.7c-17.3-0.1-25.9,0.7-43.2,2.6
          C279,75.3,279,50.1,279,13.9"
          />
        </g>
      </g>
    </svg>
  )
}

export { CityOfHobartLogo }

import { Text } from "theme-ui";

const ExplodedWord = ({
  children,
  variant,
  charGap,
  sx,
  as = 'h3',
}) => {
  const wordEls = children.split('').map(
    (char, index) => (
      <span key={index}>{char}</span>
    )
  )

  return (
    <Text
      as={as}
      variant={variant}
      sx={{
        // We don't support overriding any of the required `sx` properties
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: charGap,
        letterSpacing: 0,
      }}
    >
      {wordEls}
    </Text>
  )
}

export {
  ExplodedWord,
  ExplodedWord as default,
}

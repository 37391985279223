/** @jsx jsx */
import { useBreakpointIndex } from '@theme-ui/match-media'
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { HeaderFilterBy } from 'components/global/HeaderFilterBy'
import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { jsx, Text, Flex, Grid, useColorMode, Container } from 'theme-ui'
import { CrossSVG } from './Cross'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { ColorModeButton } from 'components/buttons/ColorModeButton'
import { backgroundHeader } from './helpers/backgroundHeader'
import Link from 'next/link'
import { FilterIcon } from 'components/global/icons/FilterIcon'
import { MenuIcon } from './icons/MenuIcon'
import { SavedIcon } from './icons/SavedIcon'
import { RenderIf } from 'components/RenderIf'
import { HeaderFilterByMobile } from './HeaderFilterByMobile'
import { crossSize } from 'lib/theme'

const Header = ({ page = 'program', isBackgroundInvisible = true, }) => {
  const {
    toggleMenu,
    filterByOpen,
    setFilterByOpen,
    tagFilterBy,
    setTagFilterBy,
    dateFilters,
    favourites,
    menuDisplay,
    setMenuDisplay,
    searchValue,
    clearFilters,
  } = useDarkContext()
  const [colorMode] = useColorMode()

  const router = useRouter()
  const isHome = router.route === '/'
  const [isProgram, setIsProgram] = useState(false)
  //const [isSaved, setIsSaved] = useState(false)

  const backgroundColor = backgroundHeader(page, colorMode, isBackgroundInvisible, filterByOpen);

  const handleSavedClick = () => {
    /* eslint-disable no-undef */
    dataLayer.push({
      event: 'custom.generic.interaction',
      action: `SAVED | CLICK`,
      label: `Saved | ${!!tagFilterBy['saved']}`,
      data: {
        mode: colorMode,
      },
    })

    //filterByOpenClose(!tagFilterBy['saved'])
    setTagFilterBy({
      ...tagFilterBy,
      saved: !tagFilterBy['saved'],
    })
  }


  const filterByOpenClose = o => {
    setFilterByOpen(o)
    //turns tags into string
    if (!o) {
      let filterToString = Object.keys(tagFilterBy)
        //get only true values
        .map((value, i) => (tagFilterBy[value] ? `${value}` : null))
        //filter for non null
        .filter((v, i) => v)
        //join string together
        .join('_')

      let dates = dateFilters
        .map((v, i) => {
          let d = new Date(v)
          return d.getDate()
        })
        .join('_')

      let finalFilterString = filterToString
        ? dates
          ? `${filterToString}_${dates}`
          : filterToString
        : dates

      let routePrevious = window.location.pathname
      let routeTitle = 'Program'
      let routeType = 'Filter'
      let days = dateFilters.length
      let fields = Object.values(tagFilterBy).reduce((a, item) => a + item, 0)
      let routeCurrent = `${window.location.pathname}?filter=${finalFilterString}&days=${days}&fields=${fields}`

      dataLayer.push({
        event: 'custom.route.change',
        route: {
          current: routeCurrent,
          previous: routePrevious,
          title: routeTitle,
          type: routeType,
        },
        data: {
          days: days,
          fields: fields,
          mode: colorMode, // dark or light
        },
      })
      clearFilters()
    }
  }

  const closeFilterManually = o => {
    if (!o) {
      clearFilters()
    }
  }



  const [open, setOpen] = useState(false)
  const [openComplete, setOpenComplete] = useState(true)
  // const [open, setOpen] = useState(isHome ? true : false)
  // const [openComplete, setOpenComplete] = useState(isHome ? false : true)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoMute, setVideoMute] = useState(true)

  const getMode = () => {
    return colorMode === 'default' ? 'dark' : 'light'
  }

  const {
    home: { homeVideo, homeVideoMobile },
  } = useGlobalStaticData()

  const bp = useBreakpointIndex({ defaultIndex: 2 })

  const activeFiltersCount = Object.keys(tagFilterBy).filter(
    filterName => (filterName !== 'saved' ? !!tagFilterBy[filterName] : false),
  ).length + dateFilters.length + (searchValue === '' ? 0 : 1)

  const toggleFilter = () => {
    if (activeFiltersCount > 0 && bp > 1) {
      if (router.asPath === '/program' && !menuDisplay) {
        setFilterByOpen(true)
      }
      else {
        setFilterByOpen(false)
      }
    }
    else {
      setFilterByOpen(false)
    }
  }

  const scrollToTop = () => {
    if (activeFiltersCount > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  var savedCount = 0

  for (let key in favourites) {
    savedCount++
  }

  const height = page === 'event' ? '0' : filterByOpen && bp > 1 ? '10rem' : '3rem';

  const vid = useRef()

  useEffect(() => {
    scrollToTop()
  }, [activeFiltersCount])

  useEffect(() => {
    const handleScroll = () => setOpen(false)
    toggleFilter()
    if (open) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [open, setOpen, router.asPath, menuDisplay])

  useEffect(() => {
    toggleFilter()
  }, [router.asPath])


  return (
    <>
      <Container
        variant="noPaddingContainer"
        sx={{
          height: height,
        }}
      >
        <>
          <Flex
            as={Container}
            sx={{
              position: 'fixed',
              flexDirection: 'column',
              top: 0,
              mb: 0,
              pb: 0,
              zIndex: 500,
              background: backgroundColor,
            }}
          >
            <div>
              {/* Cross */}
              <Link href='/program' scroll={false}>
                <div
                  onClick={() => {
                    if (menuDisplay) {
                      setMenuDisplay(false)
                    }
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    maxHeight: 'fill-available',
                    height: crossSize,
                    width: crossSize,
                    paddingBottom: '0px',
                    cursor: 'pointer',
                  }}
                >
                  <CrossSVG />
                </div>
              </Link>

              {/* Cross End */}
              {/* Top Menu */}
              <div
                sx={{
                  mb: 0,
                }}
              >
                <Flex
                  sx={{
                    flexDirection: 'column',
                    mb: 0,
                  }}
                >
                  <Flex
                    variant="default"
                    sx={{
                      pt: [3, null, 4, 4, 4],
                      pb: filterByOpen ? "0em" : [3, null, 5, 6, 8],
                      color: 'red',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Flex
                      sx={{
                        width: '33%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                      }}>{/* Menu Column */}
                      {bp > 1 ? <Flex>
                        <Button
                          onClick={() => {
                            /* eslint-disable no-undef */
                            dataLayer.push({
                              event: 'custom.generic.interaction',
                              action: `MENU BUTTON PRESSED`,
                              label: undefined,
                              data: {
                                mode: colorMode,
                              },
                            })
                            /* eslint-enable no-undef */
                            toggleMenu(router.asPath)
                          }}
                          sx={{ color: page === 'menu' ? 'red' : (page === 'form' ? 'alwaysBlack' : page === 'program' ? 'grey' : (page == 'event' && !isBackgroundInvisible ? 'alwaysBlack' : (page === 'today'? 'grey' : 'imageRed'))) }}
                        >
                          Menu
                        </Button>
                      </Flex> : <Flex>
                        <Button
                          onClick={() => {
                            /* eslint-disable no-undef */
                            dataLayer.push({
                              event: 'custom.generic.interaction',
                              action: `MENU BUTTON PRESSED`,
                              label: undefined,
                              data: {
                                mode: colorMode,
                              },
                            })
                            /* eslint-enable no-undef */
                            toggleMenu(router.asPath)
                          }}
                          sx={{
                            color: page === 'menu' ? 'red' : (page === 'form' ? 'alwaysBlack' : page === 'program' ? 'grey' : (page == 'event' && !isBackgroundInvisible ? 'alwaysBlack' : 'red')),
                            padding: '0px',
                          }}
                        >
                          <MenuIcon size="2em" color={page === 'menu' ? 'red' : (page === 'form' ? 'alwaysBlack' : page === 'program' ? 'grey' : (page == 'event' && !isBackgroundInvisible ? 'alwaysBlack' : (page === 'today'? 'grey' : 'imageRed')))} />
                        </Button>
                      </Flex>
                      }
                      {/* Filter Column */}
                      {page === 'program' && (bp > 1 ?
                        <Flex>
                          <Button

                            aria-expanded={filterByOpen}
                            onClick={() => {
                              filterByOpenClose(!filterByOpen)
                              closeFilterManually(!filterByOpen)
                            }
                            }
                          >
                            <Text
                              as="span"
                              sx={{
                                color: filterByOpen || activeFiltersCount > 0 ? 'red' : 'grey'
                              }}
                            >
                              Search
                            </Text>
                            {/* {!!activeFiltersCount && ` (${activeFiltersCount})`} */}
                          </Button>
                        </Flex> :
                        <Flex>
                          <Button
                            onClick={() => {
                              dataLayer.push({
                                event: 'custom.generic.interaction',
                                action: `FILTER BUTTON PRESSED`,
                                label: undefined,
                                data: {
                                  mode: colorMode,
                                },
                              })
                              filterByOpenClose(!filterByOpen)
                            }}
                            sx={{
                              padding: '0px',
                            }}
                          >
                            <FilterIcon size="2em" iconColor={filterByOpen || activeFiltersCount > 0 ? 'red' : 'grey'} />
                          </Button>
                        </Flex>
                      )}
                    </Flex>

                    {/* Empty column for equal spacing */}
                    {/* Saved Column */}
                    <Flex
                      sx={{
                        width: '33%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      <Flex>
                        {page === 'program' && (bp > 1 ? <Flex>
                          {/* Saved Column */}
                          <Button onClick={handleSavedClick}>
                            <Text as="span" sx={{ color: tagFilterBy['saved'] ? 'red' : 'grey' }}>Saved{!!savedCount && ` (${savedCount})`}
                            </Text>
                          </Button>
                        </Flex> :
                          <Flex>
                            {/* Saved Column */}
                            <Button onClick={handleSavedClick}
                              sx={{
                                paddingBo: '0px',
                              }}
                            >
                              <SavedIcon iconColor={tagFilterBy['saved'] ? 'red' : 'grey'} size="2em" />
                            </Button>
                          </Flex>
                        )}
                      </Flex>

                      {/* Color mode Column */}
                      <ColorModeButton color={page === 'program' ? 'grey' : (page === 'form' ? 'alwaysBlack' : page === 'menu' ? 'alwaysBlack' : (page == 'event' && !isBackgroundInvisible ? 'alwaysBlack' : (page === 'today'? 'grey' : 'imageRed')))} />
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            </div>
            {/* Bottom Menu, need for filter space */}
            <div>
              {bp > 1 ?
                <RenderIf show={filterByOpen}>
                  <Container
                    variant="noPaddingContainer"
                    sx={{
                      mb: 0,
                      height: '7rem',
                      display: 'inline-block',
                    }}
                  >
                    <HeaderFilterBy />
                  </Container>
                </RenderIf> :
                <RenderIf show={filterByOpen}>
                  <Container
                    variant="noPaddingContainer"
                    sx={{
                      mb: 0,
                      height: '100%',
                      display: 'inline-block'
                    }}
                  >
                    <HeaderFilterByMobile />
                  </Container>
                </RenderIf>
              }
            </div>
          </Flex>
        </>

      </Container>
    </>
  )
}

export { Header }

import theme from 'lib/theme'
import { Button, useThemeUI } from 'theme-ui'

const JointExclusiveIcon = ({
  size = '1em',
  darkColor = '#A0A0A0',
  lightColor = 'black',
}) => {
  const { colorMode } = useThemeUI()
  const color = colorMode === 'light' ? lightColor : darkColor;

  return (
    <Button
      title='Dark Mofo &amp; Vivid Exclusive'
      sx={{
        cursor: 'initial',
        padding:'0px',
        margin:'0px',
        border:'0px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="0.8em"
        height="1em"
        viewBox="0 0 100 100"
        sx={{
          display: 'inline-block',
          fontSize: size,
        }}
      >
        <path
          d="M 50 0
          V 100
          M 0 33
          H 100
          M 0 66
          H 100"
          fill="none"
          stroke={color}
          strokeWidth="1.5"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </Button>

  )
}

export {
  JointExclusiveIcon,
}

/** @jsx jsx */
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, useColorMode, Box, Container, Flex } from 'theme-ui'
import DatePicker from './DatePicker'
import { Button } from 'components/buttons/Button'
import { FilterCheck } from './FilterCheck'
import { useBreakpointIndex } from '@theme-ui/match-media'
import InputSwitchboard from 'components/form/InputSwitchBoard'
import { useForm, Controller } from 'react-hook-form'
import { useRef, useState } from 'react'


const HeaderFilterBy = ({ id }) => {
  const {
    filterNames,
    dateFilters,
    tagFilterBy,
    setTagFilterBy,
    clearFilters,
    searchValue,
    setSearchValue,
  } = useDarkContext()
  const [colorMode] = useColorMode()

  const updateFilters = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    dataLayer.push({
      event: `custom.filter.${value ? 'on' : 'off'}`,
      action: `Article Filter`,
      parentLabel: 'Filter By',
      label: name,
      data: {
        mode: colorMode,
      },
    })

    if (name === 'ticketed' && value && tagFilterBy['free']) {
      tagFilterBy['free'] = false
    }
    if (name === 'free' && value && tagFilterBy['ticketed']) {
      tagFilterBy['ticketed'] = false
    }
    const updatedFilters = {
      ...tagFilterBy,
      [name]: value,
    }
    setTagFilterBy(updatedFilters)
  }

  const tagFilterKeysArray = Object.keys(tagFilterBy).filter(name => {
    return (name !== 'saved' ? !!tagFilterBy[name] : false)
  })
  const showClear = dateFilters.length > 0 || tagFilterKeysArray.length > 0

  const bp = useBreakpointIndex({ defaultIndex: 2 })

  const input = {
    _key: '0',
    _type: 'searchTextInput',
    title: 'keyword search',
    value: searchValue === '' ? '' : searchValue,
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    trigger,
    errors,
  } = useForm({ mode: 'onChange' })

  const errorAnchor = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [started, setStarted] = useState(false)


  const inputProps = {
    control,
    watch,
    register,
    getValues,
    trigger,
    errors,
    errorAnchor,
    isSubmitting,
    isComplete,
  }

  return (
    <Container
      variant="noPaddingContainer"
      sx={{
      }}
    >
      <Flex
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "33%",
          }}
        >
          <Flex
            sx={{
              alignSelf: "flex-end",
              alignContent: 'center',
            }}
          >
            <form
              // onSubmit={handleSubmit(onSubmit, onError)}
              autoComplete="off"
              onSubmit={() => {
                event.preventDefault()
              }}
              onChange={() => {
                if (getValues(input._key).length >= 3) {
                  setSearchValue(getValues(input._key))
                }
                if (getValues(input._key).length === 0) {
                  setSearchValue("")
                }
              }}
            >
              <Flex
                sx={{
                  alignSelf: 'flex-end',
                  alignContent: 'flex-end',
                  width:'20vw',
                  maxWidth:'8.888rem',
                }}
              >
                <InputSwitchboard
                  input={input}
                  isSubmitting='true'
                  uniqueId='123456'
                  variant='tinySearch'
                  color='grey'
                  {...inputProps}
                />
              </Flex>
            </form>
          </Flex>
          {filterNames.slice(0, 1).map(filterName => (
            <div
              sx={{
                display: 'flex',
                alignSelf: "center",
                pt: '0px',
                lineHeight: '1em'
              }}
              key={filterName}
            >
              <FilterCheck
                title={filterName}
                type={'checkbox'}
                checked={tagFilterBy[filterName]}
                onChange={updateFilters}
                id={`filter-${filterName}`}
              />
            </div>
          ))}
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            width: "33%",
          }}
        >
          {filterNames.slice(1, filterNames.length).map(filterName => (
            <div
              sx={{
                display: 'flex',
                alignSelf: "center",
                pt: '0px',
                lineHeight: '1em'
              }}
              key={filterName}
            >
              <FilterCheck
                title={filterName}
                type={'checkbox'}
                checked={tagFilterBy[filterName]}
                onChange={updateFilters}
                id={`filter-${filterName}`}
              />
            </div>
          ))}
        </Flex>
      </Flex>

      <DatePicker />
      {/* {showClear && (
        <Box
          sx={{

          }}
        >
          <Button
            variant="smallCopySmallCapsClear"
            sx={{
              // width: '100%',
              mr: 0,
              border: ['1px solid', '1px solid'],
              textAlign: 'center',
            }}
            onClick={() => clearFilters()}
          >
            Clear Filters
          </Button>
        </Box>
      )
      } */}
    </Container >
  )
}

export { HeaderFilterBy }

/** @jsx jsx */
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { jsx } from 'theme-ui'

const Copy = ({ input }) => {
  const { size } = input
  if (size === 'large') {
    return <DefaultBlocks blocks={input.copy} reverseColor={true} />
  } else if (size === 'medium' || size === 'small') {
    return <DefaultBlocks blocks={input.copy} reverseColor={true} />
  } else {
    return <DefaultBlocks blocks={input.copy} reverseColor={true} />
  }
}

export default Copy

/** @jsx jsx */
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { ProgramButton } from 'components/buttons/programButton'
import { useDarkContext } from 'components/context/DarkContext'
import { MenuItems } from 'components/global/menu/MenuItems'
import { Line } from 'components/Line'
import { RenderIf } from 'components/RenderIf'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { useRouter } from 'next/router'
import { useRef, useEffect } from 'react'
import { jsx, Box, Flex, Text, Grid, useColorMode, Container } from 'theme-ui'
import { Header } from '../Header'

const Menu = () => {
  const { menuDisplay, toggleMenu, setMenuDisplay, parentRoute } = useDarkContext()
  const {
    global: { mainMenu, siteSettings },
  } = useGlobalStaticData()
  const [colorMode] = useColorMode()
  const { menuItems } = mainMenu
  const menuRef = useRef(undefined)

  useEffect(() => {
    if (menuDisplay === true) disableBodyScroll(menuRef.current)
    return () => clearAllBodyScrollLocks()
  }, [menuDisplay])

  const router = useRouter()

  useEffect(() => {
    if (
      router.route === '/program/[slug]' ||
      router.route === '/map/[[...slug]]' ||
      router.route === '/form/[slug]'
      // router.asPath === '/program' ||
      // router.asPath === '/'
    )
      setMenuDisplay(false)
  }, [router.route, setMenuDisplay])

  const menuContent = <MenuItems menuItems={menuItems} parent={parentRoute} />

  return (
    <>
      <RenderIf show={menuDisplay} >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 1499,
            top: 0,
            left: 0,
            // background: 'blue', // blue background for debugging
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => {
            dataLayer.push({
              event: 'custom.generic.interaction',
              action: `CLOSE MENU OUTSIDE`,
              label: undefined,
              data: {
                mode: colorMode,
              },
            })

            toggleMenu(router.asPath)
          }}
        />
        <div
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1500,
            backgroundColor: 'lightGrey', // blue background for debugging
            overflowY: 'scroll',
            overflowX: 'hidden',
            margin: '0px', padding: '0px',
            '&::-webkit-scrollbar': {
              width: '0px',
            },
          }}
          data-nosnippet={menuDisplay ? undefined : true}
          data-current-page={menuDisplay ? true : false}
          ref={menuRef}
          inert={menuDisplay ? undefined : 'true'}
        >
          <Container
            variant="noPaddingContainer"
            sx={{
              height: '3rem',
            }}
          >
            <Flex
              variant="noPaddingContainer"
              sx={{
                position: 'fixed',
                flexDirection: 'column',
                top: 0,
                mb: 0,
                pb: 0,
                zIndex: 500,
              }}
            >
              <Header page='menu'/>
            </Flex>
          </Container>
          <Container
            as={menuDisplay ? 'main' : 'aside'}
          >
            <ProgramButton />
            <Line color="alwaysBlack" hrHeight="1px" />
            {menuContent}
            <MenuFooterBlock siteSettings={siteSettings} />
          </Container>
        </div>
      </RenderIf>

    </>

  )
}

const MenuFooterBlock = ({ siteSettings }) => {
  return (
    <Grid
      variant="default"
      columns={3}
      sx={{
        mt: '2rem',
        mb: '2rem',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        color: 'alwaysBlack',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Text
          variant="smallCopySmallCapsWide"
          sx={{
            display: 'block',
            textDecoration: 'none',
            color: 'alwaysBlack',
          }}
        >
          Dark Mofo
        </Text>
        <Text
          variant="smallCopySmallCapsWide"
          sx={{
            display: 'block',
            textDecoration: 'none',
            color: 'alwaysBlack',
          }}
        >
          Resurrection
        </Text>
        <Text
          variant="smallCopySmallCapsWide"
          sx={{
            display: 'block',
            textDecoration: 'none',
            color: 'alwaysBlack',
          }}
        >
          08 — 22 June
        </Text>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <ul sx={{ listStyle: 'none', margin: 0, padding: 0, }}>
          {siteSettings?.facebookUrl && (
            <li sx={{ display: 'flex', justifyContent: 'center' }}>
              <Text
                href={siteSettings?.facebookUrl}
                target="_blank"
                as="a"
                variant="smallCopySmallCapsWide"
                sx={{
                  textDecoration: 'none',
                  color: 'alwaysBlack',
                }}
              >
                Facebook
              </Text>
            </li>
          )}
          {siteSettings?.twitterUrl && (
            <li sx={{ display: 'flex', justifyContent: 'center' }}>
              <Text
                href={siteSettings?.twitterUrl}
                target="_blank"
                as="a"
                variant="smallCopySmallCapsWide"
                sx={{
                  textDecoration: 'none',
                  color: 'alwaysBlack',
                }}
              >
                Twitter
              </Text>
            </li>
          )}
          {siteSettings?.instagramUrl && (
            <li sx={{ display: 'flex', justifyContent: 'center' }}>
              <Text
                href={siteSettings?.instagramUrl}
                target="_blank"
                as="a"
                variant="smallCopySmallCapsWide"
                sx={{
                  textDecoration: 'none',
                  color: 'alwaysBlack',
                }}
              >
                Instagram
              </Text>
            </li>
          )}
          {siteSettings?.youtubeUrl && (
            <li sx={{ display: 'flex', justifyContent: 'center' }}>
              <Text
                href={siteSettings?.youtubeUrl}
                target="_blank"
                as="a"
                variant="smallCopySmallCapsWide"
                sx={{
                  textDecoration: 'none',
                  color: 'alwaysBlack',
                }}
              >
                Youtube
              </Text>
            </li>
          )}
        </ul>
      </Flex>
      <Flex sx={{ justifyContent: 'flex-end', flexDirection:'column'}}>
            <Text
              href={'https://darklab.net.au/privacy-policy'}
              target="_blank"
              as="a"
              variant="smallCopySmallCapsWide"
              sx={{
                textDecoration: 'none',
                display: 'inline-block',
                textAlign:'right',
                color: 'alwaysBlack',
              }}
            >
              Privacy Policy
            </Text>
            <Text
              href={'https://darklab.net.au/terms-and-conditions'}
              target="_blank"
              as="a"
              variant="smallCopySmallCapsWide"
              sx={{
                textDecoration: 'none',
                display: 'inline-block',
                textAlign:'right',
                color: 'alwaysBlack',
              }}
            >
              Terms + Conditions
            </Text>
          <Text
          variant="smallCopySmallCapsWide"
          sx={{
            display: 'block',
            textDecoration: 'none',
            textAlign:'right',
            color: 'alwaysBlack',
          }}
        >
          nipaluna / Hobart
        </Text>
      </Flex>
    </Grid>
  )
}

const MenuInfoBlock = ({ siteSettings }) => {
  return (
    <Grid
      variant="default"
      columns={2}
      sx={{
        mt: ['10px', null, '12px', null, '14px'],
        mb: ['14px', null, '17px', null, '20px'],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Text
          variant="smallCopySmallCaps"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          Dark Mofo
        </Text>
        <Text
          variant="smallCopySmallCaps"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          16–22 June
        </Text>
        <Text
          variant="smallCopySmallCaps"
          sx={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          lutruwita / Tasmania
        </Text>
      </Flex>
      <Flex>
        {siteSettings?.acknowledgement && (
          <Text
            variant="smallCopySmallCaps"
            sx={{
              display: 'block',
              textDecoration: 'none',
            }}
          >
            {siteSettings?.acknowledgement}
          </Text>
        )}
      </Flex>
    </Grid>
  )
}

export { Menu }

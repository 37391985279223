import FlexCentre from "components/utils/FlexCentre";
import { Flex, Text } from "theme-ui";

const VideoCredit = () => {
  return (
    <FlexCentre
      sx={{
        zIndex: 4,
      }}
    >
      <Text variant="splashCredit">
        Return
        <br/>Emma Ruth Rundle
      </Text>
    </FlexCentre>
  )
}

export default VideoCredit;

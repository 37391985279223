import ExplodedLine from 'components/utils/ExplodedLine'
import FlexCentre from 'components/utils/FlexCentre';
import { Flex } from 'theme-ui'

const ResurrectionTitle = ({
  text,
}) => {
  if (!text) {
    return null;
  }

  return (
    <ExplodedLine
      variant="splashHeader"
    >
      {text}
    </ExplodedLine>
  )
}

const ResurrectionText = ({
  text,
  zIndex = 2,
  children = <ResurrectionTitle text={text} />,
}) => {
  return (
    <FlexCentre
      sx={{
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'relative',
        zIndex,
      }}
    >
      {children}
    </FlexCentre>
  )
}

export default ResurrectionText

/** @jsx jsx */
import { jsx } from 'theme-ui'

const RiseFundLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 333.06"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <g>
          <rect y="272.3" width="500" height="2.56" />
        </g>
        <g>
          <path d="M208.36,151.16c-0.17,0.29-0.37,0.52-0.66,0.67" />
          <path
            d="M214.38,104.06c0.7,0,1.25-0.56,1.25-1.27c0-0.71-0.56-1.27-1.25-1.27c-0.7,0-1.26,0.56-1.26,1.27
			C213.12,103.49,213.68,104.06,214.38,104.06"
          />
          <path
            d="M257.56,52.06h13.79V76.4h-13.79V52.06z M271.35,87.97c-0.23,2.62-1.13,4.81-2.94,6.04l-10.85,6.49V77.65
			h13.79V87.97z M243.61,52.06h12.73V76.4h-12.73V52.06z M256.34,101.24l-5.01,3h-0.09c-0.36,0.29-0.82,0.46-1.31,0.46
			c-0.51,0-0.97-0.19-1.34-0.49l-4.98-2.98V77.65h12.73V101.24z M238.46,67.49h3.92v8.91h-3.92V67.49z M242.38,100.5l-10.84-6.49
			c-1.81-1.23-2.7-3.42-2.94-6.04V77.65h13.78V100.5z M228.6,52.06h4.76v9.39h-4.76V52.06z M234.54,55.59l0.56-0.58l-0.35-0.64
			l0.73,0.07l0.44-0.78l0.43,0.76l0.74-0.05l-0.34,0.64l0.55,0.58l-0.71,0.14v0.79l-0.68-0.51l-0.67,0.51v-0.79L234.54,55.59z
			 M237.91,65.39c-0.03-0.23-0.34-0.3-0.47-0.3c-0.42,0-0.59,0.71-0.95,0.72c-0.26,0.01-0.45,0.04-0.45-0.28
			c0-0.23,0.54-0.42,0.75-0.63c-2.94,0-2.99,1.35-3.45,1.35c-0.77,0,0.65-1.42,1.05-1.83c-0.59-0.09-0.94-0.83-1.59-1.19
			c0.3-0.44,0.48-0.25,0.86,0.06c0-0.31,0.23-0.68,0.91-0.68c1.31,0,0.95,1.27,1.16,1.27c0.21,0-0.02-1.27,0.87-1.27
			c1.3,0,2.93,3.36,1.45,3.36C237.42,65.96,237.93,65.49,237.91,65.39z M234.76,72.56l0.73,0.08l0.44-0.78l0.43,0.77l0.74-0.07
			l-0.34,0.65l0.55,0.57l-0.71,0.14v0.79l-0.68-0.51l-0.67,0.51v-0.79l-0.71-0.14l0.56-0.57L234.76,72.56z M229.39,63.5l0.72,0.08
			l0.45-0.78l0.43,0.77l0.74-0.07l-0.35,0.65l0.56,0.57l-0.71,0.14v0.79l-0.67-0.5l-0.68,0.5v-0.79l-0.71-0.14l0.56-0.57
			L229.39,63.5z M228.6,67.49h4.76v8.91h-4.76V67.49z M239.53,64.72l0.56-0.57l-0.35-0.65l0.74,0.08l0.44-0.78l0.43,0.77l0.75-0.07
			l-0.35,0.65l0.56,0.57l-0.71,0.14v0.79l-0.68-0.5l-0.66,0.5v-0.79L239.53,64.72z M238.46,52.06h3.92v9.39h-3.92V52.06z
			 M272.63,50.85v-0.02h-45.3v0.02h-0.01l0.04,36.65c0,3.54,1.5,6.64,3.56,7.58l17.42,10.43c0.38,0.44,0.95,0.72,1.59,0.72
			c0.55,0,1.04-0.21,1.41-0.56l17.69-10.6c2.07-0.95,3.56-4.04,3.56-7.58L272.63,50.85L272.63,50.85z"
          />
          <path
            d="M241.65,86.05c-0.07-0.15-1.73,0.55-1.26,0.51c0.05-0.49,0.97-1.49,1.38-1.86c-0.08-0.58-0.81-0.06-1.59,0.35
			c2.58-3.7,0.92-2.19-0.51-1.92c2.3-2.49,1.69-2.44,0-1.67c1.34-1.06,1.62-2.32,1.41-2.69c-1.26,1.67-2.4,1.41-2.4,2.96
			c0,1.02-0.37,2.69-0.89,3.25c-0.68,0.74-0.16-2.72-1.92-2.72c-0.57,0-1.9-0.05-2.3,0.65c0.36,0,0.9,0.91,0.9,1.28
			s0.33,1.37-0.27,1.37c-0.6,0-1.3-1.27-1.3-1.89c0-0.6-0.05-0.46-0.05-1.33c0-1.48-2.38-3.53-3.34-3.53c0,1.02,1.32,1.27,1.32,2.57
			c-0.57,0-1.37-1.06-1.37-0.4c0,0.65,0.85,0.77,0.85,1.34c0,0.58-0.97-0.2-0.97,0.41c0,0.54,0.54,0.45,0.66,0.97
			c-0.4-0.11-0.75-0.06-0.82,0.14c-0.06,0.17,0.12,0.4,0.42,0.57c-0.14,0.12-0.26,0.43-0.26,1.29c0,2.89,1.82,4.23,3.15,4.23
			c0.95,0,0.8-0.66,0.92-0.97c0.04,0.48,0.51-0.24,0.51,0.61c0,0.9-0.92,0.51-0.92,1.28c0,1.04,1.45-0.04,1.45,1.11
			c0,1.38-0.17,1.36-0.17,1.89c0,0.36,1.52-0.51,1.42-0.67c0.36,0.14,0.35,1.21,1.03,1.19c0.43-0.02,0.53-1.11,0.87-1.19
			c-0.21,0.05,1.37,1.03,1.37,0.53c0-1.35-0.55-0.77-0.55-1.7c0-0.85,1.86-0.69,1.57-1.13c-0.24-0.37-0.42-0.13-0.61-0.77
			c0.04,0.12-1.46-2.14,0.31-0.97C241.54,90.33,241.75,86.25,241.65,86.05"
          />
          <path
            d="M289.97,95.64c0.82,0,1.49-0.59,1.49-1.32c0-0.73-0.67-1.32-1.49-1.32c-0.82,0-1.48,0.59-1.48,1.32
			C288.48,95.05,289.15,95.64,289.97,95.64"
          />
          <path
            d="M283.95,99.22c0.82,0,1.49-0.58,1.49-1.32c0-0.72-0.67-1.31-1.49-1.31c-0.82,0-1.49,0.59-1.49,1.31
			C282.46,98.63,283.12,99.22,283.95,99.22"
          />
          <path
            d="M212.35,107.49c0.82,0,1.49-0.59,1.49-1.31c0-0.73-0.67-1.32-1.49-1.32c-0.83,0-1.49,0.59-1.49,1.32
			C210.86,106.9,211.52,107.49,212.35,107.49"
          />
          <path
            d="M216.99,107.89c0.82,0,1.49-0.59,1.49-1.32c0-0.73-0.66-1.31-1.49-1.31c-0.82,0-1.49,0.58-1.49,1.31
			C215.5,107.31,216.17,107.89,216.99,107.89"
          />
          <path
            d="M345.89,45.29c2.21,2.63,2.69,6.85,1.11,9.88c-0.87-0.07-0.71-1.19-0.87-1.83c-1.58-2.95-2.85-6.7-1.42-10.05
			C345.73,43.46,345.33,44.73,345.89,45.29"
          />
          <path
            d="M156.75,48.88c-0.47,1.9-0.47,4.54,0,6.29c-0.4,0.4-0.31-0.39-0.71-0.32c-3.08-2.23-1.66-6.61-2.45-9.72
			c0.16-0.24,0.55-0.31,0.79-0.16C155.8,45.93,156.43,47.28,156.75,48.88"
          />
          <path
            d="M352.69,49.12c0.47,0.88-0.4,1.76-1.03,2.14c-0.71,0.17-1.5-0.23-1.97-0.87c-0.25-0.88,0.23-1.68,0.94-2.08
			C351.42,48.25,352.21,48.39,352.69,49.12"
          />
          <path
            d="M151.93,52.62c-0.16,0.72-0.4,1.44-1.03,1.75c-0.47,0-1.18,0-1.5-0.39c-0.47-0.4-0.55-1.12-0.31-1.67
			c0.31-0.56,0.95-0.65,1.5-0.72C151.13,51.83,151.68,51.98,151.93,52.62"
          />
          <path
            d="M355.76,53.98c0.16,0.47,0.16,0.8,0,1.28c-0.55,0.72-1.34,1.04-2.13,0.72c-0.55-0.32-0.71-0.88-0.87-1.37
			c0.16-0.63,0.79-1.19,1.34-1.43C354.82,53.18,355.29,53.58,355.76,53.98"
          />
          <path
            d="M351.27,54.06c0.63,0.31,0.16,1.11,0.08,1.59c-0.4,0.4-0.94,1.04-1.65,0.8c-0.4-0.4-0.87-0.4-1.03-0.95
			c0-0.8,0.32-1.6,1.11-1.84C350.4,53.5,350.78,53.82,351.27,54.06"
          />
          <path
            d="M345.49,55.26c0,0.88,0.08,1.99-0.95,2.15c-0.95,0.32-1.58-0.48-1.81-1.12c-0.09-0.8,0.39-1.6,1.18-1.92
			C344.55,54.37,345.11,54.78,345.49,55.26"
          />
          <path
            d="M160.53,55.65c0.24,0.8-0.55,1.28-1.02,1.68c-0.56,0.16-1.27-0.32-1.59-0.88c-0.55-0.56,0.16-1.04,0.32-1.51
			C159.03,54.22,160.22,54.78,160.53,55.65"
          />
          <path
            d="M155.8,55.89c0,0.64-0.32,1.28-0.87,1.52c-0.63,0.23-1.26-0.24-1.58-0.72c-0.39-0.8,0.08-1.52,0.71-1.84
			C154.93,54.54,155.48,55.33,155.8,55.89"
          />
          <path
            d="M326.38,56.21c0.78,1.68,1.41,3.67,1.58,5.74c-0.32,1.51,0.23,3.5-0.88,4.54c-1.1-0.4-1.18-1.75-1.9-2.48
			c-1.41-2.78-0.16-6.06,0.33-8.76C326.14,55.01,326.14,55.82,326.38,56.21"
          />
          <path
            d="M152.08,57.17c0.24,0.96-0.47,1.43-1.03,1.99c-0.95,0.24-1.58-0.48-1.98-1.11c-0.08-0.8,0.4-1.6,1.03-1.91
			C150.9,56.05,151.68,56.45,152.08,57.17"
          />
          <path
            d="M338.86,59.48c1.03,2.3,1.1,5.11,0.4,7.65l-0.47,0.4c-1.11-2.16-2.69-4.15-3.24-6.46
			c0-1.67,0.32-3.18,0.94-4.54c0.56-0.39,0.88,0.07,1.11,0.55C337.67,57.97,338.07,59,338.86,59.48"
          />
          <path
            d="M348.82,58.05c-0.09,0.79-0.4,1.67-1.19,1.91c-0.79,0.16-1.58-0.07-1.82-0.95c-0.24-0.79,0.23-1.51,0.86-1.92
			C347.54,56.61,348.42,57.41,348.82,58.05"
          />
          <path
            d="M155.88,60.51c0,0.64-0.56,1.36-1.19,1.51c-0.63,0.48-1.11-0.32-1.66-0.55c-0.24-0.64-0.4-1.2,0-1.84
			c0.23-0.48,0.79-0.48,1.26-0.63C155.01,59.4,155.72,59.72,155.88,60.51"
          />
          <path
            d="M159.35,60.12c0.31,0.8-0.55,1.2-0.87,1.59c-0.95,0.32-1.42-0.39-1.82-0.96c-0.16-0.8,0.32-1.27,0.87-1.75
			C158.49,58.77,158.96,59.4,159.35,60.12"
          />
          <path
            d="M351.03,59.88c-0.08,1.04-1.66,2.07-1.82,3.1c-0.86,2.16-2.77,3.75-4.89,4.47c-0.48,0.4-1.1,0.96-1.82,1.04
			c-0.16-1.59,1.27-3.03,2.14-4.47C346.29,61.87,348.58,60.51,351.03,59.88"
          />
          <path
            d="M154.3,63.24c1.18,1.35,1.81,2.54,3.24,3.74c0.08,0.32-0.16,0.8-0.54,0.8c-3.88-0.24-6.49-4.23-8.38-7.02
			c0.23-0.87,1.26-0.16,1.81-0.24C151.77,61.56,153.27,61.87,154.3,63.24"
          />
          <path
            d="M352.14,64.34c-0.16,3.44-1.82,6.94-4.9,8.62c-0.87,0.32-1.82,1.2-2.69,0.64c1.66-3.44,4.19-6.23,6.95-9.26
			H352.14"
          />
          <path
            d="M336.96,66.65c0.24,1.2-0.39,1.93-1.34,2.31c-0.86,0.09-1.41-0.63-1.82-1.27c-0.08-0.79,0.4-1.51,1.11-1.76
			c0.23-0.24,0.63-0.24,0.95-0.24C336.33,65.86,336.57,66.26,336.96,66.65"
          />
          <path
            d="M149.95,67.37c1.51,1.04,2.85,2.47,4.27,3.75c-1.03,0.72-2.29,0.09-3.48,0.09c-2.69-0.96-4.5-3.27-7.27-4.07
			c0.08-0.88,1.11-0.56,1.66-0.8C146.87,66.18,148.29,66.98,149.95,67.37"
          />
          <path
            d="M234.57,43.77c-4.42-1.75-7.35,2.87-11.85,2.31c-0.56,0.32-0.39,0.88-0.71,1.28
			c-0.25,0.23-0.64,0.07-0.88-0.09c-0.23-1.91-0.86-3.66-1.11-5.49c-0.47-0.81-0.24-1.92-0.63-2.71c-2.53-6.94,6.17-1.68,7.9-5.74
			c1.51-3.52-2.92-3.91-4.27-6.38v-1.04c-1.26-1.28-2.6-1.68-3.4-3.36c0.56-1.99,0.79-4.15,0.24-6.13c-0.08-0.49-0.39-1.12-1.1-0.88
			c-1.5,2.08-2.61,3.74-4.03,5.74c-1.5-1.11-2.92-2.54-4.66-3.5c-1.18-0.16-2.28-1.35-3.63-0.88c-0.23,0.48,0,0.88,0.24,1.19
			c0.07,3.12,1.65,5.59,3.87,7.82l0.24,1.19c-1.42,3.27-1.03,7.01-1.42,10.53c-3.56,9.08-9.33,17.86-17.15,24.15
			c-4.5,5.11-9.87,10.05-10.59,17.06c-0.24,1.12,0.16,2.48-0.71,3.28c-2.61-0.96-5.22-2.24-7.35-3.91
			c-3.63-2.55-8.84-2.23-11.22-6.86c-0.94-1.84,0.24-3.67,1.11-5.03c2.69-2.55,2.3-7.09,0.87-10.2c-0.32-0.09-0.4,0.31-0.63,0.39
			c-0.24,4.71-3.95,7.98-2.53,13.16c0.32,1.52,1.11,2.47,1.51,3.91c-0.56-0.17-1.34-0.17-1.82-0.4c-1.74-0.24-3.48-0.95-5.29-0.72
			c-3.63,1.68-8.6-0.39-11.53,2.95c-0.47,0.08-1.11,0.16-0.94,0.87c1.74,0,3.39,0.49,5.13,0.4c2.77,0.48,4.58-1.83,6.48-2.86h0.47
			c0.78-0.81,2.13-0.33,3.16-0.49c4.98,0.56,8.85,2.95,12.96,5.43v0.32c-1.58,0.71-3.39,1.19-4.98,2.07
			c-1.82,1.35-3.87,3.11-4.42,5.34c-1.27,1.99,0.4,3.74,1.19,5.42c0.07,0.79,0.16,1.75,0.87,2.07c2.53-2.72,0.16-6.07,0.55-9.17
			c0.63-2.39,2.85-3.51,5.14-4.15c4.51-1.92,7.75,1.76,11.37,3.83c-0.07,3.67,0.4,7.18-0.38,10.76c-1.11,0.4-2.53,0.65-3.72,0.56
			c-6.24,0.48-11.14,3.91-16.75,5.82c-2.53,0.88-5.53,0.81-8.06,1.76c1.98,0.56,4.03-0.16,6.08-0.24c7.74-1.12,13.67-7.1,22.05-6.06
			l0.15,0.24c-0.94,2-2.21,3.67-3.71,5.18c-0.24-0.88-0.32-1.59-1.11-2.07c-0.63-0.32-1.34,0-1.82,0.63c-0.24,0.4-0.24,0.88,0,1.2
			c0.4,0.72,1.02,0.88,1.74,1.11c-0.16,0.72-1.03,1.04-1.5,1.68c-2.21,3.74-6.72,2.23-9.32,4.86c-0.4,0.56-1.82,0.88-1.19,1.76
			c3,0.32,5.92-0.48,8.14-2.07c2.92-3.27,6-6.38,8.92-9.49c0.47,2.55-0.23,5.18-1.74,7.26c-0.71-0.4-1.27-0.96-2.13-1.21
			c-0.79,0.16-1.34,0.96-1.42,1.6c0.31,0.56,0.63,1.2,1.18,1.6c-2.68,1.92-6.08,2.08-9.16,2.95c-5.77,0.72-11.37,1.2-17.22,1.27
			c-2.77-0.16-5.92,0.81-8.53,0c-3,0.56-6.24-0.39-8.69,1.28c-0.31,0.4,0.08,0.8,0.08,1.2c3.63,2.4,8.61,0.48,12.72,1.2h31.13
			c2.3-0.08,4.58-0.16,6.4-1.2c4.04-2.32,6.25-6.46,8.3-10.53c0.79-1.44,1.34-3.1,1.66-4.78c0.79-2.71,3.24-4.64,5.92-4.87
			c0.39,4.14-0.39,8.29-1.34,12.11c-0.48,2.55-2.14,5.03-1.11,7.74c0.24,1.27,1.58,1.27,2.61,1.44c9.56-0.16,19.13-0.56,29.23,0.16
			c1.82,0.32,3.4,0.88,5.38,0.81c0.16-0.25,0.71-0.32,0.48-0.81c-0.24-0.88-0.87-1.98-1.9-2.23c-0.16-0.47-0.86-0.31-1.11-0.63
			c-5.05-2.87-11.14-1.36-17.06-1.28c-3.08,0.32-6.16,0.88-8.77-0.88c-1.18-1.43-0.32-5.98-0.32-5.98c0-1.8,4.35-14.36,4.9-15.39
			c0.16-1.2,0.63-2.31,0.48-3.67c0.63-1.59,0.47-3.66,0.47-5.41c0.31-2.55,1.58-4.95,2.44-7.42c0.48-1.19,0.87-2.47,1.34-3.66
			c0.47-1.52,0.63-2.95,1.58-4.15c2.58,1.65,5.1,1.6,6.34,4.52l1.08,6.64c0,0.4,0.31,1.2,0.87,0.72c2.05-1.36,1.42-4.38,1.42-6.45
			c0.47-2.48-1.27-3.67-1.98-5.83c-0.55-0.39-1.46-2.29-1.46-2.29c-0.31-1.76-4.15-2.96-3.27-5.12c0.47-1.83,0.95-3.51,2.69-4.54
			c0.25,0.18,0.36,0.37,0.39,0.56l0.95-0.67l0.1-6.66c2.56-0.01,6.38,0.32,8.98,0.32c0.71,0.24,1.27,0.8,2.05,0.87l0.63-0.87
			l6.41-1.25C238.81,46.9,237.15,43.77,234.57,43.77"
          />
          <path d="M241.53,46.6c0.19-0.02,0.38-0.06,0.58-0.11L241.53,46.6" />
          <path
            d="M172.71,68.81c0.08,0.96-0.48,1.52-1.19,1.84c-0.63-0.25-1.1-0.32-1.49-0.96c-0.32-0.88,0.47-1.52,1.1-1.83
			C171.76,68.02,172.55,68.1,172.71,68.81"
          />
          <path
            d="M167.57,68.81c0.47,0.48,0.55,1.27,0.32,1.84c-0.56,0.47-1.43,0.88-1.9,0.23c-0.47-0.48-0.94-1.04-0.55-1.67
			C165.99,68.65,166.94,68.17,167.57,68.81"
          />
          <path d="M277.55,69.69c0.02,0.04,0.04,0.08,0.06,0.11" />
          <path
            d="M337.76,71.04c0.31,0.88-0.4,1.51-1.03,1.83c-0.79-0.24-1.82-0.4-1.82-1.44c0.08-0.63,0.63-1.04,1.18-1.19
			C336.81,70,337.44,70.56,337.76,71.04"
          />
          <path
            d="M341.94,70.88c0.23,0.4,0.79,1.04,0.16,1.44c-0.32,0.24-0.55,0.32-0.79,0.64c-0.86,0.16-1.65-0.16-1.9-0.96
			c-0.16-0.88,0.55-1.27,1.18-1.67C341.15,70.16,341.55,70.56,341.94,70.88"
          />
          <path
            d="M147.74,71.84c-0.31,0.95-1.11,1.35-2.13,1.04c-0.23-0.24-0.47-0.63-0.63-1.04c0.07-0.56,0.24-1.12,0.87-1.35
			C146.79,70.56,147.5,70.97,147.74,71.84"
          />
          <path
            d="M174.36,73.2c-0.08,0.64-0.79,1.2-1.34,1.52c-0.8,0.07-1.35-0.56-1.74-1.11c0-0.64,0.32-1.44,0.94-1.6
			C173.1,71.69,174.05,72.32,174.36,73.2"
          />
          <path
            d="M169.7,72.64c0.24,0.24,0.48,0.87,0.24,1.27c-0.47,0.4-1.18,1.13-1.97,0.64c-0.47-0.39-1.03-0.71-0.87-1.52
			c0.32-0.31,0.71-0.79,1.19-0.95C168.91,71.92,169.23,72.32,169.7,72.64"
          />
          <path
            d="M354.02,73.6c0,0.63-0.63,1.27-1.03,1.51c-0.63,0.24-1.34,0.08-1.73-0.4c-0.48-0.16-0.32-0.8-0.32-1.21
			c0.4-0.55,0.95-0.87,1.51-1.03C353.16,72.64,353.64,73.11,354.02,73.6"
          />
          <path
            d="M359.09,74.31c-0.25,0.72-0.8,1.35-1.51,1.44c-0.47-0.08-1.34-0.24-1.18-0.96c0-0.56,0.32-1.11,0.71-1.28
			C357.9,73.11,358.84,73.51,359.09,74.31"
          />
          <path
            d="M156.75,75.43c0.16,0.95-0.16,1.59-0.94,1.91c-0.56,0.32-1.11-0.08-1.5-0.4c-0.4-0.39-0.71-1.04-0.4-1.59
			c0.16-0.72,0.88-0.56,1.35-0.88C155.95,74.55,156.35,74.87,156.75,75.43"
          />
          <path
            d="M160.61,76.14c0.24,0.64-0.47,0.88-0.63,1.35c-0.4,0.25-0.95,0.48-1.42,0.17c-0.56-0.49-1.19-1.2-0.64-1.91
			C158.56,74.55,160.22,75.19,160.61,76.14"
          />
          <path
            d="M349.36,75.75c0.08,0.8-0.63,1.35-1.1,1.83c-0.71,0.16-1.42-0.32-1.58-0.95c-0.07-0.64,0.33-1.12,0.87-1.52
			C348.26,75.04,348.97,75.11,349.36,75.75"
          />
          <path
            d="M355.85,77.02c-0.16,0.72-0.09,1.52-0.87,2c-0.63,0.16-1.42,0.08-1.9-0.32c-0.47-0.4-0.56-1.11-0.32-1.67
			c0.4-0.64,1.1-1.12,1.9-0.95C355.21,76.38,355.37,76.78,355.85,77.02"
          />
          <path
            d="M153.74,78.7c0.32,0.64-0.4,0.81-0.63,1.12c-0.55,0.32-1.1,0.08-1.58-0.16c-0.55-0.32-0.31-1.12-0.23-1.52
			C152.01,76.78,153.42,77.75,153.74,78.7"
          />
          <path
            d="M350.87,80.22c0.31,0.07,0.16,0.55,0.08,0.71c-1.9,0.97-4.74,1.21-6.4-0.32c-0.95-0.8-2.13-1.11-3.16-1.76
			c0.4-1.03,1.83-0.95,2.84-1.19C346.52,77.98,348.34,80.14,350.87,80.22"
          />
          <path
            d="M157.53,79.5c0.16,0.63-0.31,0.87-0.47,1.35c-0.32,0.48-0.95,0.32-1.34,0.16c-0.71-0.48-1.03-1.35-0.55-1.99
			C156.04,78.14,157.06,78.62,157.53,79.5"
          />
          <path
            d="M164.49,79.98c0.31,0.72-0.16,1.35-0.79,1.75c-0.56,0.32-1.03-0.16-1.5-0.25c-0.39-0.56-1.1-1.19-0.39-1.83
			C162.52,78.46,163.94,79.1,164.49,79.98"
          />
          <path
            d="M255.84,83.34c-0.15,1.59-2.78-0.22-2.4,1.9c0.37,1.52,0.67,3.33,1.57,4.47c0.76,1.06,0.38,2.72,0.31,4.02
			c-0.68,1.13-1.8,1.97-3,2.58c-2.25-0.23-5.55,1.13-6.61-1.68c-0.44-1.21-1.28-2.42-1.28-3.78c0.23-0.16,0.53-0.16,0.75-0.38
			c0-0.54-0.6-0.83-0.52-1.36c2.55-0.46-1.05-2.73,1.12-2.88c1.8,1.89,4.74,1.29,6.31,3.33c0.37,0.31,0.67,0.76,1.12,0.98
			c-0.3-1.89-1.2-3.78-1.5-5.83c-0.23-1.05,0.15-2.04,0.9-2.64C253.96,81,255.09,82.51,255.84,83.34"
          />
          <path
            d="M171.44,82.85c0.24,0.8-0.39,1.28-0.86,1.67c-0.71,0.32-1.11-0.48-1.43-0.88c-0.24-0.8,0.31-1.19,0.79-1.6
			C170.5,82.05,171.12,82.29,171.44,82.85"
          />
          <path
            d="M220.2,83.64c0.94,3.11,0.63,6.3-0.96,8.93c-0.07,0.64,0.16,1.68-0.71,1.6c-2.13-2.95-0.55-7.09,0.32-10.21
			C219,83.16,219.95,82.85,220.2,83.64"
          />
          <path
            d="M287.82,85.16c1.1,2.23,1.19,5.17,0.16,7.41l-0.64,0.64c-0.87-1.83-1.58-3.66-1.82-5.66
			c0.4-1.43,0.87-2.7,0.95-4.22C287.58,83.08,287.51,84.52,287.82,85.16"
          />
          <path
            d="M177.6,86.04c-0.47,0.95-1.81,1.19-2.68,1.82c-1.9,1.45-4.67,1.76-7.03,1.36c2.05-2.86,5.21-4.55,9-3.75
			C177.05,85.8,177.45,85.64,177.6,86.04"
          />
          <path
            d="M145.13,88.91c2.92,2.07,3.71,4.94,5.45,7.82c0.24,0.31,1.03,0.39,0.71,1.03c-0.64,0.48-1.27-0.48-2.14-0.32
			c-3-1.35-4.9-4.71-4.82-8.21C144.33,88.91,144.82,88.91,145.13,88.91"
          />
          <path
            d="M284.74,91.38c0.08,0.8-0.09,1.59-0.71,1.83c-0.87,0.25-1.58-0.24-1.82-0.95c-0.24-0.56,0.23-0.88,0.4-1.28
			C283.24,90.33,284.34,90.65,284.74,91.38"
          />
          <path
            d="M318.08,95.6c0-1.52,0.4-2.95,0.55-4.39c0.24-0.47,0.4-1.04,0.32-1.67c1.9-2.56,5.76-1.76,8.78-1.52
			c1.18,0.64,2.68,0.56,3.94,0.88c0.55-0.4,1.35-0.48,1.98-0.4c2.53-1.35,0.86-4.31,1.03-6.61l-0.95-2.39
			c-0.23-0.48-0.47-1.04-0.63-1.6c-0.47-0.56-0.79-1.6-1.27-2.15c-1.34-3.51-3.95-5.5-6.16-8.29c-4.03-5.26-10.36-8.21-16.67-10.28
			c-6.25-2.16-12.57,0.63-18.17,2.63c-1.74-0.48-2.62-1.84-3.08-3.35c-0.32-3.03,0.3-5.98,1.49-8.45c0.24-2,1.58-3.52,2.14-5.34
			c1.42-4.47,2.92-8.85,3.24-13.72c0.16-2.95-2.05-5.02-4.26-6.3c-1.5-0.4-3.25-0.32-4.36,0.88c-1.02,2.15-3.23,3.59-5.14,4.62
			c-0.15,1.36,1.52,0.48,2.06,0.96c1.74,0.32,3.73-0.24,5.14,0.87c0.16,3.2-1.65,5.82-3.31,8.21c-2.7,3.99-5.53,8.06-7.19,12.44
			l-0.32-0.31l0.38,19.49c2.52,4.2,5.66,9,10.36,10.57c2.53,2.08,5.69,4.54,9.07,2.71c1.83-0.56,1.99,2,2.86,3.03
			c0.94,2.95-1.19,5.02-1.34,7.73l-2.06,4.39c-2.36,4.62-4.51,9.57-8.14,13.48c-1.19,0-1.9,1.28-3.08,1.35
			c-0.64,0.88-1.9,0.24-2.69,0.55c-2.44,1.04-5.93,0.41-7.58,2.72c0.16,0.24,0.39,0.32,0.63,0.4c4.82,0.08,10.19,0.4,15.33,0.16
			c0.63-0.24,1.19-0.8,1.27-1.51c-0.71-1.36,0.23-2.56,0.87-3.6c0.39-1.91,1.65-3.11,2.21-4.94c1.65-3.27,3.55-6.46,5.36-9.73
			l2.45-3.99c0.55-1.35,1.5-3.03,2.45-4.46c0.64-0.64,0.96-1.36,1.42-2.15c0.09-0.8-0.31-1.12-0.78-1.67
			c0.56-0.56,1.42-0.4,2.14-0.56c1.97,1.19,4.02,3.11,4.42,5.27l0.71,2.07l0.32,0.95l-0.16,2.64l0.31,2.47
			c0.4,3.83-0.31,7.74-0.7,11.56c-1.35,4.62-6.65,4.15-10.43,5.18c-0.96,0.64-2.77,0.32-2.77,1.91c4.58,0.96,9.95,0.4,14.85,0.32
			c0.78-0.32,0.94-1.04,1.34-1.76c0.16-1.04,1.19-1.91,0.87-3.03c-0.87-1.11-0.08-2.86-0.31-4.38c0.16-0.4,0.16-0.95,0-1.51
			c0.63-2.31,0.47-5.1,0.95-7.34L318.08,95.6"
          />
          <path
            d="M356.95,92.49c-2.52,3.59-5.21,7.66-9.63,8.94c-0.32,0-0.64,0.16-0.8-0.25c2.69-2.88,4.11-7.35,8.3-8.37
			C355.45,92.49,356.32,92.18,356.95,92.49"
          />
          <path
            d="M141.26,96.4c-0.39,0.32-0.07,1.04-0.79,1.12c-0.63,0.32-1.34-0.08-1.66-0.72c-0.31-0.8,0.4-1.59,1.03-1.75
			C140.47,95.37,141.1,95.6,141.26,96.4"
          />
          <path
            d="M164.01,97.52c0.24,0.72-0.55,0.72-0.94,0.87c-3.32,1.2-6.72,0.08-9.09-2.31c0-0.32,0-0.64,0.32-0.72
			C157.53,96.16,161.33,95.53,164.01,97.52"
          />
          <path
            d="M145.61,96.49c0.16,0.31,0.24,0.87,0.16,1.19c-0.47,0.72-1.18,0.88-1.97,0.72c-0.49-0.23-0.71-0.55-1.03-1.04
			c0.08-0.79,0.71-1.27,1.34-1.52C144.74,95.93,145.21,96.08,145.61,96.49"
          />
          <path
            d="M219.64,96.88c0.16,0.71-0.47,1.2-0.94,1.51c-0.71,0.16-1.34-0.16-1.66-0.64c-0.24-0.56,0-1.35,0.56-1.51
			C218.3,96.08,219.4,96.16,219.64,96.88"
          />
          <path
            d="M359.95,97.19c0.24,0.81-0.47,1.2-1.02,1.68c-0.49,0.24-1.03,0-1.51-0.24c-0.09-0.4-0.55-0.72-0.4-1.28
			c0.31-0.71,1.03-1.04,1.81-0.95C359.24,96.73,359.56,96.8,359.95,97.19"
          />
          <path
            d="M365.17,96.88c0.47,0.71,0.08,1.28-0.32,1.83c-0.32,0.56-0.95,0.72-1.43,0.47c-0.47-0.47-1.1-0.72-1.18-1.44
			c0-0.63,0.71-1.11,1.18-1.35C364.22,96.08,364.54,96.88,365.17,96.88"
          />
          <path
            d="M137.94,99.19c0.08,0.56-0.39,0.88-0.55,1.36c-0.39,0.16-0.79,0.48-1.27,0.32c-0.56-0.25-0.94-0.72-1.18-1.28
			c0.16-0.72,0.62-1.35,1.41-1.59C137,98.16,137.71,98.47,137.94,99.19"
          />
          <path
            d="M304.33,99.19c0.16,0.32,0.07,0.72,0.07,1.04c-0.47,0.64-1.1,1.2-1.89,0.8c-0.47-0.48-1.03-0.96-0.79-1.68
			C302.36,98.47,303.7,98.16,304.33,99.19"
          />
          <path
            d="M150.66,100.39c-0.16,0.56-0.39,1.12-1.1,1.2c-0.71,0.07-0.95-0.56-1.35-0.88c0-0.72-0.08-1.44,0.63-1.83
			C149.8,98.55,150.66,99.35,150.66,100.39"
          />
          <path
            d="M143.08,100.55c0.23,0.8-0.32,1.35-0.8,1.75c-0.94,0.32-1.73-0.4-2.12-1.12c0.07-0.72,0.63-1.6,1.42-1.83
			C142.28,99.35,142.69,100.15,143.08,100.55"
          />
          <path
            d="M309.55,100.39c0.16,0.8-0.07,1.59-0.88,1.92c-0.94,0.32-1.72-0.49-2.12-1.12c0.4-0.63,0.47-1.51,1.41-1.68
			C308.67,99.51,309.15,99.84,309.55,100.39"
          />
          <path
            d="M146.79,100.71c0.23,0.55-0.24,0.8-0.4,1.19c-0.4,0-0.63,0.49-1.1,0.32c-0.31-0.4-0.96-0.56-1.03-1.12
			c-0.07-0.8,0.48-1.19,1.03-1.51C146.08,99.51,146.55,100.07,146.79,100.71"
          />
          <path
            d="M355.61,101.35c-0.24,0.63-0.71,0.95-1.34,1.27c-0.63,0.16-1.03-0.4-1.5-0.64c-0.4-0.32-0.24-1.03-0.08-1.43
			C353.48,99.19,355.61,99.84,355.61,101.35"
          />
          <path
            d="M361.61,101.66c-0.24,0.88-1.11,1.28-1.9,1.36c-0.71-0.33-1.5-0.88-0.95-1.84c0.32-0.31,0.47-0.63,0.95-0.72
			C360.66,100.15,361.37,100.87,361.61,101.66"
          />
          <path
            d="M324.08,102.22c0.4,1.03-0.55,1.35-1.19,1.84c-0.55-0.08-1.03-0.32-1.49-0.64c-0.16-0.88,0.23-1.67,1.1-2.07
			C323.21,101.35,323.61,101.66,324.08,102.22"
          />
          <path
            d="M302.36,103.18c0.39,0.17,0.23,0.72,0.16,0.95c-0.47,0.32-1.11,1.12-1.83,0.64c-0.54-0.32-0.94-0.8-0.78-1.52
			C300.3,102.39,301.96,102.3,302.36,103.18"
          />
          <path
            d="M306.79,103.74c0.07,0.63-0.09,0.96-0.64,1.27c-0.56,0.56-1.19-0.07-1.75-0.24c-0.16-0.32-0.31-0.8-0.07-1.2
			c0.24-0.32,0.63-0.55,1.03-0.63C305.92,103.1,306.55,103.1,306.79,103.74"
          />
          <path
            d="M172.71,103.82c0.47,0.47-0.09,1.04-0.16,1.51c-0.4,0.48-1.26,0.87-1.97,0.4c-0.79-0.32-0.71-1.36-0.56-1.99
			c0.47-0.16,0.47-0.72,1.1-0.72C171.83,103.1,172.23,103.42,172.71,103.82"
          />
          <path
            d="M332.53,104.13c0.17,0.81-0.63,1.12-1.19,1.28c-0.94,0.32-1.41-0.4-1.81-1.04c0-0.47,0.47-0.79,0.63-1.19
			C331.28,102.7,331.99,103.5,332.53,104.13"
          />
          <path
            d="M328.28,104.13c0.31,0.81-0.55,1.2-0.87,1.6c-0.56,0.24-1.27,0.15-1.74-0.32c-0.4-0.25-0.4-0.71-0.4-1.2
			c0.48-0.39,0.71-0.87,1.27-1.04C327.24,103.18,327.96,103.58,328.28,104.13"
          />
          <path
            d="M363.91,105.09c0.16,1.11-1.27,0.56-1.82,0.88c-3.32,1.99-7.58,0.48-10.74-0.72
			c0.24-1.04,1.42-0.64,2.05-1.04c3.4-2.15,6.88,0.49,10.27,0.64L363.91,105.09"
          />
          <path
            d="M147.66,104.13l-0.31,0.57c-2.93,1.75-6.72,2.95-10.2,1.83l-0.31-0.49c2.92-2.14,6.79-2.7,10.59-2.14
			L147.66,104.13"
          />
          <path
            d="M340.59,104.7c0,0.63-0.31,1.18-0.78,1.5c-0.79,0.25-1.82,0-2.05-0.87c-0.24-0.63,0.4-1.27,1.03-1.51
			C339.34,103.82,340.28,103.9,340.59,104.7"
          />
          <path
            d="M161.8,105.01c-0.32,0.64-0.16,1.28-0.95,1.59c-0.79,0.32-1.74-0.4-1.97-1.04c-0.23-0.87,0.4-1.12,0.79-1.51
			C160.61,103.58,161.33,104.29,161.8,105.01"
          />
          <path
            d="M156.19,105.41c0.24,0.56-0.24,0.88-0.39,1.27c-0.4,0.47-1.11,0.4-1.58,0.24c-0.55-0.48-1.11-1.04-0.87-1.91
			c0.48-0.4,0.71-0.64,1.27-0.88C155.32,104.29,156.04,104.61,156.19,105.41"
          />
          <path
            d="M346.13,107.24c-0.09,0.72-0.64,1.28-1.27,1.44c-0.64,0.07-1.19-0.4-1.51-0.8c-0.23-0.48-0.15-1.12,0.32-1.52
			c0.16-0.24,0.71-0.24,1.03-0.4C345.42,106.04,345.98,106.6,346.13,107.24"
          />
          <path
            d="M150.35,106.37c0.24,0.64-0.32,0.88-0.4,1.35c-0.23,1.36-1.1,2.23-1.74,3.43c-1.5,1.83-3.63,1.83-5.76,2.39
			l-0.31-0.64c2.28-2.95,4.74-5.26,7.97-6.7L150.35,106.37"
          />
          <path
            d="M342.18,108.12c0.16,0.96-0.63,1.12-1.18,1.51c-0.71,0-1.27-0.4-1.59-0.95c-0.24-0.88,0.55-1.2,1.03-1.6
			C341.23,106.92,341.94,107.48,342.18,108.12"
          />
          <path
            d="M159.43,108.12c-0.08,0.16,0.08,0.32,0.23,0.4c-0.08,0.88-0.71,1.12-1.18,1.52c-0.71,0-1.27-0.24-1.74-0.8
			c-0.31-0.72,0-1.59,0.79-1.83C158.25,107.32,159.2,107.41,159.43,108.12"
          />
          <path
            d="M356.72,110.51c0.78-0.07,0.95-1.03,1.81-1.19c0.79-0.16,1.5,0.32,1.9,0.88c0.31,0.96-0.4,1.44-1.03,1.84
			c-0.56,0.39-1.73-0.49-1.66,0.55c0.55,0.4,1.03,0.96,1.1,1.59c-0.16,0.33-0.71,0.25-0.94,0.09c-2.29-0.96-4.74-2.56-5.69-4.95
			c-0.31-0.72-1.8-1.28-0.94-1.91C353.56,107.24,355.45,109.08,356.72,110.51"
          />
          <path
            d="M364.29,108.04c0.4,0.4,0.24,1.12-0.08,1.51c-0.4,0.8-1.58,0.48-2.28,0.4c-0.4-0.4-0.78-0.8-0.63-1.44
			c0.31-0.48,0.63-0.8,1.18-1.04C363.2,107.32,363.75,107.65,364.29,108.04"
          />
          <path
            d="M335.62,112.35c0.71,0.88,1.42,2.24,1.74,3.2c-0.16,0.24-0.55,0.16-0.71,0.16c-2.69-1.52-6.15-3.19-7.19-6.38
			c-0.24-0.32-0.87-0.72-0.79-1.28c2.05-0.39,4.04,1.12,5.45,2.23C334.67,111.08,335.3,111.47,335.62,112.35"
          />
          <path
            d="M155.01,109.32c0.31,0.72-0.32,1.28-0.71,1.6c-0.87,0.16-1.34-0.16-1.82-0.64c-0.55-0.88,0.47-1.59,1.11-1.92
			C154.22,108.28,154.61,108.92,155.01,109.32"
          />
          <path
            d="M345.49,111.15c0.08,0.48-0.55,0.8-0.78,1.11c-0.64,0.08-1.27,0.32-1.82-0.23c-0.16-0.4-0.55-1.04-0.09-1.44
			C343.68,109.48,344.94,110.36,345.49,111.15"
          />
          <path
            d="M365.17,112.26c0.23,0.96-0.47,1.6-1.1,2c-0.8,0.24-1.43-0.32-1.9-0.72c-0.4-0.48-0.08-0.96,0-1.51
			c0.54-0.49,1.26-0.88,2.05-0.56C364.54,111.71,364.85,111.87,365.17,112.26"
          />
          <path
            d="M310.49,123.28c0,0.95-1.74,0.72-2.46,1.43c-2.45,1.28-5.36,1.28-8.29,1.2v-0.48
			C302.59,123.03,306.7,122.16,310.49,123.28"
          />
          <path
            d="M195.23,126.7c-0.09,0.72-0.48,1.12-1.19,1.35c-0.55,0-1.11-0.31-1.34-0.79c-0.31-0.95,0.47-1.36,1.03-1.75
			C194.43,125.59,195.07,126.15,195.23,126.7"
          />
          <path
            d="M309.15,127.58c-0.16,0.72-0.4,1.52-1.19,1.84c-0.7,0.16-1.17-0.25-1.73-0.56c-0.4-0.31-0.16-0.88-0.23-1.27
			C306.55,126.15,308.6,126.15,309.15,127.58"
          />
          <path d="M324.87,127.9c-1.97,1.27-4.18,2.79-6.79,2.79l-4.27-0.08l-0.4-0.31c2.61-3.2,7.35-3.2,11.46-2.88V127.9" />
          <path
            d="M173.57,129.81c0,0.72-0.31,1.27-1.03,1.51c-0.79,0.16-1.34-0.16-1.66-0.72c-0.31-0.72,0-1.44,0.63-1.83
			C172.39,128.22,173.26,129.17,173.57,129.81"
          />
          <path
            d="M177.52,130.61c0.23,0.4-0.15,0.64-0.31,1.04c-0.48,0.24-1.11,0.4-1.51,0c-0.55-0.32-0.71-0.88-0.47-1.35
			c0.23-0.48,0.71-0.41,0.94-0.72C176.73,129.81,177.29,130.04,177.52,130.61"
          />
          <path
            d="M147.26,138.67c-0.79,0.95-2.05-0.24-2.93-0.72c-2.75-1.59-2.68-4.71-5.45-6.54
			c0.32-0.96,1.03,0.08,1.58-0.08c0.48,0.32,1.11,0.64,1.66,0.88C144.82,133.72,145.45,136.27,147.26,138.67"
          />
          <path
            d="M251.95,137.15c-0.31,0.96-0.94,1.76-0.87,2.8l-0.31,0.16c0.24-1.2-0.95-1.92-0.63-3.19
			c-0.63-0.96-0.63-2.16-1.19-3.19c-0.71-1.28,0.63-1.84,1.19-2.72c1.11,0.48,1.5,1.53,2.29,2.48
			C252.9,134.83,251.88,135.87,251.95,137.15 M377.57,107.72c-1.27-2.31-3.4-3.27-5.93-3.27c-2.05,0.16-3.55,1.52-4.42,3.27
			c-0.47,1.76,0.16,3.36,1.5,4.39c0.56,0.23,1.27,0.15,1.58-0.4c0.4-1.04-0.71-1.04-0.87-1.83c-0.32-1.12,0.09-2.08,0.87-2.79
			c0.78-0.79,2.13-0.4,3.08-0.4c0.95,0.64,1.9,1.2,2.37,2.32c0.48,1.35,0.48,2.86,0,4.22c-0.87,2-2.84,3.59-4.89,3.99
			c-6.56,0.96-78.54,0.64-78.54,0.64c-8.45,0.79-18.09-1.6-25.37,2.86c-3.31,1.59-4.98,5.26-5.84,8.53
			c-2.13,1.92-4.42,4.15-6.16,6.3l-0.24-0.24c0.4-1.92,1.27-3.59,1.67-5.5c1.18-1.6,1.18-3.75,2.05-5.5
			c3.01-4.87,5.85-9.81,10.51-13.23c1.97-2.64,5.45-2.16,8.45-2.16c3.23,1.52,7.04,2,10.98,1.59c0.16-0.16,0.24-0.32,0.24-0.56
			c-1.1-1.04-2.92-1.44-4.18-2.47c-3.16-1.51-6.17,0.47-9.49,0.24c-0.16-0.16-0.32-0.24-0.23-0.48c3-2.23,6.39-3.83,9.79-5.26
			c2.77-1.51,5.69-2.31,8.62-3.43c1.18,0,2.28-1.04,3.39-0.32l4.18-1.12c0.16,0.07,0.33,0.32,0.63,0.24
			c3.57-1.2,8.07-0.08,12.09-0.16l0.56,0.48l3.8,0.96c1.42,0.16,2.05,1.84,3.55,1.92c0.79-1.12-0.87-1.6-1.26-2.32
			c1.73-0.39,3.71,0.07,5.29,0.8l-0.79,0.88c-0.47,0.88,0.24,1.52,0.96,1.83c0.55,0.16,0.95-0.24,1.34-0.39
			c1.03-0.72-0.08-1.44,0.23-2.08c5.85,1.04,11.46,2.47,16.84,4.79c1.5,0.47,2.92,1.59,4.58,1.51c-1.66-1.51-3.95-2.32-6.01-3.18
			c-1.34-0.8-3.24-0.88-4.26-2c0.23-0.4,0.78-0.24,1.03-0.55c2.69-0.33,4.97-2.32,6.88-4.47c0.16-0.47,1.03-0.32,0.95-0.96
			c-0.71-0.8-1.9,0.24-2.85,0.16c-3.32,0.64-4.74,4.55-8.06,5.27c-4.02-0.96-8.14-1.92-12.17-2.95c-1.97-0.32-4.11-0.48-5.93-1.28
			l-4.42-0.4h-8.06c-0.08-0.16-0.31-0.25-0.23-0.48c2.84-0.72,5.29-2.4,8.13-3.11l21.18-9.72c2.21-2,4.19-4.23,6.01-6.54
			c0.06-0.63-0.32-1.28-0.88-1.51c-0.55-0.24-1.18-0.16-1.58,0.32c-0.95,0.71,0,1.59,0,2.47c-0.95,1.27-2.46,2.39-3.64,3.1
			c-0.32-0.07-21.81,9.82-21.81,9.82c-3.16,1.35-6.32,2.87-9.64,3.58l-4.58,0.72c-0.32,0.97-1.11,1.53-1.98,1.84
			c-1.26,0.25-2.29,1.04-3.63,1.04c-10.28,3.98-20.94,8.21-29.08,16.34c0.47-0.95,0.71-2.15,0.79-3.35
			c-0.16-1.2-0.09-2.56-0.87-3.51c-0.19-0.43-0.77-1.72-0.58-2.18l-1.16,0.75c-0.71,1.76-1.34,4.94-0.63,6.77
			c0.23,1.44,1.42,3.11,0.32,4.39c-3.8,4.62-5.93,10.21-8.46,15.47c-0.95-0.16-1.42-1.04-1.82-1.91c2.69-3.43,4.82-7.26,5.61-11.63
			c0.55-0.4,0.15-1.45,0.7-1.92c-0.15-1.27,0.72-2.55,0.09-3.59c0.5-1.74-2.31-3.34-0.51-4.46l-1.5,0.72
			c-3.4,4.46,1.77,11.17-2.1,15.47v0.32c-1.11,1.43-1.66,2.87-3.17,3.83c-1.9-0.8-2.6-2.95-3.71-4.55c-1.74-1.67-1.74-5.1-0.71-7.41
			c0.71-2.31-0.47-4.31-0.71-6.38l-0.79-0.48c-1.9,2.47-2.61,6.38-1.11,9.33c0.79,1.43,0.87,3.43,2.06,4.62
			c0.71,2.32,2.12,3.83,3.4,5.9c0.32,0.79-0.47,0.95-0.87,1.35c-0.87-0.56-0.71-1.83-1.5-2.39c-2.61-5.58-6.01-10.04-10.11-14.6
			c-0.16-0.71-0.87-1.35-0.48-2.14c1.51-1.44,1.82-4.23,1.03-6.06c-0.16-0.4-0.63-0.88-0.55-1.28l-1.19-0.95
			c0.16,2.79-1.66,5.26-0.87,8.21v0.16c-8.14-6.13-17.13-11.62-26.3-15.92l-0.73,2.02c2.32,0.59,8.46,4.33,8.46,4.33
			c2.77,1.04,5.29,2.72,7.9,4.22c10.74,5.99,17.86,15.87,22.67,26.88c0.08,0.88,0.87,1.84,0.32,2.71c-1.66-2.55-4.03-3.98-6-6.07
			c-0.56-3.74-2.77-7.33-6.01-8.84c-1.19-1.75-3.72-1.91-5.69-2.47l-28.76-0.16c-23.39-0.32-44.73,0.71-67.95,0.32
			c-3.24-0.32-7.19-1.44-8.14-4.86c-0.63-1.2-0.63-3.27,0-4.39c0.87-1.51,2.45-1.99,4.03-2.16c1.27,0.16,2.14,1.36,2.69,2.24
			c0,1.04-0.87,1.99-1.27,2.86c0.08,0.32,0.4,0.96,0.95,0.72c1.02-0.4,1.82-1.51,2.06-2.55c0.07-0.56,0.31-1.35-0.24-1.76
			c-0.47-1.68-2.21-2.88-3.71-3.27c-1.98-0.16-4.03,0.39-5.37,1.83c-1.9,1.6-1.81,4.47-1.27,6.71c1.27,3.5,4.98,5.89,8.53,6.38
			l76.88,0.32c7.51,0.39,15.96-0.87,23.54,0.64c2.77,0.88,5.69,2.95,6.41,6.06c-0.08,0.16-0.32,0.07-0.56,0.07
			c-6.25-4.07-14.46-5.58-22.44-4.62c-3.16-0.15-6,1.04-8.85,1.84c-1.5,0.32-2.92-0.4-3.87-1.28c-2.85-2.55-7.82-2.39-11.46-1.44
			c0,0.25-0.08,0.48,0.08,0.56c3.87-0.32,6.16,3.03,9.64,3.43c0.79,0.16,1.42-0.71,2.14-0.08c-3.64,1.76-7.11,3.83-10.99,4.94
			c-1.18-0.23-1.81-1.35-2.76-1.98c-1.5-1.68-4.27-0.96-6.32-1.13c-0.71,0.16-1.34-0.31-1.81,0c0.63,1.28,2.05,2,3.39,2.64
			c1.97,0.87,4.27-0.09,6.08,1.11c-0.47,0.56-1.11,0.71-1.66,1.13c-3.71,1.43-6.8,4.78-10.9,4.53c-4.35,0.48-8.78-0.8-11.7-3.83
			c-1.27-3.1-4.5-4.78-7.98-4.62c-0.23,0.16-0.54,0.16-0.47,0.56c1.74,1.04,3.08,3.1,4.82,4.31c1.1,1.35,3.24,0,4.27,1.51
			c1.5,1.04,3.39,1.92,5.14,2.23c1.26,0.56,2.68,0.79,4.11,1.12l-0.56,0.32c-7.18,2.39-15.48,2.63-23.54,2.31
			c-2.37-0.08-4.67-0.8-7.03-0.72c0.4,0.48,1.18,0.87,1.9,1.11c-0.63,0.4-1.34,0.57-2.13,0.65c-0.94,0.23-2.05,0.31-3.01,0.79
			c-1.26-0.07-2.37,0.4-3.39,0.8c-1.34,1.43-2.92,2.95-4.5,3.67c0,0.32-0.08,0.8,0.32,0.8c1.5-0.64,3.39-0.16,4.89-0.8
			c2.61-0.24,4.11-3.11,6.16-4.31c2.21-1.76,5.37-1.35,8.06-0.95c1.58,0.39-0.16,1.04,0.31,2.07c0.63,0.56,1.19,1.19,2.06,0.95
			c0.16-0.16,0.31-0.32,0.55-0.39c0.47-0.63,0.4-1.35,0.08-1.99c1.11-0.72,2.61,0.08,3.8-0.4c3-0.07,6.01-0.71,8.93-0.79
			c0.47-0.09,1.03-0.56,1.5-0.09c-0.24,0.65-1.03,0.72-0.71,1.6c0.16,0.71,0.95,1.04,1.58,1.19c0.78-0.32,1.5-0.88,1.18-1.92
			c-0.16-0.55-0.78-0.63-1.18-0.88c1.65-0.79,3.55-1.03,5.21-1.82c1.18-0.25,2.29-0.64,3.31-1.12c1.03-0.08,1.9-1.04,2.84-0.64
			c-0.39,1.35-0.94,2.63-1.66,3.83c-1.26,4.23-2.21,9.81,0,13.88c0.25,0.07,0.32-0.09,0.47-0.16c-1.66-4.23-0.71-9.09,0.71-13.08
			c1.11-6.77,8.06-8.76,13.04-11.32l0.24,0.32c-1.34,3.5-3.32,6.86-3.01,11.08c0.16,0.72-0.31,1.44,0,1.91
			c1.34-0.72,2.21-2.31,2.93-3.67c0.56-3.58-1.5-8.04,2.13-10.68c6.48-2.55,12.57-5.9,20.07-5.98c0.24,0.24,0.79-0.16,0.86,0.32
			c-1.57,1.76-3.86,3.42-4.58,5.59c-2.53,3.5-2.45,9.48-1.34,13.79c0.71,2.47-1.42,3.83-2.45,5.74c-0.5,0.67-0.63,1.84-1.09,2.62
			l1.57,0.16c1.34-1.43,2.6-3.18,2.69-5.26c0.15,0.8,0.85,1.44,1.5,1.6c0.55-0.88-0.47-1.52-0.56-2.39
			c1.27-0.72,1.82,0.87,2.84,1.19c1.67,0.4,3.08,1.11,4.82,1.11l0.55-0.56c-1.74-2.71-5.21-3.19-8.22-3.35
			c-1.58-2.63-1.58-5.9-1.42-9.09c0.32-1.67,0.63-3.43,1.58-4.94c0.39-1.11,1.5-2.15,2.21-3.03c1.42-1.35,3.24-2.31,4.98-2.71h0.23
			c0,0.16,0.08,0.25,0.08,0.25c-2.13,1.68-2.37,3.99-3.63,6.06c-1.34,1.99-1.98,4.71-0.71,6.94c0.48,1.04,0.24,2.63,1.35,3.36
			c1.02-1.44,1.11-3.28,1.65-4.87c-0.24-2.63-1.81-5.1-0.31-7.64c0.79-2,2.46-2.79,4.27-3.51c4.35-0.4,8.14,1.83,11.93,3.51
			c0.8,0.56,2.14,1.04,2.3,2.15c-0.08,2.15-1.26,4.3-2.92,5.34c-2.13,1.28-5.22,1.44-7.19-0.07c-1.19-1.2-1.66-3.27-1.26-4.87
			c0.87-0.95,2.05-1.75,3.47-1.28c0.79,0.4,1.5,1.21,2.53,0.72c0.39-0.23,0.55-0.88,0.31-1.27c-0.87-0.48-1.18-1.67-2.37-1.84
			c-1.5-0.24-3.32-0.16-4.34,0.89c-1.66,1.43-2.3,3.58-1.82,5.57c0.55,2.55,2.45,4.39,4.9,5.11c3.64,0.88,7.43-0.24,9.72-3.35
			c0.95-1.04,1.18-2.15,1.74-3.43c3.79,2.31,6.63,6.38,8.13,10.53c0,1.35,0.4,2.79,0.08,3.98l2.77,0.24c-0.24-0.63,0-1.35,0.24-1.92
			c1.03-5.25,4.27-9.64,8.13-13.07c1.34-0.8,1.03,1.03,1.34,1.59c1.18,3.04,3.95,5.19,6.88,6.06c3.31,0.56,6.48-0.24,8.53-2.79
			c1.03-1.91,2.29-4.7,0.55-6.69c-1.1-1.35-2.6-2.07-4.34-2.07c-2.06,0-3.32,1.42-4.35,2.95c0,0.63-0.07,1.35,0.48,1.67
			c0.31,0,0.94,0.07,1.18-0.39c0.16-1.21,1.19-1.77,2.14-2.4c0.86-0.47,1.82,0,2.53,0.47c0.87,0.72,1.03,1.92,1.1,3.11
			c-0.16,1.51-1.18,3.11-2.6,3.74c-2.28,1.2-5.61,0.64-7.59-0.72c-0.95-1.03-1.49-2.23-2.21-3.51c-0.16-1.27-0.63-2.39,0-3.66
			c3.47-2.08,7.11-3.59,11.3-3.99c1.66,0.31,3.08,1.43,4.19,2.63c3.63,3.74-0.79,9.09,1.74,13.23c0.94-0.16,0.94-1.43,1.34-2.23
			c1.18-2.87-0.33-5.99-1.03-8.78c-0.96-1.75-1.52-3.26-3.01-4.7c0.39-0.16,0.55-0.16,0.95-0.16c1.66,1.11,3.47,2.38,4.82,4.13
			c2.29,3.76,3.48,8.22,3.08,13.09c-0.62,2.4-3.15,3.51-5.14,4.38c-0.78,0.64-1.72,1.6-2.13,2.55c0.94,0.57,2.29,0,3.24-0.39
			c1.5-0.56,2.37-2.48,3.4-3.35c0.16,0.08,0.09,0.32,0.16,0.47c0.09,0.89-0.4,2.07,0.39,2.32c0.4-1.12,0.48-2.71,0.71-4.08
			c1.03,1.12,1.19,2.56,1.5,3.99c0.71,1.44,1.9,3.35,3.8,3.58c0.47-0.71-0.08-1.44-0.24-2.07c-0.23-1.99-1.9-3.03-3-4.46
			c-2.77-3.83-1.51-9.81-3.64-14.12c-0.78-2.31-2.45-3.99-4.27-5.9l0.32-0.32c3.87-0.08,7.11,1.2,10.67,1.92
			c4.34,2.15,10.66,1.67,12.72,7.33c0.87,3.2,2.28,6.14,4.5,8.62l0.24-0.16c0.47-0.8,0.47-2.15,0-2.95
			c-0.47-3.98-3.95-4.94-5.77-8.29l0.24-0.32c1.9,0.56,3.87,1.04,5.76,1.84c6.32,1.76,10.75,7.97,11.54,14.43
			c0,5.11-0.95,9.33-2.92,13.63c-0.23,0.65-1.34,1.37-0.64,1.92c0.87-0.65,1.03-1.69,1.5-2.4c2.62-4.39,3.95-10.44,2.62-16.02
			c-0.09-1.03-1.27-1.84-0.71-2.87c0.4,0.48,0.86,0.56,1.34,0.72c0.47-0.32,1.19-0.32,1.5-0.87c0.09-0.48,0.4-1.04,0-1.44
			c-0.47-0.71-1.5-0.71-2.28-0.55c-0.4,0.47-0.87,1.11-0.95,1.75c-0.79-1.36-1.58-3.11-2.84-4.23l0.16-0.24
			c3.55,1.2,6.8,2.95,10.43,3.9c0.78,0.49,1.65,0.89,2.77,0.89c3.08,1.19,6.48,1.67,9.71,2.46c2.37-0.24,4.35,0.88,6.72,0.32v-0.16
			l-0.08,0.07c-0.87-1.11-2.77-0.07-3.79-0.87c-5.45-0.56-10.67-1.99-15.58-3.9c0.09-0.25,0.4-0.16,0.64-0.16
			c0.78-0.4,1.34-1.12,1.26-2c-0.39-0.56-1.03-0.88-1.82-0.88c-0.47,0.24-1.11,0.79-1.19,1.35c0.08,0.64,0.64,1.35,1.19,1.52
			c-0.16,0.16-0.47,0.07-0.71,0.07c-2.45-1.12-4.82-2.15-7.19-3.26c-0.87-0.17-1.58-0.88-2.45-1.04c-1.82-0.88-3.64-1.76-5.46-2.47
			c-5.04-2.64-10.66-4.06-16.03-5.98c-1.19-0.32-2.29-0.63-3.4-0.95c-1.97-0.56-3.95-1.04-5.93-1.6c-6.7-1.67-15.71-1.83-21.56,1.83
			c-0.95-0.32-1.51,1.2-1.97,0.25c1.65-4.39,6.47-5.82,10.82-5.99c31.67-0.32,61.39-0.56,92.6-1.04c3.63,0,7.19-1.27,9.09-4.54
			C378.36,113.15,378.6,109.88,377.57,107.72z"
          />
          <path
            d="M337.67,132.12c0,0.25,0.48,0.32,0.32,0.72c-2.77,2.47-6.64,3.19-10.58,3.35c-0.33,0.07-0.64,0.32-1.03,0.23
			c-0.24-0.16-0.32-0.39-0.16-0.55C329.06,132.76,333.41,131.17,337.67,132.12"
          />
          <path
            d="M324.95,133.4c0.31,1.11-0.4,1.76-1.27,2.15c-0.87-0.07-1.34-0.63-1.73-1.27c-0.24-0.64,0.31-0.96,0.54-1.35
			C323.13,132.27,324.64,132.27,324.95,133.4"
          />
          <path
            d="M174.75,133.56c0.17,0.56,0.08,1.27-0.39,1.67c-0.31,0.4-1.02,0.48-1.42,0.4c-0.55-0.08-0.87-0.71-1.03-1.2
			c0-0.71,0.4-1.12,1.03-1.51C173.57,132.92,174.28,132.84,174.75,133.56"
          />
          <path
            d="M160.61,138.74v0.56c-1.89,0.4-3.71-0.07-5.14-1.19c-1.1-1.6-2.37-3.19-4.18-3.99c0-0.24-0.47-0.56,0-0.72
			C155.25,133.4,157.61,136.6,160.61,138.74"
          />
          <path
            d="M349.69,133.49c-1.83,2.71-3.4,6.06-6.63,7.25c-0.56,0-1.03,0.8-1.51,0.24c1.26-3.03,3.95-6.3,7.03-7.57
			C348.9,133.4,349.36,133.32,349.69,133.49"
          />
          <path
            d="M179.35,134.43c0.08,0.88-0.56,1.04-1.11,1.51c-0.64,0.09-0.95-0.4-1.34-0.88c-0.32-0.79,0.4-1.11,0.79-1.51
			C178.32,133.4,179.03,133.88,179.35,134.43"
          />
          <path
            d="M359.64,134.92c-0.71,1.03-0.4,2.55-1.11,3.66c-1.59,1.83-2.92,3.91-5.45,4.31c-0.4-0.81,0.55-1.6,0.94-2.32
			c1.11-2.39,2.7-5.1,5.22-6.14L359.64,134.92"
          />
          <path
            d="M136.12,135.63c0.24,0.56,0.48,1.11,0,1.75c-0.47,0.4-1.18,0.56-1.81,0.32c-0.71-0.4-0.96-1.28-0.64-1.92
			C134.15,135.07,135.57,134.67,136.12,135.63"
          />
          <path
            d="M131.62,136.6c0.07,0.4,0.31,0.71,0.24,1.11c-0.24,0.4-0.63,0.88-1.1,0.96c-0.87,0.16-1.42-0.32-1.82-1.04
			c-0.08-0.71,0.15-1.35,0.78-1.67C130.44,135.63,131.23,136.03,131.62,136.6"
          />
          <path
            d="M140.95,137.07c0.16,0.64,0.31,1.76-0.64,1.92c-1.1,0.48-1.74-0.32-2.37-0.97c0-0.71,0.4-1.27,0.95-1.59
			C139.68,136.35,140.39,136.42,140.95,137.07"
          />
          <path
            d="M153.9,138.11c-0.07,0.56,0.08,1.35-0.55,1.51c-0.39,0.4-1.1,0.24-1.5,0.16c-0.16-0.49-1.03-0.88-0.55-1.44
			c0.31-0.4,0.63-0.64,1.1-0.88C152.87,137.7,153.59,137.47,153.9,138.11"
          />
          <path
            d="M367.22,138.67c-0.24,0.56-0.55,1.28-1.19,1.28c-0.71-0.09-1.42-0.16-1.82-0.89
			c-0.31-0.87,0.4-1.35,1.03-1.59C366.03,137.47,367.07,137.79,367.22,138.67"
          />
          <path
            d="M352.77,139.06c0.08,0.65-0.63,1.12-0.96,1.36c-1.1,0.47-2.04-0.32-2.45-1.19c0.16-0.72,0.72-1.21,1.34-1.44
			C351.74,137.7,352.29,138.26,352.77,139.06"
          />
          <path
            d="M362.55,138.9c0,0.56-0.31,1.12-0.7,1.35c-0.64,0.24-1.42,0.16-1.9-0.31c-0.71-0.65-0.16-1.21,0.32-1.68
			C360.99,137.87,362.33,137.94,362.55,138.9"
          />
          <path
            d="M134.07,140.26c0.16,0.87-0.15,1.68-0.94,1.99c-0.71,0-1.5-0.39-1.74-1.12c-0.08-0.71,0.16-1.43,0.8-1.67
			C132.96,139.22,133.6,139.7,134.07,140.26"
          />
          <path
            d="M348.82,141.13c0.23,0.89-0.24,1.68-1.11,2.08c-0.63,0.16-1.03-0.16-1.5-0.56c-0.32-0.4-0.71-1.28-0.08-1.68
			C346.76,139.78,348.34,140.26,348.82,141.13"
          />
          <path
            d="M176.58,142.02c0.32,0.88-0.63,1.04-0.87,1.51c-0.31,0.16-0.95,0.24-1.18-0.07
			c-0.39-0.48-0.87-0.96-0.63-1.68c0.39-0.32,0.55-0.64,1.03-0.8C175.79,140.74,176.11,141.53,176.58,142.02"
          />
          <path
            d="M365.79,142.57c0.17,0.55-0.31,0.79-0.39,1.19c-0.48,0.4-1.26,0.48-1.83,0.32c-0.71-0.4-0.71-1.04-0.71-1.76
			c0.48-0.31,0.55-0.71,1.11-0.88C364.7,141.45,365.57,141.77,365.79,142.57"
          />
          <path
            d="M360.9,142.81c0.32,0.96-0.55,1.44-1.18,1.67c-0.32,0.08-0.78-0.16-0.88,0.32c2.06,1.04,3.33,2.72,4.91,4.23
			v0.63c-2.85-0.07-5.77-0.79-7.83-3.02c-0.55-0.4-1.5-0.4-2.13-0.96c1.03-1.04,2.93-0.64,4.34-0.72c1.03-1.12-1.19-1.91,0.16-2.87
			c0.48-0.07,0.8-0.56,1.43-0.39C360.27,141.94,360.59,142.33,360.9,142.81"
          />
          <path
            d="M328.59,142.9c0.16,0.39-0.31,0.87-0.4,1.27c-0.47,0.48-1.18,0.55-1.73,0.32c-0.4-0.55-1.03-1.03-0.79-1.83
			C326.3,141.45,328.36,141.7,328.59,142.9"
          />
          <path
            d="M151.93,145.68c-0.24,0.72-0.56,1.19-1.34,1.27c-0.64-0.08-1.19-0.31-1.43-0.95
			c-0.08-0.56,0.32-0.88,0.4-1.28C150.43,144.01,152.01,144.48,151.93,145.68"
          />
          <path
            d="M158.33,144.64c0,1.12-1.19,1.76-1.58,2.87c-1.19,2.63-3.32,5.02-6.32,5.26c-0.32-0.72,1.03-1.58,1.34-2.31
			c1.27-2.71,3.79-4.15,6.08-5.82H158.33"
          />
          <path
            d="M326.69,145.76c0.08,0.88-0.71,1.51-1.34,1.83c-0.71,0.24-1.27-0.32-1.66-0.64
			c-0.63-0.64-0.23-1.27,0.09-1.91C324.71,144.09,326.06,144.87,326.69,145.76"
          />
          <path
            d="M331.59,146.31c-0.09,0.88-0.64,1.52-1.43,1.76c-0.16-0.25-0.55-0.09-0.78-0.16
			c-0.56-0.32-1.03-0.8-1.03-1.44c0.39-0.39,0.55-1.04,1.1-1.11C330.17,145.28,331.35,145.36,331.59,146.31"
          />
          <path
            d="M170.58,146.24c0.08,0.88-0.56,1.36-1.19,1.68c-0.71,0.07-1.18-0.4-1.5-0.96c-0.16-0.72,0.55-1.19,0.94-1.59
			C169.79,145.04,170.02,145.76,170.58,146.24"
          />
          <path
            d="M176.02,146.56c-0.24,0.72-0.71,1.19-1.5,1.35c-0.56-0.16-1.18-0.32-1.43-0.96c-0.07-0.8,0.56-1.19,1.11-1.59
			C175,145.36,175.87,145.68,176.02,146.56"
          />
          <path
            d="M348.58,152.13c0.4,0.32,1.11,0.72,0.95,1.28c-1.27,0.56-2.29-0.88-3.48-1.12c-1.9-1.91-3.31-3.99-4.1-6.38
			c0.71-0.71,1.49,0.16,2.13,0.4C345.89,147.83,347.63,149.83,348.58,152.13"
          />
          <path
            d="M351.5,146.56c0.24,0.56,0.16,1.19-0.16,1.75c-0.47,0.81-1.5,0.64-2.21,0.49c-0.4-0.49-1.1-1.12-0.63-1.84
			c0.23-0.64,0.94-0.8,1.5-1.04C350.55,145.84,350.95,146.31,351.5,146.56"
          />
          <path
            d="M183.29,148.63c0.08,0.64,0.24,1.59-0.55,1.99c-0.72,0.16-1.66-0.07-1.9-0.96c-0.39-0.95,0.47-1.12,0.95-1.58
			C182.43,147.91,182.82,148.23,183.29,148.63"
          />
          <path
            d="M331.35,153.82c1.11,1.58,1.9,3.58,2.06,5.66c-0.23,0.56,0.4,1.12-0.08,1.51h-0.31
			c-3.56-2.55-4.35-6.15-5.69-9.73c-0.31-0.16-0.4-0.47-0.31-0.79c0.78-0.72,1.34,0.72,2.12,0.96L331.35,153.82"
          />
          <path
            d="M193.56,151.1c-0.08,0.09,0,0.16,0.08,0.16c-3.15,2.24-7.58,3.59-11.21,1.44c-0.08-0.24,0.08-0.63,0.39-0.72
			c2.3-0.72,4.51-1.83,6.95-1.51L193.56,151.1"
          />
          <path
            d="M175.55,151.5c-2.68,1.68-5.14,4.95-8.46,5.75c-0.63,0.24-1.02,1.11-1.81,0.64
			c1.42-4.32,6.08-6.63,10.27-7.02V151.5"
          />
          <path
            d="M326.69,155.09c0.23,0.64,0,1.51-0.63,1.67c-0.4,0.4-1.2,0.4-1.51,0c-0.71-0.32-0.71-1.04-0.71-1.6
			c0.24-0.56,0.8-0.63,1.19-0.87C325.74,154.45,326.3,154.45,326.69,155.09"
          />
          <path
            d="M320.13,155.49c-0.47,1.11-1.81,1.75-2.84,2.07c-2.69,0.95-5.14-0.32-7.51-0.96c0.08-0.64,0.87-0.4,1.27-0.8
			c2.61-0.56,5.3-1.11,8.06-0.4C319.34,155.25,319.89,155.16,320.13,155.49"
          />
          <path
            d="M175.55,157.8c-0.24,0.32-0.31,1.04-0.95,1.27c-0.55,0.25-1.1,0-1.57-0.31c-0.16-0.49-0.71-0.88-0.32-1.44
			c0.16-0.56,0.79-0.72,1.26-0.88C174.75,156.53,175.39,157,175.55,157.8"
          />
          <path
            d="M180.22,158.52c-0.24,0.48-0.48,1.2-1.11,1.52c-0.63,0.16-1.1-0.16-1.58-0.56c-0.23-0.47-0.47-1.03-0.23-1.59
			c0.16-0.4,0.71-0.48,1.1-0.72C179.18,157.08,179.9,157.88,180.22,158.52"
          />
          <path
            d="M325.19,159.32c0.08,0.63-0.23,1.04-0.54,1.51c-0.4,0.56-1.19,0.63-1.75,0.32c-0.55-0.08-0.71-0.72-0.94-1.12
			c-0.47-0.64,0.31-1.04,0.54-1.59C323.37,157.72,324.8,158.12,325.19,159.32"
          />
          <path
            d="M328.67,159.16c0.08,0.4,0.31,0.8,0.16,1.12c-0.47,0.4-0.79,1.43-1.74,1.04c-0.47,0.16-0.7-0.48-0.94-0.63
			c-0.32-0.79-0.08-1.99,0.94-2.16C327.8,158.28,328.28,158.91,328.67,159.16"
          />
          <path
            d="M177.37,161.46c0.15,0.89-0.16,1.68-0.8,2.16c-0.87,0.16-1.98,0.16-2.29-0.89c-0.08-0.79,0-1.82,0.96-2.3
			C176.02,160.35,176.97,160.51,177.37,161.46"
          />
          <path
            d="M181.87,161.15c-0.16,2.23-0.63,4.94-2.85,6.22c-1.27,0.24-2.53,1.44-3.79,0.64v-0.4
			C176.89,165.22,179.9,163.39,181.87,161.15"
          />
          <path
            d="M312.79,162.99c0.31,0.47-0.23,0.88-0.47,1.27c-0.47,0.57-1.34,0.32-1.9,0.09c-0.56-0.24-0.64-1.04-0.4-1.6
			c0.16-0.47,0.63-0.71,1.03-0.88C311.76,161.79,312.39,162.43,312.79,162.99"
          />
          <path
            d="M189.77,166.64c1.1,1.84,0.47,4.31,1.1,6.31c-1.34,0.16-1.89-1.36-2.76-2.15c-1.74-2.4-1.5-5.58-2.29-8.38
			c0.16-0.24,0.4-0.24,0.56-0.16c1.18,0.8,2.05,2.31,3,3.34C189.22,166.17,189.85,166.17,189.77,166.64"
          />
          <path
            d="M316.73,163.62c0,0.63-0.71,0.8-1.1,1.2c-0.56-0.08-1.03-0.32-1.51-0.72c-0.23-0.79,0.39-1.03,0.79-1.43
			C315.71,162.35,316.5,162.74,316.73,163.62"
          />
          <path
            d="M326.14,170.72c0,0.24-0.08,0.72-0.47,0.56h-0.32c-2.29-2.08-4.59-4.38-4.26-7.58l0.46-0.07
			C323.69,165.45,326.06,167.45,326.14,170.72"
          />
          <path
            d="M320.21,168.16c0.08,0.64-0.32,1.12-0.71,1.52c-0.87,0.24-2.21,0.16-2.37-1.04c-0.08-0.8,0.79-1.2,1.19-1.6
			C319.27,166.81,319.89,167.45,320.21,168.16"
          />
          <path
            d="M315,169.84c-1.1,2.95-4.12,4.47-6.17,6.69c-0.39-0.24-0.23-0.87-0.16-1.19c0.81-3.03,3.17-5.98,6.09-7.18
			C316.02,168.33,314.76,169.2,315,169.84"
          />
          <path
            d="M320.93,172.23c0,0.72-0.71,1.04-1.11,1.44c-0.63-0.08-1.26-0.24-1.74-0.88c-0.07-0.79,0.55-1.03,1.03-1.43
			C319.89,171.19,320.61,171.6,320.93,172.23"
          />
          <path
            d="M262.25,28.1h-0.88c-2.29,0-0.35,2.78,0.89,2.78C263.78,30.87,263.88,28.1,262.25,28.1 M255.17,30.87
			c2.29,0,0.13-2.78-1.11-2.78h-2.29c-2.29,0-0.13,2.78,1.11,2.78H255.17z M245.57,30.87c2.3,0,0.13-2.78-1.1-2.78h-2.29
			c-2.29,0-0.12,2.78,1.1,2.78H245.57z M262.13,27.46c1.08,0,1.96,0.89,1.96,1.97c0,1.1-0.88,1.97-1.96,1.97H238.7
			c-1.08,0-1.95-0.88-1.95-1.97c0-1.09,0.87-1.97,1.95-1.97H262.13z"
          />
          <path
            d="M262.3,63.86c0,1.04,1.39,0.35,2.42,0.35c1.03,0,2.42,0.69,2.42-0.35c0-1.48-1.38-0.96-2.42-0.96
			C263.69,62.9,262.3,62.38,262.3,63.86 M266.69,66.31c1,0,1.09-1.39,1.09-2.58c0-2.72-2.15,0-3.25-2.56
			c-0.8,2.16-3.01,0.16-3.01,2.48c0,1.61-0.01,2.67,1.58,2.67H266.69z M260.08,54.4l4.47,1.72l4.4-1.72l-1.74,6.61l3.49-0.72
			l-1.19,4l1.19,3.84l-3.09-0.83l1.34,7.12l-4.4-1.73l-4.47,1.73l1.83-7.12l-3.57,0.99l0.95-4l-0.95-3.84l3.41,0.56L260.08,54.4z"
          />
          <polyline
            points="249.6,64.11 249.6,63.28 248.85,63.14 249.44,62.53 249.08,61.85 249.85,61.93 250.31,61.11 
			250.76,61.92 251.55,61.85 251.19,62.53 251.77,63.14 251.02,63.28 251.02,64.11 250.31,63.58 249.6,64.11 		"
          />
          <polyline
            points="246.12,68.6 246.12,67.76 245.38,67.62 245.97,67.01 245.6,66.33 246.37,66.41 246.84,65.59 
			247.29,66.4 248.07,66.33 247.71,67.01 248.3,67.62 247.55,67.76 247.55,68.6 246.84,68.07 246.12,68.6 		"
          />
          <polyline
            points="252.02,68.03 252.02,67.19 251.28,67.05 251.86,66.44 251.5,65.76 252.26,65.84 252.73,65.03 
			253.18,65.83 253.97,65.76 253.61,66.44 254.19,67.05 253.44,67.19 253.44,68.03 252.73,67.5 252.02,68.03 		"
          />
          <polyline
            points="252.72,71.38 252.72,70.9 252.29,70.82 252.63,70.47 252.42,70.1 252.86,70.14 253.12,69.68 
			253.37,70.14 253.82,70.1 253.62,70.47 253.94,70.82 253.52,70.9 253.52,71.38 253.12,71.07 252.72,71.38 		"
          />
          <polyline
            points="247.25,75.2 247.25,73.78 245.96,73.52 246.97,72.48 246.34,71.32 247.66,71.47 248.46,70.07 
			249.24,71.44 250.57,71.32 249.95,72.48 250.96,73.52 249.67,73.78 249.67,75.2 248.46,74.31 247.25,75.2 		"
          />
          <polyline
            points="250.03,0 252.82,6.85 259.86,4.76 256.29,11.24 262.3,15.5 255.05,16.71 255.49,24.11 250.03,19.16 
			244.58,24.11 245.02,16.71 237.78,15.5 243.78,11.24 240.2,4.76 247.25,6.85 250.03,0 		"
          />
          <path
            d="M224.54,153.76c-0.84,0.32-1.16,0.54-0.96,1.42l0.99,4.2c0.19,0.77,0.24,2-0.28,2.75
			c-0.61,0.9-1.92,1.48-2.86,1.7c-0.69,0.16-2.18,0.44-3.38-0.06c-0.79-0.35-1.31-0.71-1.64-2.13l-1.04-4.4
			c-0.26-1.11-0.67-1.03-1.56-0.91l-0.09-0.33l5.3-1.27l0.08,0.33c-1.03,0.28-1.51,0.42-1.24,1.57l1.03,4.41
			c0.18,0.74,0.61,2.57,2.86,2.02c2.76-0.66,2.32-2.69,2.12-3.53l-0.94-4.02c-0.19-0.79-0.49-1.2-1.85-0.93l-0.07-0.33l3.45-0.83
			L224.54,153.76"
          />
          <path
            d="M232.39,154.64c-0.48-0.86-1.76-2.17-3.62-1.75c-0.82,0.18-1.6,0.77-1.41,1.63c0.16,0.72,0.52,0.94,1.97,1.2
			l2.53,0.45c0.58,0.11,1.95,0.6,2.26,2.06c0.25,1.16-0.4,2.99-3.29,3.62c-1.5,0.34-2.3,0.05-2.79,0.15
			c-0.33,0.08-0.35,0.33-0.37,0.56l-0.46,0.1l-0.71-3.27l0.45-0.1c0.61,1.27,1.85,2.55,3.85,2.12c1.89-0.42,1.68-1.76,1.64-1.92
			c-0.19-0.88-1-1.04-2.12-1.22l-1.15-0.19c-2.93-0.49-3.18-1.62-3.31-2.23c-0.18-0.81-0.08-2.71,2.91-3.38
			c1.26-0.28,2.1-0.01,2.55-0.11c0.36-0.08,0.4-0.3,0.42-0.56l0.47-0.11l0.64,2.86L232.39,154.64"
          />
          <path
            d="M242.82,159.87l-5.73,0.55l-0.03-0.33c1.17-0.19,1.55-0.26,1.45-1.39l-0.68-7.11
			c-1.12,0.16-2.77,0.39-2.88,2.58l-0.46,0.04l-0.22-2.72l9.63-0.93l0.29,2.71l-0.46,0.05c-0.53-2.13-2.19-2.04-3.3-1.98l0.67,7.12
			c0.11,1.14,0.5,1.13,1.69,1.09L242.82,159.87"
          />
          <path
            d="M249.24,155.06c2.11,0.02,2.95-0.26,2.99-2.02c0.02-1.37-0.63-1.97-1.91-2c-0.89-0.02-0.92,0.14-1.01,0.55
			L249.24,155.06 M256.22,159.87l-3.3-0.07l-3.25-4.3l-0.44-0.01l-0.05,2.65c-0.02,1.09,0.33,1.13,1.39,1.28l0,0.33l-5.44-0.11
			v-0.33c1.1-0.09,1.43-0.13,1.44-1.23l0.12-5.98c0.03-1.17-0.48-1.21-1.39-1.28l0.01-0.34l5.19,0.1c1.13,0.02,4.54,0.2,4.49,2.48
			c-0.03,1.67-1.69,2.05-2.57,2.27l3.17,3.92c0.22,0.25,0.46,0.27,0.65,0.28L256.22,159.87z"
          />
          <path
            d="M262.31,157.43l-1.21-3.46l-1.9,3.08L262.31,157.43 M266.79,161.23l-5.17-0.65l0.03-0.34
			c1.01,0.06,1.31,0.08,1.35-0.36c0.05-0.36-0.34-1.43-0.51-1.88l-3.6-0.46c-0.57,0.91-0.89,1.43-0.92,1.65
			c-0.06,0.51,0.71,0.64,1.25,0.74l-0.05,0.33l-3.25-0.4l0.05-0.34c0.87,0,1.16-0.42,1.76-1.38l4.45-6.92l0.45,0.05l2.69,7.52
			c0.54,1.53,0.75,2.02,1.51,2.09L266.79,161.23z"
          />
          <path
            d="M276.17,163.15l-9.17-1.69l0.07-0.33c0.9,0.07,1.38,0.12,1.54-0.69l1.16-6.45c0.16-0.88-0.47-1.03-1.19-1.2
			l0.06-0.33l5.52,1.02l-0.05,0.34c-1.11-0.16-1.61-0.25-1.83,0.93l-1.13,6.29c-0.12,0.63,0.21,0.81,1.27,1
			c2.39,0.44,3.48-0.5,4.48-1.88l0.46,0.09L276.17,163.15"
          />
          <path
            d="M282.25,164.51l-5.52-1.16l0.07-0.33c0.86,0.11,1.5,0.18,1.65-0.64l1.33-6.42c0.19-0.87-0.55-1.06-1.27-1.25
			l0.06-0.33l5.52,1.15l-0.07,0.33c-0.75-0.12-1.51-0.27-1.69,0.63l-1.32,6.43c-0.18,0.84,0.44,1.02,1.3,1.25L282.25,164.51"
          />
          <path
            d="M288.89,162.25l-1.29-3.44l-1.83,3.12L288.89,162.25 M293.46,165.95l-5.2-0.54l0.04-0.33
			c0.99,0.03,1.28,0.04,1.33-0.4c0.04-0.36-0.37-1.41-0.55-1.87l-3.61-0.38c-0.54,0.92-0.86,1.45-0.88,1.67
			c-0.05,0.51,0.71,0.63,1.26,0.72l-0.04,0.33l-3.25-0.33l0.02-0.33c0.89-0.02,1.16-0.46,1.73-1.41l4.32-7.03l0.44,0.05l2.87,7.47
			c0.57,1.52,0.78,2,1.55,2.05L293.46,165.95z"
          />
          <path
            d="M249.44,57.22c-0.95,0-2.23,0.65-2.23-0.47c0-1.6,1.08-1.24,2.03-1.24c0.94,0,2.42-0.36,2.42,1.24
			C251.66,57.87,250.39,57.22,249.44,57.22 M252.26,56.59c0-3.25-1.82-0.2-2.83-2.97c-0.74,2.34-2.93,0.37-2.93,2.89
			c0,0.96,0.25,1.52,0.71,1.83v0.96c0,0.25,0.2,0.45,0.44,0.45h3.46c0.24,0,0.45-0.2,0.45-0.45v-1.04
			C251.97,57.9,252.26,57.34,252.26,56.59z"
          />
          <path
            d="M280.53,96.55c0.82,0,1.49-0.59,1.49-1.32c0-0.72-0.67-1.31-1.49-1.31c-0.83,0-1.5,0.59-1.5,1.31
			C279.03,95.96,279.7,96.55,280.53,96.55"
          />
          <path
            d="M273.52,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.38,0,0.71-0.35,0.71-0.79
			C274.24,49.7,273.52,48.33,273.52,48.33"
          />
          <path
            d="M225.8,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C226.52,49.7,225.8,48.33,225.8,48.33"
          />
          <path
            d="M237.73,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C238.45,49.7,237.73,48.33,237.73,48.33"
          />
          <path
            d="M249.66,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C250.38,49.7,249.66,48.33,249.66,48.33"
          />
          <path
            d="M261.59,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.4,0,0.71-0.35,0.71-0.79
			C262.31,49.7,261.59,48.33,261.59,48.33"
          />
          <path
            d="M225.82,64.74c0,0,0.7,1.18,0.7,1.4c0,0.43-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.35-0.71-0.78
			C225.11,66.12,225.82,64.74,225.82,64.74"
          />
          <path
            d="M225.82,80.68c0,0,0.7,1.19,0.7,1.4c0,0.42-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.36-0.71-0.78
			C225.11,82.06,225.82,80.68,225.82,80.68"
          />
          <path
            d="M227.73,93.31c0,0,0.7,1.18,0.7,1.41c0,0.43-0.32,0.77-0.71,0.77c-0.4,0-0.71-0.34-0.71-0.77
			C227.02,94.69,227.73,93.31,227.73,93.31"
          />
          <path
            d="M237.75,100.02c0,0,0.7,1.18,0.7,1.4c0,0.43-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.35-0.71-0.78
			C237.02,101.4,237.75,100.02,237.75,100.02"
          />
          <path
            d="M274.13,64.74c0,0-0.7,1.18-0.7,1.4c0,0.43,0.32,0.78,0.71,0.78c0.4,0,0.71-0.35,0.71-0.78
			C274.85,66.12,274.13,64.74,274.13,64.74"
          />
          <path
            d="M274.13,80.68c0,0-0.7,1.19-0.7,1.4c0,0.42,0.32,0.78,0.71,0.78c0.4,0,0.71-0.36,0.71-0.78
			C274.85,82.06,274.13,80.68,274.13,80.68"
          />
          <path
            d="M272.22,93.31c0,0-0.7,1.18-0.7,1.41c0,0.43,0.33,0.77,0.71,0.77c0.39,0,0.71-0.34,0.71-0.77
			C272.94,94.69,272.22,93.31,272.22,93.31"
          />
          <path
            d="M262.21,100.02c0,0-0.7,1.18-0.7,1.4c0,0.43,0.31,0.78,0.71,0.78s0.71-0.35,0.71-0.78
			C262.93,101.4,262.21,100.02,262.21,100.02"
          />
          <path
            d="M250.02,107.13c0,0-0.69,1.18-0.69,1.41c0,0.43,0.31,0.78,0.71,0.78c0.39,0,0.7-0.35,0.7-0.78
			C250.75,108.51,250.02,107.13,250.02,107.13"
          />
          <path
            d="M269.44,83.34c0.15,0.24,0.53,0.31,1,0.14l0.44-1.29c0.05-0.08-0.52-0.6-0.85-0.51
			c-1.27,0.3-2.62,0.46-3.37,1.66c0,0.84,0.6,1.6,1.2,1.97c0.45-0.15,0.53,0.3,0.82,0.53c0.09,0.38-0.15,0.69-0.52,0.69
			c-0.9-0.92-2.47,0.3-3.15-0.84c0.3-1.13,0.89-2.42-0.09-3.48c0.09-0.23,0.45-0.46,0.23-0.68c-0.37-1.07-1.49-0.23-2.17-0.23
			c-1.13-0.15-2.04,0.46-2.71,1.36c0.08,0.38,0.23,0.76,0.61,1.14c0.3-0.07,0.45,0,0.52,0.3l-0.75,0.46
			c0.23,0.22,0.23,0.53,0.3,0.83c-0.52,0.22-1.04-0.53-1.27-0.91c0-0.6,0.08-1.66-0.75-1.82c-0.15,0-0.65,0.23-0.65,0.23
			c-0.18,0.23-0.3,0.7-0.3,1.25c0,0.54,0.3,1.35,0.3,1.35s2.32,1.71,2.84,2.78c-0.68,1.36-0.68,3.25-2.25,4.01
			c-0.46,0.3-0.46,0.76-0.46,1.21l0.38,0.31c0.98-0.54,2.85-0.69,2.7-2.35c0.38-0.68,0.75-1.43,1.43-1.97
			c0.76-0.15,1.43,0.23,1.73,0.84c-0.23,1.13-0.97,1.89-2.03,2.27c-0.15,0.16-0.08,0.38-0.08,0.61c0.3,0.53,1.13,0.3,1.58,0.22
			c1.27-0.83,1.8-2.35,2.93-2.8c0.15,0.75-1.5,0.98-0.45,1.74c0.68-0.15,1.58,0.08,2.03-0.84c-0.53-1.28,1.79-1.89,0.37-3.03
			c0-1.13,1.21-1.58,1.13-2.8c-0.45-1.21-1.87-1.21-2.25-2.35C268.32,83.04,268.98,83.11,269.44,83.34"
          />
          <path
            d="M214.62,97.13c0.82,0,1.48-0.59,1.48-1.31c0-0.73-0.66-1.32-1.48-1.32c-0.83,0-1.5,0.59-1.5,1.32
			C213.13,96.54,213.79,97.13,214.62,97.13"
          />
          <path
            d="M137.24,142.43c0.81,0,1.46-0.63,1.46-1.41c0-0.78-0.65-1.41-1.46-1.41c-0.81,0-1.46,0.63-1.46,1.41
			C135.78,141.8,136.43,142.43,137.24,142.43"
          />
          <path
            d="M186.28,153.29c0.07,0,0.09-0.01,0.09-0.06c0-0.05-0.02-0.06-0.06-0.06c-0.03,0-0.03,0.01-0.03,0.02V153.29
			 M186.49,153.42h-0.1l-0.09-0.12h-0.02v0.08c0,0.03,0.01,0.03,0.05,0.04v0.01h-0.17v-0.01c0.04-0.01,0.05-0.01,0.05-0.04v-0.18
			c0-0.03-0.02-0.04-0.05-0.04v-0.01h0.16c0.03,0,0.13,0.01,0.13,0.08c0,0.04-0.05,0.06-0.07,0.07l0.09,0.11
			C186.47,153.41,186.49,153.41,186.49,153.42L186.49,153.42z"
          />
          <path
            d="M186.7,153.24c-0.01-0.03-0.05-0.07-0.1-0.07c-0.02,0-0.05,0.02-0.05,0.04c0,0.02,0.01,0.03,0.05,0.04
			l0.07,0.04c0.02,0,0.05,0.03,0.05,0.07c0,0.04-0.03,0.09-0.12,0.09c-0.04,0-0.07-0.02-0.08-0.02c-0.01,0-0.01,0-0.01,0.02h-0.02
			v-0.11h0.02c0.01,0.04,0.03,0.09,0.09,0.09c0.06,0,0.06-0.04,0.06-0.05c0-0.02-0.02-0.03-0.05-0.05l-0.03-0.01
			c-0.09-0.03-0.09-0.07-0.09-0.08c0-0.03,0.02-0.08,0.1-0.08c0.04,0,0.07,0.01,0.08,0.01c0.01,0,0.01-0.01,0.02-0.01h0.01v0.09
			H186.7"
          />
          <path
            d="M187.19,153.16c-0.02,0-0.03,0.02-0.03,0.03l-0.1,0.24h-0.01l-0.08-0.19l-0.09,0.19h-0.01l-0.1-0.24
			c-0.01-0.02-0.02-0.02-0.03-0.03v-0.01h0.14v0.01c-0.02,0-0.03,0.01-0.03,0.02c0,0,0,0.01,0.01,0.02l0.05,0.14h0l0.05-0.12
			l-0.02-0.05c-0.01-0.01-0.01-0.02-0.04-0.02v-0.01h0.15v0.01c-0.02,0-0.04,0-0.04,0.02c0,0.01,0,0.02,0.01,0.02l0.05,0.13
			l0.05-0.13c0,0,0-0.01,0-0.02c0-0.02-0.02-0.02-0.04-0.02v-0.01L187.19,153.16L187.19,153.16"
          />
          <path
            d="M187.32,153.31c0,0,0.02-0.01,0.02-0.02c0,0,0-0.02,0-0.03c0-0.08-0.01-0.11-0.04-0.11
			c-0.03,0-0.03,0.02-0.03,0.06C187.27,153.27,187.28,153.31,187.32,153.31 M187.2,153.42c0.05-0.02,0.1-0.03,0.12-0.11
			c0,0-0.01,0-0.02,0c0,0.01-0.02,0.01-0.02,0.01c-0.04,0-0.09-0.01-0.09-0.08c0-0.02,0.02-0.09,0.11-0.09
			c0.04,0,0.11,0.02,0.11,0.11C187.41,153.34,187.35,153.42,187.2,153.42L187.2,153.42z"
          />
          <path
            d="M187.47,153.24c0.05,0.01,0.07,0.01,0.11,0.02c0.02,0.01,0.05,0.04,0.05,0.07c0,0.05-0.05,0.1-0.14,0.1
			c-0.03,0-0.06-0.02-0.06-0.04c0-0.01,0.01-0.03,0.03-0.03c0.03,0,0.06,0.04,0.09,0.04c0.02,0,0.05,0,0.05-0.04
			c0-0.02-0.02-0.05-0.05-0.05c-0.03-0.02-0.07-0.02-0.1-0.02l0.05-0.14h0.15l-0.02,0.05h-0.13L187.47,153.24"
          />
          <path
            d="M275.93,89.16c-0.29,3.19-1.37,5.85-3.57,7.35l-20.75,13.22h-0.11c-0.43,0.35-0.99,0.57-1.6,0.57
			c-0.63,0-1.18-0.22-1.62-0.59l-20.72-13.2c-2.21-1.5-3.28-4.16-3.58-7.35V47.91h51.96V89.16 M277.48,46.44V46.4h-55.06v0.04h-0.01
			l0.05,42.16c0,4.29,1.82,8.06,4.32,9.22l21.18,13.47c0.47,0.54,1.16,0.87,1.93,0.87c0.67,0,1.27-0.25,1.72-0.67l21.5-13.67
			c2.51-1.16,4.34-4.92,4.34-9.22L277.48,46.44L277.48,46.44z"
          />
          <path d="M257.37,107.89l0.67-0.32C257.75,107.66,257.55,107.77,257.37,107.89" />
          <path
            d="M304.96,148.79c-0.71,1.43-1.82,2.46-3.39,2.7c0.08-1.91,1.81-3.26,1.18-5.41c-1.03-1.52-3.08-1.99-4.98-1.6
			c-0.71,0.48-0.39,1.28-0.63,1.99l-0.31,2.64c-1.59,0-1.66-1.44-2.14-2.55c0.08-1.69,0.79-3.35,2.45-4.15
			c1.27-0.64,3.32-0.56,4.58,0.16c1.03,0.23,2.21,0.72,2.61,1.67C305.51,145.36,305.2,147.36,304.96,148.79 M298.57,149.59
			c0.87-0.88-0.24-5.9,2.21-2.64c0,0.64,0.08,1.59-0.55,2.08C299.82,149.35,299.2,149.99,298.57,149.59z M299.44,151.58
			c0.07,1.27-1.11,0.95-1.74,1.43h-1.9c-0.4-0.63-0.25-1.58-0.25-2.23C296.83,151.02,298.25,151.02,299.44,151.58z M301.65,161.31
			c-0.63,0.8-0.08,2.08-0.87,2.79c0,1.44-1.18,2.15-2.21,2.71c-9.41,2.07-18.65-1.11-27.74-2.71c-12.73-2.79-27.57-3.59-40.53-0.64
			c-6.09,1.12-12.01,2.55-17.86,4.23c-3.63,0.47-7.59,1.04-11.06-0.08c-2.85-1.35-1.66-5.02-2.53-7.57c0.16-2-0.55-3.59-0.63-5.5
			c-0.24-0.64-0.4-1.44-0.31-2.08c1.18,0.17,2.37,1.04,3.63,1.37c6.63,1.27,12.56-0.88,18.73-2c5.92-1.27,12.01-2.31,18.25-2.78
			c7.12-0.08,14.38-0.49,21.17,0.71c2.21,0.09,4.11,0.64,6.24,0.81c4.43,0.55,8.53,1.75,13.04,2.07c0.7,0.24,1.57,0.24,2.37,0.39
			c7.11,1.13,15.08,3.11,21.88,0C303.22,155.89,302.04,158.52,301.65,161.31z M261.51,167.13c0.4,1.12,1.11,2.16,0.71,3.51
			c-0.78,1.52-2.37,1.52-3.79,1.84c-0.71,0.07-1.26-0.16-1.81-0.56c0.07-0.32-0.08-0.55-0.32-0.8c0.24-1.04-1.82-2.07-0.39-2.95
			C257.17,166.98,260.17,164.25,261.51,167.13z M263.25,167.45c0.94,0.24,1.03,1.27,1.1,2.07c-0.08,1.76-1.03,3.27-2.69,3.91
			c-0.86,0.47-2.21,0.63-3.08,0.32c1.89-0.08,3.95-0.79,4.74-2.71L263.25,167.45z M265.3,178.05c-0.16,0.08-0.16,0.24-0.16,0.32
			c-2.28,0.08-3.79-1.27-5.53-2.47l-0.07-0.4C261.67,175.66,263.41,177.02,265.3,178.05z M262.38,185.39
			c-0.08,0.24-0.47,0.24-0.63,0.07c-2.37-3.02-3.48-6.22-4.98-9.57l0.32-0.08C258.99,178.93,260.48,182.35,262.38,185.39z
			 M258.43,183.47l-0.39,0.16c-0.4-0.32-0.25-1.18-0.16-1.67C258.35,182.35,258.74,182.91,258.43,183.47z M256.22,178.7
			c0.08,0.79,0.87,1.11,0.4,1.83C256.45,180.21,255.99,179.49,256.22,178.7z M255.74,173.43h0.16v0.24h-0.16V173.43z M251.16,185.15
			c-1.5,0.08-3.79,0.96-5.22-0.32c0.09-0.63-0.63-0.96-0.55-1.6c1.26-0.48,2.36,1.04,3.55-0.07c1.82,0.07,3.72-0.25,5.53,0.15
			c0.87,0.24,2.21-1.43,2.37,0.24C256.22,186.19,253.06,184.35,251.16,185.15z M247.69,175.66l0.38-0.63
			c-0.62,1.91-1.57,3.9-2.6,5.73C245,178.85,246.98,177.34,247.69,175.66z M249.89,174.15c1.03-1.12,1.98,0.63,2.37,1.51
			c1.2,1.75,4.27,3.35,3.08,5.74c-1.49,1.35-3.87,0.08-5.53,1.11c-0.87-0.07-2.37,0.17-2.84-0.71
			C247.84,179.25,248.64,176.38,249.89,174.15z M249.66,171.11h-0.31l0.47-0.47C250.06,170.8,249.74,170.96,249.66,171.11z
			 M251.48,171.28c0.32-0.48,0.71-1.2,1.26-1.2c0,0.07,0.08,0.16,0.16,0.25C252.42,170.72,252.03,171.11,251.48,171.28z
			 M252.58,172.47c0.16-0.16,0.55-0.16,0.71,0H252.58z M254.71,174.94c-0.08,0.16,0.08,0.4-0.16,0.56l-1.82-1.44
			C253.62,173.9,254.24,174.31,254.71,174.94z M254.48,170.08h0.09v0.24h-0.09V170.08z M257.88,165.13
			c-0.95,0.32-1.67,1.44-2.61,1.67C255.74,165.93,256.93,165.05,257.88,165.13z M253.93,164.1c-0.16-0.71,0.71-0.16,1.03-0.48
			l2.45,0.16C256.13,163.62,254.56,166.57,253.93,164.1z M248.16,166.33c0.39-1.11,0.47-2.08,1.18-2.95c0.79,0,1.5,0,2.13,0.31
			c0.16,1.6,2.14,3.75,0,5.11c-0.71,0.32-1.74,1.04-2.53,0.32C247.92,168.25,247.76,167.45,248.16,166.33z M246.98,171.28
			c-0.08-0.32,0.16-0.72,0.24-1.13c0.39-0.39,0.79,0.33,0.95,0.56C247.92,171.11,247.45,171.36,246.98,171.28z M246.82,166.73h0.08
			v0.87h-0.08V166.73z M245.95,165.22c-1.03-0.25-1.58-1.44-2.68-1.44c0.94-0.33,1.89-0.25,3-0.09
			C246.66,164.25,246.03,164.74,245.95,165.22z M245.08,171.67c0.24-1.04,0.71-2.39,1.26-3.34
			C246.27,169.36,245.79,170.8,245.08,171.67z M243.34,171.67c-1.34-0.16-2.61-0.56-3.79-0.96c-0.8-0.79,0-1.67,0-2.55
			c1.1-1.75,4.27-2.62,5.76-1.03C245.08,168.88,244.45,170.4,243.34,171.67z M244.05,173.83v0.32h-0.16v-0.4h0.32
			c-0.07,0-0.23,0-0.32,0.08H244.05z M242.08,178.45c-1.11,2.55-2.37,5.02-3.87,7.5c-0.16,0.4-0.78,0.48-1.26,0.56
			c0-1.12,1.11-2,1.58-2.96c1.66-2.15,2.14-4.46,4.03-6.54C243.03,177.5,242.08,177.82,242.08,178.45z M234.57,177.66
			c1.9-0.81,3.87-2.48,6.01-1.76C239.07,177.1,236.63,177.57,234.57,177.66z M237.42,172.16c-0.4-2.79,0.71-5.18,2.53-7.19
			c1.11-0.23,2.37-0.47,3.39,0.16c-2.05,0.56-4.66,1.04-5.14,3.67c0.08,1.04-0.24,1.59,0.24,2.47c0.87,1.51,2.85,0.88,3.95,2.07
			c0,0.4-0.32,0.88-0.71,0.96C240.34,173.51,238.68,173.19,237.42,172.16z M200.36,146.56c0.24-0.64,0-1.6,0.87-1.76
			c0.55-0.16,1.42-0.16,1.5,0.47c-0.08,0.96-0.16,1.92-0.4,2.8C201.23,148.15,200.67,147.52,200.36,146.56z M200.6,149.24
			c1.18,0.87,2.84,0.47,4.26,0.72c0.23,0.88,0.23,2-0.39,2.72c-1.35-0.32-3.08-0.16-3.8-1.44
			C200.83,150.67,200.43,149.87,200.6,149.24z M196.88,146.95c0.24-1.43,0.56-2.94,1.9-3.98c1.89-1.04,4.5-0.8,6.24,0.24
			c0.56,0.32,0.96,0.8,1.27,1.51c0.39,1.36-0.24,2.8-1.2,3.59h-0.78c0-1.27,0-2.55,0.47-3.59c-0.07-0.95-0.87-1.04-1.5-1.43
			c-1.34-0.39-2.53,0.24-3.55,0.8c-0.79,0.95-1.5,2.15-1.03,3.51c0.55,0.88,0.08,2.07,0.39,3.03l-0.24,0.31
			C197.91,149.91,196.64,148.79,196.88,146.95z M271.01,180.84c1.89,0.86,5.69,1.69,8.43,2c-0.25,1.09-2.18,0.07-1.95,1.59
			c-2.45-0.96-5.28-1.44-7.11-3.35C270.54,181.01,270.69,180.77,271.01,180.84z M221.69,182.12l-1.1-1.04
			c0.55-0.71,1.74-0.32,2.61-0.79c2.05-0.64,4.42-1.44,6.71-0.95C227.54,181.25,224.46,181.49,221.69,182.12z M307.01,145.76
			c-0.94-2.47-2.92-4.15-5.37-4.95c-2.53-0.55-5.22-0.16-6.88,1.76c-2.84,2.3-0.87,6.14-0.79,9.24c0,0.64,0.09,1.12-0.55,1.44
			c-11.46-1.04-22.11-3.91-33.5-5.11c-2.61-0.45-5.22-0.33-7.72-0.86l-3.17-0.33c-9.87,0.32-19.75,0.88-29,3.27
			c-1.97,0.64-4.18,0.8-6.16,1.52c-1.42-0.25-2.61,0.8-3.95,0.39l-2.61,0.61c-0.25,0.05-0.53,0.03-0.87-0.05
			c-1.27-3.27,2.53-5.26,1.35-8.45c-1.03-1.83-3.08-3.59-5.46-3.27c-2.69-0.32-5.05,1.2-6.32,3.27c-1.82,3.99,0.48,8.14,1.18,12.04
			c-0.16,1.28,1.26,2.72,0,3.75c-2.53-1.76-5.84-2.71-8.92-1.84c-0.94,0.49-2.69-0.16-3.16,0.96c3.39,1.83,7.74,1.59,11.53,1.44
			c0.48-0.16,0.79-0.24,1.18,0.08c0.32,2.06-0.39,4.87,0.87,6.69c1.42,1.92,3.79,2.39,6.08,2.63c7.28-0.24,13.75-2.39,20.38-4.06
			c1.03-0.07,1.82-0.63,2.93-0.63c3.55-0.79,7.43-1.44,11.21-1.51c-1.65,2.14-2.6,4.54-3.31,7.17c-0.95,2.15,1.66,2.47,2.45,3.66
			c-2.45,0.72-4.18,2.4-6.47,3.36c-5.06-0.16-9.33,1.75-14.07,2.31c-0.09,1.04,1.66,0.71,1.82,1.76c-0.24,0.72-0.95,0.8-1.5,1.19
			c-0.08,0.48,0,0.56,0.47,0.64c0.47-0.56,1.5-0.16,1.97-0.71c1.58,0,2.92-0.47,4.42-0.56c1.35-0.56,2.85-0.64,4.03-1.6
			c1.66-0.47,2.69-1.59,4.35-2.07c2.37,0,4.5-0.8,6.72-0.95v0.47c-1.82,2.95-3.71,5.98-5.61,8.77c-0.31,0.56,0.08,1.11,0.56,1.27
			c1.1,0.32,2.21,0,3.08-0.48c1.66-2.87,3.16-5.57,4.5-8.53c0.56-2.71,2.53-4.15,4.51-5.98c0.32-0.16,0.79-0.63,1.11-0.16
			c-1.58,1.83-2.6,3.59-3.79,5.59c-0.64,2.23-0.8,5.1,1.19,6.94c0.94,0.79,2.35,0.56,3.63,0.64c3.08-1.6,7.03,0.95,9.4-2.08
			c1.42-0.24,1.66,1.44,2.45,2.23c1.34,0.4,2.77,1.75,4.11,0.79c-0.07-0.86-1.03-1.67-1.5-2.47c-0.95-1.68-2.14-3.03-3-4.55
			c-0.4-1.11-1.11-1.83-1.27-2.95c1.42,0.48,2.68,1.44,4.26,1.68c1.34,0.55,3,0.47,4.27,1.27c1.03,1.36,2.6,2.16,3.95,3.03
			c1.97,0.96,4.18,1.12,6.24,1.92c0.79,0.07,1.97,0.72,2.53,0c-0.32-0.4-0.71-0.64-1.18-0.89c-0.24-1.04,1.42-0.47,1.97-1.27
			c-0.19-0.39-0.54-0.54-0.91-0.6c-1.13-0.61-3.95-0.82-4.69-1.23c-0.95-0.16-1.82-0.56-2.77-0.88c-4.74,0-7.34-4.31-11.14-5.9
			c0.87-0.79,2.21-1.43,2.77-2.63c0.71-1.12,0.94-2.95,0.23-4.22c-0.71-2.47-3.87-2.16-5.68-3.52l0.23-0.24
			c3.08-0.16,5.78,0.56,8.54,1.12c7.59,1.04,14.61,3.91,22.52,4.06c4.34,0,9.79,0.24,11.93-3.97c0.4-2.49,0.87-4.95,1.27-7.42
			C304.81,153.82,307.41,150.15,307.01,145.76z"
          />
        </g>
        <g>
          <rect x="0" y="272.3" width="500" height="2.56" />
        </g>
        <g>
          <path d="M208.36,151.16c-0.17,0.29-0.37,0.52-0.66,0.67" />
          <path
            d="M214.38,104.06c0.7,0,1.25-0.56,1.25-1.27c0-0.71-0.56-1.27-1.25-1.27c-0.7,0-1.26,0.56-1.26,1.27
			C213.12,103.49,213.68,104.06,214.38,104.06"
          />
          <path
            d="M257.56,52.06h13.79V76.4h-13.79V52.06z M271.35,87.97c-0.23,2.62-1.13,4.81-2.94,6.04l-10.85,6.49V77.65
			h13.79V87.97z M243.61,52.06h12.73V76.4h-12.73V52.06z M256.34,101.24l-5.01,3h-0.09c-0.36,0.29-0.82,0.46-1.31,0.46
			c-0.51,0-0.97-0.19-1.34-0.49l-4.98-2.98V77.65h12.73V101.24z M238.46,67.49h3.92v8.91h-3.92V67.49z M242.38,100.5l-10.84-6.49
			c-1.81-1.23-2.7-3.42-2.94-6.04V77.65h13.78V100.5z M228.6,52.06h4.76v9.39h-4.76V52.06z M234.54,55.59l0.56-0.58l-0.35-0.64
			l0.73,0.07l0.44-0.78l0.43,0.76l0.74-0.05l-0.34,0.64l0.55,0.58l-0.71,0.14v0.79l-0.68-0.51l-0.67,0.51v-0.79L234.54,55.59z
			 M237.91,65.39c-0.03-0.23-0.34-0.3-0.47-0.3c-0.42,0-0.59,0.71-0.95,0.72c-0.26,0.01-0.45,0.04-0.45-0.28
			c0-0.23,0.54-0.42,0.75-0.63c-2.94,0-2.99,1.35-3.45,1.35c-0.77,0,0.65-1.42,1.05-1.83c-0.59-0.09-0.94-0.83-1.59-1.19
			c0.3-0.44,0.48-0.25,0.86,0.06c0-0.31,0.23-0.68,0.91-0.68c1.31,0,0.95,1.27,1.16,1.27c0.21,0-0.02-1.27,0.87-1.27
			c1.3,0,2.93,3.36,1.45,3.36C237.42,65.96,237.93,65.49,237.91,65.39z M234.76,72.56l0.73,0.08l0.44-0.78l0.43,0.77l0.74-0.07
			l-0.34,0.65l0.55,0.57l-0.71,0.14v0.79l-0.68-0.51l-0.67,0.51v-0.79l-0.71-0.14l0.56-0.57L234.76,72.56z M229.39,63.5l0.72,0.08
			l0.45-0.78l0.43,0.77l0.74-0.07l-0.35,0.65l0.56,0.57l-0.71,0.14v0.79l-0.67-0.5l-0.68,0.5v-0.79l-0.71-0.14l0.56-0.57
			L229.39,63.5z M228.6,67.49h4.76v8.91h-4.76V67.49z M239.53,64.72l0.56-0.57l-0.35-0.65l0.74,0.08l0.44-0.78l0.43,0.77l0.75-0.07
			l-0.35,0.65l0.56,0.57l-0.71,0.14v0.79l-0.68-0.5l-0.66,0.5v-0.79L239.53,64.72z M238.46,52.06h3.92v9.39h-3.92V52.06z
			 M272.63,50.85v-0.02h-45.3v0.02h-0.01l0.04,36.65c0,3.54,1.5,6.64,3.56,7.58l17.42,10.43c0.38,0.44,0.95,0.72,1.59,0.72
			c0.55,0,1.04-0.21,1.41-0.56l17.69-10.6c2.07-0.95,3.56-4.04,3.56-7.58L272.63,50.85L272.63,50.85z"
          />
          <path
            d="M241.65,86.05c-0.07-0.15-1.73,0.55-1.26,0.51c0.05-0.49,0.97-1.49,1.38-1.86c-0.08-0.58-0.81-0.06-1.59,0.35
			c2.58-3.7,0.92-2.19-0.51-1.92c2.3-2.49,1.69-2.44,0-1.67c1.34-1.06,1.62-2.32,1.41-2.69c-1.26,1.67-2.4,1.41-2.4,2.96
			c0,1.02-0.37,2.69-0.89,3.25c-0.68,0.74-0.16-2.72-1.92-2.72c-0.57,0-1.9-0.05-2.3,0.65c0.36,0,0.9,0.91,0.9,1.28
			s0.33,1.37-0.27,1.37c-0.6,0-1.3-1.27-1.3-1.89c0-0.6-0.05-0.46-0.05-1.33c0-1.48-2.38-3.53-3.34-3.53c0,1.02,1.32,1.27,1.32,2.57
			c-0.57,0-1.37-1.06-1.37-0.4c0,0.65,0.85,0.77,0.85,1.34c0,0.58-0.97-0.2-0.97,0.41c0,0.54,0.54,0.45,0.66,0.97
			c-0.4-0.11-0.75-0.06-0.82,0.14c-0.06,0.17,0.12,0.4,0.42,0.57c-0.14,0.12-0.26,0.43-0.26,1.29c0,2.89,1.82,4.23,3.15,4.23
			c0.95,0,0.8-0.66,0.92-0.97c0.04,0.48,0.51-0.24,0.51,0.61c0,0.9-0.92,0.51-0.92,1.28c0,1.04,1.45-0.04,1.45,1.11
			c0,1.38-0.17,1.36-0.17,1.89c0,0.36,1.52-0.51,1.42-0.67c0.36,0.14,0.35,1.21,1.03,1.19c0.43-0.02,0.53-1.11,0.87-1.19
			c-0.21,0.05,1.37,1.03,1.37,0.53c0-1.35-0.55-0.77-0.55-1.7c0-0.85,1.86-0.69,1.57-1.13c-0.24-0.37-0.42-0.13-0.61-0.77
			c0.04,0.12-1.46-2.14,0.31-0.97C241.54,90.33,241.75,86.25,241.65,86.05"
          />
          <path
            d="M289.97,95.64c0.82,0,1.49-0.59,1.49-1.32c0-0.73-0.67-1.32-1.49-1.32c-0.82,0-1.48,0.59-1.48,1.32
			C288.48,95.05,289.15,95.64,289.97,95.64"
          />
          <path
            d="M283.95,99.22c0.82,0,1.49-0.58,1.49-1.32c0-0.72-0.67-1.31-1.49-1.31c-0.82,0-1.49,0.59-1.49,1.31
			C282.46,98.63,283.12,99.22,283.95,99.22"
          />
          <path
            d="M212.35,107.49c0.82,0,1.49-0.59,1.49-1.31c0-0.73-0.67-1.32-1.49-1.32c-0.83,0-1.49,0.59-1.49,1.32
			C210.86,106.9,211.52,107.49,212.35,107.49"
          />
          <path
            d="M216.99,107.89c0.82,0,1.49-0.59,1.49-1.32c0-0.73-0.66-1.31-1.49-1.31c-0.82,0-1.49,0.58-1.49,1.31
			C215.5,107.31,216.17,107.89,216.99,107.89"
          />
          <path
            d="M345.89,45.29c2.21,2.63,2.69,6.85,1.11,9.88c-0.87-0.07-0.71-1.19-0.87-1.83c-1.58-2.95-2.85-6.7-1.42-10.05
			C345.73,43.46,345.33,44.73,345.89,45.29"
          />
          <path
            d="M156.75,48.88c-0.47,1.9-0.47,4.54,0,6.29c-0.4,0.4-0.31-0.39-0.71-0.32c-3.08-2.23-1.66-6.61-2.45-9.72
			c0.16-0.24,0.55-0.31,0.79-0.16C155.8,45.93,156.43,47.28,156.75,48.88"
          />
          <path
            d="M352.69,49.12c0.47,0.88-0.4,1.76-1.03,2.14c-0.71,0.17-1.5-0.23-1.97-0.87c-0.25-0.88,0.23-1.68,0.94-2.08
			C351.42,48.25,352.21,48.39,352.69,49.12"
          />
          <path
            d="M151.93,52.62c-0.16,0.72-0.4,1.44-1.03,1.75c-0.47,0-1.18,0-1.5-0.39c-0.47-0.4-0.55-1.12-0.31-1.67
			c0.31-0.56,0.95-0.65,1.5-0.72C151.13,51.83,151.68,51.98,151.93,52.62"
          />
          <path
            d="M355.76,53.98c0.16,0.47,0.16,0.8,0,1.28c-0.55,0.72-1.34,1.04-2.13,0.72c-0.55-0.32-0.71-0.88-0.87-1.37
			c0.16-0.63,0.79-1.19,1.34-1.43C354.82,53.18,355.29,53.58,355.76,53.98"
          />
          <path
            d="M351.27,54.06c0.63,0.31,0.16,1.11,0.08,1.59c-0.4,0.4-0.94,1.04-1.65,0.8c-0.4-0.4-0.87-0.4-1.03-0.95
			c0-0.8,0.32-1.6,1.11-1.84C350.4,53.5,350.78,53.82,351.27,54.06"
          />
          <path
            d="M345.49,55.26c0,0.88,0.08,1.99-0.95,2.15c-0.95,0.32-1.58-0.48-1.81-1.12c-0.09-0.8,0.39-1.6,1.18-1.92
			C344.55,54.37,345.11,54.78,345.49,55.26"
          />
          <path
            d="M160.53,55.65c0.24,0.8-0.55,1.28-1.02,1.68c-0.56,0.16-1.27-0.32-1.59-0.88c-0.55-0.56,0.16-1.04,0.32-1.51
			C159.03,54.22,160.22,54.78,160.53,55.65"
          />
          <path
            d="M155.8,55.89c0,0.64-0.32,1.28-0.87,1.52c-0.63,0.23-1.26-0.24-1.58-0.72c-0.39-0.8,0.08-1.52,0.71-1.84
			C154.93,54.54,155.48,55.33,155.8,55.89"
          />
          <path
            d="M326.38,56.21c0.78,1.68,1.41,3.67,1.58,5.74c-0.32,1.51,0.23,3.5-0.88,4.54c-1.1-0.4-1.18-1.75-1.9-2.48
			c-1.41-2.78-0.16-6.06,0.33-8.76C326.14,55.01,326.14,55.82,326.38,56.21"
          />
          <path
            d="M152.08,57.17c0.24,0.96-0.47,1.43-1.03,1.99c-0.95,0.24-1.58-0.48-1.98-1.11c-0.08-0.8,0.4-1.6,1.03-1.91
			C150.9,56.05,151.68,56.45,152.08,57.17"
          />
          <path
            d="M338.86,59.48c1.03,2.3,1.1,5.11,0.4,7.65l-0.47,0.4c-1.11-2.16-2.69-4.15-3.24-6.46
			c0-1.67,0.32-3.18,0.94-4.54c0.56-0.39,0.88,0.07,1.11,0.55C337.67,57.97,338.07,59,338.86,59.48"
          />
          <path
            d="M348.82,58.05c-0.09,0.79-0.4,1.67-1.19,1.91c-0.79,0.16-1.58-0.07-1.82-0.95c-0.24-0.79,0.23-1.51,0.86-1.92
			C347.54,56.61,348.42,57.41,348.82,58.05"
          />
          <path
            d="M155.88,60.51c0,0.64-0.56,1.36-1.19,1.51c-0.63,0.48-1.11-0.32-1.66-0.55c-0.24-0.64-0.4-1.2,0-1.84
			c0.23-0.48,0.79-0.48,1.26-0.63C155.01,59.4,155.72,59.72,155.88,60.51"
          />
          <path
            d="M159.35,60.12c0.31,0.8-0.55,1.2-0.87,1.59c-0.95,0.32-1.42-0.39-1.82-0.96c-0.16-0.8,0.32-1.27,0.87-1.75
			C158.49,58.77,158.96,59.4,159.35,60.12"
          />
          <path
            d="M351.03,59.88c-0.08,1.04-1.66,2.07-1.82,3.1c-0.86,2.16-2.77,3.75-4.89,4.47c-0.48,0.4-1.1,0.96-1.82,1.04
			c-0.16-1.59,1.27-3.03,2.14-4.47C346.29,61.87,348.58,60.51,351.03,59.88"
          />
          <path
            d="M154.3,63.24c1.18,1.35,1.81,2.54,3.24,3.74c0.08,0.32-0.16,0.8-0.54,0.8c-3.88-0.24-6.49-4.23-8.38-7.02
			c0.23-0.87,1.26-0.16,1.81-0.24C151.77,61.56,153.27,61.87,154.3,63.24"
          />
          <path
            d="M352.14,64.34c-0.16,3.44-1.82,6.94-4.9,8.62c-0.87,0.32-1.82,1.2-2.69,0.64c1.66-3.44,4.19-6.23,6.95-9.26
			H352.14"
          />
          <path
            d="M336.96,66.65c0.24,1.2-0.39,1.93-1.34,2.31c-0.86,0.09-1.41-0.63-1.82-1.27c-0.08-0.79,0.4-1.51,1.11-1.76
			c0.23-0.24,0.63-0.24,0.95-0.24C336.33,65.86,336.57,66.26,336.96,66.65"
          />
          <path
            d="M149.95,67.37c1.51,1.04,2.85,2.47,4.27,3.75c-1.03,0.72-2.29,0.09-3.48,0.09c-2.69-0.96-4.5-3.27-7.27-4.07
			c0.08-0.88,1.11-0.56,1.66-0.8C146.87,66.18,148.29,66.98,149.95,67.37"
          />
          <path
            d="M234.57,43.77c-4.42-1.75-7.35,2.87-11.85,2.31c-0.56,0.32-0.39,0.88-0.71,1.28
			c-0.25,0.23-0.64,0.07-0.88-0.09c-0.23-1.91-0.86-3.66-1.11-5.49c-0.47-0.81-0.24-1.92-0.63-2.71c-2.53-6.94,6.17-1.68,7.9-5.74
			c1.51-3.52-2.92-3.91-4.27-6.38v-1.04c-1.26-1.28-2.6-1.68-3.4-3.36c0.56-1.99,0.79-4.15,0.24-6.13c-0.08-0.49-0.39-1.12-1.1-0.88
			c-1.5,2.08-2.61,3.74-4.03,5.74c-1.5-1.11-2.92-2.54-4.66-3.5c-1.18-0.16-2.28-1.35-3.63-0.88c-0.23,0.48,0,0.88,0.24,1.19
			c0.07,3.12,1.65,5.59,3.87,7.82l0.24,1.19c-1.42,3.27-1.03,7.01-1.42,10.53c-3.56,9.08-9.33,17.86-17.15,24.15
			c-4.5,5.11-9.87,10.05-10.59,17.06c-0.24,1.12,0.16,2.48-0.71,3.28c-2.61-0.96-5.22-2.24-7.35-3.91
			c-3.63-2.55-8.84-2.23-11.22-6.86c-0.94-1.84,0.24-3.67,1.11-5.03c2.69-2.55,2.3-7.09,0.87-10.2c-0.32-0.09-0.4,0.31-0.63,0.39
			c-0.24,4.71-3.95,7.98-2.53,13.16c0.32,1.52,1.11,2.47,1.51,3.91c-0.56-0.17-1.34-0.17-1.82-0.4c-1.74-0.24-3.48-0.95-5.29-0.72
			c-3.63,1.68-8.6-0.39-11.53,2.95c-0.47,0.08-1.11,0.16-0.94,0.87c1.74,0,3.39,0.49,5.13,0.4c2.77,0.48,4.58-1.83,6.48-2.86h0.47
			c0.78-0.81,2.13-0.33,3.16-0.49c4.98,0.56,8.85,2.95,12.96,5.43v0.32c-1.58,0.71-3.39,1.19-4.98,2.07
			c-1.82,1.35-3.87,3.11-4.42,5.34c-1.27,1.99,0.4,3.74,1.19,5.42c0.07,0.79,0.16,1.75,0.87,2.07c2.53-2.72,0.16-6.07,0.55-9.17
			c0.63-2.39,2.85-3.51,5.14-4.15c4.51-1.92,7.75,1.76,11.37,3.83c-0.07,3.67,0.4,7.18-0.38,10.76c-1.11,0.4-2.53,0.65-3.72,0.56
			c-6.24,0.48-11.14,3.91-16.75,5.82c-2.53,0.88-5.53,0.81-8.06,1.76c1.98,0.56,4.03-0.16,6.08-0.24c7.74-1.12,13.67-7.1,22.05-6.06
			l0.15,0.24c-0.94,2-2.21,3.67-3.71,5.18c-0.24-0.88-0.32-1.59-1.11-2.07c-0.63-0.32-1.34,0-1.82,0.63c-0.24,0.4-0.24,0.88,0,1.2
			c0.4,0.72,1.02,0.88,1.74,1.11c-0.16,0.72-1.03,1.04-1.5,1.68c-2.21,3.74-6.72,2.23-9.32,4.86c-0.4,0.56-1.82,0.88-1.19,1.76
			c3,0.32,5.92-0.48,8.14-2.07c2.92-3.27,6-6.38,8.92-9.49c0.47,2.55-0.23,5.18-1.74,7.26c-0.71-0.4-1.27-0.96-2.13-1.21
			c-0.79,0.16-1.34,0.96-1.42,1.6c0.31,0.56,0.63,1.2,1.18,1.6c-2.68,1.92-6.08,2.08-9.16,2.95c-5.77,0.72-11.37,1.2-17.22,1.27
			c-2.77-0.16-5.92,0.81-8.53,0c-3,0.56-6.24-0.39-8.69,1.28c-0.31,0.4,0.08,0.8,0.08,1.2c3.63,2.4,8.61,0.48,12.72,1.2h31.13
			c2.3-0.08,4.58-0.16,6.4-1.2c4.04-2.32,6.25-6.46,8.3-10.53c0.79-1.44,1.34-3.1,1.66-4.78c0.79-2.71,3.24-4.64,5.92-4.87
			c0.39,4.14-0.39,8.29-1.34,12.11c-0.48,2.55-2.14,5.03-1.11,7.74c0.24,1.27,1.58,1.27,2.61,1.44c9.56-0.16,19.13-0.56,29.23,0.16
			c1.82,0.32,3.4,0.88,5.38,0.81c0.16-0.25,0.71-0.32,0.48-0.81c-0.24-0.88-0.87-1.98-1.9-2.23c-0.16-0.47-0.86-0.31-1.11-0.63
			c-5.05-2.87-11.14-1.36-17.06-1.28c-3.08,0.32-6.16,0.88-8.77-0.88c-1.18-1.43-0.32-5.98-0.32-5.98c0-1.8,4.35-14.36,4.9-15.39
			c0.16-1.2,0.63-2.31,0.48-3.67c0.63-1.59,0.47-3.66,0.47-5.41c0.31-2.55,1.58-4.95,2.44-7.42c0.48-1.19,0.87-2.47,1.34-3.66
			c0.47-1.52,0.63-2.95,1.58-4.15c2.58,1.65,5.1,1.6,6.34,4.52l1.08,6.64c0,0.4,0.31,1.2,0.87,0.72c2.05-1.36,1.42-4.38,1.42-6.45
			c0.47-2.48-1.27-3.67-1.98-5.83c-0.55-0.39-1.46-2.29-1.46-2.29c-0.31-1.76-4.15-2.96-3.27-5.12c0.47-1.83,0.95-3.51,2.69-4.54
			c0.25,0.18,0.36,0.37,0.39,0.56l0.95-0.67l0.1-6.66c2.56-0.01,6.38,0.32,8.98,0.32c0.71,0.24,1.27,0.8,2.05,0.87l0.63-0.87
			l6.41-1.25C238.81,46.9,237.15,43.77,234.57,43.77"
          />
          <path d="M241.53,46.6c0.19-0.02,0.38-0.06,0.58-0.11L241.53,46.6" />
          <path
            d="M172.71,68.81c0.08,0.96-0.48,1.52-1.19,1.84c-0.63-0.25-1.1-0.32-1.49-0.96c-0.32-0.88,0.47-1.52,1.1-1.83
			C171.76,68.02,172.55,68.1,172.71,68.81"
          />
          <path
            d="M167.57,68.81c0.47,0.48,0.55,1.27,0.32,1.84c-0.56,0.47-1.43,0.88-1.9,0.23c-0.47-0.48-0.94-1.04-0.55-1.67
			C165.99,68.65,166.94,68.17,167.57,68.81"
          />
          <path d="M277.55,69.69c0.02,0.04,0.04,0.08,0.06,0.11" />
          <path
            d="M337.76,71.04c0.31,0.88-0.4,1.51-1.03,1.83c-0.79-0.24-1.82-0.4-1.82-1.44c0.08-0.63,0.63-1.04,1.18-1.19
			C336.81,70,337.44,70.56,337.76,71.04"
          />
          <path
            d="M341.94,70.88c0.23,0.4,0.79,1.04,0.16,1.44c-0.32,0.24-0.55,0.32-0.79,0.64c-0.86,0.16-1.65-0.16-1.9-0.96
			c-0.16-0.88,0.55-1.27,1.18-1.67C341.15,70.16,341.55,70.56,341.94,70.88"
          />
          <path
            d="M147.74,71.84c-0.31,0.95-1.11,1.35-2.13,1.04c-0.23-0.24-0.47-0.63-0.63-1.04c0.07-0.56,0.24-1.12,0.87-1.35
			C146.79,70.56,147.5,70.97,147.74,71.84"
          />
          <path
            d="M174.36,73.2c-0.08,0.64-0.79,1.2-1.34,1.52c-0.8,0.07-1.35-0.56-1.74-1.11c0-0.64,0.32-1.44,0.94-1.6
			C173.1,71.69,174.05,72.32,174.36,73.2"
          />
          <path
            d="M169.7,72.64c0.24,0.24,0.48,0.87,0.24,1.27c-0.47,0.4-1.18,1.13-1.97,0.64c-0.47-0.39-1.03-0.71-0.87-1.52
			c0.32-0.31,0.71-0.79,1.19-0.95C168.91,71.92,169.23,72.32,169.7,72.64"
          />
          <path
            d="M354.02,73.6c0,0.63-0.63,1.27-1.03,1.51c-0.63,0.24-1.34,0.08-1.73-0.4c-0.48-0.16-0.32-0.8-0.32-1.21
			c0.4-0.55,0.95-0.87,1.51-1.03C353.16,72.64,353.64,73.11,354.02,73.6"
          />
          <path
            d="M359.09,74.31c-0.25,0.72-0.8,1.35-1.51,1.44c-0.47-0.08-1.34-0.24-1.18-0.96c0-0.56,0.32-1.11,0.71-1.28
			C357.9,73.11,358.84,73.51,359.09,74.31"
          />
          <path
            d="M156.75,75.43c0.16,0.95-0.16,1.59-0.94,1.91c-0.56,0.32-1.11-0.08-1.5-0.4c-0.4-0.39-0.71-1.04-0.4-1.59
			c0.16-0.72,0.88-0.56,1.35-0.88C155.95,74.55,156.35,74.87,156.75,75.43"
          />
          <path
            d="M160.61,76.14c0.24,0.64-0.47,0.88-0.63,1.35c-0.4,0.25-0.95,0.48-1.42,0.17c-0.56-0.49-1.19-1.2-0.64-1.91
			C158.56,74.55,160.22,75.19,160.61,76.14"
          />
          <path
            d="M349.36,75.75c0.08,0.8-0.63,1.35-1.1,1.83c-0.71,0.16-1.42-0.32-1.58-0.95c-0.07-0.64,0.33-1.12,0.87-1.52
			C348.26,75.04,348.97,75.11,349.36,75.75"
          />
          <path
            d="M355.85,77.02c-0.16,0.72-0.09,1.52-0.87,2c-0.63,0.16-1.42,0.08-1.9-0.32c-0.47-0.4-0.56-1.11-0.32-1.67
			c0.4-0.64,1.1-1.12,1.9-0.95C355.21,76.38,355.37,76.78,355.85,77.02"
          />
          <path
            d="M153.74,78.7c0.32,0.64-0.4,0.81-0.63,1.12c-0.55,0.32-1.1,0.08-1.58-0.16c-0.55-0.32-0.31-1.12-0.23-1.52
			C152.01,76.78,153.42,77.75,153.74,78.7"
          />
          <path
            d="M350.87,80.22c0.31,0.07,0.16,0.55,0.08,0.71c-1.9,0.97-4.74,1.21-6.4-0.32c-0.95-0.8-2.13-1.11-3.16-1.76
			c0.4-1.03,1.83-0.95,2.84-1.19C346.52,77.98,348.34,80.14,350.87,80.22"
          />
          <path
            d="M157.53,79.5c0.16,0.63-0.31,0.87-0.47,1.35c-0.32,0.48-0.95,0.32-1.34,0.16c-0.71-0.48-1.03-1.35-0.55-1.99
			C156.04,78.14,157.06,78.62,157.53,79.5"
          />
          <path
            d="M164.49,79.98c0.31,0.72-0.16,1.35-0.79,1.75c-0.56,0.32-1.03-0.16-1.5-0.25c-0.39-0.56-1.1-1.19-0.39-1.83
			C162.52,78.46,163.94,79.1,164.49,79.98"
          />
          <path
            d="M255.84,83.34c-0.15,1.59-2.78-0.22-2.4,1.9c0.37,1.52,0.67,3.33,1.57,4.47c0.76,1.06,0.38,2.72,0.31,4.02
			c-0.68,1.13-1.8,1.97-3,2.58c-2.25-0.23-5.55,1.13-6.61-1.68c-0.44-1.21-1.28-2.42-1.28-3.78c0.23-0.16,0.53-0.16,0.75-0.38
			c0-0.54-0.6-0.83-0.52-1.36c2.55-0.46-1.05-2.73,1.12-2.88c1.8,1.89,4.74,1.29,6.31,3.33c0.37,0.31,0.67,0.76,1.12,0.98
			c-0.3-1.89-1.2-3.78-1.5-5.83c-0.23-1.05,0.15-2.04,0.9-2.64C253.96,81,255.09,82.51,255.84,83.34"
          />
          <path
            d="M171.44,82.85c0.24,0.8-0.39,1.28-0.86,1.67c-0.71,0.32-1.11-0.48-1.43-0.88c-0.24-0.8,0.31-1.19,0.79-1.6
			C170.5,82.05,171.12,82.29,171.44,82.85"
          />
          <path
            d="M220.2,83.64c0.94,3.11,0.63,6.3-0.96,8.93c-0.07,0.64,0.16,1.68-0.71,1.6c-2.13-2.95-0.55-7.09,0.32-10.21
			C219,83.16,219.95,82.85,220.2,83.64"
          />
          <path
            d="M287.82,85.16c1.1,2.23,1.19,5.17,0.16,7.41l-0.64,0.64c-0.87-1.83-1.58-3.66-1.82-5.66
			c0.4-1.43,0.87-2.7,0.95-4.22C287.58,83.08,287.51,84.52,287.82,85.16"
          />
          <path
            d="M177.6,86.04c-0.47,0.95-1.81,1.19-2.68,1.82c-1.9,1.45-4.67,1.76-7.03,1.36c2.05-2.86,5.21-4.55,9-3.75
			C177.05,85.8,177.45,85.64,177.6,86.04"
          />
          <path
            d="M145.13,88.91c2.92,2.07,3.71,4.94,5.45,7.82c0.24,0.31,1.03,0.39,0.71,1.03c-0.64,0.48-1.27-0.48-2.14-0.32
			c-3-1.35-4.9-4.71-4.82-8.21C144.33,88.91,144.82,88.91,145.13,88.91"
          />
          <path
            d="M284.74,91.38c0.08,0.8-0.09,1.59-0.71,1.83c-0.87,0.25-1.58-0.24-1.82-0.95c-0.24-0.56,0.23-0.88,0.4-1.28
			C283.24,90.33,284.34,90.65,284.74,91.38"
          />
          <path
            d="M318.08,95.6c0-1.52,0.4-2.95,0.55-4.39c0.24-0.47,0.4-1.04,0.32-1.67c1.9-2.56,5.76-1.76,8.78-1.52
			c1.18,0.64,2.68,0.56,3.94,0.88c0.55-0.4,1.35-0.48,1.98-0.4c2.53-1.35,0.86-4.31,1.03-6.61l-0.95-2.39
			c-0.23-0.48-0.47-1.04-0.63-1.6c-0.47-0.56-0.79-1.6-1.27-2.15c-1.34-3.51-3.95-5.5-6.16-8.29c-4.03-5.26-10.36-8.21-16.67-10.28
			c-6.25-2.16-12.57,0.63-18.17,2.63c-1.74-0.48-2.62-1.84-3.08-3.35c-0.32-3.03,0.3-5.98,1.49-8.45c0.24-2,1.58-3.52,2.14-5.34
			c1.42-4.47,2.92-8.85,3.24-13.72c0.16-2.95-2.05-5.02-4.26-6.3c-1.5-0.4-3.25-0.32-4.36,0.88c-1.02,2.15-3.23,3.59-5.14,4.62
			c-0.15,1.36,1.52,0.48,2.06,0.96c1.74,0.32,3.73-0.24,5.14,0.87c0.16,3.2-1.65,5.82-3.31,8.21c-2.7,3.99-5.53,8.06-7.19,12.44
			l-0.32-0.31l0.38,19.49c2.52,4.2,5.66,9,10.36,10.57c2.53,2.08,5.69,4.54,9.07,2.71c1.83-0.56,1.99,2,2.86,3.03
			c0.94,2.95-1.19,5.02-1.34,7.73l-2.06,4.39c-2.36,4.62-4.51,9.57-8.14,13.48c-1.19,0-1.9,1.28-3.08,1.35
			c-0.64,0.88-1.9,0.24-2.69,0.55c-2.44,1.04-5.93,0.41-7.58,2.72c0.16,0.24,0.39,0.32,0.63,0.4c4.82,0.08,10.19,0.4,15.33,0.16
			c0.63-0.24,1.19-0.8,1.27-1.51c-0.71-1.36,0.23-2.56,0.87-3.6c0.39-1.91,1.65-3.11,2.21-4.94c1.65-3.27,3.55-6.46,5.36-9.73
			l2.45-3.99c0.55-1.35,1.5-3.03,2.45-4.46c0.64-0.64,0.96-1.36,1.42-2.15c0.09-0.8-0.31-1.12-0.78-1.67
			c0.56-0.56,1.42-0.4,2.14-0.56c1.97,1.19,4.02,3.11,4.42,5.27l0.71,2.07l0.32,0.95l-0.16,2.64l0.31,2.47
			c0.4,3.83-0.31,7.74-0.7,11.56c-1.35,4.62-6.65,4.15-10.43,5.18c-0.96,0.64-2.77,0.32-2.77,1.91c4.58,0.96,9.95,0.4,14.85,0.32
			c0.78-0.32,0.94-1.04,1.34-1.76c0.16-1.04,1.19-1.91,0.87-3.03c-0.87-1.11-0.08-2.86-0.31-4.38c0.16-0.4,0.16-0.95,0-1.51
			c0.63-2.31,0.47-5.1,0.95-7.34L318.08,95.6"
          />
          <path
            d="M356.95,92.49c-2.52,3.59-5.21,7.66-9.63,8.94c-0.32,0-0.64,0.16-0.8-0.25c2.69-2.88,4.11-7.35,8.3-8.37
			C355.45,92.49,356.32,92.18,356.95,92.49"
          />
          <path
            d="M141.26,96.4c-0.39,0.32-0.07,1.04-0.79,1.12c-0.63,0.32-1.34-0.08-1.66-0.72c-0.31-0.8,0.4-1.59,1.03-1.75
			C140.47,95.37,141.1,95.6,141.26,96.4"
          />
          <path
            d="M164.01,97.52c0.24,0.72-0.55,0.72-0.94,0.87c-3.32,1.2-6.72,0.08-9.09-2.31c0-0.32,0-0.64,0.32-0.72
			C157.53,96.16,161.33,95.53,164.01,97.52"
          />
          <path
            d="M145.61,96.49c0.16,0.31,0.24,0.87,0.16,1.19c-0.47,0.72-1.18,0.88-1.97,0.72c-0.49-0.23-0.71-0.55-1.03-1.04
			c0.08-0.79,0.71-1.27,1.34-1.52C144.74,95.93,145.21,96.08,145.61,96.49"
          />
          <path
            d="M219.64,96.88c0.16,0.71-0.47,1.2-0.94,1.51c-0.71,0.16-1.34-0.16-1.66-0.64c-0.24-0.56,0-1.35,0.56-1.51
			C218.3,96.08,219.4,96.16,219.64,96.88"
          />
          <path
            d="M359.95,97.19c0.24,0.81-0.47,1.2-1.02,1.68c-0.49,0.24-1.03,0-1.51-0.24c-0.09-0.4-0.55-0.72-0.4-1.28
			c0.31-0.71,1.03-1.04,1.81-0.95C359.24,96.73,359.56,96.8,359.95,97.19"
          />
          <path
            d="M365.17,96.88c0.47,0.71,0.08,1.28-0.32,1.83c-0.32,0.56-0.95,0.72-1.43,0.47c-0.47-0.47-1.1-0.72-1.18-1.44
			c0-0.63,0.71-1.11,1.18-1.35C364.22,96.08,364.54,96.88,365.17,96.88"
          />
          <path
            d="M137.94,99.19c0.08,0.56-0.39,0.88-0.55,1.36c-0.39,0.16-0.79,0.48-1.27,0.32c-0.56-0.25-0.94-0.72-1.18-1.28
			c0.16-0.72,0.62-1.35,1.41-1.59C137,98.16,137.71,98.47,137.94,99.19"
          />
          <path
            d="M304.33,99.19c0.16,0.32,0.07,0.72,0.07,1.04c-0.47,0.64-1.1,1.2-1.89,0.8c-0.47-0.48-1.03-0.96-0.79-1.68
			C302.36,98.47,303.7,98.16,304.33,99.19"
          />
          <path
            d="M150.66,100.39c-0.16,0.56-0.39,1.12-1.1,1.2c-0.71,0.07-0.95-0.56-1.35-0.88c0-0.72-0.08-1.44,0.63-1.83
			C149.8,98.55,150.66,99.35,150.66,100.39"
          />
          <path
            d="M143.08,100.55c0.23,0.8-0.32,1.35-0.8,1.75c-0.94,0.32-1.73-0.4-2.12-1.12c0.07-0.72,0.63-1.6,1.42-1.83
			C142.28,99.35,142.69,100.15,143.08,100.55"
          />
          <path
            d="M309.55,100.39c0.16,0.8-0.07,1.59-0.88,1.92c-0.94,0.32-1.72-0.49-2.12-1.12c0.4-0.63,0.47-1.51,1.41-1.68
			C308.67,99.51,309.15,99.84,309.55,100.39"
          />
          <path
            d="M146.79,100.71c0.23,0.55-0.24,0.8-0.4,1.19c-0.4,0-0.63,0.49-1.1,0.32c-0.31-0.4-0.96-0.56-1.03-1.12
			c-0.07-0.8,0.48-1.19,1.03-1.51C146.08,99.51,146.55,100.07,146.79,100.71"
          />
          <path
            d="M355.61,101.35c-0.24,0.63-0.71,0.95-1.34,1.27c-0.63,0.16-1.03-0.4-1.5-0.64c-0.4-0.32-0.24-1.03-0.08-1.43
			C353.48,99.19,355.61,99.84,355.61,101.35"
          />
          <path
            d="M361.61,101.66c-0.24,0.88-1.11,1.28-1.9,1.36c-0.71-0.33-1.5-0.88-0.95-1.84c0.32-0.31,0.47-0.63,0.95-0.72
			C360.66,100.15,361.37,100.87,361.61,101.66"
          />
          <path
            d="M324.08,102.22c0.4,1.03-0.55,1.35-1.19,1.84c-0.55-0.08-1.03-0.32-1.49-0.64c-0.16-0.88,0.23-1.67,1.1-2.07
			C323.21,101.35,323.61,101.66,324.08,102.22"
          />
          <path
            d="M302.36,103.18c0.39,0.17,0.23,0.72,0.16,0.95c-0.47,0.32-1.11,1.12-1.83,0.64c-0.54-0.32-0.94-0.8-0.78-1.52
			C300.3,102.39,301.96,102.3,302.36,103.18"
          />
          <path
            d="M306.79,103.74c0.07,0.63-0.09,0.96-0.64,1.27c-0.56,0.56-1.19-0.07-1.75-0.24c-0.16-0.32-0.31-0.8-0.07-1.2
			c0.24-0.32,0.63-0.55,1.03-0.63C305.92,103.1,306.55,103.1,306.79,103.74"
          />
          <path
            d="M172.71,103.82c0.47,0.47-0.09,1.04-0.16,1.51c-0.4,0.48-1.26,0.87-1.97,0.4c-0.79-0.32-0.71-1.36-0.56-1.99
			c0.47-0.16,0.47-0.72,1.1-0.72C171.83,103.1,172.23,103.42,172.71,103.82"
          />
          <path
            d="M332.53,104.13c0.17,0.81-0.63,1.12-1.19,1.28c-0.94,0.32-1.41-0.4-1.81-1.04c0-0.47,0.47-0.79,0.63-1.19
			C331.28,102.7,331.99,103.5,332.53,104.13"
          />
          <path
            d="M328.28,104.13c0.31,0.81-0.55,1.2-0.87,1.6c-0.56,0.24-1.27,0.15-1.74-0.32c-0.4-0.25-0.4-0.71-0.4-1.2
			c0.48-0.39,0.71-0.87,1.27-1.04C327.24,103.18,327.96,103.58,328.28,104.13"
          />
          <path
            d="M363.91,105.09c0.16,1.11-1.27,0.56-1.82,0.88c-3.32,1.99-7.58,0.48-10.74-0.72
			c0.24-1.04,1.42-0.64,2.05-1.04c3.4-2.15,6.88,0.49,10.27,0.64L363.91,105.09"
          />
          <path
            d="M147.66,104.13l-0.31,0.57c-2.93,1.75-6.72,2.95-10.2,1.83l-0.31-0.49c2.92-2.14,6.79-2.7,10.59-2.14
			L147.66,104.13"
          />
          <path
            d="M340.59,104.7c0,0.63-0.31,1.18-0.78,1.5c-0.79,0.25-1.82,0-2.05-0.87c-0.24-0.63,0.4-1.27,1.03-1.51
			C339.34,103.82,340.28,103.9,340.59,104.7"
          />
          <path
            d="M161.8,105.01c-0.32,0.64-0.16,1.28-0.95,1.59c-0.79,0.32-1.74-0.4-1.97-1.04c-0.23-0.87,0.4-1.12,0.79-1.51
			C160.61,103.58,161.33,104.29,161.8,105.01"
          />
          <path
            d="M156.19,105.41c0.24,0.56-0.24,0.88-0.39,1.27c-0.4,0.47-1.11,0.4-1.58,0.24c-0.55-0.48-1.11-1.04-0.87-1.91
			c0.48-0.4,0.71-0.64,1.27-0.88C155.32,104.29,156.04,104.61,156.19,105.41"
          />
          <path
            d="M346.13,107.24c-0.09,0.72-0.64,1.28-1.27,1.44c-0.64,0.07-1.19-0.4-1.51-0.8c-0.23-0.48-0.15-1.12,0.32-1.52
			c0.16-0.24,0.71-0.24,1.03-0.4C345.42,106.04,345.98,106.6,346.13,107.24"
          />
          <path
            d="M150.35,106.37c0.24,0.64-0.32,0.88-0.4,1.35c-0.23,1.36-1.1,2.23-1.74,3.43c-1.5,1.83-3.63,1.83-5.76,2.39
			l-0.31-0.64c2.28-2.95,4.74-5.26,7.97-6.7L150.35,106.37"
          />
          <path
            d="M342.18,108.12c0.16,0.96-0.63,1.12-1.18,1.51c-0.71,0-1.27-0.4-1.59-0.95c-0.24-0.88,0.55-1.2,1.03-1.6
			C341.23,106.92,341.94,107.48,342.18,108.12"
          />
          <path
            d="M159.43,108.12c-0.08,0.16,0.08,0.32,0.23,0.4c-0.08,0.88-0.71,1.12-1.18,1.52c-0.71,0-1.27-0.24-1.74-0.8
			c-0.31-0.72,0-1.59,0.79-1.83C158.25,107.32,159.2,107.41,159.43,108.12"
          />
          <path
            d="M356.72,110.51c0.78-0.07,0.95-1.03,1.81-1.19c0.79-0.16,1.5,0.32,1.9,0.88c0.31,0.96-0.4,1.44-1.03,1.84
			c-0.56,0.39-1.73-0.49-1.66,0.55c0.55,0.4,1.03,0.96,1.1,1.59c-0.16,0.33-0.71,0.25-0.94,0.09c-2.29-0.96-4.74-2.56-5.69-4.95
			c-0.31-0.72-1.8-1.28-0.94-1.91C353.56,107.24,355.45,109.08,356.72,110.51"
          />
          <path
            d="M364.29,108.04c0.4,0.4,0.24,1.12-0.08,1.51c-0.4,0.8-1.58,0.48-2.28,0.4c-0.4-0.4-0.78-0.8-0.63-1.44
			c0.31-0.48,0.63-0.8,1.18-1.04C363.2,107.32,363.75,107.65,364.29,108.04"
          />
          <path
            d="M335.62,112.35c0.71,0.88,1.42,2.24,1.74,3.2c-0.16,0.24-0.55,0.16-0.71,0.16c-2.69-1.52-6.15-3.19-7.19-6.38
			c-0.24-0.32-0.87-0.72-0.79-1.28c2.05-0.39,4.04,1.12,5.45,2.23C334.67,111.08,335.3,111.47,335.62,112.35"
          />
          <path
            d="M155.01,109.32c0.31,0.72-0.32,1.28-0.71,1.6c-0.87,0.16-1.34-0.16-1.82-0.64c-0.55-0.88,0.47-1.59,1.11-1.92
			C154.22,108.28,154.61,108.92,155.01,109.32"
          />
          <path
            d="M345.49,111.15c0.08,0.48-0.55,0.8-0.78,1.11c-0.64,0.08-1.27,0.32-1.82-0.23c-0.16-0.4-0.55-1.04-0.09-1.44
			C343.68,109.48,344.94,110.36,345.49,111.15"
          />
          <path
            d="M365.17,112.26c0.23,0.96-0.47,1.6-1.1,2c-0.8,0.24-1.43-0.32-1.9-0.72c-0.4-0.48-0.08-0.96,0-1.51
			c0.54-0.49,1.26-0.88,2.05-0.56C364.54,111.71,364.85,111.87,365.17,112.26"
          />
          <path
            d="M310.49,123.28c0,0.95-1.74,0.72-2.46,1.43c-2.45,1.28-5.36,1.28-8.29,1.2v-0.48
			C302.59,123.03,306.7,122.16,310.49,123.28"
          />
          <path
            d="M195.23,126.7c-0.09,0.72-0.48,1.12-1.19,1.35c-0.55,0-1.11-0.31-1.34-0.79c-0.31-0.95,0.47-1.36,1.03-1.75
			C194.43,125.59,195.07,126.15,195.23,126.7"
          />
          <path
            d="M309.15,127.58c-0.16,0.72-0.4,1.52-1.19,1.84c-0.7,0.16-1.17-0.25-1.73-0.56c-0.4-0.31-0.16-0.88-0.23-1.27
			C306.55,126.15,308.6,126.15,309.15,127.58"
          />
          <path d="M324.87,127.9c-1.97,1.27-4.18,2.79-6.79,2.79l-4.27-0.08l-0.4-0.31c2.61-3.2,7.35-3.2,11.46-2.88V127.9" />
          <path
            d="M173.57,129.81c0,0.72-0.31,1.27-1.03,1.51c-0.79,0.16-1.34-0.16-1.66-0.72c-0.31-0.72,0-1.44,0.63-1.83
			C172.39,128.22,173.26,129.17,173.57,129.81"
          />
          <path
            d="M177.52,130.61c0.23,0.4-0.15,0.64-0.31,1.04c-0.48,0.24-1.11,0.4-1.51,0c-0.55-0.32-0.71-0.88-0.47-1.35
			c0.23-0.48,0.71-0.41,0.94-0.72C176.73,129.81,177.29,130.04,177.52,130.61"
          />
          <path
            d="M147.26,138.67c-0.79,0.95-2.05-0.24-2.93-0.72c-2.75-1.59-2.68-4.71-5.45-6.54
			c0.32-0.96,1.03,0.08,1.58-0.08c0.48,0.32,1.11,0.64,1.66,0.88C144.82,133.72,145.45,136.27,147.26,138.67"
          />
          <path
            d="M251.95,137.15c-0.31,0.96-0.94,1.76-0.87,2.8l-0.31,0.16c0.24-1.2-0.95-1.92-0.63-3.19
			c-0.63-0.96-0.63-2.16-1.19-3.19c-0.71-1.28,0.63-1.84,1.19-2.72c1.11,0.48,1.5,1.53,2.29,2.48
			C252.9,134.83,251.88,135.87,251.95,137.15 M377.57,107.72c-1.27-2.31-3.4-3.27-5.93-3.27c-2.05,0.16-3.55,1.52-4.42,3.27
			c-0.47,1.76,0.16,3.36,1.5,4.39c0.56,0.23,1.27,0.15,1.58-0.4c0.4-1.04-0.71-1.04-0.87-1.83c-0.32-1.12,0.09-2.08,0.87-2.79
			c0.78-0.79,2.13-0.4,3.08-0.4c0.95,0.64,1.9,1.2,2.37,2.32c0.48,1.35,0.48,2.86,0,4.22c-0.87,2-2.84,3.59-4.89,3.99
			c-6.56,0.96-78.54,0.64-78.54,0.64c-8.45,0.79-18.09-1.6-25.37,2.86c-3.31,1.59-4.98,5.26-5.84,8.53
			c-2.13,1.92-4.42,4.15-6.16,6.3l-0.24-0.24c0.4-1.92,1.27-3.59,1.67-5.5c1.18-1.6,1.18-3.75,2.05-5.5
			c3.01-4.87,5.85-9.81,10.51-13.23c1.97-2.64,5.45-2.16,8.45-2.16c3.23,1.52,7.04,2,10.98,1.59c0.16-0.16,0.24-0.32,0.24-0.56
			c-1.1-1.04-2.92-1.44-4.18-2.47c-3.16-1.51-6.17,0.47-9.49,0.24c-0.16-0.16-0.32-0.24-0.23-0.48c3-2.23,6.39-3.83,9.79-5.26
			c2.77-1.51,5.69-2.31,8.62-3.43c1.18,0,2.28-1.04,3.39-0.32l4.18-1.12c0.16,0.07,0.33,0.32,0.63,0.24
			c3.57-1.2,8.07-0.08,12.09-0.16l0.56,0.48l3.8,0.96c1.42,0.16,2.05,1.84,3.55,1.92c0.79-1.12-0.87-1.6-1.26-2.32
			c1.73-0.39,3.71,0.07,5.29,0.8l-0.79,0.88c-0.47,0.88,0.24,1.52,0.96,1.83c0.55,0.16,0.95-0.24,1.34-0.39
			c1.03-0.72-0.08-1.44,0.23-2.08c5.85,1.04,11.46,2.47,16.84,4.79c1.5,0.47,2.92,1.59,4.58,1.51c-1.66-1.51-3.95-2.32-6.01-3.18
			c-1.34-0.8-3.24-0.88-4.26-2c0.23-0.4,0.78-0.24,1.03-0.55c2.69-0.33,4.97-2.32,6.88-4.47c0.16-0.47,1.03-0.32,0.95-0.96
			c-0.71-0.8-1.9,0.24-2.85,0.16c-3.32,0.64-4.74,4.55-8.06,5.27c-4.02-0.96-8.14-1.92-12.17-2.95c-1.97-0.32-4.11-0.48-5.93-1.28
			l-4.42-0.4h-8.06c-0.08-0.16-0.31-0.25-0.23-0.48c2.84-0.72,5.29-2.4,8.13-3.11l21.18-9.72c2.21-2,4.19-4.23,6.01-6.54
			c0.06-0.63-0.32-1.28-0.88-1.51c-0.55-0.24-1.18-0.16-1.58,0.32c-0.95,0.71,0,1.59,0,2.47c-0.95,1.27-2.46,2.39-3.64,3.1
			c-0.32-0.07-21.81,9.82-21.81,9.82c-3.16,1.35-6.32,2.87-9.64,3.58l-4.58,0.72c-0.32,0.97-1.11,1.53-1.98,1.84
			c-1.26,0.25-2.29,1.04-3.63,1.04c-10.28,3.98-20.94,8.21-29.08,16.34c0.47-0.95,0.71-2.15,0.79-3.35
			c-0.16-1.2-0.09-2.56-0.87-3.51c-0.19-0.43-0.77-1.72-0.58-2.18l-1.16,0.75c-0.71,1.76-1.34,4.94-0.63,6.77
			c0.23,1.44,1.42,3.11,0.32,4.39c-3.8,4.62-5.93,10.21-8.46,15.47c-0.95-0.16-1.42-1.04-1.82-1.91c2.69-3.43,4.82-7.26,5.61-11.63
			c0.55-0.4,0.15-1.45,0.7-1.92c-0.15-1.27,0.72-2.55,0.09-3.59c0.5-1.74-2.31-3.34-0.51-4.46l-1.5,0.72
			c-3.4,4.46,1.77,11.17-2.1,15.47v0.32c-1.11,1.43-1.66,2.87-3.17,3.83c-1.9-0.8-2.6-2.95-3.71-4.55c-1.74-1.67-1.74-5.1-0.71-7.41
			c0.71-2.31-0.47-4.31-0.71-6.38l-0.79-0.48c-1.9,2.47-2.61,6.38-1.11,9.33c0.79,1.43,0.87,3.43,2.06,4.62
			c0.71,2.32,2.12,3.83,3.4,5.9c0.32,0.79-0.47,0.95-0.87,1.35c-0.87-0.56-0.71-1.83-1.5-2.39c-2.61-5.58-6.01-10.04-10.11-14.6
			c-0.16-0.71-0.87-1.35-0.48-2.14c1.51-1.44,1.82-4.23,1.03-6.06c-0.16-0.4-0.63-0.88-0.55-1.28l-1.19-0.95
			c0.16,2.79-1.66,5.26-0.87,8.21v0.16c-8.14-6.13-17.13-11.62-26.3-15.92l-0.73,2.02c2.32,0.59,8.46,4.33,8.46,4.33
			c2.77,1.04,5.29,2.72,7.9,4.22c10.74,5.99,17.86,15.87,22.67,26.88c0.08,0.88,0.87,1.84,0.32,2.71c-1.66-2.55-4.03-3.98-6-6.07
			c-0.56-3.74-2.77-7.33-6.01-8.84c-1.19-1.75-3.72-1.91-5.69-2.47l-28.76-0.16c-23.39-0.32-44.73,0.71-67.95,0.32
			c-3.24-0.32-7.19-1.44-8.14-4.86c-0.63-1.2-0.63-3.27,0-4.39c0.87-1.51,2.45-1.99,4.03-2.16c1.27,0.16,2.14,1.36,2.69,2.24
			c0,1.04-0.87,1.99-1.27,2.86c0.08,0.32,0.4,0.96,0.95,0.72c1.02-0.4,1.82-1.51,2.06-2.55c0.07-0.56,0.31-1.35-0.24-1.76
			c-0.47-1.68-2.21-2.88-3.71-3.27c-1.98-0.16-4.03,0.39-5.37,1.83c-1.9,1.6-1.81,4.47-1.27,6.71c1.27,3.5,4.98,5.89,8.53,6.38
			l76.88,0.32c7.51,0.39,15.96-0.87,23.54,0.64c2.77,0.88,5.69,2.95,6.41,6.06c-0.08,0.16-0.32,0.07-0.56,0.07
			c-6.25-4.07-14.46-5.58-22.44-4.62c-3.16-0.15-6,1.04-8.85,1.84c-1.5,0.32-2.92-0.4-3.87-1.28c-2.85-2.55-7.82-2.39-11.46-1.44
			c0,0.25-0.08,0.48,0.08,0.56c3.87-0.32,6.16,3.03,9.64,3.43c0.79,0.16,1.42-0.71,2.14-0.08c-3.64,1.76-7.11,3.83-10.99,4.94
			c-1.18-0.23-1.81-1.35-2.76-1.98c-1.5-1.68-4.27-0.96-6.32-1.13c-0.71,0.16-1.34-0.31-1.81,0c0.63,1.28,2.05,2,3.39,2.64
			c1.97,0.87,4.27-0.09,6.08,1.11c-0.47,0.56-1.11,0.71-1.66,1.13c-3.71,1.43-6.8,4.78-10.9,4.53c-4.35,0.48-8.78-0.8-11.7-3.83
			c-1.27-3.1-4.5-4.78-7.98-4.62c-0.23,0.16-0.54,0.16-0.47,0.56c1.74,1.04,3.08,3.1,4.82,4.31c1.1,1.35,3.24,0,4.27,1.51
			c1.5,1.04,3.39,1.92,5.14,2.23c1.26,0.56,2.68,0.79,4.11,1.12l-0.56,0.32c-7.18,2.39-15.48,2.63-23.54,2.31
			c-2.37-0.08-4.67-0.8-7.03-0.72c0.4,0.48,1.18,0.87,1.9,1.11c-0.63,0.4-1.34,0.57-2.13,0.65c-0.94,0.23-2.05,0.31-3.01,0.79
			c-1.26-0.07-2.37,0.4-3.39,0.8c-1.34,1.43-2.92,2.95-4.5,3.67c0,0.32-0.08,0.8,0.32,0.8c1.5-0.64,3.39-0.16,4.89-0.8
			c2.61-0.24,4.11-3.11,6.16-4.31c2.21-1.76,5.37-1.35,8.06-0.95c1.58,0.39-0.16,1.04,0.31,2.07c0.63,0.56,1.19,1.19,2.06,0.95
			c0.16-0.16,0.31-0.32,0.55-0.39c0.47-0.63,0.4-1.35,0.08-1.99c1.11-0.72,2.61,0.08,3.8-0.4c3-0.07,6.01-0.71,8.93-0.79
			c0.47-0.09,1.03-0.56,1.5-0.09c-0.24,0.65-1.03,0.72-0.71,1.6c0.16,0.71,0.95,1.04,1.58,1.19c0.78-0.32,1.5-0.88,1.18-1.92
			c-0.16-0.55-0.78-0.63-1.18-0.88c1.65-0.79,3.55-1.03,5.21-1.82c1.18-0.25,2.29-0.64,3.31-1.12c1.03-0.08,1.9-1.04,2.84-0.64
			c-0.39,1.35-0.94,2.63-1.66,3.83c-1.26,4.23-2.21,9.81,0,13.88c0.25,0.07,0.32-0.09,0.47-0.16c-1.66-4.23-0.71-9.09,0.71-13.08
			c1.11-6.77,8.06-8.76,13.04-11.32l0.24,0.32c-1.34,3.5-3.32,6.86-3.01,11.08c0.16,0.72-0.31,1.44,0,1.91
			c1.34-0.72,2.21-2.31,2.93-3.67c0.56-3.58-1.5-8.04,2.13-10.68c6.48-2.55,12.57-5.9,20.07-5.98c0.24,0.24,0.79-0.16,0.86,0.32
			c-1.57,1.76-3.86,3.42-4.58,5.59c-2.53,3.5-2.45,9.48-1.34,13.79c0.71,2.47-1.42,3.83-2.45,5.74c-0.5,0.67-0.63,1.84-1.09,2.62
			l1.57,0.16c1.34-1.43,2.6-3.18,2.69-5.26c0.15,0.8,0.85,1.44,1.5,1.6c0.55-0.88-0.47-1.52-0.56-2.39
			c1.27-0.72,1.82,0.87,2.84,1.19c1.67,0.4,3.08,1.11,4.82,1.11l0.55-0.56c-1.74-2.71-5.21-3.19-8.22-3.35
			c-1.58-2.63-1.58-5.9-1.42-9.09c0.32-1.67,0.63-3.43,1.58-4.94c0.39-1.11,1.5-2.15,2.21-3.03c1.42-1.35,3.24-2.31,4.98-2.71h0.23
			c0,0.16,0.08,0.25,0.08,0.25c-2.13,1.68-2.37,3.99-3.63,6.06c-1.34,1.99-1.98,4.71-0.71,6.94c0.48,1.04,0.24,2.63,1.35,3.36
			c1.02-1.44,1.11-3.28,1.65-4.87c-0.24-2.63-1.81-5.1-0.31-7.64c0.79-2,2.46-2.79,4.27-3.51c4.35-0.4,8.14,1.83,11.93,3.51
			c0.8,0.56,2.14,1.04,2.3,2.15c-0.08,2.15-1.26,4.3-2.92,5.34c-2.13,1.28-5.22,1.44-7.19-0.07c-1.19-1.2-1.66-3.27-1.26-4.87
			c0.87-0.95,2.05-1.75,3.47-1.28c0.79,0.4,1.5,1.21,2.53,0.72c0.39-0.23,0.55-0.88,0.31-1.27c-0.87-0.48-1.18-1.67-2.37-1.84
			c-1.5-0.24-3.32-0.16-4.34,0.89c-1.66,1.43-2.3,3.58-1.82,5.57c0.55,2.55,2.45,4.39,4.9,5.11c3.64,0.88,7.43-0.24,9.72-3.35
			c0.95-1.04,1.18-2.15,1.74-3.43c3.79,2.31,6.63,6.38,8.13,10.53c0,1.35,0.4,2.79,0.08,3.98l2.77,0.24c-0.24-0.63,0-1.35,0.24-1.92
			c1.03-5.25,4.27-9.64,8.13-13.07c1.34-0.8,1.03,1.03,1.34,1.59c1.18,3.04,3.95,5.19,6.88,6.06c3.31,0.56,6.48-0.24,8.53-2.79
			c1.03-1.91,2.29-4.7,0.55-6.69c-1.1-1.35-2.6-2.07-4.34-2.07c-2.06,0-3.32,1.42-4.35,2.95c0,0.63-0.07,1.35,0.48,1.67
			c0.31,0,0.94,0.07,1.18-0.39c0.16-1.21,1.19-1.77,2.14-2.4c0.86-0.47,1.82,0,2.53,0.47c0.87,0.72,1.03,1.92,1.1,3.11
			c-0.16,1.51-1.18,3.11-2.6,3.74c-2.28,1.2-5.61,0.64-7.59-0.72c-0.95-1.03-1.49-2.23-2.21-3.51c-0.16-1.27-0.63-2.39,0-3.66
			c3.47-2.08,7.11-3.59,11.3-3.99c1.66,0.31,3.08,1.43,4.19,2.63c3.63,3.74-0.79,9.09,1.74,13.23c0.94-0.16,0.94-1.43,1.34-2.23
			c1.18-2.87-0.33-5.99-1.03-8.78c-0.96-1.75-1.52-3.26-3.01-4.7c0.39-0.16,0.55-0.16,0.95-0.16c1.66,1.11,3.47,2.38,4.82,4.13
			c2.29,3.76,3.48,8.22,3.08,13.09c-0.62,2.4-3.15,3.51-5.14,4.38c-0.78,0.64-1.72,1.6-2.13,2.55c0.94,0.57,2.29,0,3.24-0.39
			c1.5-0.56,2.37-2.48,3.4-3.35c0.16,0.08,0.09,0.32,0.16,0.47c0.09,0.89-0.4,2.07,0.39,2.32c0.4-1.12,0.48-2.71,0.71-4.08
			c1.03,1.12,1.19,2.56,1.5,3.99c0.71,1.44,1.9,3.35,3.8,3.58c0.47-0.71-0.08-1.44-0.24-2.07c-0.23-1.99-1.9-3.03-3-4.46
			c-2.77-3.83-1.51-9.81-3.64-14.12c-0.78-2.31-2.45-3.99-4.27-5.9l0.32-0.32c3.87-0.08,7.11,1.2,10.67,1.92
			c4.34,2.15,10.66,1.67,12.72,7.33c0.87,3.2,2.28,6.14,4.5,8.62l0.24-0.16c0.47-0.8,0.47-2.15,0-2.95
			c-0.47-3.98-3.95-4.94-5.77-8.29l0.24-0.32c1.9,0.56,3.87,1.04,5.76,1.84c6.32,1.76,10.75,7.97,11.54,14.43
			c0,5.11-0.95,9.33-2.92,13.63c-0.23,0.65-1.34,1.37-0.64,1.92c0.87-0.65,1.03-1.69,1.5-2.4c2.62-4.39,3.95-10.44,2.62-16.02
			c-0.09-1.03-1.27-1.84-0.71-2.87c0.4,0.48,0.86,0.56,1.34,0.72c0.47-0.32,1.19-0.32,1.5-0.87c0.09-0.48,0.4-1.04,0-1.44
			c-0.47-0.71-1.5-0.71-2.28-0.55c-0.4,0.47-0.87,1.11-0.95,1.75c-0.79-1.36-1.58-3.11-2.84-4.23l0.16-0.24
			c3.55,1.2,6.8,2.95,10.43,3.9c0.78,0.49,1.65,0.89,2.77,0.89c3.08,1.19,6.48,1.67,9.71,2.46c2.37-0.24,4.35,0.88,6.72,0.32v-0.16
			l-0.08,0.07c-0.87-1.11-2.77-0.07-3.79-0.87c-5.45-0.56-10.67-1.99-15.58-3.9c0.09-0.25,0.4-0.16,0.64-0.16
			c0.78-0.4,1.34-1.12,1.26-2c-0.39-0.56-1.03-0.88-1.82-0.88c-0.47,0.24-1.11,0.79-1.19,1.35c0.08,0.64,0.64,1.35,1.19,1.52
			c-0.16,0.16-0.47,0.07-0.71,0.07c-2.45-1.12-4.82-2.15-7.19-3.26c-0.87-0.17-1.58-0.88-2.45-1.04c-1.82-0.88-3.64-1.76-5.46-2.47
			c-5.04-2.64-10.66-4.06-16.03-5.98c-1.19-0.32-2.29-0.63-3.4-0.95c-1.97-0.56-3.95-1.04-5.93-1.6c-6.7-1.67-15.71-1.83-21.56,1.83
			c-0.95-0.32-1.51,1.2-1.97,0.25c1.65-4.39,6.47-5.82,10.82-5.99c31.67-0.32,61.39-0.56,92.6-1.04c3.63,0,7.19-1.27,9.09-4.54
			C378.36,113.15,378.6,109.88,377.57,107.72z"
          />
          <path
            d="M337.67,132.12c0,0.25,0.48,0.32,0.32,0.72c-2.77,2.47-6.64,3.19-10.58,3.35c-0.33,0.07-0.64,0.32-1.03,0.23
			c-0.24-0.16-0.32-0.39-0.16-0.55C329.06,132.76,333.41,131.17,337.67,132.12"
          />
          <path
            d="M324.95,133.4c0.31,1.11-0.4,1.76-1.27,2.15c-0.87-0.07-1.34-0.63-1.73-1.27c-0.24-0.64,0.31-0.96,0.54-1.35
			C323.13,132.27,324.64,132.27,324.95,133.4"
          />
          <path
            d="M174.75,133.56c0.17,0.56,0.08,1.27-0.39,1.67c-0.31,0.4-1.02,0.48-1.42,0.4c-0.55-0.08-0.87-0.71-1.03-1.2
			c0-0.71,0.4-1.12,1.03-1.51C173.57,132.92,174.28,132.84,174.75,133.56"
          />
          <path
            d="M160.61,138.74v0.56c-1.89,0.4-3.71-0.07-5.14-1.19c-1.1-1.6-2.37-3.19-4.18-3.99c0-0.24-0.47-0.56,0-0.72
			C155.25,133.4,157.61,136.6,160.61,138.74"
          />
          <path
            d="M349.69,133.49c-1.83,2.71-3.4,6.06-6.63,7.25c-0.56,0-1.03,0.8-1.51,0.24c1.26-3.03,3.95-6.3,7.03-7.57
			C348.9,133.4,349.36,133.32,349.69,133.49"
          />
          <path
            d="M179.35,134.43c0.08,0.88-0.56,1.04-1.11,1.51c-0.64,0.09-0.95-0.4-1.34-0.88c-0.32-0.79,0.4-1.11,0.79-1.51
			C178.32,133.4,179.03,133.88,179.35,134.43"
          />
          <path
            d="M359.64,134.92c-0.71,1.03-0.4,2.55-1.11,3.66c-1.59,1.83-2.92,3.91-5.45,4.31c-0.4-0.81,0.55-1.6,0.94-2.32
			c1.11-2.39,2.7-5.1,5.22-6.14L359.64,134.92"
          />
          <path
            d="M136.12,135.63c0.24,0.56,0.48,1.11,0,1.75c-0.47,0.4-1.18,0.56-1.81,0.32c-0.71-0.4-0.96-1.28-0.64-1.92
			C134.15,135.07,135.57,134.67,136.12,135.63"
          />
          <path
            d="M131.62,136.6c0.07,0.4,0.31,0.71,0.24,1.11c-0.24,0.4-0.63,0.88-1.1,0.96c-0.87,0.16-1.42-0.32-1.82-1.04
			c-0.08-0.71,0.15-1.35,0.78-1.67C130.44,135.63,131.23,136.03,131.62,136.6"
          />
          <path
            d="M140.95,137.07c0.16,0.64,0.31,1.76-0.64,1.92c-1.1,0.48-1.74-0.32-2.37-0.97c0-0.71,0.4-1.27,0.95-1.59
			C139.68,136.35,140.39,136.42,140.95,137.07"
          />
          <path
            d="M153.9,138.11c-0.07,0.56,0.08,1.35-0.55,1.51c-0.39,0.4-1.1,0.24-1.5,0.16c-0.16-0.49-1.03-0.88-0.55-1.44
			c0.31-0.4,0.63-0.64,1.1-0.88C152.87,137.7,153.59,137.47,153.9,138.11"
          />
          <path
            d="M367.22,138.67c-0.24,0.56-0.55,1.28-1.19,1.28c-0.71-0.09-1.42-0.16-1.82-0.89
			c-0.31-0.87,0.4-1.35,1.03-1.59C366.03,137.47,367.07,137.79,367.22,138.67"
          />
          <path
            d="M352.77,139.06c0.08,0.65-0.63,1.12-0.96,1.36c-1.1,0.47-2.04-0.32-2.45-1.19c0.16-0.72,0.72-1.21,1.34-1.44
			C351.74,137.7,352.29,138.26,352.77,139.06"
          />
          <path
            d="M362.55,138.9c0,0.56-0.31,1.12-0.7,1.35c-0.64,0.24-1.42,0.16-1.9-0.31c-0.71-0.65-0.16-1.21,0.32-1.68
			C360.99,137.87,362.33,137.94,362.55,138.9"
          />
          <path
            d="M134.07,140.26c0.16,0.87-0.15,1.68-0.94,1.99c-0.71,0-1.5-0.39-1.74-1.12c-0.08-0.71,0.16-1.43,0.8-1.67
			C132.96,139.22,133.6,139.7,134.07,140.26"
          />
          <path
            d="M348.82,141.13c0.23,0.89-0.24,1.68-1.11,2.08c-0.63,0.16-1.03-0.16-1.5-0.56c-0.32-0.4-0.71-1.28-0.08-1.68
			C346.76,139.78,348.34,140.26,348.82,141.13"
          />
          <path
            d="M176.58,142.02c0.32,0.88-0.63,1.04-0.87,1.51c-0.31,0.16-0.95,0.24-1.18-0.07
			c-0.39-0.48-0.87-0.96-0.63-1.68c0.39-0.32,0.55-0.64,1.03-0.8C175.79,140.74,176.11,141.53,176.58,142.02"
          />
          <path
            d="M365.79,142.57c0.17,0.55-0.31,0.79-0.39,1.19c-0.48,0.4-1.26,0.48-1.83,0.32c-0.71-0.4-0.71-1.04-0.71-1.76
			c0.48-0.31,0.55-0.71,1.11-0.88C364.7,141.45,365.57,141.77,365.79,142.57"
          />
          <path
            d="M360.9,142.81c0.32,0.96-0.55,1.44-1.18,1.67c-0.32,0.08-0.78-0.16-0.88,0.32c2.06,1.04,3.33,2.72,4.91,4.23
			v0.63c-2.85-0.07-5.77-0.79-7.83-3.02c-0.55-0.4-1.5-0.4-2.13-0.96c1.03-1.04,2.93-0.64,4.34-0.72c1.03-1.12-1.19-1.91,0.16-2.87
			c0.48-0.07,0.8-0.56,1.43-0.39C360.27,141.94,360.59,142.33,360.9,142.81"
          />
          <path
            d="M328.59,142.9c0.16,0.39-0.31,0.87-0.4,1.27c-0.47,0.48-1.18,0.55-1.73,0.32c-0.4-0.55-1.03-1.03-0.79-1.83
			C326.3,141.45,328.36,141.7,328.59,142.9"
          />
          <path
            d="M151.93,145.68c-0.24,0.72-0.56,1.19-1.34,1.27c-0.64-0.08-1.19-0.31-1.43-0.95
			c-0.08-0.56,0.32-0.88,0.4-1.28C150.43,144.01,152.01,144.48,151.93,145.68"
          />
          <path
            d="M158.33,144.64c0,1.12-1.19,1.76-1.58,2.87c-1.19,2.63-3.32,5.02-6.32,5.26c-0.32-0.72,1.03-1.58,1.34-2.31
			c1.27-2.71,3.79-4.15,6.08-5.82H158.33"
          />
          <path
            d="M326.69,145.76c0.08,0.88-0.71,1.51-1.34,1.83c-0.71,0.24-1.27-0.32-1.66-0.64
			c-0.63-0.64-0.23-1.27,0.09-1.91C324.71,144.09,326.06,144.87,326.69,145.76"
          />
          <path
            d="M331.59,146.31c-0.09,0.88-0.64,1.52-1.43,1.76c-0.16-0.25-0.55-0.09-0.78-0.16
			c-0.56-0.32-1.03-0.8-1.03-1.44c0.39-0.39,0.55-1.04,1.1-1.11C330.17,145.28,331.35,145.36,331.59,146.31"
          />
          <path
            d="M170.58,146.24c0.08,0.88-0.56,1.36-1.19,1.68c-0.71,0.07-1.18-0.4-1.5-0.96c-0.16-0.72,0.55-1.19,0.94-1.59
			C169.79,145.04,170.02,145.76,170.58,146.24"
          />
          <path
            d="M176.02,146.56c-0.24,0.72-0.71,1.19-1.5,1.35c-0.56-0.16-1.18-0.32-1.43-0.96c-0.07-0.8,0.56-1.19,1.11-1.59
			C175,145.36,175.87,145.68,176.02,146.56"
          />
          <path
            d="M348.58,152.13c0.4,0.32,1.11,0.72,0.95,1.28c-1.27,0.56-2.29-0.88-3.48-1.12c-1.9-1.91-3.31-3.99-4.1-6.38
			c0.71-0.71,1.49,0.16,2.13,0.4C345.89,147.83,347.63,149.83,348.58,152.13"
          />
          <path
            d="M351.5,146.56c0.24,0.56,0.16,1.19-0.16,1.75c-0.47,0.81-1.5,0.64-2.21,0.49c-0.4-0.49-1.1-1.12-0.63-1.84
			c0.23-0.64,0.94-0.8,1.5-1.04C350.55,145.84,350.95,146.31,351.5,146.56"
          />
          <path
            d="M183.29,148.63c0.08,0.64,0.24,1.59-0.55,1.99c-0.72,0.16-1.66-0.07-1.9-0.96c-0.39-0.95,0.47-1.12,0.95-1.58
			C182.43,147.91,182.82,148.23,183.29,148.63"
          />
          <path
            d="M331.35,153.82c1.11,1.58,1.9,3.58,2.06,5.66c-0.23,0.56,0.4,1.12-0.08,1.51h-0.31
			c-3.56-2.55-4.35-6.15-5.69-9.73c-0.31-0.16-0.4-0.47-0.31-0.79c0.78-0.72,1.34,0.72,2.12,0.96L331.35,153.82"
          />
          <path
            d="M193.56,151.1c-0.08,0.09,0,0.16,0.08,0.16c-3.15,2.24-7.58,3.59-11.21,1.44c-0.08-0.24,0.08-0.63,0.39-0.72
			c2.3-0.72,4.51-1.83,6.95-1.51L193.56,151.1"
          />
          <path
            d="M175.55,151.5c-2.68,1.68-5.14,4.95-8.46,5.75c-0.63,0.24-1.02,1.11-1.81,0.64
			c1.42-4.32,6.08-6.63,10.27-7.02V151.5"
          />
          <path
            d="M326.69,155.09c0.23,0.64,0,1.51-0.63,1.67c-0.4,0.4-1.2,0.4-1.51,0c-0.71-0.32-0.71-1.04-0.71-1.6
			c0.24-0.56,0.8-0.63,1.19-0.87C325.74,154.45,326.3,154.45,326.69,155.09"
          />
          <path
            d="M320.13,155.49c-0.47,1.11-1.81,1.75-2.84,2.07c-2.69,0.95-5.14-0.32-7.51-0.96c0.08-0.64,0.87-0.4,1.27-0.8
			c2.61-0.56,5.3-1.11,8.06-0.4C319.34,155.25,319.89,155.16,320.13,155.49"
          />
          <path
            d="M175.55,157.8c-0.24,0.32-0.31,1.04-0.95,1.27c-0.55,0.25-1.1,0-1.57-0.31c-0.16-0.49-0.71-0.88-0.32-1.44
			c0.16-0.56,0.79-0.72,1.26-0.88C174.75,156.53,175.39,157,175.55,157.8"
          />
          <path
            d="M180.22,158.52c-0.24,0.48-0.48,1.2-1.11,1.52c-0.63,0.16-1.1-0.16-1.58-0.56c-0.23-0.47-0.47-1.03-0.23-1.59
			c0.16-0.4,0.71-0.48,1.1-0.72C179.18,157.08,179.9,157.88,180.22,158.52"
          />
          <path
            d="M325.19,159.32c0.08,0.63-0.23,1.04-0.54,1.51c-0.4,0.56-1.19,0.63-1.75,0.32c-0.55-0.08-0.71-0.72-0.94-1.12
			c-0.47-0.64,0.31-1.04,0.54-1.59C323.37,157.72,324.8,158.12,325.19,159.32"
          />
          <path
            d="M328.67,159.16c0.08,0.4,0.31,0.8,0.16,1.12c-0.47,0.4-0.79,1.43-1.74,1.04c-0.47,0.16-0.7-0.48-0.94-0.63
			c-0.32-0.79-0.08-1.99,0.94-2.16C327.8,158.28,328.28,158.91,328.67,159.16"
          />
          <path
            d="M177.37,161.46c0.15,0.89-0.16,1.68-0.8,2.16c-0.87,0.16-1.98,0.16-2.29-0.89c-0.08-0.79,0-1.82,0.96-2.3
			C176.02,160.35,176.97,160.51,177.37,161.46"
          />
          <path
            d="M181.87,161.15c-0.16,2.23-0.63,4.94-2.85,6.22c-1.27,0.24-2.53,1.44-3.79,0.64v-0.4
			C176.89,165.22,179.9,163.39,181.87,161.15"
          />
          <path
            d="M312.79,162.99c0.31,0.47-0.23,0.88-0.47,1.27c-0.47,0.57-1.34,0.32-1.9,0.09c-0.56-0.24-0.64-1.04-0.4-1.6
			c0.16-0.47,0.63-0.71,1.03-0.88C311.76,161.79,312.39,162.43,312.79,162.99"
          />
          <path
            d="M189.77,166.64c1.1,1.84,0.47,4.31,1.1,6.31c-1.34,0.16-1.89-1.36-2.76-2.15c-1.74-2.4-1.5-5.58-2.29-8.38
			c0.16-0.24,0.4-0.24,0.56-0.16c1.18,0.8,2.05,2.31,3,3.34C189.22,166.17,189.85,166.17,189.77,166.64"
          />
          <path
            d="M316.73,163.62c0,0.63-0.71,0.8-1.1,1.2c-0.56-0.08-1.03-0.32-1.51-0.72c-0.23-0.79,0.39-1.03,0.79-1.43
			C315.71,162.35,316.5,162.74,316.73,163.62"
          />
          <path
            d="M326.14,170.72c0,0.24-0.08,0.72-0.47,0.56h-0.32c-2.29-2.08-4.59-4.38-4.26-7.58l0.46-0.07
			C323.69,165.45,326.06,167.45,326.14,170.72"
          />
          <path
            d="M320.21,168.16c0.08,0.64-0.32,1.12-0.71,1.52c-0.87,0.24-2.21,0.16-2.37-1.04c-0.08-0.8,0.79-1.2,1.19-1.6
			C319.27,166.81,319.89,167.45,320.21,168.16"
          />
          <path
            d="M315,169.84c-1.1,2.95-4.12,4.47-6.17,6.69c-0.39-0.24-0.23-0.87-0.16-1.19c0.81-3.03,3.17-5.98,6.09-7.18
			C316.02,168.33,314.76,169.2,315,169.84"
          />
          <path
            d="M320.93,172.23c0,0.72-0.71,1.04-1.11,1.44c-0.63-0.08-1.26-0.24-1.74-0.88c-0.07-0.79,0.55-1.03,1.03-1.43
			C319.89,171.19,320.61,171.6,320.93,172.23"
          />
          <path
            d="M262.25,28.1h-0.88c-2.29,0-0.35,2.78,0.89,2.78C263.78,30.87,263.88,28.1,262.25,28.1 M255.17,30.87
			c2.29,0,0.13-2.78-1.11-2.78h-2.29c-2.29,0-0.13,2.78,1.11,2.78H255.17z M245.57,30.87c2.3,0,0.13-2.78-1.1-2.78h-2.29
			c-2.29,0-0.12,2.78,1.1,2.78H245.57z M262.13,27.46c1.08,0,1.96,0.89,1.96,1.97c0,1.1-0.88,1.97-1.96,1.97H238.7
			c-1.08,0-1.95-0.88-1.95-1.97c0-1.09,0.87-1.97,1.95-1.97H262.13z"
          />
          <path
            d="M262.3,63.86c0,1.04,1.39,0.35,2.42,0.35c1.03,0,2.42,0.69,2.42-0.35c0-1.48-1.38-0.96-2.42-0.96
			C263.69,62.9,262.3,62.38,262.3,63.86 M266.69,66.31c1,0,1.09-1.39,1.09-2.58c0-2.72-2.15,0-3.25-2.56
			c-0.8,2.16-3.01,0.16-3.01,2.48c0,1.61-0.01,2.67,1.58,2.67H266.69z M260.08,54.4l4.47,1.72l4.4-1.72l-1.74,6.61l3.49-0.72
			l-1.19,4l1.19,3.84l-3.09-0.83l1.34,7.12l-4.4-1.73l-4.47,1.73l1.83-7.12l-3.57,0.99l0.95-4l-0.95-3.84l3.41,0.56L260.08,54.4z"
          />
          <polyline
            points="249.6,64.11 249.6,63.28 248.85,63.14 249.44,62.53 249.08,61.85 249.85,61.93 250.31,61.11 
			250.76,61.92 251.55,61.85 251.19,62.53 251.77,63.14 251.02,63.28 251.02,64.11 250.31,63.58 249.6,64.11 		"
          />
          <polyline
            points="246.12,68.6 246.12,67.76 245.38,67.62 245.97,67.01 245.6,66.33 246.37,66.41 246.84,65.59 
			247.29,66.4 248.07,66.33 247.71,67.01 248.3,67.62 247.55,67.76 247.55,68.6 246.84,68.07 246.12,68.6 		"
          />
          <polyline
            points="252.02,68.03 252.02,67.19 251.28,67.05 251.86,66.44 251.5,65.76 252.26,65.84 252.73,65.03 
			253.18,65.83 253.97,65.76 253.61,66.44 254.19,67.05 253.44,67.19 253.44,68.03 252.73,67.5 252.02,68.03 		"
          />
          <polyline
            points="252.72,71.38 252.72,70.9 252.29,70.82 252.63,70.47 252.42,70.1 252.86,70.14 253.12,69.68 
			253.37,70.14 253.82,70.1 253.62,70.47 253.94,70.82 253.52,70.9 253.52,71.38 253.12,71.07 252.72,71.38 		"
          />
          <polyline
            points="247.25,75.2 247.25,73.78 245.96,73.52 246.97,72.48 246.34,71.32 247.66,71.47 248.46,70.07 
			249.24,71.44 250.57,71.32 249.95,72.48 250.96,73.52 249.67,73.78 249.67,75.2 248.46,74.31 247.25,75.2 		"
          />
          <polyline
            points="250.03,0 252.82,6.85 259.86,4.76 256.29,11.24 262.3,15.5 255.05,16.71 255.49,24.11 250.03,19.16 
			244.58,24.11 245.02,16.71 237.78,15.5 243.78,11.24 240.2,4.76 247.25,6.85 250.03,0 		"
          />
          <path
            d="M224.54,153.76c-0.84,0.32-1.16,0.54-0.96,1.42l0.99,4.2c0.19,0.77,0.24,2-0.28,2.75
			c-0.61,0.9-1.92,1.48-2.86,1.7c-0.69,0.16-2.18,0.44-3.38-0.06c-0.79-0.35-1.31-0.71-1.64-2.13l-1.04-4.4
			c-0.26-1.11-0.67-1.03-1.56-0.91l-0.09-0.33l5.3-1.27l0.08,0.33c-1.03,0.28-1.51,0.42-1.24,1.57l1.03,4.41
			c0.18,0.74,0.61,2.57,2.86,2.02c2.76-0.66,2.32-2.69,2.12-3.53l-0.94-4.02c-0.19-0.79-0.49-1.2-1.85-0.93l-0.07-0.33l3.45-0.83
			L224.54,153.76"
          />
          <path
            d="M232.39,154.64c-0.48-0.86-1.76-2.17-3.62-1.75c-0.82,0.18-1.6,0.77-1.41,1.63c0.16,0.72,0.52,0.94,1.97,1.2
			l2.53,0.45c0.58,0.11,1.95,0.6,2.26,2.06c0.25,1.16-0.4,2.99-3.29,3.62c-1.5,0.34-2.3,0.05-2.79,0.15
			c-0.33,0.08-0.35,0.33-0.37,0.56l-0.46,0.1l-0.71-3.27l0.45-0.1c0.61,1.27,1.85,2.55,3.85,2.12c1.89-0.42,1.68-1.76,1.64-1.92
			c-0.19-0.88-1-1.04-2.12-1.22l-1.15-0.19c-2.93-0.49-3.18-1.62-3.31-2.23c-0.18-0.81-0.08-2.71,2.91-3.38
			c1.26-0.28,2.1-0.01,2.55-0.11c0.36-0.08,0.4-0.3,0.42-0.56l0.47-0.11l0.64,2.86L232.39,154.64"
          />
          <path
            d="M242.82,159.87l-5.73,0.55l-0.03-0.33c1.17-0.19,1.55-0.26,1.45-1.39l-0.68-7.11
			c-1.12,0.16-2.77,0.39-2.88,2.58l-0.46,0.04l-0.22-2.72l9.63-0.93l0.29,2.71l-0.46,0.05c-0.53-2.13-2.19-2.04-3.3-1.98l0.67,7.12
			c0.11,1.14,0.5,1.13,1.69,1.09L242.82,159.87"
          />
          <path
            d="M249.24,155.06c2.11,0.02,2.95-0.26,2.99-2.02c0.02-1.37-0.63-1.97-1.91-2c-0.89-0.02-0.92,0.14-1.01,0.55
			L249.24,155.06 M256.22,159.87l-3.3-0.07l-3.25-4.3l-0.44-0.01l-0.05,2.65c-0.02,1.09,0.33,1.13,1.39,1.28l0,0.33l-5.44-0.11
			v-0.33c1.1-0.09,1.43-0.13,1.44-1.23l0.12-5.98c0.03-1.17-0.48-1.21-1.39-1.28l0.01-0.34l5.19,0.1c1.13,0.02,4.54,0.2,4.49,2.48
			c-0.03,1.67-1.69,2.05-2.57,2.27l3.17,3.92c0.22,0.25,0.46,0.27,0.65,0.28L256.22,159.87z"
          />
          <path
            d="M262.31,157.43l-1.21-3.46l-1.9,3.08L262.31,157.43 M266.79,161.23l-5.17-0.65l0.03-0.34
			c1.01,0.06,1.31,0.08,1.35-0.36c0.05-0.36-0.34-1.43-0.51-1.88l-3.6-0.46c-0.57,0.91-0.89,1.43-0.92,1.65
			c-0.06,0.51,0.71,0.64,1.25,0.74l-0.05,0.33l-3.25-0.4l0.05-0.34c0.87,0,1.16-0.42,1.76-1.38l4.45-6.92l0.45,0.05l2.69,7.52
			c0.54,1.53,0.75,2.02,1.51,2.09L266.79,161.23z"
          />
          <path
            d="M276.17,163.15l-9.17-1.69l0.07-0.33c0.9,0.07,1.38,0.12,1.54-0.69l1.16-6.45c0.16-0.88-0.47-1.03-1.19-1.2
			l0.06-0.33l5.52,1.02l-0.05,0.34c-1.11-0.16-1.61-0.25-1.83,0.93l-1.13,6.29c-0.12,0.63,0.21,0.81,1.27,1
			c2.39,0.44,3.48-0.5,4.48-1.88l0.46,0.09L276.17,163.15"
          />
          <path
            d="M282.25,164.51l-5.52-1.16l0.07-0.33c0.86,0.11,1.5,0.18,1.65-0.64l1.33-6.42c0.19-0.87-0.55-1.06-1.27-1.25
			l0.06-0.33l5.52,1.15l-0.07,0.33c-0.75-0.12-1.51-0.27-1.69,0.63l-1.32,6.43c-0.18,0.84,0.44,1.02,1.3,1.25L282.25,164.51"
          />
          <path
            d="M288.89,162.25l-1.29-3.44l-1.83,3.12L288.89,162.25 M293.46,165.95l-5.2-0.54l0.04-0.33
			c0.99,0.03,1.28,0.04,1.33-0.4c0.04-0.36-0.37-1.41-0.55-1.87l-3.61-0.38c-0.54,0.92-0.86,1.45-0.88,1.67
			c-0.05,0.51,0.71,0.63,1.26,0.72l-0.04,0.33l-3.25-0.33l0.02-0.33c0.89-0.02,1.16-0.46,1.73-1.41l4.32-7.03l0.44,0.05l2.87,7.47
			c0.57,1.52,0.78,2,1.55,2.05L293.46,165.95z"
          />
          <path
            d="M249.44,57.22c-0.95,0-2.23,0.65-2.23-0.47c0-1.6,1.08-1.24,2.03-1.24c0.94,0,2.42-0.36,2.42,1.24
			C251.66,57.87,250.39,57.22,249.44,57.22 M252.26,56.59c0-3.25-1.82-0.2-2.83-2.97c-0.74,2.34-2.93,0.37-2.93,2.89
			c0,0.96,0.25,1.52,0.71,1.83v0.96c0,0.25,0.2,0.45,0.44,0.45h3.46c0.24,0,0.45-0.2,0.45-0.45v-1.04
			C251.97,57.9,252.26,57.34,252.26,56.59z"
          />
          <path
            d="M280.53,96.55c0.82,0,1.49-0.59,1.49-1.32c0-0.72-0.67-1.31-1.49-1.31c-0.83,0-1.5,0.59-1.5,1.31
			C279.03,95.96,279.7,96.55,280.53,96.55"
          />
          <path
            d="M273.52,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.38,0,0.71-0.35,0.71-0.79
			C274.24,49.7,273.52,48.33,273.52,48.33"
          />
          <path
            d="M225.8,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C226.52,49.7,225.8,48.33,225.8,48.33"
          />
          <path
            d="M237.73,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C238.45,49.7,237.73,48.33,237.73,48.33"
          />
          <path
            d="M249.66,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.39,0,0.71-0.35,0.71-0.79
			C250.38,49.7,249.66,48.33,249.66,48.33"
          />
          <path
            d="M261.59,48.33c0,0-0.7,1.18-0.7,1.39c0,0.43,0.32,0.79,0.71,0.79c0.4,0,0.71-0.35,0.71-0.79
			C262.31,49.7,261.59,48.33,261.59,48.33"
          />
          <path
            d="M225.82,64.74c0,0,0.7,1.18,0.7,1.4c0,0.43-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.35-0.71-0.78
			C225.11,66.12,225.82,64.74,225.82,64.74"
          />
          <path
            d="M225.82,80.68c0,0,0.7,1.19,0.7,1.4c0,0.42-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.36-0.71-0.78
			C225.11,82.06,225.82,80.68,225.82,80.68"
          />
          <path
            d="M227.73,93.31c0,0,0.7,1.18,0.7,1.41c0,0.43-0.32,0.77-0.71,0.77c-0.4,0-0.71-0.34-0.71-0.77
			C227.02,94.69,227.73,93.31,227.73,93.31"
          />
          <path
            d="M237.75,100.02c0,0,0.7,1.18,0.7,1.4c0,0.43-0.32,0.78-0.71,0.78c-0.39,0-0.71-0.35-0.71-0.78
			C237.02,101.4,237.75,100.02,237.75,100.02"
          />
          <path
            d="M274.13,64.74c0,0-0.7,1.18-0.7,1.4c0,0.43,0.32,0.78,0.71,0.78c0.4,0,0.71-0.35,0.71-0.78
			C274.85,66.12,274.13,64.74,274.13,64.74"
          />
          <path
            d="M274.13,80.68c0,0-0.7,1.19-0.7,1.4c0,0.42,0.32,0.78,0.71,0.78c0.4,0,0.71-0.36,0.71-0.78
			C274.85,82.06,274.13,80.68,274.13,80.68"
          />
          <path
            d="M272.22,93.31c0,0-0.7,1.18-0.7,1.41c0,0.43,0.33,0.77,0.71,0.77c0.39,0,0.71-0.34,0.71-0.77
			C272.94,94.69,272.22,93.31,272.22,93.31"
          />
          <path
            d="M262.21,100.02c0,0-0.7,1.18-0.7,1.4c0,0.43,0.31,0.78,0.71,0.78s0.71-0.35,0.71-0.78
			C262.93,101.4,262.21,100.02,262.21,100.02"
          />
          <path
            d="M250.02,107.13c0,0-0.69,1.18-0.69,1.41c0,0.43,0.31,0.78,0.71,0.78c0.39,0,0.7-0.35,0.7-0.78
			C250.75,108.51,250.02,107.13,250.02,107.13"
          />
          <path
            d="M269.44,83.34c0.15,0.24,0.53,0.31,1,0.14l0.44-1.29c0.05-0.08-0.52-0.6-0.85-0.51
			c-1.27,0.3-2.62,0.46-3.37,1.66c0,0.84,0.6,1.6,1.2,1.97c0.45-0.15,0.53,0.3,0.82,0.53c0.09,0.38-0.15,0.69-0.52,0.69
			c-0.9-0.92-2.47,0.3-3.15-0.84c0.3-1.13,0.89-2.42-0.09-3.48c0.09-0.23,0.45-0.46,0.23-0.68c-0.37-1.07-1.49-0.23-2.17-0.23
			c-1.13-0.15-2.04,0.46-2.71,1.36c0.08,0.38,0.23,0.76,0.61,1.14c0.3-0.07,0.45,0,0.52,0.3l-0.75,0.46
			c0.23,0.22,0.23,0.53,0.3,0.83c-0.52,0.22-1.04-0.53-1.27-0.91c0-0.6,0.08-1.66-0.75-1.82c-0.15,0-0.65,0.23-0.65,0.23
			c-0.18,0.23-0.3,0.7-0.3,1.25c0,0.54,0.3,1.35,0.3,1.35s2.32,1.71,2.84,2.78c-0.68,1.36-0.68,3.25-2.25,4.01
			c-0.46,0.3-0.46,0.76-0.46,1.21l0.38,0.31c0.98-0.54,2.85-0.69,2.7-2.35c0.38-0.68,0.75-1.43,1.43-1.97
			c0.76-0.15,1.43,0.23,1.73,0.84c-0.23,1.13-0.97,1.89-2.03,2.27c-0.15,0.16-0.08,0.38-0.08,0.61c0.3,0.53,1.13,0.3,1.58,0.22
			c1.27-0.83,1.8-2.35,2.93-2.8c0.15,0.75-1.5,0.98-0.45,1.74c0.68-0.15,1.58,0.08,2.03-0.84c-0.53-1.28,1.79-1.89,0.37-3.03
			c0-1.13,1.21-1.58,1.13-2.8c-0.45-1.21-1.87-1.21-2.25-2.35C268.32,83.04,268.98,83.11,269.44,83.34"
          />
          <path
            d="M214.62,97.13c0.82,0,1.48-0.59,1.48-1.31c0-0.73-0.66-1.32-1.48-1.32c-0.83,0-1.5,0.59-1.5,1.32
			C213.13,96.54,213.79,97.13,214.62,97.13"
          />
          <path
            d="M137.24,142.43c0.81,0,1.46-0.63,1.46-1.41c0-0.78-0.65-1.41-1.46-1.41c-0.81,0-1.46,0.63-1.46,1.41
			C135.78,141.8,136.43,142.43,137.24,142.43"
          />
          <path
            d="M186.28,153.29c0.07,0,0.09-0.01,0.09-0.06c0-0.05-0.02-0.06-0.06-0.06c-0.03,0-0.03,0.01-0.03,0.02V153.29
			 M186.49,153.42h-0.1l-0.09-0.12h-0.02v0.08c0,0.03,0.01,0.03,0.05,0.04v0.01h-0.17v-0.01c0.04-0.01,0.05-0.01,0.05-0.04v-0.18
			c0-0.03-0.02-0.04-0.05-0.04v-0.01h0.16c0.03,0,0.13,0.01,0.13,0.08c0,0.04-0.05,0.06-0.07,0.07l0.09,0.11
			C186.47,153.41,186.49,153.41,186.49,153.42L186.49,153.42z"
          />
          <path
            d="M186.7,153.24c-0.01-0.03-0.05-0.07-0.1-0.07c-0.02,0-0.05,0.02-0.05,0.04c0,0.02,0.01,0.03,0.05,0.04
			l0.07,0.04c0.02,0,0.05,0.03,0.05,0.07c0,0.04-0.03,0.09-0.12,0.09c-0.04,0-0.07-0.02-0.08-0.02c-0.01,0-0.01,0-0.01,0.02h-0.02
			v-0.11h0.02c0.01,0.04,0.03,0.09,0.09,0.09c0.06,0,0.06-0.04,0.06-0.05c0-0.02-0.02-0.03-0.05-0.05l-0.03-0.01
			c-0.09-0.03-0.09-0.07-0.09-0.08c0-0.03,0.02-0.08,0.1-0.08c0.04,0,0.07,0.01,0.08,0.01c0.01,0,0.01-0.01,0.02-0.01h0.01v0.09
			H186.7"
          />
          <path
            d="M187.19,153.16c-0.02,0-0.03,0.02-0.03,0.03l-0.1,0.24h-0.01l-0.08-0.19l-0.09,0.19h-0.01l-0.1-0.24
			c-0.01-0.02-0.02-0.02-0.03-0.03v-0.01h0.14v0.01c-0.02,0-0.03,0.01-0.03,0.02c0,0,0,0.01,0.01,0.02l0.05,0.14h0l0.05-0.12
			l-0.02-0.05c-0.01-0.01-0.01-0.02-0.04-0.02v-0.01h0.15v0.01c-0.02,0-0.04,0-0.04,0.02c0,0.01,0,0.02,0.01,0.02l0.05,0.13
			l0.05-0.13c0,0,0-0.01,0-0.02c0-0.02-0.02-0.02-0.04-0.02v-0.01L187.19,153.16L187.19,153.16"
          />
          <path
            d="M187.32,153.31c0,0,0.02-0.01,0.02-0.02c0,0,0-0.02,0-0.03c0-0.08-0.01-0.11-0.04-0.11
			c-0.03,0-0.03,0.02-0.03,0.06C187.27,153.27,187.28,153.31,187.32,153.31 M187.2,153.42c0.05-0.02,0.1-0.03,0.12-0.11
			c0,0-0.01,0-0.02,0c0,0.01-0.02,0.01-0.02,0.01c-0.04,0-0.09-0.01-0.09-0.08c0-0.02,0.02-0.09,0.11-0.09
			c0.04,0,0.11,0.02,0.11,0.11C187.41,153.34,187.35,153.42,187.2,153.42L187.2,153.42z"
          />
          <path
            d="M187.47,153.24c0.05,0.01,0.07,0.01,0.11,0.02c0.02,0.01,0.05,0.04,0.05,0.07c0,0.05-0.05,0.1-0.14,0.1
			c-0.03,0-0.06-0.02-0.06-0.04c0-0.01,0.01-0.03,0.03-0.03c0.03,0,0.06,0.04,0.09,0.04c0.02,0,0.05,0,0.05-0.04
			c0-0.02-0.02-0.05-0.05-0.05c-0.03-0.02-0.07-0.02-0.1-0.02l0.05-0.14h0.15l-0.02,0.05h-0.13L187.47,153.24"
          />
          <path
            d="M275.93,89.16c-0.29,3.19-1.37,5.85-3.57,7.35l-20.75,13.22h-0.11c-0.43,0.35-0.99,0.57-1.6,0.57
			c-0.63,0-1.18-0.22-1.62-0.59l-20.72-13.2c-2.21-1.5-3.28-4.16-3.58-7.35V47.91h51.96V89.16 M277.48,46.44V46.4h-55.06v0.04h-0.01
			l0.05,42.16c0,4.29,1.82,8.06,4.32,9.22l21.18,13.47c0.47,0.54,1.16,0.87,1.93,0.87c0.67,0,1.27-0.25,1.72-0.67l21.5-13.67
			c2.51-1.16,4.34-4.92,4.34-9.22L277.48,46.44L277.48,46.44z"
          />
          <path d="M257.37,107.89l0.67-0.32C257.75,107.66,257.55,107.77,257.37,107.89" />
          <path
            d="M304.96,148.79c-0.71,1.43-1.82,2.46-3.39,2.7c0.08-1.91,1.81-3.26,1.18-5.41c-1.03-1.52-3.08-1.99-4.98-1.6
			c-0.71,0.48-0.39,1.28-0.63,1.99l-0.31,2.64c-1.59,0-1.66-1.44-2.14-2.55c0.08-1.69,0.79-3.35,2.45-4.15
			c1.27-0.64,3.32-0.56,4.58,0.16c1.03,0.23,2.21,0.72,2.61,1.67C305.51,145.36,305.2,147.36,304.96,148.79 M298.57,149.59
			c0.87-0.88-0.24-5.9,2.21-2.64c0,0.64,0.08,1.59-0.55,2.08C299.82,149.35,299.2,149.99,298.57,149.59z M299.44,151.58
			c0.07,1.27-1.11,0.95-1.74,1.43h-1.9c-0.4-0.63-0.25-1.58-0.25-2.23C296.83,151.02,298.25,151.02,299.44,151.58z M301.65,161.31
			c-0.63,0.8-0.08,2.08-0.87,2.79c0,1.44-1.18,2.15-2.21,2.71c-9.41,2.07-18.65-1.11-27.74-2.71c-12.73-2.79-27.57-3.59-40.53-0.64
			c-6.09,1.12-12.01,2.55-17.86,4.23c-3.63,0.47-7.59,1.04-11.06-0.08c-2.85-1.35-1.66-5.02-2.53-7.57c0.16-2-0.55-3.59-0.63-5.5
			c-0.24-0.64-0.4-1.44-0.31-2.08c1.18,0.17,2.37,1.04,3.63,1.37c6.63,1.27,12.56-0.88,18.73-2c5.92-1.27,12.01-2.31,18.25-2.78
			c7.12-0.08,14.38-0.49,21.17,0.71c2.21,0.09,4.11,0.64,6.24,0.81c4.43,0.55,8.53,1.75,13.04,2.07c0.7,0.24,1.57,0.24,2.37,0.39
			c7.11,1.13,15.08,3.11,21.88,0C303.22,155.89,302.04,158.52,301.65,161.31z M261.51,167.13c0.4,1.12,1.11,2.16,0.71,3.51
			c-0.78,1.52-2.37,1.52-3.79,1.84c-0.71,0.07-1.26-0.16-1.81-0.56c0.07-0.32-0.08-0.55-0.32-0.8c0.24-1.04-1.82-2.07-0.39-2.95
			C257.17,166.98,260.17,164.25,261.51,167.13z M263.25,167.45c0.94,0.24,1.03,1.27,1.1,2.07c-0.08,1.76-1.03,3.27-2.69,3.91
			c-0.86,0.47-2.21,0.63-3.08,0.32c1.89-0.08,3.95-0.79,4.74-2.71L263.25,167.45z M265.3,178.05c-0.16,0.08-0.16,0.24-0.16,0.32
			c-2.28,0.08-3.79-1.27-5.53-2.47l-0.07-0.4C261.67,175.66,263.41,177.02,265.3,178.05z M262.38,185.39
			c-0.08,0.24-0.47,0.24-0.63,0.07c-2.37-3.02-3.48-6.22-4.98-9.57l0.32-0.08C258.99,178.93,260.48,182.35,262.38,185.39z
			 M258.43,183.47l-0.39,0.16c-0.4-0.32-0.25-1.18-0.16-1.67C258.35,182.35,258.74,182.91,258.43,183.47z M256.22,178.7
			c0.08,0.79,0.87,1.11,0.4,1.83C256.45,180.21,255.99,179.49,256.22,178.7z M255.74,173.43h0.16v0.24h-0.16V173.43z M251.16,185.15
			c-1.5,0.08-3.79,0.96-5.22-0.32c0.09-0.63-0.63-0.96-0.55-1.6c1.26-0.48,2.36,1.04,3.55-0.07c1.82,0.07,3.72-0.25,5.53,0.15
			c0.87,0.24,2.21-1.43,2.37,0.24C256.22,186.19,253.06,184.35,251.16,185.15z M247.69,175.66l0.38-0.63
			c-0.62,1.91-1.57,3.9-2.6,5.73C245,178.85,246.98,177.34,247.69,175.66z M249.89,174.15c1.03-1.12,1.98,0.63,2.37,1.51
			c1.2,1.75,4.27,3.35,3.08,5.74c-1.49,1.35-3.87,0.08-5.53,1.11c-0.87-0.07-2.37,0.17-2.84-0.71
			C247.84,179.25,248.64,176.38,249.89,174.15z M249.66,171.11h-0.31l0.47-0.47C250.06,170.8,249.74,170.96,249.66,171.11z
			 M251.48,171.28c0.32-0.48,0.71-1.2,1.26-1.2c0,0.07,0.08,0.16,0.16,0.25C252.42,170.72,252.03,171.11,251.48,171.28z
			 M252.58,172.47c0.16-0.16,0.55-0.16,0.71,0H252.58z M254.71,174.94c-0.08,0.16,0.08,0.4-0.16,0.56l-1.82-1.44
			C253.62,173.9,254.24,174.31,254.71,174.94z M254.48,170.08h0.09v0.24h-0.09V170.08z M257.88,165.13
			c-0.95,0.32-1.67,1.44-2.61,1.67C255.74,165.93,256.93,165.05,257.88,165.13z M253.93,164.1c-0.16-0.71,0.71-0.16,1.03-0.48
			l2.45,0.16C256.13,163.62,254.56,166.57,253.93,164.1z M248.16,166.33c0.39-1.11,0.47-2.08,1.18-2.95c0.79,0,1.5,0,2.13,0.31
			c0.16,1.6,2.14,3.75,0,5.11c-0.71,0.32-1.74,1.04-2.53,0.32C247.92,168.25,247.76,167.45,248.16,166.33z M246.98,171.28
			c-0.08-0.32,0.16-0.72,0.24-1.13c0.39-0.39,0.79,0.33,0.95,0.56C247.92,171.11,247.45,171.36,246.98,171.28z M246.82,166.73h0.08
			v0.87h-0.08V166.73z M245.95,165.22c-1.03-0.25-1.58-1.44-2.68-1.44c0.94-0.33,1.89-0.25,3-0.09
			C246.66,164.25,246.03,164.74,245.95,165.22z M245.08,171.67c0.24-1.04,0.71-2.39,1.26-3.34
			C246.27,169.36,245.79,170.8,245.08,171.67z M243.34,171.67c-1.34-0.16-2.61-0.56-3.79-0.96c-0.8-0.79,0-1.67,0-2.55
			c1.1-1.75,4.27-2.62,5.76-1.03C245.08,168.88,244.45,170.4,243.34,171.67z M244.05,173.83v0.32h-0.16v-0.4h0.32
			c-0.07,0-0.23,0-0.32,0.08H244.05z M242.08,178.45c-1.11,2.55-2.37,5.02-3.87,7.5c-0.16,0.4-0.78,0.48-1.26,0.56
			c0-1.12,1.11-2,1.58-2.96c1.66-2.15,2.14-4.46,4.03-6.54C243.03,177.5,242.08,177.82,242.08,178.45z M234.57,177.66
			c1.9-0.81,3.87-2.48,6.01-1.76C239.07,177.1,236.63,177.57,234.57,177.66z M237.42,172.16c-0.4-2.79,0.71-5.18,2.53-7.19
			c1.11-0.23,2.37-0.47,3.39,0.16c-2.05,0.56-4.66,1.04-5.14,3.67c0.08,1.04-0.24,1.59,0.24,2.47c0.87,1.51,2.85,0.88,3.95,2.07
			c0,0.4-0.32,0.88-0.71,0.96C240.34,173.51,238.68,173.19,237.42,172.16z M200.36,146.56c0.24-0.64,0-1.6,0.87-1.76
			c0.55-0.16,1.42-0.16,1.5,0.47c-0.08,0.96-0.16,1.92-0.4,2.8C201.23,148.15,200.67,147.52,200.36,146.56z M200.6,149.24
			c1.18,0.87,2.84,0.47,4.26,0.72c0.23,0.88,0.23,2-0.39,2.72c-1.35-0.32-3.08-0.16-3.8-1.44
			C200.83,150.67,200.43,149.87,200.6,149.24z M196.88,146.95c0.24-1.43,0.56-2.94,1.9-3.98c1.89-1.04,4.5-0.8,6.24,0.24
			c0.56,0.32,0.96,0.8,1.27,1.51c0.39,1.36-0.24,2.8-1.2,3.59h-0.78c0-1.27,0-2.55,0.47-3.59c-0.07-0.95-0.87-1.04-1.5-1.43
			c-1.34-0.39-2.53,0.24-3.55,0.8c-0.79,0.95-1.5,2.15-1.03,3.51c0.55,0.88,0.08,2.07,0.39,3.03l-0.24,0.31
			C197.91,149.91,196.64,148.79,196.88,146.95z M271.01,180.84c1.89,0.86,5.69,1.69,8.43,2c-0.25,1.09-2.18,0.07-1.95,1.59
			c-2.45-0.96-5.28-1.44-7.11-3.35C270.54,181.01,270.69,180.77,271.01,180.84z M221.69,182.12l-1.1-1.04
			c0.55-0.71,1.74-0.32,2.61-0.79c2.05-0.64,4.42-1.44,6.71-0.95C227.54,181.25,224.46,181.49,221.69,182.12z M307.01,145.76
			c-0.94-2.47-2.92-4.15-5.37-4.95c-2.53-0.55-5.22-0.16-6.88,1.76c-2.84,2.3-0.87,6.14-0.79,9.24c0,0.64,0.09,1.12-0.55,1.44
			c-11.46-1.04-22.11-3.91-33.5-5.11c-2.61-0.45-5.22-0.33-7.72-0.86l-3.17-0.33c-9.87,0.32-19.75,0.88-29,3.27
			c-1.97,0.64-4.18,0.8-6.16,1.52c-1.42-0.25-2.61,0.8-3.95,0.39l-2.61,0.61c-0.25,0.05-0.53,0.03-0.87-0.05
			c-1.27-3.27,2.53-5.26,1.35-8.45c-1.03-1.83-3.08-3.59-5.46-3.27c-2.69-0.32-5.05,1.2-6.32,3.27c-1.82,3.99,0.48,8.14,1.18,12.04
			c-0.16,1.28,1.26,2.72,0,3.75c-2.53-1.76-5.84-2.71-8.92-1.84c-0.94,0.49-2.69-0.16-3.16,0.96c3.39,1.83,7.74,1.59,11.53,1.44
			c0.48-0.16,0.79-0.24,1.18,0.08c0.32,2.06-0.39,4.87,0.87,6.69c1.42,1.92,3.79,2.39,6.08,2.63c7.28-0.24,13.75-2.39,20.38-4.06
			c1.03-0.07,1.82-0.63,2.93-0.63c3.55-0.79,7.43-1.44,11.21-1.51c-1.65,2.14-2.6,4.54-3.31,7.17c-0.95,2.15,1.66,2.47,2.45,3.66
			c-2.45,0.72-4.18,2.4-6.47,3.36c-5.06-0.16-9.33,1.75-14.07,2.31c-0.09,1.04,1.66,0.71,1.82,1.76c-0.24,0.72-0.95,0.8-1.5,1.19
			c-0.08,0.48,0,0.56,0.47,0.64c0.47-0.56,1.5-0.16,1.97-0.71c1.58,0,2.92-0.47,4.42-0.56c1.35-0.56,2.85-0.64,4.03-1.6
			c1.66-0.47,2.69-1.59,4.35-2.07c2.37,0,4.5-0.8,6.72-0.95v0.47c-1.82,2.95-3.71,5.98-5.61,8.77c-0.31,0.56,0.08,1.11,0.56,1.27
			c1.1,0.32,2.21,0,3.08-0.48c1.66-2.87,3.16-5.57,4.5-8.53c0.56-2.71,2.53-4.15,4.51-5.98c0.32-0.16,0.79-0.63,1.11-0.16
			c-1.58,1.83-2.6,3.59-3.79,5.59c-0.64,2.23-0.8,5.1,1.19,6.94c0.94,0.79,2.35,0.56,3.63,0.64c3.08-1.6,7.03,0.95,9.4-2.08
			c1.42-0.24,1.66,1.44,2.45,2.23c1.34,0.4,2.77,1.75,4.11,0.79c-0.07-0.86-1.03-1.67-1.5-2.47c-0.95-1.68-2.14-3.03-3-4.55
			c-0.4-1.11-1.11-1.83-1.27-2.95c1.42,0.48,2.68,1.44,4.26,1.68c1.34,0.55,3,0.47,4.27,1.27c1.03,1.36,2.6,2.16,3.95,3.03
			c1.97,0.96,4.18,1.12,6.24,1.92c0.79,0.07,1.97,0.72,2.53,0c-0.32-0.4-0.71-0.64-1.18-0.89c-0.24-1.04,1.42-0.47,1.97-1.27
			c-0.19-0.39-0.54-0.54-0.91-0.6c-1.13-0.61-3.95-0.82-4.69-1.23c-0.95-0.16-1.82-0.56-2.77-0.88c-4.74,0-7.34-4.31-11.14-5.9
			c0.87-0.79,2.21-1.43,2.77-2.63c0.71-1.12,0.94-2.95,0.23-4.22c-0.71-2.47-3.87-2.16-5.68-3.52l0.23-0.24
			c3.08-0.16,5.78,0.56,8.54,1.12c7.59,1.04,14.61,3.91,22.52,4.06c4.34,0,9.79,0.24,11.93-3.97c0.4-2.49,0.87-4.95,1.27-7.42
			C304.81,153.82,307.41,150.15,307.01,145.76z"
          />
        </g>
        <g>
          <path
            d="M25.1,236.45l-5.2-12.02l-5.36,12.02H25.1 M25.97,238.35H13.65l-1.46,3.4c-0.48,1.12-0.72,2.06-0.72,2.8
			c0,0.99,0.39,1.7,1.19,2.17c0.46,0.27,1.6,0.47,3.42,0.61v0.96H4.47v-0.96c1.25-0.19,2.28-0.7,3.09-1.53
			c0.8-0.86,1.8-2.62,2.98-5.28l12.48-27.85h0.49l12.58,28.61c1.2,2.73,2.19,4.42,2.95,5.13c0.59,0.54,1.42,0.84,2.47,0.92v0.96
			H24.64v-0.96h0.7c1.35,0,2.3-0.19,2.85-0.57c0.38-0.27,0.56-0.67,0.56-1.18c0-0.31-0.05-0.63-0.16-0.96
			c-0.03-0.15-0.28-0.79-0.77-1.92L25.97,238.35z"
          />
          <path
            d="M62.9,224.23v18.83c0,1.84,0.16,2.99,0.51,3.44c0.34,0.45,1.04,0.74,2.08,0.86v0.93h-9.8v-3.22
			c-1.16,1.37-2.3,2.37-3.45,3.01c-1.15,0.61-2.44,0.92-3.86,0.92c-1.35,0-2.57-0.4-3.64-1.23c-1.05-0.83-1.74-1.82-2.1-2.94
			c-0.37-1.12-0.54-3.06-0.54-5.84v-9.55c0-1.81-0.18-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95h9.8v16.44
			c0,1.71,0.07,2.83,0.23,3.33c0.18,0.49,0.43,0.87,0.77,1.12c0.36,0.26,0.77,0.39,1.24,0.39c0.61,0,1.18-0.16,1.67-0.49
			c0.68-0.45,1.51-1.35,2.47-2.7v-12.87c0-1.81-0.17-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95H62.9"
          />
          <path
            d="M82.33,223.55l0.39,8.21h-0.87c-1.04-2.44-2.13-4.1-3.26-5c-1.12-0.88-2.23-1.34-3.35-1.34
			c-0.7,0-1.3,0.25-1.8,0.72c-0.5,0.46-0.75,1-0.75,1.63c0,0.47,0.17,0.9,0.52,1.34c0.55,0.7,2.08,1.92,4.61,3.63
			c2.51,1.69,4.17,3.14,4.96,4.34c0.81,1.18,1.21,2.51,1.21,3.99c0,1.34-0.33,2.65-1,3.94c-0.67,1.28-1.61,2.27-2.83,2.97
			c-1.22,0.68-2.56,1.02-4.04,1.02c-1.15,0-2.69-0.36-4.61-1.07c-0.51-0.19-0.86-0.28-1.05-0.29c-0.56,0-1.04,0.44-1.42,1.28h-0.85
			l-0.41-8.64h0.87c0.77,2.27,1.83,3.96,3.17,5.09c1.35,1.13,2.63,1.7,3.83,1.7c0.82,0,1.49-0.25,2-0.75
			c0.53-0.51,0.8-1.14,0.8-1.85c0-0.83-0.26-1.54-0.78-2.15c-0.51-0.58-1.66-1.5-3.45-2.72c-2.62-1.82-4.32-3.21-5.09-4.17
			c-1.13-1.41-1.7-2.96-1.7-4.66c0-1.85,0.63-3.52,1.9-5.01c1.28-1.51,3.14-2.27,5.55-2.27c1.31,0,2.56,0.32,3.79,0.95
			c0.46,0.26,0.84,0.39,1.13,0.39c0.31,0,0.56-0.06,0.75-0.18c0.19-0.14,0.49-0.51,0.9-1.11H82.33"
          />
          <path
            d="M95.07,215.42v8.81h5.71v2.54h-5.71v14.86c0,1.38,0.06,2.28,0.18,2.7c0.14,0.4,0.37,0.72,0.69,0.98
			c0.33,0.24,0.63,0.36,0.9,0.36c1.12,0,2.18-0.86,3.16-2.55l0.78,0.57c-1.39,3.29-3.65,4.94-6.77,4.94c-1.53,0-2.82-0.42-3.89-1.26
			c-1.04-0.86-1.71-1.81-2-2.85c-0.17-0.6-0.26-2.16-0.26-4.75v-12.99h-3.14v-0.89c2.16-1.53,4-3.14,5.51-4.82
			c1.53-1.67,2.85-3.56,3.99-5.64H95.07"
          />
          <path
            d="M112.53,224.23v5.45c1.6-2.48,3-4.13,4.2-4.94c1.2-0.83,2.36-1.24,3.47-1.24c0.96,0,1.73,0.3,2.3,0.9
			c0.58,0.59,0.87,1.42,0.87,2.51c0,1.14-0.28,2.03-0.85,2.67c-0.54,0.63-1.21,0.95-2,0.95c-0.91,0-1.7-0.3-2.37-0.87
			c-0.67-0.58-1.07-0.91-1.19-0.98c-0.17-0.1-0.37-0.16-0.6-0.16c-0.5,0-0.97,0.19-1.41,0.57c-0.7,0.59-1.24,1.42-1.6,2.5
			c-0.55,1.67-0.83,3.5-0.83,5.5v5.54l0.03,1.43c0,0.99,0.06,1.61,0.18,1.88c0.2,0.48,0.5,0.82,0.9,1.04
			c0.41,0.2,1.1,0.33,2.06,0.39v0.93h-13v-0.93c1.05-0.09,1.76-0.37,2.11-0.86c0.37-0.49,0.57-1.78,0.57-3.88v-13.31
			c0-1.37-0.07-2.24-0.21-2.62c-0.17-0.48-0.42-0.83-0.74-1.05c-0.33-0.23-0.9-0.38-1.73-0.47v-0.95H112.53"
          />
          <path
            d="M136.57,242.85v-8.05c-2.07,1.21-3.62,2.52-4.62,3.92c-0.67,0.92-1,1.85-1,2.8c0,0.8,0.27,1.49,0.84,2.09
			c0.42,0.45,1.02,0.68,1.8,0.68C134.45,244.29,135.45,243.82,136.57,242.85 M136.57,244.73c-2.93,2.59-5.56,3.89-7.9,3.89
			c-1.37,0-2.51-0.45-3.42-1.34c-0.91-0.91-1.37-2.04-1.37-3.39c0-1.84,0.79-3.48,2.37-4.94c1.58-1.47,5.02-3.43,10.31-5.86v-2.42
			c0-1.82-0.1-2.96-0.31-3.43c-0.19-0.47-0.55-0.9-1.11-1.24c-0.55-0.35-1.16-0.54-1.85-0.54c-1.11,0-2.03,0.26-2.75,0.75
			c-0.45,0.3-0.67,0.67-0.67,1.07c0,0.36,0.24,0.82,0.71,1.35c0.66,0.74,0.98,1.45,0.98,2.13c0,0.84-0.32,1.57-0.95,2.16
			c-0.62,0.59-1.43,0.88-2.44,0.88c-1.09,0-2-0.33-2.73-0.98c-0.72-0.65-1.09-1.42-1.09-2.29c0-1.24,0.49-2.41,1.47-3.53
			c0.98-1.12,2.35-1.99,4.09-2.6c1.76-0.59,3.57-0.9,5.46-0.9c2.28,0,4.08,0.49,5.4,1.46c1.33,0.97,2.2,2.01,2.6,3.15
			c0.24,0.72,0.35,2.37,0.35,4.97v9.34c0,1.1,0.05,1.79,0.13,2.09c0.09,0.26,0.22,0.48,0.39,0.61c0.17,0.14,0.37,0.2,0.6,0.2
			c0.44,0,0.9-0.31,1.36-0.94l0.77,0.62c-0.85,1.27-1.75,2.2-2.68,2.78c-0.91,0.56-1.94,0.85-3.11,0.85c-1.37,0-2.44-0.32-3.22-0.96
			C137.22,247.01,136.75,246.04,136.57,244.73z"
          />
          <path
            d="M157.48,213.39v29.9c0,1.7,0.2,2.78,0.6,3.27c0.39,0.45,1.16,0.72,2.31,0.79v0.93h-13v-0.93
			c1.07-0.04,1.85-0.35,2.37-0.93c0.34-0.39,0.51-1.43,0.51-3.13v-24.91c0-1.68-0.19-2.75-0.59-3.22c-0.39-0.48-1.15-0.75-2.29-0.83
			v-0.95H157.48"
          />
          <path
            d="M172.09,224.23v19.07c0,1.7,0.2,2.78,0.6,3.27c0.39,0.45,1.16,0.72,2.32,0.79v0.93h-13v-0.93
			c1.07-0.04,1.85-0.35,2.37-0.93c0.34-0.39,0.51-1.43,0.51-3.13v-14.05c0-1.7-0.19-2.78-0.59-3.25c-0.39-0.48-1.16-0.76-2.29-0.82
			v-0.95H172.09 M168.49,212.56c1.11,0,2.06,0.4,2.83,1.19c0.77,0.76,1.16,1.7,1.16,2.79c0,1.11-0.39,2.04-1.18,2.81
			c-0.77,0.77-1.7,1.16-2.8,1.16s-2.03-0.39-2.81-1.16c-0.77-0.77-1.16-1.7-1.16-2.81c0-1.09,0.39-2.03,1.16-2.79
			C166.46,212.96,167.39,212.56,168.49,212.56z"
          />
          <path
            d="M189.03,242.85v-8.05c-2.07,1.21-3.61,2.52-4.62,3.92c-0.67,0.92-1,1.85-1,2.8c0,0.8,0.28,1.49,0.84,2.09
			c0.43,0.45,1.03,0.68,1.8,0.68C186.91,244.29,187.91,243.82,189.03,242.85 M189.03,244.73c-2.93,2.59-5.56,3.89-7.89,3.89
			c-1.38,0-2.52-0.45-3.42-1.34c-0.91-0.91-1.36-2.04-1.36-3.39c0-1.84,0.79-3.48,2.36-4.94c1.58-1.47,5.01-3.43,10.31-5.86v-2.42
			c0-1.82-0.09-2.96-0.3-3.43c-0.19-0.47-0.56-0.9-1.11-1.24c-0.54-0.35-1.16-0.54-1.84-0.54c-1.12,0-2.03,0.26-2.76,0.75
			c-0.45,0.3-0.67,0.67-0.67,1.07c0,0.36,0.24,0.82,0.72,1.35c0.65,0.74,0.98,1.45,0.98,2.13c0,0.84-0.32,1.57-0.95,2.16
			c-0.62,0.59-1.43,0.88-2.44,0.88c-1.08,0-1.99-0.33-2.72-0.98c-0.72-0.65-1.08-1.42-1.08-2.29c0-1.24,0.49-2.41,1.47-3.53
			c0.98-1.12,2.34-1.99,4.09-2.6c1.75-0.59,3.57-0.9,5.46-0.9c2.28,0,4.08,0.49,5.4,1.46c1.34,0.97,2.21,2.01,2.6,3.15
			c0.24,0.72,0.36,2.37,0.36,4.97v9.34c0,1.1,0.04,1.79,0.12,2.09c0.08,0.26,0.21,0.48,0.39,0.61c0.17,0.14,0.37,0.2,0.59,0.2
			c0.44,0,0.9-0.31,1.36-0.94l0.77,0.62c-0.86,1.27-1.75,2.2-2.68,2.78c-0.91,0.56-1.95,0.85-3.11,0.85c-1.38,0-2.45-0.32-3.22-0.96
			C189.68,247.01,189.21,246.04,189.03,244.73z"
          />
          <path
            d="M209.83,224.23v3.11c1.24-1.35,2.42-2.31,3.55-2.91c1.13-0.62,2.35-0.93,3.66-0.93
			c1.56,0,2.85,0.44,3.88,1.31c1.03,0.86,1.72,1.94,2.06,3.22c0.28,0.98,0.42,2.86,0.42,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.34,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.91-0.12,1.56-0.5,1.95-1.13c0.27-0.43,0.42-1.5,0.42-3.2v-10.7
			c0-1.97-0.08-3.22-0.23-3.73c-0.16-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.21-0.44c-1.48,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.49,3.43c0.34,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.01-0.1,1.73-0.42,2.16-0.97
			c0.3-0.38,0.44-1.5,0.44-3.36v-13.59c0-1.81-0.18-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95H209.83"
          />
          <path
            d="M269.55,212.6v12.31h-0.96c-1.14-3.38-2.87-5.93-5.17-7.68c-2.29-1.74-4.81-2.62-7.54-2.62
			c-2.6,0-4.78,0.73-6.5,2.21c-1.74,1.46-2.97,3.5-3.69,6.13c-0.72,2.62-1.07,5.32-1.07,8.08c0,3.34,0.4,6.28,1.18,8.79
			c0.78,2.51,2.05,4.38,3.81,5.56c1.76,1.19,3.86,1.78,6.27,1.78c0.84,0,1.7-0.09,2.58-0.25c0.9-0.19,1.79-0.46,2.72-0.81v-7.25
			c0-1.37-0.09-2.26-0.28-2.65c-0.19-0.41-0.59-0.78-1.19-1.1c-0.59-0.33-1.29-0.49-2.14-0.49h-0.9v-0.96h16.95v0.96
			c-1.28,0.08-2.19,0.26-2.69,0.54c-0.5,0.26-0.89,0.69-1.16,1.32c-0.16,0.33-0.23,1.12-0.23,2.39v7.25
			c-2.23,1.01-4.55,1.74-6.98,2.24c-2.4,0.51-4.89,0.77-7.48,0.77c-3.31,0-6.06-0.44-8.26-1.33c-2.18-0.91-4.11-2.09-5.79-3.55
			c-1.66-1.48-2.97-3.13-3.91-4.98c-1.2-2.36-1.8-5.01-1.8-7.95c0-5.24,1.84-9.68,5.53-13.3c3.69-3.62,8.33-5.43,13.92-5.43
			c1.74,0,3.29,0.14,4.69,0.41c0.75,0.14,1.97,0.54,3.65,1.18c1.7,0.65,2.7,0.96,3,0.96c0.49,0,0.93-0.16,1.34-0.51
			c0.41-0.36,0.79-1.04,1.13-2.01H269.55"
          />
          <path
            d="M286.36,225.33c-0.88,0-1.63,0.33-2.27,1c-0.62,0.65-1.03,1.96-1.23,3.92c-0.19,1.94-0.28,4.64-0.28,8.1
			c0,1.84,0.11,3.56,0.37,5.15c0.19,1.21,0.59,2.14,1.2,2.77c0.61,0.64,1.32,0.95,2.11,0.95c0.77,0,1.42-0.22,1.93-0.64
			c0.67-0.58,1.12-1.4,1.34-2.44c0.34-1.63,0.51-4.92,0.51-9.88c0-2.92-0.17-4.91-0.49-5.99c-0.32-1.11-0.81-1.9-1.44-2.39
			C287.66,225.51,287.07,225.33,286.36,225.33 M286.25,223.5c2.09,0,4.04,0.54,5.83,1.62c1.8,1.08,3.17,2.62,4.1,4.61
			c0.94,1.99,1.41,4.17,1.41,6.53c0,3.42-0.86,6.27-2.6,8.57c-2.09,2.78-4.98,4.17-8.68,4.17c-3.62,0-6.4-1.27-8.36-3.81
			c-1.96-2.53-2.93-5.47-2.93-8.82c0-3.46,0.99-6.45,2.98-9.01C280.03,224.79,282.77,223.5,286.25,223.5z"
          />
          <path
            d="M307.53,249l-8.1-18.65c-0.98-2.25-1.74-3.68-2.29-4.27c-0.39-0.45-0.98-0.75-1.74-0.9v-0.95h12.79v0.95
			c-0.81,0-1.35,0.15-1.65,0.44c-0.42,0.37-0.63,0.83-0.63,1.34c0,0.64,0.38,1.83,1.14,3.57l3.96,9.04l3.16-7.8
			c0.91-2.23,1.37-3.81,1.37-4.73c0-0.54-0.2-0.96-0.6-1.28c-0.38-0.34-1.05-0.53-2.04-0.57v-0.95h7.88v0.95
			c-0.75,0.1-1.37,0.38-1.85,0.86c-0.49,0.45-1.2,1.82-2.17,4.09L308.73,249H307.53"
          />
          <path
            d="M332.74,233.67c0-2.76-0.16-4.66-0.46-5.68c-0.3-1.04-0.75-1.81-1.39-2.34c-0.36-0.31-0.84-0.47-1.45-0.47
			c-0.88,0-1.62,0.44-2.18,1.31c-1.01,1.53-1.52,3.62-1.52,6.28v0.9H332.74 M339.07,235.39h-13.2c0.16,3.19,1.01,5.71,2.55,7.57
			c1.18,1.43,2.61,2.13,4.27,2.13c1.03,0,1.97-0.29,2.8-0.85c0.85-0.58,1.75-1.62,2.7-3.12l0.88,0.57c-1.3,2.66-2.74,4.55-4.33,5.67
			c-1.57,1.1-3.4,1.64-5.48,1.64c-3.57,0-6.27-1.37-8.1-4.12c-1.47-2.2-2.21-4.96-2.21-8.23c0-4.02,1.07-7.21,3.24-9.57
			c2.18-2.39,4.73-3.58,7.64-3.58c2.44,0,4.55,1,6.34,3.01C337.97,228.51,338.93,231.46,339.07,235.39z"
          />
          <path
            d="M350.48,224.22v5.45c1.59-2.48,2.99-4.13,4.19-4.94c1.2-0.83,2.36-1.24,3.47-1.24c0.97,0,1.72,0.3,2.29,0.9
			c0.58,0.59,0.88,1.42,0.88,2.51c0,1.14-0.29,2.03-0.85,2.67c-0.55,0.63-1.22,0.95-2,0.95c-0.91,0-1.7-0.3-2.37-0.87
			c-0.67-0.58-1.06-0.91-1.18-0.98c-0.17-0.1-0.37-0.16-0.59-0.16c-0.5,0-0.97,0.19-1.42,0.57c-0.7,0.59-1.23,1.42-1.59,2.5
			c-0.55,1.67-0.82,3.5-0.82,5.5v5.54l0.02,1.43c0,0.99,0.07,1.61,0.18,1.88c0.2,0.48,0.51,0.82,0.9,1.04
			c0.41,0.2,1.1,0.33,2.05,0.39v0.93h-12.98v-0.93c1.05-0.09,1.74-0.37,2.1-0.86c0.38-0.49,0.58-1.78,0.58-3.88v-13.31
			c0-1.37-0.07-2.24-0.2-2.62c-0.17-0.48-0.42-0.83-0.75-1.05c-0.32-0.23-0.9-0.38-1.72-0.47v-0.95H350.48"
          />
          <path
            d="M372.66,224.22v3.11c1.24-1.35,2.42-2.31,3.56-2.91c1.13-0.62,2.34-0.93,3.65-0.93
			c1.56,0,2.86,0.44,3.89,1.31c1.03,0.86,1.72,1.94,2.05,3.22c0.27,0.98,0.42,2.86,0.42,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.34,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.91-0.12,1.56-0.5,1.96-1.13c0.28-0.43,0.41-1.5,0.41-3.2v-10.7
			c0-1.97-0.07-3.22-0.23-3.73c-0.15-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.21-0.44c-1.47,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.49,3.43c0.35,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.01-0.1,1.74-0.42,2.16-0.97
			c0.29-0.38,0.44-1.5,0.44-3.36v-13.59c0-1.81-0.17-2.95-0.51-3.39c-0.33-0.45-1.02-0.74-2.09-0.88v-0.95H372.66"
          />
          <path
            d="M400.54,224.22v3.16c1.32-1.45,2.54-2.44,3.65-3.01c1.13-0.58,2.37-0.88,3.71-0.88
			c1.54,0,2.84,0.37,3.88,1.08c1.04,0.72,1.85,1.82,2.42,3.29c1.37-1.55,2.69-2.65,3.93-3.34c1.27-0.69,2.59-1.04,3.97-1.04
			c1.67,0,2.99,0.38,3.96,1.16c1,0.75,1.68,1.73,2.05,2.91c0.4,1.16,0.6,3.03,0.6,5.61v9.85c0,1.85,0.16,3.01,0.49,3.45
			c0.33,0.46,1.04,0.74,2.11,0.88v0.93h-12.43v-0.93c0.98-0.09,1.7-0.46,2.16-1.13c0.32-0.48,0.46-1.53,0.46-3.2v-10.34
			c0-2.14-0.08-3.5-0.26-4.09c-0.16-0.59-0.44-1.01-0.82-1.29c-0.35-0.29-0.79-0.44-1.28-0.44c-0.74,0-1.49,0.27-2.24,0.83
			c-0.75,0.54-1.52,1.33-2.29,2.42v12.91c0,1.74,0.15,2.83,0.44,3.27c0.4,0.65,1.15,0.99,2.27,1.06v0.93h-12.45v-0.93
			c0.67-0.04,1.2-0.2,1.57-0.5c0.4-0.3,0.66-0.65,0.77-1.07c0.14-0.44,0.2-1.35,0.21-2.76v-10.34c0-2.17-0.09-3.53-0.25-4.09
			c-0.18-0.55-0.47-0.97-0.88-1.29c-0.4-0.31-0.82-0.46-1.29-0.46c-0.69,0-1.32,0.18-1.88,0.54c-0.81,0.54-1.67,1.44-2.58,2.73
			v12.91c0,1.7,0.16,2.83,0.49,3.38c0.34,0.54,1.04,0.85,2.11,0.95v0.93h-12.4v-0.93c1.01-0.1,1.73-0.42,2.16-0.97
			c0.29-0.38,0.43-1.5,0.44-3.36v-13.59c0-1.81-0.17-2.95-0.52-3.39c-0.32-0.45-1.02-0.74-2.08-0.88v-0.95H400.54"
          />
          <path
            d="M446.39,233.67c0-2.76-0.16-4.66-0.46-5.68c-0.29-1.04-0.75-1.81-1.39-2.34c-0.37-0.31-0.84-0.47-1.44-0.47
			c-0.89,0-1.62,0.44-2.18,1.31c-1.01,1.53-1.52,3.62-1.52,6.28v0.9H446.39 M452.71,235.39h-13.2c0.16,3.19,1,5.71,2.55,7.57
			c1.18,1.43,2.61,2.13,4.27,2.13c1.03,0,1.96-0.29,2.8-0.85c0.84-0.58,1.74-1.62,2.7-3.12l0.88,0.57c-1.3,2.66-2.74,4.55-4.32,5.67
			c-1.58,1.1-3.4,1.64-5.48,1.64c-3.57,0-6.27-1.37-8.1-4.12c-1.48-2.2-2.22-4.96-2.22-8.23c0-4.02,1.08-7.21,3.24-9.57
			c2.18-2.39,4.73-3.58,7.65-3.58c2.43,0,4.54,1,6.32,3.01C451.61,228.51,452.58,231.46,452.71,235.39z"
          />
          <path
            d="M463.45,224.22v3.11c1.23-1.35,2.42-2.31,3.55-2.91c1.13-0.62,2.35-0.93,3.65-0.93
			c1.56,0,2.86,0.44,3.88,1.31c1.03,0.86,1.72,1.94,2.06,3.22c0.27,0.98,0.41,2.86,0.41,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.33,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.92-0.12,1.56-0.5,1.96-1.13c0.28-0.43,0.41-1.5,0.41-3.2v-10.7
			c0-1.97-0.08-3.22-0.23-3.73c-0.16-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.2-0.44c-1.48,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.48,3.43c0.34,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.02-0.1,1.74-0.42,2.16-0.97
			c0.3-0.38,0.44-1.5,0.45-3.36v-13.59c0-1.81-0.18-2.95-0.51-3.39c-0.33-0.45-1.03-0.74-2.09-0.88v-0.95H463.45"
          />
          <path
            d="M489.83,215.42v8.81h5.72v2.54h-5.72v14.86c0,1.38,0.07,2.28,0.19,2.7c0.14,0.4,0.37,0.72,0.69,0.98
			c0.33,0.24,0.62,0.36,0.9,0.36c1.12,0,2.18-0.86,3.16-2.55l0.77,0.57c-1.38,3.29-3.65,4.94-6.77,4.94c-1.53,0-2.82-0.42-3.88-1.26
			c-1.05-0.86-1.72-1.81-2.01-2.85c-0.18-0.6-0.26-2.16-0.26-4.75v-13h-3.14v-0.89c2.17-1.53,4-3.14,5.51-4.82
			c1.53-1.67,2.86-3.56,3.99-5.64H489.83"
          />
          <path
            d="M25.1,236.45l-5.2-12.02l-5.36,12.02H25.1 M25.97,238.35H13.65l-1.46,3.4c-0.48,1.12-0.72,2.06-0.72,2.8
			c0,0.99,0.39,1.7,1.19,2.17c0.46,0.27,1.6,0.47,3.42,0.61v0.96H4.47v-0.96c1.25-0.19,2.28-0.7,3.09-1.53
			c0.8-0.86,1.8-2.62,2.98-5.28l12.48-27.85h0.49l12.58,28.61c1.2,2.73,2.19,4.42,2.95,5.13c0.59,0.54,1.42,0.84,2.47,0.92v0.96
			H24.64v-0.96h0.7c1.35,0,2.3-0.19,2.85-0.57c0.38-0.27,0.56-0.67,0.56-1.18c0-0.31-0.05-0.63-0.16-0.96
			c-0.03-0.15-0.28-0.79-0.77-1.92L25.97,238.35z"
          />
          <path
            d="M62.9,224.23v18.83c0,1.84,0.16,2.99,0.51,3.44c0.34,0.45,1.04,0.74,2.08,0.86v0.93h-9.8v-3.22
			c-1.16,1.37-2.3,2.37-3.45,3.01c-1.15,0.61-2.44,0.92-3.86,0.92c-1.35,0-2.57-0.4-3.64-1.23c-1.05-0.83-1.74-1.82-2.1-2.94
			c-0.37-1.12-0.54-3.06-0.54-5.84v-9.55c0-1.81-0.18-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95h9.8v16.44
			c0,1.71,0.07,2.83,0.23,3.33c0.18,0.49,0.43,0.87,0.77,1.12c0.36,0.26,0.77,0.39,1.24,0.39c0.61,0,1.18-0.16,1.67-0.49
			c0.68-0.45,1.51-1.35,2.47-2.7v-12.87c0-1.81-0.17-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95H62.9"
          />
          <path
            d="M82.33,223.55l0.39,8.21h-0.87c-1.04-2.44-2.13-4.1-3.26-5c-1.12-0.88-2.23-1.34-3.35-1.34
			c-0.7,0-1.3,0.25-1.8,0.72c-0.5,0.46-0.75,1-0.75,1.63c0,0.47,0.17,0.9,0.52,1.34c0.55,0.7,2.08,1.92,4.61,3.63
			c2.51,1.69,4.17,3.14,4.96,4.34c0.81,1.18,1.21,2.51,1.21,3.99c0,1.34-0.33,2.65-1,3.94c-0.67,1.28-1.61,2.27-2.83,2.97
			c-1.22,0.68-2.56,1.02-4.04,1.02c-1.15,0-2.69-0.36-4.61-1.07c-0.51-0.19-0.86-0.28-1.05-0.29c-0.56,0-1.04,0.44-1.42,1.28h-0.85
			l-0.41-8.64h0.87c0.77,2.27,1.83,3.96,3.17,5.09c1.35,1.13,2.63,1.7,3.83,1.7c0.82,0,1.49-0.25,2-0.75
			c0.53-0.51,0.8-1.14,0.8-1.85c0-0.83-0.26-1.54-0.78-2.15c-0.51-0.58-1.66-1.5-3.45-2.72c-2.62-1.82-4.32-3.21-5.09-4.17
			c-1.13-1.41-1.7-2.96-1.7-4.66c0-1.85,0.63-3.52,1.9-5.01c1.28-1.51,3.14-2.27,5.55-2.27c1.31,0,2.56,0.32,3.79,0.95
			c0.46,0.26,0.84,0.39,1.13,0.39c0.31,0,0.56-0.06,0.75-0.18c0.19-0.14,0.49-0.51,0.9-1.11H82.33"
          />
          <path
            d="M95.07,215.42v8.81h5.71v2.54h-5.71v14.86c0,1.38,0.06,2.28,0.18,2.7c0.14,0.4,0.37,0.72,0.69,0.98
			c0.33,0.24,0.63,0.36,0.9,0.36c1.12,0,2.18-0.86,3.16-2.55l0.78,0.57c-1.39,3.29-3.65,4.94-6.77,4.94c-1.53,0-2.82-0.42-3.89-1.26
			c-1.04-0.86-1.71-1.81-2-2.85c-0.17-0.6-0.26-2.16-0.26-4.75v-12.99h-3.14v-0.89c2.16-1.53,4-3.14,5.51-4.82
			c1.53-1.67,2.85-3.56,3.99-5.64H95.07"
          />
          <path
            d="M112.53,224.23v5.45c1.6-2.48,3-4.13,4.2-4.94c1.2-0.83,2.36-1.24,3.47-1.24c0.96,0,1.73,0.3,2.3,0.9
			c0.58,0.59,0.87,1.42,0.87,2.51c0,1.14-0.28,2.03-0.85,2.67c-0.54,0.63-1.21,0.95-2,0.95c-0.91,0-1.7-0.3-2.37-0.87
			c-0.67-0.58-1.07-0.91-1.19-0.98c-0.17-0.1-0.37-0.16-0.6-0.16c-0.5,0-0.97,0.19-1.41,0.57c-0.7,0.59-1.24,1.42-1.6,2.5
			c-0.55,1.67-0.83,3.5-0.83,5.5v5.54l0.03,1.43c0,0.99,0.06,1.61,0.18,1.88c0.2,0.48,0.5,0.82,0.9,1.04
			c0.41,0.2,1.1,0.33,2.06,0.39v0.93h-13v-0.93c1.05-0.09,1.76-0.37,2.11-0.86c0.37-0.49,0.57-1.78,0.57-3.88v-13.31
			c0-1.37-0.07-2.24-0.21-2.62c-0.17-0.48-0.42-0.83-0.74-1.05c-0.33-0.23-0.9-0.38-1.73-0.47v-0.95H112.53"
          />
          <path
            d="M136.57,242.85v-8.05c-2.07,1.21-3.62,2.52-4.62,3.92c-0.67,0.92-1,1.85-1,2.8c0,0.8,0.27,1.49,0.84,2.09
			c0.42,0.45,1.02,0.68,1.8,0.68C134.45,244.29,135.45,243.82,136.57,242.85 M136.57,244.73c-2.93,2.59-5.56,3.89-7.9,3.89
			c-1.37,0-2.51-0.45-3.42-1.34c-0.91-0.91-1.37-2.04-1.37-3.39c0-1.84,0.79-3.48,2.37-4.94c1.58-1.47,5.02-3.43,10.31-5.86v-2.42
			c0-1.82-0.1-2.96-0.31-3.43c-0.19-0.47-0.55-0.9-1.11-1.24c-0.55-0.35-1.16-0.54-1.85-0.54c-1.11,0-2.03,0.26-2.75,0.75
			c-0.45,0.3-0.67,0.67-0.67,1.07c0,0.36,0.24,0.82,0.71,1.35c0.66,0.74,0.98,1.45,0.98,2.13c0,0.84-0.32,1.57-0.95,2.16
			c-0.62,0.59-1.43,0.88-2.44,0.88c-1.09,0-2-0.33-2.73-0.98c-0.72-0.65-1.09-1.42-1.09-2.29c0-1.24,0.49-2.41,1.47-3.53
			c0.98-1.12,2.35-1.99,4.09-2.6c1.76-0.59,3.57-0.9,5.46-0.9c2.28,0,4.08,0.49,5.4,1.46c1.33,0.97,2.2,2.01,2.6,3.15
			c0.24,0.72,0.35,2.37,0.35,4.97v9.34c0,1.1,0.05,1.79,0.13,2.09c0.09,0.26,0.22,0.48,0.39,0.61c0.17,0.14,0.37,0.2,0.6,0.2
			c0.44,0,0.9-0.31,1.36-0.94l0.77,0.62c-0.85,1.27-1.75,2.2-2.68,2.78c-0.91,0.56-1.94,0.85-3.11,0.85c-1.37,0-2.44-0.32-3.22-0.96
			C137.22,247.01,136.75,246.04,136.57,244.73z"
          />
          <path
            d="M157.48,213.39v29.9c0,1.7,0.2,2.78,0.6,3.27c0.39,0.45,1.16,0.72,2.31,0.79v0.93h-13v-0.93
			c1.07-0.04,1.85-0.35,2.37-0.93c0.34-0.39,0.51-1.43,0.51-3.13v-24.91c0-1.68-0.19-2.75-0.59-3.22c-0.39-0.48-1.15-0.75-2.29-0.83
			v-0.95H157.48"
          />
          <path
            d="M172.09,224.23v19.07c0,1.7,0.2,2.78,0.6,3.27c0.39,0.45,1.16,0.72,2.32,0.79v0.93h-13v-0.93
			c1.07-0.04,1.85-0.35,2.37-0.93c0.34-0.39,0.51-1.43,0.51-3.13v-14.05c0-1.7-0.19-2.78-0.59-3.25c-0.39-0.48-1.16-0.76-2.29-0.82
			v-0.95H172.09 M168.49,212.56c1.11,0,2.06,0.4,2.83,1.19c0.77,0.76,1.16,1.7,1.16,2.79c0,1.11-0.39,2.04-1.18,2.81
			c-0.77,0.77-1.7,1.16-2.8,1.16s-2.03-0.39-2.81-1.16c-0.77-0.77-1.16-1.7-1.16-2.81c0-1.09,0.39-2.03,1.16-2.79
			C166.46,212.96,167.39,212.56,168.49,212.56z"
          />
          <path
            d="M189.03,242.85v-8.05c-2.07,1.21-3.61,2.52-4.62,3.92c-0.67,0.92-1,1.85-1,2.8c0,0.8,0.28,1.49,0.84,2.09
			c0.43,0.45,1.03,0.68,1.8,0.68C186.91,244.29,187.91,243.82,189.03,242.85 M189.03,244.73c-2.93,2.59-5.56,3.89-7.89,3.89
			c-1.38,0-2.52-0.45-3.42-1.34c-0.91-0.91-1.36-2.04-1.36-3.39c0-1.84,0.79-3.48,2.36-4.94c1.58-1.47,5.01-3.43,10.31-5.86v-2.42
			c0-1.82-0.09-2.96-0.3-3.43c-0.19-0.47-0.56-0.9-1.11-1.24c-0.54-0.35-1.16-0.54-1.84-0.54c-1.12,0-2.03,0.26-2.76,0.75
			c-0.45,0.3-0.67,0.67-0.67,1.07c0,0.36,0.24,0.82,0.72,1.35c0.65,0.74,0.98,1.45,0.98,2.13c0,0.84-0.32,1.57-0.95,2.16
			c-0.62,0.59-1.43,0.88-2.44,0.88c-1.08,0-1.99-0.33-2.72-0.98c-0.72-0.65-1.08-1.42-1.08-2.29c0-1.24,0.49-2.41,1.47-3.53
			c0.98-1.12,2.34-1.99,4.09-2.6c1.75-0.59,3.57-0.9,5.46-0.9c2.28,0,4.08,0.49,5.4,1.46c1.34,0.97,2.21,2.01,2.6,3.15
			c0.24,0.72,0.36,2.37,0.36,4.97v9.34c0,1.1,0.04,1.79,0.12,2.09c0.08,0.26,0.21,0.48,0.39,0.61c0.17,0.14,0.37,0.2,0.59,0.2
			c0.44,0,0.9-0.31,1.36-0.94l0.77,0.62c-0.86,1.27-1.75,2.2-2.68,2.78c-0.91,0.56-1.95,0.85-3.11,0.85c-1.38,0-2.45-0.32-3.22-0.96
			C189.68,247.01,189.21,246.04,189.03,244.73z"
          />
          <path
            d="M209.83,224.23v3.11c1.24-1.35,2.42-2.31,3.55-2.91c1.13-0.62,2.35-0.93,3.66-0.93
			c1.56,0,2.85,0.44,3.88,1.31c1.03,0.86,1.72,1.94,2.06,3.22c0.28,0.98,0.42,2.86,0.42,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.34,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.91-0.12,1.56-0.5,1.95-1.13c0.27-0.43,0.42-1.5,0.42-3.2v-10.7
			c0-1.97-0.08-3.22-0.23-3.73c-0.16-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.21-0.44c-1.48,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.49,3.43c0.34,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.01-0.1,1.73-0.42,2.16-0.97
			c0.3-0.38,0.44-1.5,0.44-3.36v-13.59c0-1.81-0.18-2.95-0.52-3.39c-0.33-0.45-1.02-0.74-2.08-0.88v-0.95H209.83"
          />
          <path
            d="M269.55,212.6v12.31h-0.96c-1.14-3.38-2.87-5.93-5.17-7.68c-2.29-1.74-4.81-2.62-7.54-2.62
			c-2.6,0-4.78,0.73-6.5,2.21c-1.74,1.46-2.97,3.5-3.69,6.13c-0.72,2.62-1.07,5.32-1.07,8.08c0,3.34,0.4,6.28,1.18,8.79
			c0.78,2.51,2.05,4.38,3.81,5.56c1.76,1.19,3.86,1.78,6.27,1.78c0.84,0,1.7-0.09,2.58-0.25c0.9-0.19,1.79-0.46,2.72-0.81v-7.25
			c0-1.37-0.09-2.26-0.28-2.65c-0.19-0.41-0.59-0.78-1.19-1.1c-0.59-0.33-1.29-0.49-2.14-0.49h-0.9v-0.96h16.95v0.96
			c-1.28,0.08-2.19,0.26-2.69,0.54c-0.5,0.26-0.89,0.69-1.16,1.32c-0.16,0.33-0.23,1.12-0.23,2.39v7.25
			c-2.23,1.01-4.55,1.74-6.98,2.24c-2.4,0.51-4.89,0.77-7.48,0.77c-3.31,0-6.06-0.44-8.26-1.33c-2.18-0.91-4.11-2.09-5.79-3.55
			c-1.66-1.48-2.97-3.13-3.91-4.98c-1.2-2.36-1.8-5.01-1.8-7.95c0-5.24,1.84-9.68,5.53-13.3c3.69-3.62,8.33-5.43,13.92-5.43
			c1.74,0,3.29,0.14,4.69,0.41c0.75,0.14,1.97,0.54,3.65,1.18c1.7,0.65,2.7,0.96,3,0.96c0.49,0,0.93-0.16,1.34-0.51
			c0.41-0.36,0.79-1.04,1.13-2.01H269.55"
          />
          <path
            d="M286.36,225.33c-0.88,0-1.63,0.33-2.27,1c-0.62,0.65-1.03,1.96-1.23,3.92c-0.19,1.94-0.28,4.64-0.28,8.1
			c0,1.84,0.11,3.56,0.37,5.15c0.19,1.21,0.59,2.14,1.2,2.77c0.61,0.64,1.32,0.95,2.11,0.95c0.77,0,1.42-0.22,1.93-0.64
			c0.67-0.58,1.12-1.4,1.34-2.44c0.34-1.63,0.51-4.92,0.51-9.88c0-2.92-0.17-4.91-0.49-5.99c-0.32-1.11-0.81-1.9-1.44-2.39
			C287.66,225.51,287.07,225.33,286.36,225.33 M286.25,223.5c2.09,0,4.04,0.54,5.83,1.62c1.8,1.08,3.17,2.62,4.1,4.61
			c0.94,1.99,1.41,4.17,1.41,6.53c0,3.42-0.86,6.27-2.6,8.57c-2.09,2.78-4.98,4.17-8.68,4.17c-3.62,0-6.4-1.27-8.36-3.81
			c-1.96-2.53-2.93-5.47-2.93-8.82c0-3.46,0.99-6.45,2.98-9.01C280.03,224.79,282.77,223.5,286.25,223.5z"
          />
          <path
            d="M307.53,249l-8.1-18.65c-0.98-2.25-1.74-3.68-2.29-4.27c-0.39-0.45-0.98-0.75-1.74-0.9v-0.95h12.79v0.95
			c-0.81,0-1.35,0.15-1.65,0.44c-0.42,0.37-0.63,0.83-0.63,1.34c0,0.64,0.38,1.83,1.14,3.57l3.96,9.04l3.16-7.8
			c0.91-2.23,1.37-3.81,1.37-4.73c0-0.54-0.2-0.96-0.6-1.28c-0.38-0.34-1.05-0.53-2.04-0.57v-0.95h7.88v0.95
			c-0.75,0.1-1.37,0.38-1.85,0.86c-0.49,0.45-1.2,1.82-2.17,4.09L308.73,249H307.53"
          />
          <path
            d="M332.74,233.67c0-2.76-0.16-4.66-0.46-5.68c-0.3-1.04-0.75-1.81-1.39-2.34c-0.36-0.31-0.84-0.47-1.45-0.47
			c-0.88,0-1.62,0.44-2.18,1.31c-1.01,1.53-1.52,3.62-1.52,6.28v0.9H332.74 M339.07,235.39h-13.2c0.16,3.19,1.01,5.71,2.55,7.57
			c1.18,1.43,2.61,2.13,4.27,2.13c1.03,0,1.97-0.29,2.8-0.85c0.85-0.58,1.75-1.62,2.7-3.12l0.88,0.57c-1.3,2.66-2.74,4.55-4.33,5.67
			c-1.57,1.1-3.4,1.64-5.48,1.64c-3.57,0-6.27-1.37-8.1-4.12c-1.47-2.2-2.21-4.96-2.21-8.23c0-4.02,1.07-7.21,3.24-9.57
			c2.18-2.39,4.73-3.58,7.64-3.58c2.44,0,4.55,1,6.34,3.01C337.97,228.51,338.93,231.46,339.07,235.39z"
          />
          <path
            d="M350.48,224.22v5.45c1.59-2.48,2.99-4.13,4.19-4.94c1.2-0.83,2.36-1.24,3.47-1.24c0.97,0,1.72,0.3,2.29,0.9
			c0.58,0.59,0.88,1.42,0.88,2.51c0,1.14-0.29,2.03-0.85,2.67c-0.55,0.63-1.22,0.95-2,0.95c-0.91,0-1.7-0.3-2.37-0.87
			c-0.67-0.58-1.06-0.91-1.18-0.98c-0.17-0.1-0.37-0.16-0.59-0.16c-0.5,0-0.97,0.19-1.42,0.57c-0.7,0.59-1.23,1.42-1.59,2.5
			c-0.55,1.67-0.82,3.5-0.82,5.5v5.54l0.02,1.43c0,0.99,0.07,1.61,0.18,1.88c0.2,0.48,0.51,0.82,0.9,1.04
			c0.41,0.2,1.1,0.33,2.05,0.39v0.93h-12.98v-0.93c1.05-0.09,1.74-0.37,2.1-0.86c0.38-0.49,0.58-1.78,0.58-3.88v-13.31
			c0-1.37-0.07-2.24-0.2-2.62c-0.17-0.48-0.42-0.83-0.75-1.05c-0.32-0.23-0.9-0.38-1.72-0.47v-0.95H350.48"
          />
          <path
            d="M372.66,224.22v3.11c1.24-1.35,2.42-2.31,3.56-2.91c1.13-0.62,2.34-0.93,3.65-0.93
			c1.56,0,2.86,0.44,3.89,1.31c1.03,0.86,1.72,1.94,2.05,3.22c0.27,0.98,0.42,2.86,0.42,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.34,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.91-0.12,1.56-0.5,1.96-1.13c0.28-0.43,0.41-1.5,0.41-3.2v-10.7
			c0-1.97-0.07-3.22-0.23-3.73c-0.15-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.21-0.44c-1.47,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.49,3.43c0.35,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.01-0.1,1.74-0.42,2.16-0.97
			c0.29-0.38,0.44-1.5,0.44-3.36v-13.59c0-1.81-0.17-2.95-0.51-3.39c-0.33-0.45-1.02-0.74-2.09-0.88v-0.95H372.66"
          />
          <path
            d="M400.54,224.22v3.16c1.32-1.45,2.54-2.44,3.65-3.01c1.13-0.58,2.37-0.88,3.71-0.88
			c1.54,0,2.84,0.37,3.88,1.08c1.04,0.72,1.85,1.82,2.42,3.29c1.37-1.55,2.69-2.65,3.93-3.34c1.27-0.69,2.59-1.04,3.97-1.04
			c1.67,0,2.99,0.38,3.96,1.16c1,0.75,1.68,1.73,2.05,2.91c0.4,1.16,0.6,3.03,0.6,5.61v9.85c0,1.85,0.16,3.01,0.49,3.45
			c0.33,0.46,1.04,0.74,2.11,0.88v0.93h-12.43v-0.93c0.98-0.09,1.7-0.46,2.16-1.13c0.32-0.48,0.46-1.53,0.46-3.2v-10.34
			c0-2.14-0.08-3.5-0.26-4.09c-0.16-0.59-0.44-1.01-0.82-1.29c-0.35-0.29-0.79-0.44-1.28-0.44c-0.74,0-1.49,0.27-2.24,0.83
			c-0.75,0.54-1.52,1.33-2.29,2.42v12.91c0,1.74,0.15,2.83,0.44,3.27c0.4,0.65,1.15,0.99,2.27,1.06v0.93h-12.45v-0.93
			c0.67-0.04,1.2-0.2,1.57-0.5c0.4-0.3,0.66-0.65,0.77-1.07c0.14-0.44,0.2-1.35,0.21-2.76v-10.34c0-2.17-0.09-3.53-0.25-4.09
			c-0.18-0.55-0.47-0.97-0.88-1.29c-0.4-0.31-0.82-0.46-1.29-0.46c-0.69,0-1.32,0.18-1.88,0.54c-0.81,0.54-1.67,1.44-2.58,2.73
			v12.91c0,1.7,0.16,2.83,0.49,3.38c0.34,0.54,1.04,0.85,2.11,0.95v0.93h-12.4v-0.93c1.01-0.1,1.73-0.42,2.16-0.97
			c0.29-0.38,0.43-1.5,0.44-3.36v-13.59c0-1.81-0.17-2.95-0.52-3.39c-0.32-0.45-1.02-0.74-2.08-0.88v-0.95H400.54"
          />
          <path
            d="M446.39,233.67c0-2.76-0.16-4.66-0.46-5.68c-0.29-1.04-0.75-1.81-1.39-2.34c-0.37-0.31-0.84-0.47-1.44-0.47
			c-0.89,0-1.62,0.44-2.18,1.31c-1.01,1.53-1.52,3.62-1.52,6.28v0.9H446.39 M452.71,235.39h-13.2c0.16,3.19,1,5.71,2.55,7.57
			c1.18,1.43,2.61,2.13,4.27,2.13c1.03,0,1.96-0.29,2.8-0.85c0.84-0.58,1.74-1.62,2.7-3.12l0.88,0.57c-1.3,2.66-2.74,4.55-4.32,5.67
			c-1.58,1.1-3.4,1.64-5.48,1.64c-3.57,0-6.27-1.37-8.1-4.12c-1.48-2.2-2.22-4.96-2.22-8.23c0-4.02,1.08-7.21,3.24-9.57
			c2.18-2.39,4.73-3.58,7.65-3.58c2.43,0,4.54,1,6.32,3.01C451.61,228.51,452.58,231.46,452.71,235.39z"
          />
          <path
            d="M463.45,224.22v3.11c1.23-1.35,2.42-2.31,3.55-2.91c1.13-0.62,2.35-0.93,3.65-0.93
			c1.56,0,2.86,0.44,3.88,1.31c1.03,0.86,1.72,1.94,2.06,3.22c0.27,0.98,0.41,2.86,0.41,5.64v9.36c0,1.85,0.16,3.02,0.49,3.47
			c0.33,0.45,1.04,0.74,2.11,0.86v0.93h-12.17v-0.93c0.92-0.12,1.56-0.5,1.96-1.13c0.28-0.43,0.41-1.5,0.41-3.2v-10.7
			c0-1.97-0.08-3.22-0.23-3.73c-0.16-0.51-0.42-0.91-0.8-1.18c-0.36-0.3-0.76-0.44-1.2-0.44c-1.48,0-2.85,1.05-4.12,3.17v12.89
			c0,1.81,0.16,2.94,0.48,3.43c0.34,0.49,0.97,0.79,1.88,0.9v0.93h-12.17v-0.93c1.02-0.1,1.74-0.42,2.16-0.97
			c0.3-0.38,0.44-1.5,0.45-3.36v-13.59c0-1.81-0.18-2.95-0.51-3.39c-0.33-0.45-1.03-0.74-2.09-0.88v-0.95H463.45"
          />
          <path
            d="M489.83,215.42v8.81h5.72v2.54h-5.72v14.86c0,1.38,0.07,2.28,0.19,2.7c0.14,0.4,0.37,0.72,0.69,0.98
			c0.33,0.24,0.62,0.36,0.9,0.36c1.12,0,2.18-0.86,3.16-2.55l0.77,0.57c-1.38,3.29-3.65,4.94-6.77,4.94c-1.53,0-2.82-0.42-3.88-1.26
			c-1.05-0.86-1.72-1.81-2.01-2.85c-0.18-0.6-0.26-2.16-0.26-4.75v-13h-3.14v-0.89c2.17-1.53,4-3.14,5.51-4.82
			c1.53-1.67,2.86-3.56,3.99-5.64H489.83"
          />
        </g>
        <path
          d="M210.66,162.71l-2.19-2.94l-0.9,3.51L210.66,162.71 M216.06,164.96l-5.13,0.95l-0.06-0.33
		c0.96-0.25,1.25-0.32,1.17-0.76c-0.06-0.36-0.75-1.25-1.05-1.64l-3.57,0.66c-0.27,1.04-0.42,1.64-0.39,1.85
		c0.1,0.51,0.87,0.4,1.41,0.33l0.06,0.34l-3.22,0.6l-0.06-0.34c0.84-0.26,0.97-0.76,1.27-1.85l2.18-7.94l0.45-0.08l4.81,6.35
		c0.98,1.3,1.31,1.7,2.07,1.53L216.06,164.96z"
        />
        <g>
          <path
            d="M128.36,331.01c1.76-0.08,2.96-0.42,3.6-0.99c0.64-0.58,0.97-1.83,0.97-3.76v-23.01c0-2-0.38-3.31-1.14-3.91
			c-0.51-0.41-1.65-0.68-3.43-0.83v-1.32h16.74c3.47,0,6.28,0.36,8.43,1.09c4.17,1.48,6.25,4.12,6.25,7.95
			c0,2.83-1.09,5.05-3.27,6.68c-1.67,1.22-3.43,2-5.3,2.34l11.06,14.8c0.29,0.41,0.59,0.68,0.91,0.8c0.32,0.12,0.72,0.21,1.19,0.24
			v1.25h-10.85l-12.13-16.25h-0.02v10.18c0,1.83,0.3,3.06,0.91,3.69c0.61,0.64,1.83,0.99,3.66,1.06v1.32h-17.58V331.01z
			 M148.67,312.97c1.46-0.91,2.2-3,2.2-6.29c0-2.01-0.28-3.6-0.84-4.77c-0.96-1.98-2.73-2.97-5.28-2.97c-1.39,0-2.3,0.17-2.73,0.52
			c-0.43,0.34-0.64,0.92-0.64,1.71v13.23C144.77,314.35,147.2,313.87,148.67,312.97z"
          />
          <path
            d="M165.66,331.01c1.47-0.04,2.53-0.21,3.17-0.53c1.05-0.54,1.58-1.58,1.58-3.12v-25.19
			c0-1.53-0.5-2.56-1.5-3.08c-0.61-0.32-1.7-0.51-3.25-0.58v-1.32h18.18v1.32c-1.63,0.07-2.74,0.25-3.35,0.56
			c-1.02,0.52-1.52,1.55-1.52,3.1v25.19c0,1.46,0.41,2.43,1.22,2.92c0.81,0.49,2.03,0.74,3.66,0.74v1.32h-18.18V331.01z"
          />
          <path
            d="M185.7,320.19h1.5c0.83,3.88,2.16,6.71,4.01,8.48c1.85,1.76,3.96,2.65,6.35,2.65c2.31,0,3.96-0.61,4.94-1.82
			c0.98-1.21,1.46-2.49,1.46-3.82c0-1.56-0.51-2.83-1.53-3.82c-0.68-0.64-2.04-1.48-4.1-2.51l-3.31-1.68
			c-3.29-1.66-5.61-3.3-6.96-4.93c-1.35-1.63-2.03-3.63-2.03-6.02c0-2.54,0.89-4.91,2.69-7.11c1.79-2.2,4.53-3.3,8.23-3.3
			c1.88,0,3.7,0.29,5.46,0.86c1.76,0.58,2.76,0.87,3.01,0.87c0.57,0,0.95-0.17,1.17-0.49c0.22-0.33,0.39-0.77,0.53-1.31h1.55v11.32
			h-1.45c-0.68-2.62-1.87-4.88-3.59-6.78c-1.71-1.9-3.79-2.84-6.24-2.84c-1.72,0-3.08,0.48-4.09,1.44c-1,0.96-1.5,2.12-1.5,3.49
			c0,1.64,0.47,2.87,1.4,3.69c0.92,0.83,2.99,2.05,6.2,3.66l3.84,1.93c1.71,0.87,3.07,1.82,4.1,2.87c1.9,1.97,2.85,4.35,2.85,7.16
			c0,2.62-1.02,5.1-3.06,7.42c-2.04,2.32-5.31,3.49-9.81,3.49c-1.03,0-2.08-0.1-3.14-0.29c-1.06-0.19-2.1-0.5-3.14-0.91l-1-0.38
			c-0.23-0.07-0.46-0.12-0.68-0.16c-0.22-0.04-0.4-0.07-0.55-0.07c-0.53,0-0.9,0.16-1.1,0.47s-0.38,0.76-0.53,1.33h-1.5V320.19z"
          />
          <path
            d="M211.27,332.33v-1.32c1.49-0.05,2.55-0.25,3.17-0.61c0.97-0.54,1.45-1.56,1.45-3.05v-25.19
			c0-1.51-0.5-2.53-1.5-3.05c-0.57-0.32-1.62-0.52-3.12-0.61v-1.32h29.97v10.49h-1.27c-0.66-3.35-1.78-5.65-3.37-6.89
			c-1.58-1.23-4.47-1.85-8.64-1.85c-1.59,0-2.61,0.16-3.04,0.49c-0.43,0.33-0.64,0.98-0.64,1.97v12.04c2.96,0.04,4.97-0.5,6.02-1.61
			c1.05-1.11,1.86-3.14,2.42-6.08h1.32v17.57h-1.32c-0.38-2.96-1.13-5.05-2.27-6.26c-1.14-1.21-3.19-1.82-6.16-1.82v12.6
			c0,1.1,0.28,1.85,0.85,2.25c0.57,0.4,1.73,0.6,3.49,0.6c3.32,0,6.03-0.64,8.13-1.93c2.1-1.29,3.96-3.7,5.59-7.24h1.45l-2.11,10.82
			H211.27z"
          />
          <path
            d="M258.45,331.01c1.49-0.05,2.55-0.25,3.17-0.61c0.97-0.54,1.45-1.56,1.45-3.05v-25.16
			c0-1.51-0.47-2.52-1.42-3.05c-0.57-0.32-1.64-0.53-3.2-0.64v-1.32h29.49v10.49h-1.27c-0.64-3.23-1.73-5.5-3.25-6.79
			c-1.52-1.3-4.33-1.94-8.43-1.94c-1.52,0-2.49,0.17-2.91,0.52c-0.42,0.34-0.62,0.99-0.62,1.94v12.04c2.81,0.05,4.71-0.54,5.7-1.78
			c0.99-1.24,1.74-3.21,2.25-5.92h1.32v17.57h-1.32c-0.36-2.81-1.07-4.86-2.15-6.15c-1.08-1.29-3.01-1.93-5.8-1.93v12.11
			c0,1.61,0.47,2.63,1.41,3.06c0.94,0.43,2.37,0.63,4.31,0.6v1.32h-18.72V331.01z"
          />
          <path
            d="M299.5,308.38v17.65c0,1.05,0.13,1.82,0.38,2.31c0.44,0.87,1.31,1.29,2.61,1.29c0.83,0,1.64-0.26,2.44-0.79
			c0.45-0.29,1.03-0.77,1.73-1.45v-14.75c0-1.1-0.22-1.86-0.65-2.29c-0.43-0.44-1.3-0.68-2.6-0.73v-1.25h10.36v18.74
			c0,1.08,0.2,1.82,0.61,2.23s1.2,0.67,2.39,0.77v1.19c-3.22,0.3-5.24,0.53-6.08,0.67c-0.84,0.15-2.18,0.5-4.02,1.08v-3.43
			c-1.24,1.07-2.26,1.82-3.07,2.26c-1.42,0.78-2.9,1.17-4.44,1.17c-1.68,0-3.24-0.53-4.69-1.6c-1.45-1.06-2.17-2.96-2.17-5.69
			v-13.13c0-1.11-0.18-1.84-0.53-2.18c-0.36-0.34-1.04-0.62-2.06-0.84v-1.25H299.5z"
          />
          <path
            d="M318.1,331.08c0.95-0.12,1.65-0.38,2.09-0.77c0.45-0.4,0.67-1.19,0.67-2.38v-15.29c0-1.06-0.18-1.79-0.54-2.2
			c-0.37-0.41-1.1-0.68-2.22-0.82v-1.25h9.85v3.73c0.83-1.25,1.88-2.29,3.16-3.11c1.28-0.82,2.69-1.23,4.25-1.23
			c2.23,0,3.97,0.57,5.19,1.73c1.23,1.15,1.84,3.18,1.84,6.07v12.57c0,1.17,0.2,1.92,0.6,2.26c0.4,0.34,1.06,0.57,1.99,0.68v1.25
			h-12.22v-1.25c0.93-0.19,1.57-0.44,1.9-0.76c0.34-0.32,0.51-1.05,0.51-2.19v-12.6c0-1.18-0.12-2.07-0.36-2.66
			c-0.41-1.07-1.21-1.6-2.41-1.6c-0.9,0-1.72,0.33-2.48,0.98c-0.75,0.65-1.33,1.31-1.74,1.97v13.92c0,1.14,0.17,1.86,0.51,2.19
			c0.34,0.32,0.98,0.57,1.9,0.76v1.25H318.1V331.08z"
          />
          <path
            d="M348.17,311.34c1.96-2.38,4.31-3.57,7.05-3.57c1.37,0,2.59,0.34,3.66,1.02c0.66,0.41,1.49,1.14,2.49,2.18
			v-9.52c0-1.32-0.25-2.13-0.76-2.45c-0.51-0.31-1.56-0.51-3.15-0.57v-1.25h11.2v29.94c0,1.08,0.2,1.82,0.61,2.23
			s1.21,0.67,2.39,0.77v1.19c-2.91,0.29-4.79,0.5-5.64,0.65c-0.84,0.14-2.33,0.51-4.47,1.1v-3.43c-0.98,1.02-1.88,1.77-2.69,2.26
			c-1.29,0.78-2.68,1.17-4.17,1.17c-2.54,0-4.75-1.08-6.63-3.23c-1.88-2.15-2.82-5.07-2.82-8.76
			C345.23,316.96,346.21,313.72,348.17,311.34z M360.02,328.62c0.89-1,1.34-1.7,1.34-2.11v-12.64c0-0.42-0.38-1.05-1.14-1.88
			c-0.76-0.83-1.78-1.25-3.03-1.25c-1.85,0-3.1,1.28-3.77,3.84c-0.36,1.37-0.53,3.31-0.53,5.82c0,2.67,0.17,4.69,0.51,6.04
			c0.63,2.46,1.83,3.68,3.62,3.68C358.13,330.12,359.13,329.62,360.02,328.62z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export { RiseFundLogo }

/** @jsx jsx */
import { jsx, Text } from 'theme-ui'

const EventHeader = ({ title }) => {
  const words = title.split(' ');

  return (
    <Text
      as="h3"
      variant="largeHeader"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexBasis: "100%",
        flexWrap: 'wrap',
        gap: `${Math.max(15, 30 - title.length)}%`,
        lineHeight: '1.5em',
        letterSpacing: '0',
        '&:hover': {
          color:'red',
        }
      }}
    >
      {words.map((word, index) => (
        (word.length > 1 ? 
        <span
          sx={{
            flexGrow: word.length,
            display: 'flex',
            justifyContent: 'space-between',
            gap: word.length > 10 ? '0.33em' : '0.83em'
          }}
          key={index}
        >
          {word.split('').map((char, index) => (
            <span key={index}>{char}</span>)
          )} { }
        </span>
        :
        <span
          key={index}
        >
          {word}
        </span>
        )
      ))}
    </Text>
  )
}

export { EventHeader }
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { sanityImage } from 'lib/sanity/sanity'
// import PropTypes from 'prop-types'

const Meta = ({ metaFields }) => {
  // Page specific
  const {
    metaTitle,
    metaDescription,
    openGraphDescription,
    openImage,
    openTitle,
    twitterDescription,
    twitterImage,
    twitterTitle,
  } = metaFields
  const router = useRouter()

  const {
    global: { metaFields: globalMetaFields },
  } = useGlobalStaticData()

  // Defaults
  const {
    metaTitle: defaultTitle,
    metaDescription: defaultDescription,
    openImage: {
      asset: { url: defaultOGImage },
    },
  } = globalMetaFields
  const defaultOGURL = 'https://darkmofo.net.au'

  const ogImage = openImage?.asset?.url
    ? sanityImage(openImage)
      .quality(80)
      .width(1200)
      .height(630)
      .url()
    : sanityImage(defaultOGImage)
      .quality(80)
      .width(1200)
      .height(630)
      .url()

  const twImage = openImage?.asset?.url
    ? sanityImage(openImage)
      .quality(80)
      .width(800)
      .height(418)
      .url()
    : sanityImage(defaultOGImage)
      .quality(80)
      .width(800)
      .height(418)
      .url()

  return (
    <NextHead>
      <title key="title">
        {metaTitle && metaTitle != defaultTitle
          ? `${defaultTitle} | ${metaTitle}`
          : defaultTitle}
      </title>
      <meta
        name="viewport"
        key="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      />
      <link rel="icon"
        href="/icon/favicon.ico"
        sizes="any"
      />
      <link
        rel="icon"
        type="image/svg+xml"
        href="/icon/favicon.svg"
      />
      <link
        rel="apple-touch-icon"
        href="/icon/apple-touch-icon.png"
      />
      <link rel="manifest" href="/icon/site.webmanifest"/>
      <meta name="msapplication-TileColor" content="#ffffff" key="msatc" />
      <meta
        name="msapplication-config"
        content="/icon/browserconfig.xml"
        key="msac"
      />
      <meta name="theme-color" content="#ffffff" key="theme-color" />

      <meta
        name="description"
        content={metaDescription ? metaDescription : defaultDescription}
        key="desc"
      />
      <meta
        property="og:url"
        content={`${defaultOGURL}${router.asPath}`}
        key="ogurl"
      />
      <meta
        property="og:title"
        content={
          openTitle && openTitle != defaultTitle
            ? `${defaultTitle} | ${openTitle}`
            : metaTitle && metaTitle != defaultTitle
              ? `${defaultTitle} | ${metaTitle}`
              : defaultTitle
        }
        key="ogtitle"
      />
      <meta
        property="og:description"
        content={
          openGraphDescription
            ? openGraphDescription
            : metaDescription
              ? metaDescription
              : defaultDescription
        }
        key="ogdesc"
      />
      <meta
        name="twitter:site"
        content={`${defaultOGURL}${router.asPath}`}
        key="twittersite"
      />
      <meta
        name="twitter:title"
        content={
          twitterTitle && twitterTitle != defaultTitle
            ? `${defaultTitle} | ${twitterTitle}`
            : metaTitle && metaTitle != defaultTitle
              ? `${defaultTitle} | ${metaTitle}`
              : defaultTitle
        }
        key="twitter:title"
      />
      <meta
        name="twitter:description"
        content={
          twitterDescription
            ? twitterDescription
            : metaDescription
              ? metaDescription
              : defaultDescription
        }
        key="twitter:description"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twittercard"
      />
      <meta name="twitter:image" content={twImage} key="twitterimage" />
      <meta property="og:image" content={ogImage} key="ogimage" />
      <meta property="og:image:width" content="1200" key="ogimagewidth" />
      <meta property="og:image:height" content="630" key="ogimageheight" />

      {/* animated gif meta image */}
      {/* og:image must point to gif located on the current domain - no cross domain gifs */}
      {/* <meta
        property="og:image"
        content="https://dark-mofo-2021-git-feature-meta-image-dark-lab.vercel.app/rot.gif"
      /> */}
      {/* og:url must point to gif located on the current domain - no cross domain gifs */}
      {/* <meta
        property="og:url"
        content="https://dark-mofo-2021-git-feature-meta-image-dark-lab.vercel.app/rot.gif"
      /> */}
    </NextHead>
  )
}

// Meta.PropTypes = {
//   metaFields: PropTypes.shape({
//     metaTitle: PropTypes.string,
//     metaDescription: PropTypes.string,
//     openGraphDescription: PropTypes.string,
//     openImage: PropTypes.string,
//     openTitle: PropTypes.string,
//     twitterDescription: PropTypes.string,
//     twitterImage: PropTypes.string,
//     twitterTitle: PropTypes.string,
//   }),
// }

export default Meta

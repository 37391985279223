/** @jsx jsx */
import { useBreakpointIndex } from '@theme-ui/match-media'
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, Box, Flex, Grid, Text, useColorMode } from 'theme-ui'

const DatePicker = () => {
  const bp = useBreakpointIndex({ defaultIndex: 2 })
  const gridGap = ['3px', null, 1, null, '9px']
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pt: "0.6em",
      }}
    >
      {bp > 1 ?
        <div>
          <Flex
            sx={{
              pb: "1rem",
              pt:'0em',
              justifyContent: "space-between"
            }}
          >
            {/* Row 1 */}
            <DateButton day={8} weekday="WED" />
            <DateButton day={9} weekday="THU" />
            <DateButton day={10} weekday="FRI" />
            <DateButton day={11} weekday="SAT" />
            <DateButton day={12} weekday="SUN" />
            <DateButton day={13} weekday="MON" />
            <DateButton day={14} weekday="TUE" disabled={true} />
          </Flex>
          <Flex
            sx={{
              pb: "1rem",
              justifyContent: "space-between"
            }}
          >        {/* Row 2 */}
            <DateButton day={15} weekday="WED" />
            <DateButton day={16} weekday="THU" />
            <DateButton day={17} weekday="FRI" />
            <DateButton day={18} weekday="SAT" />
            <DateButton day={19} weekday="SUN" />
            <DateButton day={20} weekday="MON" />
            <DateButton day={21} weekday="TUE" />
            <DateButton day={22} weekday="WED" />
          </Flex>
        </div>
        :
        <Grid
          columns={7}
          gridGap='1em'
          alignItems='center'
          sx={{
            pb: 1,
          }}
        >

          {/* Row 1 */}
          <DayHeader>S</DayHeader>
          <DayHeader>M</DayHeader>
          <DayHeader>T</DayHeader>
          <DayHeader>W</DayHeader>
          <DayHeader>T</DayHeader>
          <DayHeader>F</DayHeader>
          <DayHeader>S</DayHeader>
          {/* Row 2 */}
          <Box/>
          <Box/>
          <Box/>
          <DateButton day={8} gridColumnStart="4"/>
          <DateButton day={9} />
          <DateButton day={10} />
          <DateButton day={11} />

          {/* Row 3 */}
          <DateButton day={12} />
          <DateButton day={13} />
          <DateButton day={14} disabled={true}/>
          <DateButton day={15} />
          <DateButton day={16} />
          <DateButton day={17} />
          <DateButton day={18} />

          {/* Row 4 */}
          <DateButton day={19} />
          <DateButton day={20} />
          <DateButton day={21} />
          <DateButton day={22} />

        </Grid>
      }
    </Flex>
  )
}

const DayHeader = ({ children }) => {
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        margin: '0 auto',
      }}
    >
      <Text variant="smallCopySmallCapsWide" sx={{ color: 'grey' }}>{children}</Text>
    </Box>
  )
}

const DateButton = ({ day, disabled = false, weekday }) => {
  const { dateFilters, setDateFilters } = useDarkContext()
  const [colorMode] = useColorMode()

  const date =
    day.toString().length === 1 ? `2022-06-0${day}` : `2022-06-${day}`
  const ticked = dateFilters.includes(date)

  const handleClick = () => {
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    dataLayer.push({
      event: `custom.filter.${!ticked ? 'on' : 'off'}`,
      action: `Article Filter`,
      parentLabel: 'date',
      label: date,
      data: {
        date: date,
        day: days[new Date(date).getDay()],
        mode: colorMode,
      },
    })

    if (ticked)
      return setDateFilters(dateFilters.filter(arrDate => arrDate != date))
    return setDateFilters([...dateFilters, date])
  }
  const bp = useBreakpointIndex({ defaultIndex: 2 })
  const margin= bp > 1? "0": "0 auto";

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        margin: margin,
        justifyItems: 'center',
        backgroundColor: colorMode === 'light' && ticked ? 'grey' : 'none',
      }}
    >
      <Button
        sx={{
          display: 'block',
          color: 'black',
          cursor: disabled ? 'auto' : 'pointer',
          width: '100%',
          justifyItems: 'center',
        }}
        disabled={disabled}
        onClick={handleClick}
        aria-checked={ticked}
      >
        <Text
          variant="smallCopySmallCapsWide"
          sx={{
            lineHeight: '1em',
            color: colorMode === 'dark' && ticked ? 'red' : (ticked ? 'black' : 'grey'),
            fontWeight: 'normal',
            textDecoration: disabled? 'line-through' : 'none',
            borderBottom: 'none',
            width: '100%',
          justifyItems: 'center',
          }}
        >
          {weekday}{day < 10 ? " 0" : " "}{day}
        </Text>
      </Button>
    </Box>
  )
}

export default DatePicker

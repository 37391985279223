/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

const MenuIcon = ({
  color = 'grey',
  size = '1em',
  show = 'default',
  ...props
}) => {

  const { colorMode } = useThemeUI()
  const fillColor = colorMode === 'light' ? color : 'none';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      sx={{
        display: 'inline-block',
        fill: fillColor,
        stroke: color,
        fontSize: size,
        padding:'0px',
      }}
      {...props}
    >
      <line x1="0" y1="5" x2="100" y2="5"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
      <line x1="0" y1="45" x2="100" y2="45"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
      <line x1="0" y1="90" x2="100" y2="90"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
    </svg>
  )
}

export { MenuIcon }

import Meta from 'components/Meta';
import FlexCentre from 'components/utils/FlexCentre';
import { useGlobalStaticData } from 'hooks/useGlobalStaticData';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  resurrect,
  resurrected,
} from './helpers/resurrectedStorage'
import ResurrectionSplash from './ResurrectionSplash';
import SiteLockout from './SiteLockout';

const ResurrectYourself = ({
  children,
  lockout,
  enabled = lockout !== undefined,
}) => {
  if (!enabled) {
    return children
  }

  const {
    global: { metaFields },
  } = useGlobalStaticData()

  if (lockout) {
    return <>
      <Meta metaFields={metaFields} />
      <SiteLockout message={lockout} />
    </>
  }

  if (!enabled) {
    return children
  }

  const router = useRouter()
  const skipBypass = enabled === 'skip' && router.pathname !== '/'

  const [
    bypassed,
    setBypassed,
  ] = useState(skipBypass)

  useEffect(() => {
    const bypass = resurrected()

    if (!bypass && !skipBypass) {
      router.push('/')
    }

    setBypassed(bypass)
  }, [])

  if (skipBypass || bypassed) {
    return children
  }

  const crossClick = enabled === 'skip'
    ? () => {
      router.push('/program')
      setBypassed(true)
    }
    : undefined

  const bypassToProgram = () => {
    setBypassed(true)
    resurrect()
    router.push('/program')
  }

  return (
    <>
      <Meta metaFields={metaFields} />
      <FlexCentre
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <ResurrectionSplash
          canSkip
          onClick={crossClick}
          onComplete={bypassToProgram}
          onSkip={bypassToProgram}
        />
      </FlexCentre>
    </>
  )
}

export default ResurrectYourself;

import FlexCentre from 'components/utils/FlexCentre'
import { Container } from 'theme-ui'
import ResurrectionText from './ResurrectionText'

const SiteLockout = ({
  message,
}) => {
  return (
    <FlexCentre 
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <ResurrectionText text={message} />
    </FlexCentre>
  )
}

export default SiteLockout

import React from 'react';
import ResurrectionText from './ResurrectionText'

const ResurrectionTitles = ({
  title,
}) => {
  if (!title) {
    return null;
  }

  // i.e. if title is a react component
  if (title instanceof Object) {
    return title;
  }

  return (
    <ResurrectionText
      text={title}
      zIndex={3}
    />
  )
}

export default ResurrectionTitles;

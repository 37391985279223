/** @jsx jsx */
import { jsx, Text } from 'theme-ui'

const LockedWord = ({
  title,
  fontVariant,
  lineHeight,
  margin,
  textColor,
  wordGap = `${Math.max(15, 30 - title.length)}%`,
  charGap,
  textDecoration = "none",
}) => {
  const words = title.split(' ');
  return (
    <Text
      as="h3"
      variant={fontVariant}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexBasis: "100%",
        flexWrap: 'wrap',
        lineHeight: lineHeight,
        AlignItems: 'flex-end',
        margin: margin,
        color: textColor,
        gap: wordGap,
        textDecorationLine: textDecoration,
        letterSpacing: '0',
      }}
    >
      {words.map((word, index) => (
        ( word.length>1 ?
          <span
          sx={{
            flexGrow: word.length,
            display: 'flex',
            justifyContent: 'space-between',
            gap: charGap,
            textDecorationLine: textDecoration,
          }}
          key={index}
        >
          {word.split('').map((char, index) => (
            <span key={index}>{char}</span>)
          )} { }
        </span>
         : 
        <span key={index}>
          {word}
        </span>
        )
      ))}
    </Text>
  )
}

export { LockedWord }
import SplashCross from '../SplashCross';
import VideoCredit from '../VideoCredit';
import { programList } from './programList';

const FRAME_RATE = 24;
const PROGRAM_START_TIME = time('23:17');
const PROGRAM_END_TIME = 5 * 60 + time('14:10');
const PROGRAM_TITLE_DURATION = (PROGRAM_END_TIME - PROGRAM_START_TIME) / programList.length;
const CREDIT_START_TIME = 5 * 60 + time('15:10');
const VIDEO_DURATION = 5 * 60 + time('25:15');
const CREDIT_DURATION = (VIDEO_DURATION - CREDIT_START_TIME) / 2;

const CREDIT_END_TIME = CREDIT_START_TIME + CREDIT_DURATION;

const titleTimes = Object.freeze([
  ['We stand with Ukraine', 0, 8, time('10:00')],
  ['Dark Mofo',             time('10:00'), time('13:09')],
  ['Presents',              time('13:09'), time('16:07')],
  ['Resurrection',          time('16:07'), time('19:02')],
  ['08 — 22',               time('19:02'), time('22:02')],
  ...programList.map(
    (title, i) => [title, ...programIndexTime(i)]
  ),
  [['Credit', <VideoCredit />], CREDIT_START_TIME, CREDIT_END_TIME],
  [['Cross', <SplashCross />],     CREDIT_END_TIME, VIDEO_DURATION + FRAME_RATE],
]);

function time(timestamp) {
  const [seconds, frames] = timestamp.split(':');

  return Number(seconds) + Number(frames) / FRAME_RATE;
}

function programIndexTime(index) {
  const start = PROGRAM_START_TIME + PROGRAM_TITLE_DURATION * index

  return [
    start,
    start + PROGRAM_TITLE_DURATION,
  ];
}

/**
 * Retrieve a title for a given time
 * @param {number} time
 */
 export function titleAtTime(time = 0) {
  const match = titleTimes.find(
    ([_title, start, end]) => time >= start && time < end
  )

  if (match) {
    if (match[0] instanceof Array) {
      return match[0][1];
    }

    return match[0];
  }

  return null;
}

/**
 * For a given title, either skip ahead in the video, or run
 * @param {string} title
 */
export function skipFromTitle(title) {
  const skipTo = titleTimes.find(([titleSearch]) => title === titleSearch);

  if (skipTo) {
    return skipTo[3];
  }

  return undefined;
}

/** @jsx jsx */
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { jsx, Box } from 'theme-ui'

const Error = ({ input, errors, errorAnchor }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        color: 'white',
      }}
    >
      <Box
        id="error-anchor"
        ref={errorAnchor}
        sx={{
          position: 'absolute',
          mt: '-1.285em',
        }}
      />
      {errors && Object.keys(errors).length > 0 ? (
        <Box role="alert">
          <DefaultBlocks blocks={input.copy} />
        </Box>
      ) : null}
    </Box>
  )
}

export default Error

import { useEffect, useState } from 'react'

const DarkLabASCII = () => {
  const [comment, setComment] = useState(false)
  /* eslint-disable no-irregular-whitespace */
  useEffect(() => {
    if (!comment) {
      const comment = document.createComment(`
  ██████   █████  ██████  ██   ██ ██      █████  ██████
  ██   ██ ██   ██ ██   ██ ██  ██  ██     ██   ██ ██   ██
  ██   ██ ███████ ██████  █████   ██     ███████ ██████
  ██   ██ ██   ██ ██   ██ ██  ██  ██     ██   ██ ██   ██
  ██████  ██   ██ ██   ██ ██   ██ ██████ ██   ██ ██████
  DarkLab acknowledges and respects the palawa/pakana people as the traditional and ongoing owners and custodians of the skies, land and water of lutruwita. We pay our respects to their elders both past and present and acknowledge that sovereignty has never been ceded.
  `)
      const htmlDom = document.querySelector('html')
      htmlDom.insertBefore(comment, htmlDom.firstChild)
    }
    setComment(true)
  }, [comment])
  /*eslint-enable no-irregular-whitespace */
  return <></>
}

export { DarkLabASCII }

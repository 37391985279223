/** @jsx jsx */
import { jsx } from 'theme-ui'

const RenderIf = ({ show, children }) => {
  if (show) {
    return children;
  }

  return null;
}

export { RenderIf }
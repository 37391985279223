import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import Cookies from 'js-cookie'
import { objectToString, stringToObject } from 'lib/general/objectHelpers'
import { useRouter } from 'next/router'
import { createContext, useContext, useState, useEffect } from 'react'

const DarkContext = createContext()

const DarkContextProvider = props => {
  const router = useRouter()

  const {
    global: { mainMenu },
  } = useGlobalStaticData()

  const [menuDisplay, setMenuDisplay] = useState(
    mainMenu.menuItems.filter(item => {
      return (
        item._type === 'menuPage' &&
        router.query.slug &&
        router.query.slug[0] === item.slug.current
      )
    }).length > 0,
  )
  const [favourites, setFavourites] = useState({})
  const [tnewFavourites, setTnewFavourites] = useState([])

  // All available filter names, stored as an array to preserve ordering since
  // the plain object `availableFilters` below may not preserve its key order.
  const filterNames = [
    'free',
    'ticketed',
    'exclusives',
  ]

  // All available filters, inactive by default
  const availableFilters = {}
  filterNames.forEach(filterName => {
    availableFilters[filterName] = false
  })

  // View + Filter Options
  // <string> lineUp | date
  const [viewBy, setViewBy] = useState('lineup')
  const [viewByOpen, setViewByOpen] = useState(false)
  // [] of objects {type: ''. value: ''}
  // eg {type: 'date', value: 'date'}
  const [tagFilterBy, setTagFilterBy] = useState(availableFilters)
  const [searchValue, setSearchValue] = useState("")
  const [dateFilters, setDateFilters] = useState([]) //'2021-03-19', '2021-03-02'
  const [filterByOpen, setFilterByOpen] = useState(false)
  const [parentRoute, setParentRoute] = useState('/program')
  const [filteredEvents, setFilteredEvents] = useState(0)


  const toggleMenu = (parentRouter) => {
    if (menuDisplay === true) {
      router.push(parentRoute, undefined, { scroll: false })
      clearAllBodyScrollLocks()
    } else {
      setParentRoute(parentRouter)
      router.push(parentRoute, undefined, { scroll: false, shallow: true })
    }
    setMenuDisplay(!menuDisplay)
    if (filterByOpen) {
      setFilterByOpen(false)
    }
  }

  const toggleSearch = () => {
    router.push('/search', undefined, { scroll: false, shallow: true })
  }

  const toggleFavourite = (listing, title, tnew) => {
    // Paid and unpaid favourites
    // Paid favourites for tickets.darkmofo
    if (favourites[listing]) {
      delete favourites[listing]
      if (tnew) {
        const t = tnewFavourites.indexOf(tnew)
        if (t > -1) {
          tnewFavourites.splice(t, 1)
        }
      }
    } else {
      favourites[listing] = true
      if (tnew) {
        tnewFavourites.push(tnew)
      }
    }

    // Save favourites
    saveFavourites(favourites, tnewFavourites)

    // Track
    // if (title) {
    //   track({
    //     event: favourites[listing] ? 'custom.toggle.on' : 'custom.toggle.off',
    //     group: 'Favourites',
    //     type: 'Favourites',
    //     label: title,
    //   });
    // }
  }

  function saveFavourites(favourites, tnewFavourites) {
    // Save both states
    setFavourites({ ...favourites })
    setTnewFavourites(tnewFavourites)

    // Save to localstorage
    if (window.localStorage) {
      localStorage.setItem('favourites', objectToString(favourites))
    }

    // Save to cookies
    Cookies.set('tnewFavourites', tnewFavourites.join(','), {
      expires: 305,
      domain: '.darkmofo.net.au',
    })
  }

  const clearFilters = () => {
    clearTagFilterBy()
    clearDateFilters()
    setSearchValue('')
  }

  const clearTagFilterBy = () => {
    setTagFilterBy(availableFilters)
  }

  const clearDateFilters = () => {
    setDateFilters([])
  }

  useEffect(() => {
    // set initial favourites
    if (window.localStorage) {
      if (localStorage.getItem('favourites')) {
        setFavourites(stringToObject(localStorage.getItem('favourites')))
      }
    }

    let tnewFavouritesString = Cookies.get('tnewFavourites') + ''
    if (tnewFavouritesString !== '' && tnewFavouritesString !== 'undefined') {
      setTnewFavourites(tnewFavouritesString.split(','))
    }

    // Restore user's filters across page reloads
    if (window.sessionStorage && sessionStorage.getItem('filters')) {
      const updatedFilters = availableFilters
      const activeFilterNames = sessionStorage.getItem('filters').split(',')
      activeFilterNames.forEach(
        filterName => (updatedFilters[filterName] = true),
      )
      setTagFilterBy(updatedFilters)
    }
  }, [])

  // Persist user's filters across page reloads, but not beyond page session.
  // Apply with useEffect is used to ensure sessionStorage is updated every
  // time `tagFilterBy` is updated, no matter where from.
  useEffect(() => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      const activeFilterNames = Object.keys(tagFilterBy).filter(
        filterName => !!tagFilterBy[filterName],
      )
      sessionStorage.setItem('filters', activeFilterNames.join(','))
    }
  }, [tagFilterBy])

  useEffect(() => {
    if (router.query) {
      let query = router.query

      let viewByQuery = query.viewBy || query.viewby || query.view || query.sort // Allow a few different options
      if (viewByQuery === 'date') {
        setViewBy('date')
      }
      let filterQuery = query.filters || query.filter
      if (filterQuery) {
        let initialFilters = availableFilters
        filterQuery.split(',').map(queryFilter => {
          if (availableFilters[queryFilter] === false) {
            // Make sure it's an available filter
            initialFilters[queryFilter] = true
          }
        })
        setTagFilterBy(initialFilters)
      }
      let dateQuery = query.dates || query.date
      if (dateQuery) {
        let initialDates = []
        dateQuery.split(',').map(queryDate => {
          let queryDateInt = parseInt(queryDate)
          if (
            Number.isInteger(queryDateInt) &&
            queryDateInt >= 1 &&
            queryDateInt <= 31
          ) {
            // Make sure it's a valid date
            initialDates.push('2022-06-' + queryDateInt)
          }
        })
        setDateFilters(initialDates)
      }
    }
  }, [router.query])

  return (
    <DarkContext.Provider
      value={{
        menuDisplay,
        toggleMenu,
        setMenuDisplay,
        toggleSearch,
        favourites,
        toggleFavourite,
        // Filters and views
        // availableFilters,
        // Filter values
        viewBy,
        setViewBy,
        filterNames,
        tagFilterBy,
        parentRoute,
        setParentRoute,
        filteredEvents,
        setFilteredEvents,
        setTagFilterBy,
        searchValue,
        setSearchValue,
        dateFilters,
        setDateFilters,
        clearFilters,
        clearTagFilterBy,
        clearDateFilters,
        // Filter accordions
        viewByOpen,
        setViewByOpen,
        filterByOpen,
        setFilterByOpen,
      }}
    >
      {props.children}
    </DarkContext.Provider>
  )
}

const useDarkContext = () => {
  const {
    menuDisplay,
    toggleMenu,
    setMenuDisplay,
    searchDisplay,
    toggleSearch,
    favourites,
    toggleFavourite,
    // availableFilters,
    parentRoute,
    setParentRoute,
    filteredEvents,
    setFilteredEvents,
    viewBy,
    setViewBy,
    filterNames,
    tagFilterBy,
    setTagFilterBy,
    searchValue,
    setSearchValue,
    dateFilters,
    setDateFilters,
    clearFilters,
    clearTagFilterBy,
    clearDateFilters,
    viewByOpen,
    setViewByOpen,
    filterByOpen,
    setFilterByOpen,
    resurrected,
    setResurrected,
  } = useContext(DarkContext)

  return {
    menuDisplay,
    toggleMenu,
    setMenuDisplay,
    searchDisplay,
    toggleSearch,
    favourites,
    toggleFavourite,
    parentRoute,
    setParentRoute,
    filteredEvents,
    setFilteredEvents,
    // availableFilters,
    viewBy,
    setViewBy,
    filterNames,
    tagFilterBy,
    setTagFilterBy,
    searchValue,
    setSearchValue,
    dateFilters,
    setDateFilters,
    clearFilters,
    clearTagFilterBy,
    clearDateFilters,
    viewByOpen,
    setViewByOpen,
    filterByOpen,
    setFilterByOpen,
    resurrected,
    setResurrected,
  }
}

export { DarkContextProvider, DarkContext, useDarkContext }

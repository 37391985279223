import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

// Initialise dayjs
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(LocalizedFormat)
const hobartTimeZone = 'Australia/Hobart'

export function hobartTime(dateString) {
  return dayjs(dateString).tz(hobartTimeZone)
}

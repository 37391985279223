import * as Sentry from '@sentry/node'
import * as Tracing from '@sentry/tracing'
import { DarkContextProvider } from 'components/context/DarkContext'
import { DarkLabASCII } from 'components/global/DarkLabASCII'
import GlobalLayout from 'components/global/GlobalLayout'
import { GlobalScrollbarStyles } from 'components/global/GlobalScrollbarStyles'
import { Header } from 'components/global/Header'
import { Menu } from 'components/global/menu/Menu'
import { AnimatePresence } from 'framer-motion'
import { isClient } from 'lib/general/isClient'
import theme from 'lib/theme'
import { useEffect, useRef } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { ThemeProvider, useThemeUI } from 'theme-ui'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useRouter } from 'next/router'
import * as gtm from 'lib/general/gtag'
import { GlobalStyles } from 'components/global/GlobalStyles'
import ResurrectYourself from 'components/splash/ResurrectYourself'

// Webpack config aliases '@sentry/node' to '@sentry/browser' on client
// This MUST be imported for tracing to work, even though it isn't used

// Configure Sentry error reporting
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  // Tracing integrations differ between front- and back-end
  const integrations = []
  if (isClient) {
    // Client-side integrations, via '@sentry/tracing'
    integrations.push(new Tracing.Integrations.BrowserTracing())
  } else {
    // Server-side integrations, via '@sentry/node'
    integrations.push(new Sentry.Integrations.Http({ tracing: true }))
  }

  // Sentry config docs: https://docs.sentry.io/clients/javascript/config/
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    // Only log errors for production deployments, or if FORCE_SENTRY_LOGGING
    // envvar is set
    enabled:
      process.env.ENV === 'production' || !!process.env.FORCE_SENTRY_LOGGING,

    // TODO Tag releases by Git commit SHA for now
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,

    integrations,

    // Stay under Sentry monthly transaction limit, log only 1% of transactions
    tracesSampleRate: 0.01,

    // Log more deeply nested data reported to Sentry (default is 3)
    normalizeDepth: 6,

    // Ignore errors we can't do anything about or don't care about, especially
    // for third-party scripts or hostile browser extensions or setups.
    // See for inspiration https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      // Ignore error raised excessively by Safari depending on user's settings
      'SecurityError',
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
    ],
    denyUrls: [
      // Google flakiness
      /gtag\/js/i,
      /maps\/api\/js/i,
      /maps-api-v3\/api\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],

  })
}

// smoothscroll polyfill
if (isClient) {
  smoothscroll.polyfill()
  require('inert-polyfill')
}

function MyApp({ Component, pageProps, err }) {
  // Cannot use `useColorMode()` hook outside a `ThemeProvider`
  const context = useThemeUI()
  const colorMode = context.colorMode ?? 'dark'

  const lastUrl = useRef(
    typeof window !== 'undefined' ? window.location.pathname : undefined,
  )

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = url => {
      gtm.pageview(url)
      if (url !== lastUrl.current) {
        window.dataLayer.push({
          event: 'custom.route.change',
          route: {
            current: url,
            previous: lastUrl.current ? lastUrl.current : null,
            title: document.title,
            type: url.includes('/program/')
              ? 'Event'
              : url === '/program' || url === '/search'
                ? undefined
                : 'Accordion',
          },
          data: {
            mode: colorMode,
          },
        })
      }
      lastUrl.current = window.location.pathname
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // first page load
  useEffect(() => {
    dataLayer.push({
      data: {
        mode: colorMode,
      },
    })
  }, [])

  const pageKey =
    router.route === '/map/[[...slug]]' ? router.route : router.asPath

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <GlobalScrollbarStyles />
      <DarkLabASCII />
      <DarkContextProvider>
        <ResurrectYourself enabled={router.pathname === '/'}>
          <Header/>
          <GlobalLayout preview={pageProps.preview}>
            <AnimatePresence exitBeforeEnter initial={false}>
              {/* Pass through `err`or to work around
              https://github.com/vercel/next.js/issues/8592 */}
              <Component {...pageProps} key={pageKey} err={err} />
            </AnimatePresence>
          </GlobalLayout>
          <Menu />
        </ResurrectYourself>
      </DarkContextProvider>
    </ThemeProvider>
  )
}

export default MyApp

/** @jsx jsx */
import { Line } from 'components/Line'
import ExplodedLine from 'components/utils/ExplodedLine'
import { jsx, Box, Text } from 'theme-ui'

const MenuMap = ({ item, i, data = {}, preview }) => {
  return (
    <Box>
      <a
        variant="largeHeader"
        sx={{
          width: '100%',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
        id={`accordion${i}`}
        target="_blank"
        href={item.externalUrl}
        rel="noopener noreferrer"
      >
        <Text
          variant="largeHeader"
          as="h3"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            flexBasis: '100%',
            flexWrap: 'wrap',
            lineHeight: '1.5em',
            AlignItems: 'flex-end',
            margin: '2.4rem 0 2.4rem 0',
            color: 'alwaysBlack',
            gap: '0',
            textDecorationLine: 'none',
            letterSpacing: '0',
            cursor: 'pointer',
          }}
        >
          <ExplodedLine
            variant="largeHeaderBlack"
            wordGap="1em"
          >
            {item.title}
          </ExplodedLine>
        </Text>
      </a>
      <Line color="alwaysBlack" hrHeight="1px" />
    </Box>
  )
}

export { MenuMap }

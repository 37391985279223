import { useRef, useState } from 'react'
import ResurrectionVideo from './ResurrectionVideo'
import SplashPrompt from './SplashPrompt'

const ResurrectionSplash = ({
  forceFullscreen,
  canSkip,
  onComplete,
  onSkip,
  onClick,
}) => {
  const [
    isPlaying,
    setIsPlaying
  ] = useState(false)

  const videoRef = useRef()

  const useVideoClick = () => {
    if (forceFullscreen && document.body.requestFullscreen) {
      document.body.requestFullscreen()
    }

    videoRef?.current.play();
    setIsPlaying(true)
  }

  const clickHandler = (onClick || useVideoClick)

  return (
    <>
      <SplashPrompt
        enabled={!isPlaying}
        onClick={clickHandler}
      />
      <ResurrectionVideo
        canSkip={canSkip}
        playing={isPlaying}
        onComplete={onComplete}
        onSkip={onSkip}
        videoRef={videoRef}
      />
    </>
  )
}

export default ResurrectionSplash

import FlexCentre from 'components/utils/FlexCentre';
import { Button, Container, Text } from 'theme-ui';
import SplashCross from './SplashCross';

const SplashPrompt = ({
  onClick,
  enabled,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <FlexCentre>
      <Button
        sx={{
          width: '100%',
          borderRadius: 0,
        }}
        variant="clear"
        onClick={onClick}
      >
        <SplashCross />
        <Text
          variant="heading"
          sx={{
            display: 'block',
            width: '0px',
            height: '0px',
            textIndent: '-666666px',
            overflow: 'hidden',
          }}
        >
          Dark Mofo 2022 : Resurrection
        </Text>
      </Button>
    </FlexCentre>
  )
}

export default SplashPrompt

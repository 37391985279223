/** @jsx jsx */
import { jsx, Box, Flex, Grid, Text } from 'theme-ui'
import { Line } from 'components/Line'
import { EventsTasmaniaLogo } from 'components/global/svgs/EventsTasmaniaLogo'
import { MonaLogo } from 'components/global/svgs/MonaLogo'
import { RiverleeLogo } from 'components/global/svgs/RiverleeLogo'
import { RiseFundLogo } from 'components/global/svgs/RiseFundLogo'
import { TSOLogo } from 'components/global/svgs/TSOLogo'
import { UTASLogo } from 'components/global/svgs/UTASLogo'
import { HadleysLogo } from 'components/global/svgs/HadleysLogo'
import { TASPortsLogo } from 'components/global/svgs/TASPortsLogo'
import { TheOldWoolstoreLogo } from 'components/global/svgs/TheOldWoolstoreLogo'
import { CityOfHobartLogo } from 'components/global/svgs/CityOfHobartLogo'
import DefaultBlocks from 'components/sanity/DefaultBlocks'
import { MacPointLogo } from 'components/global/svgs/MacPointLogo'

const rowSx = {
  position: 'relative',
  height: '100%',
  minHeight: '135px',
  flexDirection: 'column',
}

const gridSx = {
  position: 'relative',
  minHeight: ['9rem','12rem'],
  justifyContent: 'center',
  alignItems: 'center',
}

const Partners = props => {
  return (
    <Box sx={{ color: 'alwaysBlack' }}>
      {/* Row One */}
      {/* Govt / Major Partners */}
      <Line color="alwaysBlack" hrHeight="1px" />
      <Text
        as="h3"
        variant="copyCapsReverseWide"
        sx={{
          mt: '.333em',
          color: 'alwaysBlack',
        }}
      >
        Major Partners
      </Text>
      <Flex
        sx={{
          justifyContent: ['flex-start', null, null, 'space-between', 'flex-start'],
          flexWrap: 'wrap',
          gap: ['2.222rem', null, null, '1.666rem', '6.666%'],
          minHeight: gridSx.minHeight,
          pt: '2.333rem',
          pb: '3.333rem',
          boxSizing: 'border-box',
        }}
      >
        <EventsTasmaniaLogo sx={{
          maxWidth: ['120px', '210px'],
        }} />
        <RiseFundLogo sx={{
          maxWidth: ['94px', '165px'],
        }} />
        <MonaLogo sx={{
          maxWidth: ['82px', '150px'],
        }} />
        <RiverleeLogo sx={{
          maxWidth: ['125px', '225px'],
        }} />
        <CityOfHobartLogo sx={{
          maxWidth: ['84px', '150px'],
          mr: [0, null, null, '2rem'],
        }} />
      </Flex>
      {/* Row Two */}
      {/* Venue Partners */}
      <Line color="alwaysBlack" hrHeight="1px" />
      <Text
        as="h3"
        variant="copyCapsReverseWide"
        sx={{
          mt: '.333em',
          color: 'alwaysBlack',
        }}
      >
        Venue Partners
      </Text>
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: ['2.222rem', null, null, '6.666%'],
          minHeight: gridSx.minHeight,
          pt: '2.333rem',
          pb: '3.333rem',
          boxSizing: 'border-box',
        }}
      >
        <UTASLogo sx={{
          maxWidth: ['110px', '195px'],
        }} />
        <TASPortsLogo sx={{
          maxWidth: ['108px', '180px'],
        }} />
        <MacPointLogo sx={{
          maxWidth: ['125px', '225px'],
        }} />
      </Flex>
      {/* Row Three */}
      <Grid
        columns={[1, 2]}
        variant="default"
        sx={{
          ...rowSx,
          gridRowGap: 0,
        }}
      >
        {/* Presenting Partners */}
        <Box>
          <Line color="alwaysBlack" hrHeight="1px" />
          <Text
            as="h3"
            variant="copyCapsReverseWide"
            sx={{
              mt: '.333em',
              color: 'alwaysBlack',
            }}
          >
            Presenting Partner
          </Text>
          <Grid columns={[2]} variant="default" sx={gridSx}>
            <TSOLogo sx={{ maxWidth: ['100px', '225px'] }} />
          </Grid>
        </Box>
        {/* Accomodation Partners */}
        <Box>
          <Line color="alwaysBlack" hrHeight="1px" />
          <Text
            as="h3"
            variant="copyCapsReverseWide"
            sx={{
              mt: '.333em',
              color: 'alwaysBlack',
            }}
          >
            Accommodation Partners
          </Text>
          <Grid columns={[2]} variant="default" sx={gridSx}>
            <HadleysLogo sx={{
              maxWidth: ['75px', '128px'],
            }} />
            <TheOldWoolstoreLogo sx={{
              maxWidth: ['70px', '128px'],
            }} />
          </Grid>
        </Box>
      </Grid>
      {/* Row Four */}
      { !!props.node.visible  && <PaintTheTownRed {...props?.node}/>}
    </Box>
  )
}

const PaintTheTownRed = ({
  pttrColumn1,
  pttrColumn2,
  pttrColumn3,
  pttrColumn4,
}) => {
  return (
    <>
      <Line color="alwaysBlack" hrHeight="1px" />
      <Text
        as="h3"
        variant="copyCapsReverseWide"
        sx={{
          mt: '.333em',
          color: 'alwaysBlack',
          textAlign: 'left',
        }}
      >
        Paint The Town Red: Presented by Destination Southern Tasmania
      </Text>
      <Grid
        columns={[2]}
        variant="default"
        sx={{
          ...rowSx,
          gridRowGap: 0,
          mt: '22px',
          minHeight: 'auto',
          mb: 3,
          a: {
            borderBottom: '0px solid',
          },
          textAlign: 'left',
          textAlignLast: 'left',
        }}
      >
        <Grid
          columns={[1, 2, 2]}
          sx={{
            display: ['flex', null, 'grid'],
            flexDirection: 'column',
            gridRowGap: '0px',
            'p': {
              mb: '.333em',
            }
          }}
          variant="default"
        >
          <DefaultBlocks blocks={pttrColumn1} />
          <DefaultBlocks blocks={pttrColumn2} />
        </Grid>
        <Grid
          columns={[1, 2, 2]}
          sx={{
            display: ['flex', null, 'grid'],
            flexDirection: 'column',
            gridRowGap: '0px',
            'p': {
              mb: '.333em',
            }
          }}
          variant="default"
        >
          <DefaultBlocks blocks={pttrColumn3} />
          <DefaultBlocks blocks={pttrColumn4} />
        </Grid>
      </Grid>
    </>
  )
}

export { Partners }

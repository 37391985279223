/** @jsx jsx */
import { DefaultBlocks } from 'components/sanity/DefaultBlocks'
import { jsx, Text, Box, Input, Flex, Label } from 'theme-ui'
import { hobartTime } from 'lib/util/time'

const Checkboxes = ({
  input,
  label,
  // id,
  controlProps,
  // light,
  register,
  getValues,
  trigger,
  errors,
  watch,
}) => {
  let validate = null

  if (input.required) {
    validate = () => {
      let valid = false
      let value = getValues()[input._key]
      if (value) {
        if (value.map) {
          value.map(v => {
            if (v) {
              valid = true
            }
          })
        } else {
          valid = value.indexOf('true') > -1 || value.indexOf(true) > -1
        }
      }
      return valid
    }
  }

  const onChange = () => {
    if (input.options && trigger) {
      trigger(input._key + '[0]')
    }
  }

  let { _type, options } = input
  if (_type === 'waitlistEvents') {
    const today = hobartTime()

    // Filter out events that have already happened
    options = options.filter(
      event =>
        event.eventStart.filter(date => hobartTime(date).isAfter(today)).length > 0,
    )
    // Only show events that have a sold out
    options = options.filter(
      event =>
        event.eventSoldOut.filter(soldOut => soldOut === true).length > 0,
    )

    // Sort events
    options.sort(function(a, b) {
      if (a.title < b.title) {
        return -1
      } else if (a.title > b.title) {
        return 1
      }
      return 0
    })
  }

  return (
    <Box
      sx={{
        mt: 1,
      }}
    >
      {options ? (
        <Box
          as={options.length > 1 ? 'fieldset' : 'div'}
          sx={{
            color: errors[input._key] ? 'white' : 'alwaysBlack',
            padding: 0,
            border: 'none',
          }}
        >
          {options.length > 1 || _type === 'waitlistEvents' ? (
            <Box
              as="legend"
              sx={{
                pb: ['10px'],
                pl: [0],
              }}
            >
              <Text
                variant="copyReverse"
                sx={{ mb: 0, color: errors[input._key] ? 'white' : 'alwaysBlack' }}
              >
                {label}
              </Text>
            </Box>
          ) : null}
          {options.length === 0 ? (
            <Box as="legend" sx={{ pb: ['10px'], pl: [0] }}>
              <Text variant="copyReverse" sx={{ mb: 0 }}>
                <b>No options available</b>
              </Text>
            </Box>
          ) : null}
          {options.map((option, o) => (
            <Box key={o} sx={{ pt: o > 0 ? '5px' : 0 }}>
              <Checkbox
                group={input}
                o={o}
                input={option}
                uid={controlProps.id}
                required={options.length == 0 ? input.required : false}
                validate={o == 0 ? validate : null}
                onChange={onChange}
                ariaInvalid={errors[input._key] ? 'true' : 'false'}
                value={option.slug ? option.slug : true}
                groupError={errors[input._key]}
                {...{ register, watch, errors }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Checkbox
            required={input.required}
            validate={validate}
            ariaInvalid={errors[input._key] ? 'true' : 'false'}
            value={true}
            uid={controlProps.id}
            {...{ input, register, watch, errors, controlProps }}
          />
        </Box>
      )}
    </Box>
  )
}

const Checkbox = ({
  group,
  o,
  input,
  uid,
  register,
  watch,
  onChange,
  required,
  validate,
  errors,
  groupError = false,
  value,
  ariaInvalid,
}) => {
  const name = group && o !== null ? group._key + '[' + o + ']' : input._key
  const checked = watch(name)
  return (
    <Flex
      sx={{
        color: groupError ? 'white' : errors[input._key] ? 'white' : 'alwaysBlack',
      }}
    >
      <Box
      variant="label.checkbox"
        sx={{
          position: 'relative',
          flexShrink: 0,
          margin: '0.3em 0 0 0',
        }}
      >
        <Input
          sx={{
            appearance: 'none',
            display: 'block',
            padding: 0,
            width: ['0.9em'],
            height: ['0.9em'],
            mt: '1px',
            borderRadius: 0,
            border: errors[input._key] ? '1px solid white' : '1px solid alwaysBlack',
            outline: 'none',
            cursor: 'pointer',
            '&:focus-visible': {
              outline: theme => `2px solid ${theme.colors.white}`,
            },
            background:'none',
          }}
          onChange={onChange}
          type="checkbox"
          id={`${uid}-${name}`}
          name={name}
          ref={register({ validate: validate })}
          value={value}
          aria-invalid={ariaInvalid}
        />
        {checked ? (
          <Box
            sx={{
              width: ['0.9em'],
              height: ['0.9em'],
              position: 'absolute',
              borderRadius: 0,
              top: '0em',
              left: '0em',
              cursor: 'pointer',
              pointerEvents: 'none',
              '::after': {
                content: '""',
                position: 'absolute',
                width: '1px',
                top: '1px',
                height: 'calc((0.9em - 2px) * 1.41)',
                transform: 'rotate(-45deg)',
                transformOrigin: 'top left',
                backgroundColor: 'alwaysBlack',
              },
              '::before': {
                content: '""',
                position: 'absolute',
                width: '1px',
                top: '1px',
                right: '0',
                height: 'calc((0.9em - 2px) * 1.41)',
                transform: 'rotate(45deg)',
                transformOrigin: 'top left',
                backgroundColor: 'alwaysBlack',
              },
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          paddingLeft: '10px',
          color: errors[input._key] ? 'white' : 'alwaysBlack',
          marginBottom: '-0.12em',
        }}
      >
        <Label htmlFor={`${uid}-${name}`} variant="label.checkbox">
          {input.description ? (
            <DefaultBlocks blocks={input.description} />
          ) : (
            input.label || input.title
          )}
          {required ? '*' : ''}
        </Label>
      </Box>
    </Flex>
  )
}

export default Checkboxes

import React from 'react'
import { Text } from "theme-ui";
import { wordsFromChildren } from "./helpers/words";

const CHAR_SPACE_MAX = 2.222
const CHAR_SPACE_MIN = 0.333
const CHAR_SPACE_DYN = CHAR_SPACE_MAX - CHAR_SPACE_MIN
// Number of characters before min space is hit
const CHAR_SPACE_THRESHOLD = 22

const WORD_GAP_MIN = 5
const WORD_GAP_MAX = 30
const WORD_GAP_DYN = WORD_GAP_MAX - WORD_GAP_MIN
const WORD_GAP_THRESHOLD = 22

function wordGapFromLength(length) {
  const wordSpacing = WORD_GAP_MAX - Math.min(WORD_GAP_DYN, length / WORD_GAP_THRESHOLD * WORD_GAP_DYN);

  return `${wordSpacing}%`
}

const ExplodedWords = ({
  children,
  variant,
  charGap,
  sx,
  as = 'h3',
  wordGap = wordGapFromLength(children.length),
}) => {
  const charSpacing = CHAR_SPACE_MAX - Math.min(CHAR_SPACE_DYN, children.length / CHAR_SPACE_THRESHOLD * CHAR_SPACE_DYN);
  const letterSpacing = `${charSpacing.toPrecision(4)}em`
  const marginRight = `-${letterSpacing}`

  const wordEls = wordsFromChildren(children).map((word, index) => {
    return (
      <span
        key={index}
        style={{
          display: 'flex',
          marginRight,
        }}
      >
        {word}
      </span>
    )
  })

  return (
    <Text
      as={as}
      variant={variant}
      sx={{
        // We don't support overriding any of the required `sx` properties
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: wordGap,
        letterSpacing,
      }}
    >
      {wordEls}
    </Text>
  )
}

export {
  ExplodedWords,
  ExplodedWords as default,
}

function objectToString(o) {
  let obj = o || {}
  let str = Object.keys(obj).join(',')
  return str
}

const stringToObject = s => {
  let str = s || ''
  let obj = {}

  if (str !== '') {
    str.split(',').forEach(function(key) {
      obj[key] = true
    })
  }
  return obj
}

const objectToArray = arr => Object.keys(arr).map(i => arr[i])

export { objectToString, stringToObject, objectToArray }

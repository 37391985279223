/** @jsx jsx */
import { jsx } from 'theme-ui'

const UTASLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 105.02"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <path
          d="M4.42,4.35h4.12v20.66c0,7.04,4.12,9.9,9.5,9.9c5.38,0,9.45-2.96,9.45-9.6V4.35h4.12v20.76
		c0,8.74-6.13,13.37-13.62,13.37c-7.49,0-13.57-3.52-13.57-12.66V4.35L4.42,4.35z"
        />
        <polygon
          points="46.04,4.35 50.16,4.35 69.71,31.69 69.71,4.35 73.73,4.35 73.73,37.87 69.61,37.87 50.06,10.94 
		50.06,37.87 46.04,37.87 46.04,4.35 	"
        />
        <polygon points="88.61,4.35 92.73,4.35 92.73,37.87 88.61,37.87 88.61,4.35 	" />
        <polygon
          points="103.38,4.35 107.76,4.35 117.56,32.04 127.35,4.35 131.58,4.35 119.47,38.12 115.45,38.12 
		103.38,4.35 	"
        />
        <polygon
          points="142.23,4.35 161.98,4.35 161.98,7.82 146.35,7.82 146.35,19.03 158.72,19.03 158.72,22.49 
		146.35,22.49 146.35,34.4 161.93,34.4 161.93,37.87 142.23,37.87 142.23,4.35 	"
        />
        <path
          d="M174.5,4.35h8.8c8.64,0,11.96,4.42,11.96,9.15c0,4.88-3.62,7.94-6.94,9.4l10.15,14.98h-4.87L184,23.75h-5.38
		v14.12h-4.12V4.35L174.5,4.35z M183.9,20.28c3.22,0,6.94-2.21,6.94-6.38c0-3.16-2.16-6.08-8.29-6.08h-3.92v12.46H183.9L183.9,20.28
		z"
        />
        <path
          d="M207.97,31.79c0,0,3.87,3.02,8.74,3.02c3.62,0,6.69-1.66,6.69-4.88c0-7.64-15.93-7.19-15.93-17.44
		c0-4.88,3.92-8.74,10.91-8.74c4.42,0,8.54,1.51,8.54,1.51l-1.06,3.87c0,0-3.72-1.71-7.64-1.71c-3.72,0-6.58,1.56-6.58,4.62
		c0,7.79,15.93,6.94,15.93,17.59c0,5.83-4.98,8.84-11.01,8.84c-5.83,0-9.7-2.81-9.7-2.81L207.97,31.79L207.97,31.79z"
        />
        <polygon points="239.99,4.35 244.11,4.35 244.11,37.87 239.99,37.87 239.99,4.35 	" />
        <polygon
          points="266.77,7.82 254.91,7.82 254.91,4.35 282.95,4.35 282.95,7.82 270.89,7.82 270.89,37.87 266.77,37.87 
		266.77,7.82 	"
        />
        <polygon
          points="301.25,23.3 289.24,4.35 294.11,4.35 303.36,19.28 312.7,4.35 317.33,4.35 305.37,23.25 305.37,37.87 
		301.25,37.87 301.25,23.3 	"
        />
        <polygon
          points="14.29,60.81 0,60.81 0,53.8 36.83,53.8 36.83,60.81 22.61,60.81 22.61,97.12 14.29,97.12 14.29,60.81 
			"
        />
        <path
          d="M57.75,53.47h7.99l16.31,43.65h-8.51l-3.5-9.74H53.26l-3.51,9.74h-8.32L57.75,53.47L57.75,53.47z M55.73,80.43
		h11.76l-5.84-16.37L55.73,80.43L55.73,80.43z"
        />
        <path
          d="M93.79,86.86c0,0,4.68,3.9,10.72,3.77c3.64,0,6.82-1.63,6.82-4.61c0-7.79-19.29-7.53-19.29-20.59
		c0-6.63,5.46-12.41,14.94-12.41c6.04,0,11.76,1.95,11.76,1.95l-1.36,7.53c0,0-5.07-2.21-10.27-2.21c-3.7,0-6.62,1.49-6.62,4.29
		c0,7.86,19.23,7.14,19.23,20.79c0,7.86-6.76,12.54-15.07,12.54c-7.86,0-13.25-3.64-13.25-3.64L93.79,86.86L93.79,86.86z"
        />
        <polygon
          points="135.04,53.8 147.05,53.8 157.52,83.87 167.97,53.8 179.8,53.8 181.36,97.12 173.23,97.12 172,63.22 
		159.98,97.12 154.53,97.12 142.64,63.28 141.53,97.12 133.48,97.12 135.04,53.8 	"
        />
        <path
          d="M209.09,53.47h7.99l16.31,43.65h-8.51l-3.5-9.74H204.6l-3.51,9.74h-8.32L209.09,53.47L209.09,53.47z
		 M207.07,80.43h11.76l-5.84-16.37L207.07,80.43L207.07,80.43z"
        />
        <polygon
          points="245.59,53.8 254.03,53.8 275.15,84.33 275.15,53.8 283.33,53.8 283.33,97.12 274.82,97.12 
		253.77,66.79 253.77,97.12 245.59,97.12 245.59,53.8 	"
        />
        <polygon points="300.6,53.8 308.98,53.8 308.98,97.12 300.6,97.12 300.6,53.8 	" />
        <path
          d="M337.5,53.47h7.99l16.31,43.65h-8.51l-3.51-9.74h-16.76l-3.51,9.74h-8.32L337.5,53.47L337.5,53.47z
		 M335.48,80.43h11.76l-5.84-16.37L335.48,80.43L335.48,80.43z"
        />
        <path
          d="M336.21,13.78c4.68,0,6.99,3.68,6.99,7.43c0,4.77-3.8,11.71-10.3,11.71c-4.68,0-6.95-3.68-6.95-7.71
		C325.95,19.64,330.27,13.78,336.21,13.78 M333.14,30.3c4.24,0,6.82-4.84,6.82-8.97c0-2.78-1.17-4.93-3.92-4.93
		c-3.72,0-6.86,3.75-6.86,8.68C329.18,28.11,330.35,30.3,333.14,30.3"
        />
        <path
          d="M346.91,15.15l2.83-1.05l0.12-0.89c0.6-4.76,2.58-10.17,8.52-10.17c2.87,0,4.48,1.09,4.48,1.09l-1.37,2.42
		c0,0-1.45-0.85-3.11-0.85c-4.04,0-5.01,4.2-5.69,8.56h4.89L357,16.81h-4.68c-1.21,9.81-2.54,23.06-9.86,25.2l-1.25-2.14
		c4.52-1.54,6.42-9.53,7.39-16.92l0.76-6.14h-2.46V15.15L346.91,15.15z"
        />
        <path
          d="M414.72,13c-0.58,0.03-1.83,0.1-2.63,0.11c-0.49,0.01-0.89-0.09-1.47-0.3c-0.21-0.08-0.43,0.08-0.43,0.32
		l-0.05,1.75c-0.01,0.37,0.33,0.68,0.68,0.76c1.36,0.32,2.98,0.13,5.16-2.14C416.36,13.1,415.21,12.98,414.72,13"
        />
        <path
          d="M499.96,23.81c0.05-0.04,0.05-0.12,0.01-0.17c-3.69-3.92-20.21-1.95-26.61-1.23
		c-7.96,0.9-14.44,1.77-16.41,1.88c-2.14,0.11-6.54,0.42-9.07-0.81c-2.88-1.41-3.7-2.45-3.92-3.88c-0.65-4.17,3-4.27,4.14-4.29
		c1.15-0.02,2.78,0.03,2.66,1.62c-0.08,1.09-1.01,1.62-1.96,2.06c-0.07,0.03-0.09,0.13-0.03,0.19c0.52,0.45,2.58,2.05,4.91,1.89
		c2.7-0.19,4.54-0.72,5.75-5.19c0.01-0.06,0.07-0.1,0.12-0.07c0.77,0.3,6.03,2.3,9.12,2.97c2.44,0.54,6.17,0.61,8.72-0.88
		c2.55-1.49,6.23-3.72,10.38-3.5c3.64,0.19,7.83,0.26,10.32,1.68c0.08,0.05,0.18-0.03,0.15-0.12c-0.5-1.67-3.83-10.87-14.9-8.32
		l-0.01,0c-2.79,0.82-3.2,1.45-5.39,1.85c-2.62,0.48-6.59,0.71-11.95-0.57c-0.09-0.03-0.12-0.16-0.03-0.21
		c1.42-0.78,8.82-4.57,16.94-3.19c0.09,0.02,0.16-0.09,0.1-0.17c-0.77-1.05-4.46-5.57-10.54-5.34c-7.01,0.27-8.59,1.81-12.94,4.14
		c-4.05,2.17-5.78,3.54-12.67,3.18c-4.3-0.23-8.9,3.2-9.8,5.83c-4.04,11.78,4.72,16.44,12.37,17.65c7.12,1.12,31.89-3.94,37.75-1.3
		c2.7,1.22,4.26,3.74,4.16,5.9c-0.05,1.01-1.22,2.78-4.59,3.52c-3.47,0.76-10.91,1.11-17.49-0.45c0,0,0,0-0.01-0.01
		c-0.14-0.08-6.06-3.38-10.61-3.67c-3.44-0.22-12.13,1.66-14.86,1.71c-1.92,0.04-5.6,0.25-7.59,0.38
		c0.09-8.88-4.72-16.17-4.97-16.56c-0.32-0.5-0.97-0.64-1.44-0.29c-0.01,0.01-0.63,0.29-1.79,0.87c1.28-2.52,0.99-5.78-0.22-7.05
		c-0.07-0.08-0.07-0.2,0-0.27c0.3-0.32,1.07-1.61,1.1-2.77c0.06-2.09-0.36-4.71-5.26-7.38c-0.31-0.17-0.7-0.04-0.84,0.31
		c-0.41,1.01-1.29,2.89-2.15,2.96c-1.85,0.15-7.4,1.36-8.82,2.11c-1.42,0.75-3.11,1.93-3.95,3.25c-0.83,1.31-1.9,1.99-3.33,2.3
		c-1.43,0.32-2,0.04-2.42,0.48c-0.42,0.44-0.7,0.88-0.54,1.74c0.12,0.65,1.29,3.12,1.92,4.25c0.48,0.85,1.21,1.79,1.97,2.33
		l-1.87,0.03c-0.53,0.01-1.64-0.11-1.65-0.11c-0.24-0.03-0.45,0.1-0.56,0.32c-0.11,0.22-0.08,0.48,0.06,0.67
		c0.52,0.67,1.21,1.32,3.41,1.32c0.07,0,0.14,0,0.21,0l1.77-0.03c-0.26,0.32-0.53,0.66-0.68,0.84c-0.17,0.23-1.46,1.51-0.52,3.5
		c0.75,1.58,1.74,2.15,1.74,2.15c1.28-0.9,4.03-2.11,5.17-1.3c-0.22,0.24-0.43,0.48-0.67,0.73c-2.31,2.53-5.36,5.7-7.08,9.68
		c-2.11-0.77-7.29-2.81-9.69-5.15c-0.73-0.71-1.39-2.68-0.94-4.17c0.41-1.31,0.7-2.58-0.24-4.7c-0.86-1.93-3.42-3.83-3.42-3.83
		c0.34-2.31,0.78-5.37,0.96-6.3c2.13,0.21,4.2-0.33,4.88-1.38c0.68-1.04,0.9-3.08,1.25-3.56c0.35-0.47,1.08-0.3,1.52-0.34
		c0.97-0.09,2.14-0.11,3.3-1.18c1.29-1.18,2.23-2.86,3.34-3.02c1.62-0.23,2.49-0.73,3.24-1.38c0.94-0.81,2.95-3.22,6.59-3.13
		c1.01,0.02,1.55-1.03-0.31-1.44c-2.57-0.56-5.38-0.06-6.9,0.52c-2.07,0.78-2.9,0.08-3.78-0.18c-1.36-0.41-2.7-0.28-4.69,0.48
		c-1.65,0.64-4.64,1.05-6.32,0.49c-2.46-0.82-4.52-1.16-7.68-0.4c-2.78,0.67-4.69,2.2-5.59,4.52c-0.84,2.17-0.17,3.8-0.01,4.21
		c0.16,0.41,0.56,1.03,0.69,1.23c-0.08,1.82-0.34,7.84-0.35,8.54c-0.36,0.53-0.53,1.28-0.59,1.55c-0.07,0.29-0.08,0.59-0.05,0.88
		c-0.1,0-0.21,0-0.32,0c-0.63,0.06-1.33,0.35-1.86,0.9l-0.2,0.2l-0.16,0.23c-0.1,0.15-0.23,0.31-0.29,0.47l-0.22,0.49
		c-0.07,0.17-0.12,0.4-0.17,0.6c-0.08,0.42-0.07,0.89,0.02,1.3c0.09,0.41,0.26,0.78,0.44,1.08c0.32,0.52,0.69,0.89,1.08,1.2
		c-0.17,0.17-0.32,0.36-0.46,0.58c-0.3,0.49-0.5,0.98-0.57,1.69c-0.05,0.69,0.17,1.38,0.47,1.85c0.3,0.49,0.64,0.84,0.99,1.13
		c0.33,0.27,0.78,0.58,1.11,0.77c-0.88,24.8-0.95,25.81,1.63,26.6c0.1,0.03,0.2,0.03,0.3,0.03c2.99,0,3.82-6.68,5.55-18.28
		c0.25-1.67,0.43-3.18,0.64-4.45c2.13,3.95,6.97,12.22,14,18.62c0.38,0.34,0.97,0.14,1.1-0.38c0.8-3.24,1.36-4.35,1.67-4.88
		c0.51,1.07,1.23,2.4,1.91,3.33c0.97,1.34,2.16,2.66,3.58,3.95c0.01,0,0.01,0.01,0.01,0.01c-2.13,2.04-7.43,6.71-12.88,8.37
		c-0.05,0.02-0.1-0.01-0.12-0.06c-0.2-0.44-1.15-2.53-2.32-3.62c-1.31-1.23-2.42-2.52-7.47-1.46c0,0-1.34,0.29-2.82,0.93
		c-0.6,0.25-0.45,1.19,0.19,1.22c0.61,0.03,1.17,0.08,1.44,0.16c0.05,0.01,0.08,0.06,0.07,0.12c-0.04,0.29-0.14,1.23,0.4,2.13
		c0.03,0.05,0.02,0.11-0.02,0.14c-0.58,0.42-4.06,3.2-3.42,8.9c0.05,0.39,0.55,0.46,0.68,0.09c0.27-0.79,0.57-1.64,0.59-1.61
		c0.29,0.51,1.57,2.61,3.59,2.91c0.05,0.01,0.09,0.06,0.09,0.11c-0.08,0.61-0.37,3.86,2.1,6.98c0.3,0.38,0.89,0.12,0.85-0.37
		c-0.03-0.44-0.05-0.9-0.01-1.23c0.01-0.08,0.09-0.12,0.16-0.07c0.41,0.29,1.34,0.63,2.59-0.16c1.15-0.73,2.59-2.24,3.75-4.12
		c0.04-0.06,0.12-0.06,0.17-0.01c0.92,1.17,2.36,1.27,4.01,1.27c2.78,0,5.07-1.47,5.07-4.2c0-0.07-0.01-0.13-0.02-0.2
		c6.23,0.63,10.04-0.84,14.87-3.91c4.43-2.81,6.68-4.77,6.33-7.27c-0.16-1.15-0.63-2.36-1.43-3.06c7.65-0.16,11.59-5.29,13.03-7.63
		c0.07-0.1,0.22-0.03,0.19,0.09c-0.49,2.14-0.53,6.15,0.62,8.29c1.71,3.18,5.87,7.98,6.47,9.99c0.61,2.01,0.56,7.06-1.69,9.85
		c-2.21,2.74-2.79,3.21-4.44,4.22c-0.03,0.02-0.08,0.02-0.11-0.01c-0.42-0.38-3.39-2.96-6.31-3.44c-3.02-0.5-12.47-2.62-12.95,7.67
		c-0.02,0.5,0.57,0.75,0.87,0.36c0.64-0.81,1.48-1.78,2.05-1.98c0.02-0.01,0.04-0.01,0.07,0c0.2,0.11,1.32,0.7,1.85,0.82
		c0.07,0.02,0.1,0.11,0.05,0.17c-1.58,2.1-3.05,5.36-0.11,9.05c0.26,0.33,0.77,0.11,0.73-0.31c-0.04-0.59-0.06-1.29,0.02-1.9
		c0.01-0.1,0.14-0.12,0.2-0.04c1.08,1.56,3.6,2.12,5.94,1.1c0.06-0.03,0.13,0.01,0.14,0.08c0.09,0.62,0.71,3.3,4.74,6.31
		c0.41,0.3,0.95-0.13,0.77-0.63c-0.28-0.75-0.52-1.59-0.47-2.06c0.01-0.05,0.05-0.08,0.1-0.09c1.55-0.01,3.92-0.8,4.88-2.98
		c0.88-2,1.29-3.86,1.35-4.16c0.01-0.03,0.02-0.05,0.05-0.07l3.53-2.29c0.06-0.03,0.13-0.01,0.16,0.05
		c0.27,0.66,0.56,1.39,1.16,1.57c0.06,0.02,0.09,0.07,0.08,0.12c-0.09,0.34-0.39,1.47-0.8,2.47c-0.14,0.35,0.23,0.67,0.52,0.46
		c2.32-1.7,6.98-6,4.83-10.65c7.56-5.95,12.2-13.27,12.95-17.67c0.11-0.63-0.36-1.21-0.97-1.23c-4.83-0.15-7.43-1.45-7.07-5.84
		c0.01-0.07,0.06-0.12,0.12-0.1c1.23,0.34,12.09,3.22,19.36,1.13c0.44-0.12,0.91,0.1,1.13,0.53c0.77,1.5,2.42,4.87,2.89,6.83
		c0.6,2.51,0.12,3.83,0.09,3.91c0,0.01,0,0.01-0.01,0.01c-0.42,0.58-1.2,1.15-3.26,2.49c-5.18,3.35-4.65,7.59,0.08,12.43
		c0.23,0.24,0.62,0,0.53-0.34c-0.28-1.01-0.62-2.38-0.63-2.9c0-0.07,0.07-0.11,0.13-0.1c0.71,0.19,1.24-0.11,1.63-0.28
		c0.06-0.03,0.13,0.02,0.14,0.09c0.08,0.62,0.63,3.18,4.25,4.86c0.19,0.09,0.39-0.1,0.31-0.31c-0.28-0.74-0.76-2.07-0.81-2.61
		c-0.01-0.07,0.05-0.12,0.11-0.12c0.82,0.04,3.73,0,4.38-3.86c0.01-0.07,0.07-0.11,0.13-0.08c0.69,0.26,4.62,1.54,7-1.56
		c0.11-0.14-0.01-0.36-0.18-0.32c-0.64,0.15-1.73,0.36-2.29,0.15c-0.06-0.02-0.08-0.09-0.04-0.16c0.38-0.65,2.19-4.33-2.28-8.29
		c-0.03-0.03-0.05-0.08-0.03-0.12c2.91-9.08,2.34-19.05-0.96-25.01c-0.26-0.48-0.8-0.7-1.29-0.52c-5.63,2.03-14.86,2.89-15.99-2.16
		c-0.02-0.08,0.05-0.15,0.12-0.14c0.82,0.1,4.24,0.72,7.86,0.32c2.19-0.25,5.57-0.6,8.87-1.62c3.3-1.02,5.99-2.3,7.29-5.71
		c0.89-2.35,0.87-7.3-1.85-10.85c-0.04-0.05-0.03-0.11,0.01-0.15L499.96,23.81L499.96,23.81z M383.42,14.34
		c1.85,1.23,3.77,1.63,5.49,2.04c0,0-0.64,5.17-0.73,5.12c-0.27-0.16-0.52-0.29-0.78-0.43c-0.7-0.37-1.4-0.71-2.16-1
		c-0.37-0.14-0.82-0.3-1.27-0.38c-0.25-0.04-0.51-0.07-0.8-0.06L383.42,14.34L383.42,14.34z M382.6,8.06
		c0.65-2.74,3.34-3.43,4.36-3.7c2.32-0.62,4.52-0.36,6.39,0.33c1.87,0.69,4.81,0.18,6.38-0.43c1.56-0.6,2.75-0.92,3.58-0.63
		c1.14,0.41,1.96,0.45,2.63,0.46c1.2,0.03,2.95-0.55,3.07-0.55c0,0-1.93,1.61-3.15,1.56c-1.92-0.07-3.27,1.92-3.82,2.94
		c-0.55,1.02-1.56,1.65-3.89,1.71c-3.04,0.08-3.2,2.47-3.47,3.49c-0.27,1.01-1.26,1.59-3.42,1.32c-3.49-0.44-4.7-1.09-6.19-1.84
		C383.06,11.7,382.16,9.89,382.6,8.06 M378.87,26.06c0.45-1.46,2.26-1.04,3.27-0.78c0.23,0.06,0.4-0.19,0.3-0.41
		c-0.3-0.61-0.58-1.65-0.14-2.32c0.74-1.14,2.94,0.05,3.96,0.55c1.02,0.51,3.34,1.72,2.81,3.06c-0.26,0.82-1.04,1.07-1.6,1.15
		c-0.22,0.03-0.32,0.32-0.16,0.49c0.57,0.63,1.46,1.78,1.07,2.74c-0.41,1.03-1.87,0.87-2.79,0.75c-0.26-0.03-0.4,0.29-0.23,0.49
		c0.54,0.61,1.23,1.63,0.95,2.65c-0.43,1.56-2.87,0.44-4.16-0.12c-1.28-0.56-2.84-1.63-2.61-2.78c0.15-0.75,1.09-1.22,1.74-1.45
		c0.22-0.08,0.25-0.39,0.05-0.52C380.34,28.93,378.43,27.47,378.87,26.06 M382.06,59.15c-0.47-0.02-0.39-3.44-0.23-8.72
		c0.18-5.66,0.57-12.25,0.62-13.64c0.58,0.22,1.12,0.42,1.79,0.59c0.41,0.09,0.85,0.16,1.35,0.14c0.15-0.01,0.1,0.01,0.26-0.03
		c-0.28,1.51-0.54,3.57-0.9,5.97c-0.26,1.77-0.68,4.48-1.07,7.16C383.24,54.97,382.44,59.16,382.06,59.15 M408.16,54.76
		c-0.88-1.25-1.54-2.49-1.98-3.75c-0.16-0.44-0.36-1.17-0.52-1.81c-0.06-0.24-0.34-0.32-0.52-0.16c-0.72,0.68-1.28,1.43-1.82,2.44
		c-0.03,0.06-0.06,0.11-0.07,0.16c-0.58,1.01-0.98,2.11-1.34,3.38c-0.99-0.79-2.04-1.87-3.1-3.11c0.03-0.16,0.05-0.34,0.07-0.52
		c0.05-0.32,0.11-0.64,0.18-0.96c0.13-0.65,0.3-1.3,0.5-1.94l0-0.01c0.04-0.13,0.01-0.29-0.09-0.39c-0.14-0.14-0.37-0.14-0.51,0
		c-0.48,0.51-0.91,1.08-1.3,1.68c-0.09,0.14-0.17,0.29-0.25,0.43c-0.57-0.73-1.14-1.49-1.7-2.27c0.02-0.13,0.02-0.27,0.04-0.41
		c0.03-0.3,0.08-0.59,0.14-0.89c0.11-0.59,0.27-1.2,0.44-1.81l0-0.01c0.04-0.13,0.01-0.28-0.09-0.38c-0.14-0.15-0.37-0.15-0.51,0
		c-0.44,0.47-0.86,0.98-1.23,1.55c-0.03,0.04-0.05,0.08-0.07,0.12c-3.11-4.55-5.74-9.18-6.48-10.51c0.04-0.13,0.08-0.26,0.12-0.38
		c0.16-0.6,0.12-1.23-0.06-1.72c-0.02-0.06-0.05-0.11-0.07-0.17c0.17-0.02,0.35-0.05,0.52-0.11c0.45-0.15,0.89-0.47,1.19-0.88
		c0.3-0.41,0.49-0.85,0.59-1.3c0.1-0.45,0.14-0.99,0.02-1.5c-0.1-0.44-0.3-0.82-0.54-1.12c0.44-0.29,0.78-0.72,0.99-1.15
		c0.06-0.12,0.12-0.26,0.17-0.38c0.04-0.11,0.09-0.24,0.13-0.39c0.36,0.29,0.71,0.67,0.99,1.24c0.78,1.63-0.13,4.31-0.26,5.33
		c-0.13,1.01,0.33,2.44,1.06,3.44c0.69,0.94,6.76,4.46,12.42,6.82l0.84,0.36c1.3-4.44,4.77-8.13,7.29-10.89
		c0.58-0.64,1.13-1.24,1.61-1.8l0.59-0.72l-0.83-0.76c-0.99-1.16-2.3-1.52-3.04-1.5c-1.29,0.02-3.67,0.96-3.67,0.96
		c-1.17-1.28,0.63-2.85,1.45-3.63c1,0,2.38-0.11,2.97-0.15c0.25-0.02,0.59-0.04,0.89-0.05c0.35-0.02,0.57-0.42,0.39-0.75
		c-0.31-0.58-0.75-1.36-1.09-1.78c-0.59-0.71-2.01-0.1-4.3-0.35c-3.3-0.37-4.63-5.94-4.63-5.94c0.86,0.13,2.51-0.12,3.92-1.01
		c1.42-0.89,2.1-2.92,3.79-4.11c1.7-1.19,5.55-1.54,8.41-1.74c2.69-0.18,3.32-1.98,3.97-3.29c0.07-0.14,0.24-0.21,0.37-0.12
		c3.37,2.19,2.87,5.11,1.97,6.36c-0.18,0.25-0.45,0.63-0.74,0.95c-0.08,0.09-0.06,0.25,0.05,0.3c0.56,0.3,0.86,0.64,1.17,1.58
		c0.53,1.57,0.32,3.37-0.21,4.39c-0.73,1.4-2.06,1.98-3.24,2.43c-0.19,0.08-0.46,0.19-0.76,0.32c-0.81,0.36-0.61,1.63,0.26,1.7h0.01
		c0.49,0.04,0.62,0.06,0.83,0.03v0.02c2.93,0.11,5.18-0.93,6.53-1.7c0.88,1.64,2.8,5.36,3.66,9.94c-0.33-0.33-0.69-0.63-1.06-0.91
		c-0.8-0.59-1.66-1.05-2.54-1.41c-0.89-0.35-1.8-0.6-2.71-0.72c-0.25-0.03-0.5,0.11-0.6,0.37c-0.12,0.31,0.01,0.67,0.31,0.8l0,0
		c0.8,0.36,1.58,0.72,2.31,1.16c0.73,0.43,1.42,0.92,2.04,1.46c0.62,0.55,1.18,1.16,1.67,1.84c0.25,0.33,0.48,0.69,0.68,1.05
		l0.29,0.57c0.01,0.02,0.02,0.03,0.03,0.05c0.02,2.33-0.33,4.76-1.26,7.19c-0.05,0.12-0.1,0.24-0.15,0.37
		c-0.08-0.24-0.15-0.47-0.24-0.71c-0.42-1.12-0.97-2.23-1.69-3.21c-0.72-0.98-1.61-1.83-2.61-2.47c-1-0.63-2.1-1.06-3.2-1.26
		c-0.26-0.05-0.52,0.1-0.63,0.36c-0.12,0.31,0.01,0.67,0.31,0.8h0c0.95,0.42,1.83,0.92,2.59,1.57c0.76,0.63,1.42,1.39,1.95,2.24
		c0.54,0.85,0.96,1.78,1.29,2.77c0.18,0.49,0.32,1,0.44,1.52c0.06,0.26,0.13,0.52,0.18,0.78c0.02,0.1,0.07,0.2,0.13,0.3
		c-1,1.51-2.31,3.14-3.86,4.75c-0.03-0.2-0.06-0.4-0.09-0.61c-0.05-0.32-0.11-0.65-0.19-0.97c-0.29-1.28-0.72-2.55-1.35-3.72
		c-0.63-1.17-1.46-2.24-2.44-3.08c-0.99-0.85-2.1-1.49-3.26-1.9c-0.25-0.09-0.54,0.02-0.68,0.27c-0.16,0.29-0.07,0.66,0.21,0.83l0,0
		c0.97,0.6,1.86,1.28,2.61,2.1c0.75,0.81,1.36,1.74,1.82,2.75c0.46,1.01,0.79,2.1,1.01,3.24c0.06,0.28,0.11,0.57,0.15,0.86
		c0.04,0.29,0.09,0.57,0.11,0.87c0.03,0.29,0.07,0.58,0.08,0.88c0.01,0.12,0.04,0.23,0.09,0.34c-1.26,1.14-2.61,2.24-4.04,3.23
		c-0.07-0.04-0.14-0.07-0.2-0.13c-0.25-0.14-0.47-0.31-0.7-0.47c-0.24-0.16-0.46-0.33-0.68-0.5c-0.22-0.17-0.45-0.34-0.65-0.53
		c-0.86-0.71-1.64-1.5-2.29-2.38c-0.65-0.88-1.17-1.86-1.51-2.93c-0.36-1.06-0.49-2.22-0.49-3.4v-0.01c0-0.28-0.19-0.53-0.46-0.59
		c-0.31-0.07-0.62,0.14-0.68,0.47c-0.26,1.28-0.27,2.64-0.05,3.98c0.24,1.34,0.74,2.64,1.42,3.79c0.68,1.16,1.55,2.16,2.46,3.07
		c0.23,0.23,0.47,0.44,0.71,0.65c0.21,0.19,0.43,0.37,0.65,0.55c-1.65,0.94-3.36,1.72-5.09,2.25l-0.25-0.21
		c-0.66-0.52-1.27-1.04-1.85-1.56C410.31,57.43,409.11,56.1,408.16,54.76 M464.6,69.83c-0.79,2.12-1.88,4.11-3.13,5.95
		c0-0.01-0.01-0.02-0.01-0.02c-0.33-0.53-0.69-1.01-1.1-1.43c-0.41-0.42-0.84-0.79-1.29-1.12c-0.46-0.33-0.91-0.61-1.43-0.87
		c-0.23-0.11-0.5-0.05-0.66,0.17c-0.19,0.25-0.14,0.61,0.09,0.81l0.04,0.03c0.35,0.29,0.72,0.65,1.03,1.02
		c0.32,0.37,0.61,0.76,0.86,1.16c0.25,0.4,0.44,0.83,0.59,1.25c0.14,0.41,0.2,0.83,0.24,1.24c-0.73,0.91-1.48,1.77-2.25,2.59
		c-0.05-0.1-0.1-0.21-0.16-0.31c-0.31-0.55-0.66-1.06-1.05-1.49c-0.39-0.44-0.81-0.83-1.26-1.18c-0.45-0.35-0.89-0.65-1.4-0.93
		c-0.22-0.11-0.49-0.07-0.66,0.13c-0.2,0.24-0.18,0.6,0.05,0.81l0.03,0.03c0.33,0.31,0.68,0.7,0.97,1.08
		c0.3,0.39,0.56,0.8,0.78,1.22c0.22,0.42,0.38,0.86,0.49,1.28c0.12,0.43,0.14,0.85,0.15,1.25v0.01c0,0.05,0.01,0.1,0.02,0.15
		c-1.22,1.16-2.42,2.2-3.51,3.09c-0.04,0.03-0.05,0.08-0.03,0.12c1.52,4.17-1.07,8.07-2.39,4.3c-0.3-0.86-0.59-0.71-1.15-0.27
		c-0.53,0.42-5.3,3.71-5.88,4.11c-0.04,0.03-0.05,0.07-0.04,0.11c0.56,3.13-2.53,5.96-4.61,5.76c-1.72-0.17-2.61-2.25-2.18-5
		c0.06-0.37-0.34-0.62-0.62-0.39c-1.82,1.54-5.18,3.48-7.32,0.39c-1.01-1.46,0.94-3.85,2.02-4.99c0.25-0.26,0.1-0.7-0.25-0.75
		c-1.65-0.24-4.9-0.94-3.59-2.6c1.22-1.54,3.53-1.3,5.24-1.16c1.92,0.15,5.94,0.73,9.28,4.45c0.03,0.04,0.09,0.05,0.13,0.02
		c0.52-0.31,3.66-2.25,5.01-3.8c1.54-1.75,3.81-3.09,3.95-11.32c0.07-3.9-3.4-7.08-5.47-10.15c-2.06-3.05-3.24-5.65-1.77-10.63
		c0.01-0.05,0.05-0.08,0.1-0.08c0.26-0.01,1.32-0.04,4.6-0.1c0.03,0,0.06,0.01,0.08,0.04c0.38,0.45,4.19,4.87,9.47,6.98
		c0.04,0.02,0.07,0.06,0.07,0.11C456.59,61.81,456.02,69.83,464.6,69.83 M473.95,50.19c3.3,2.13,9.53,1.85,15.45,0.05
		c0.05-0.02,0.1,0.01,0.13,0.06c0.83,1.87,1.42,3.84,1.79,5.89c-0.57-0.32-1.16-0.61-1.76-0.85c-0.62-0.25-1.26-0.45-1.91-0.59
		c-0.64-0.16-1.31-0.21-1.96-0.25c-0.16-0.01-0.31,0.1-0.36,0.27c-0.05,0.2,0.05,0.4,0.24,0.46l0.02,0
		c0.59,0.18,1.16,0.38,1.71,0.65c0.55,0.26,1.08,0.56,1.58,0.9c0.51,0.33,1,0.69,1.46,1.09c0.46,0.38,0.9,0.85,1.33,1.16l0.01,0
		c0.09,1.16,0.12,2.34,0.07,3.55c-0.16-0.09-0.32-0.19-0.48-0.28c-0.59-0.34-1.19-0.63-1.82-0.88c-0.62-0.26-1.26-0.45-1.91-0.6
		c-0.64-0.16-1.31-0.21-1.96-0.25c-0.16-0.01-0.31,0.1-0.35,0.27c-0.06,0.2,0.05,0.4,0.24,0.46l0.02,0.01
		c0.59,0.18,1.16,0.38,1.71,0.65c0.55,0.26,1.08,0.56,1.58,0.9c0.51,0.33,1,0.7,1.46,1.09c0.45,0.37,0.88,0.83,1.3,1.14
		c-0.31,2.63-0.92,5.34-1.85,8.1c0,0,0,0.01,0,0.01c-0.09,0.22-0.19,0.45-0.3,0.7c3.72,1.99,4.61,7.16,1.73,7.68
		c-0.87,0.16-1.75-0.06-2.32-0.49c-0.21-0.15-0.49-0.06-0.55,0.2c-0.31,1.47-1.33,4.38-3.38,4.38c-1.7,0-2.23-1.33-2.26-2.59
		c-0.01-0.39-0.46-0.57-0.7-0.28c-1.04,1.29-2.08,1.78-2.95,1.2c-1.12-0.76-1.31-1.98-0.89-2.85c1.24-2.62,4.95-3.6,6.44-5.55
		c0.01-0.01,0.01-0.01,0.01-0.02c1.83-3.25-1.19-9.84-3.4-14.54c-0.23-0.5-0.76-0.74-1.27-0.6c-2.04,0.6-7.54,1.85-14.16,0.57
		c-8.95-1.73-13.84-5.06-16.84-8.87c-0.02-0.02-0.04-0.04-0.05-0.07c-0.2-0.25-0.38-0.5-0.57-0.76c-0.01-0.01-0.02-0.02-0.02-0.02
		c-0.83-1.15-1.3-2-1.77-2.67c-0.09-0.12-0.21-0.22-0.36-0.24c-0.89-0.16-0.36,2.26-0.27,2.96c0.01,0.06-0.03,0.12-0.09,0.13
		c-1.79,0.37-3.89,0.09-6.22,0.19c-0.03,0-0.06,0.02-0.08,0.05c-3.39,4.26-4.55,7.28-14.22,9.04c-1.35,0.25-1.24,1.12-0.66,1.57
		c0.55,0.43,1.33,1.69,1.56,3.32c0.1,0.71-0.48,1.64-3.49,3.7c-0.07-0.18-0.15-0.36-0.23-0.53c-0.28-0.57-0.62-1.09-1.02-1.54
		c-0.39-0.45-0.82-0.85-1.28-1.19c-0.47-0.34-0.93-0.62-1.48-0.86c-0.22-0.1-0.49-0.03-0.64,0.19c-0.18,0.26-0.12,0.62,0.12,0.8
		l0.05,0.04c0.34,0.27,0.71,0.62,1.01,0.98c0.31,0.36,0.57,0.76,0.78,1.17c0.21,0.41,0.37,0.83,0.46,1.26
		c0.09,0.35,0.11,0.72,0.11,1.07c-1.29,0.81-2.49,1.47-3.6,2c-0.07-0.29-0.16-0.57-0.27-0.84c-0.23-0.59-0.54-1.14-0.89-1.63
		c-0.36-0.48-0.76-0.92-1.19-1.3c-0.44-0.38-0.87-0.69-1.4-0.98c-0.22-0.12-0.49-0.07-0.66,0.13c-0.2,0.24-0.17,0.6,0.05,0.81
		l0.05,0.04c0.32,0.29,0.66,0.68,0.93,1.06c0.28,0.39,0.5,0.81,0.68,1.23c0.18,0.42,0.3,0.86,0.36,1.29
		c0.07,0.38,0.05,0.76,0.03,1.13c-4.46,1.59-7.18,0.87-8.63,0c-0.01,0-0.01-0.01-0.01-0.01c-0.51-0.39-0.77-0.55-1.02-0.68
		c-0.37-0.2-0.83-0.08-1.07,0.28c-0.22,0.32-0.21,0.75,0.02,1.06c0.19,0.26,0.43,0.6,0.65,0.98c0.25,0.41,0.39,0.88,0.39,1.36
		c0,1.53-1.37,2.02-3.01,2.02c-1.64,0-2.94-0.49-2.94-2.02v-0.81c0-0.42-0.36-0.76-0.75-0.68c-0.33,0.07-0.62,0.28-0.8,0.59
		l-0.51,0.89c0,0.01,0,0.01-0.01,0.01c-0.07,0.18-2.72,6.07-5.42,4.72c-1.79-0.9-0.98-3.03,0.01-4.64c0.28-0.44-0.15-0.99-0.62-0.8
		c-0.85,0.33-1.8,0.48-2.64,0.01c-1.68-0.93-2.23-2.8-1.64-3.95c0.52-1.02,1.25-2.21,2.47-3.21c0.25-0.21,0.26-0.61,0.03-0.84
		c-0.6-0.62-0.91-1.12-0.58-1.83c0.41-0.87,1.28-1.22,2.96-0.91c1.9,0.33,2.95,2.45,3.53,4.8c0.21,0.84,1.01,1.36,1.81,1.16
		l0.02-0.01c6.52-1.62,11.31-6.25,15.17-9.44c0.12,0.1,0.23,0.19,0.35,0.29l1.05,0.86l0.49-0.14c9.65-2.69,18.66-12.36,20.88-18.11
		c0.74-1.93,1.16-3.84,1.34-5.71c13.49-0.4,13.27-0.75,19.29-1.83c4.67-0.84,10.92,1.92,14,5.02
		C471.61,44.37,471.53,48.62,473.95,50.19 M496.62,35.14c-0.24,3.48-1.91,5.8-5.63,6.86c-3.72,1.06-10.57,2.69-17.72,1.47
		c-0.5-0.08-1.08-1.69-1.27-2.24c-0.03-0.08,0.03-0.16,0.11-0.15c6.46,1.13,16.1,0.07,19.06-1.8c3.92-2.48,2.62-9.36-2.56-11.36
		c-7.59-2.94-23.81,0.98-34.85,1.14c-4.05,0.06-8.39,0.01-13.19-4.24c-3.06-2.97-3.29-8.02-1.21-11.52c0,0,0-0.01,0.01-0.01
		c2.09-3.06,5.9-4.25,9.32-3.99c9.03,0.71,11.64-5.27,19.37-6.9c7.47-1.57,10.85,0.6,11.8,1.4c0.08,0.07,0.02,0.22-0.08,0.2
		c-1.31-0.23-5.98-0.76-11.8,1.63c-2.15,0.88-4.13,2.42-5.79,3.72c-0.07,0.06-0.04,0.18,0.04,0.2c3.89,0.99,4.34,1.82,8.61,2.17
		c10.18,0.82,12.58-3.58,17.84-2.48c4,0.83,5.86,3.22,6.37,3.98c0.06,0.08-0.02,0.2-0.12,0.17c-15.67-3.76-14.1,3.1-22.49,3.72
		c-5.08,0.38-12.48-3.99-14.59-3.65c-0.05,0.01-0.08,0.05-0.09,0.1c-0.08,0.79-0.83,6.79-5.65,5.49c-0.09-0.02-0.11-0.14-0.04-0.2
		c1.86-1.77,1.13-4.45-1.53-5.25c-1.7-0.5-3.84-0.37-5.59,0.32c-2.92,1.15-4.06,5.27-2.3,7.83c2.64,3.85,6.7,4.78,12.53,4.39
		c6.05-0.4,10.01-0.86,18.45-2.02c2.49-0.34,7.28-0.84,11.87-1.01c-0.28,0.49-0.56,0.99-0.83,1.49c-0.06,0.11-0.06,0.26,0.01,0.38
		c0.1,0.18,0.32,0.24,0.49,0.13l0.02-0.02c0.49-0.31,0.97-0.63,1.43-0.95l0.69-0.49c0.22-0.16,0.47-0.3,0.62-0.56l0.03-0.05
		c0.93-0.01,1.82,0,2.67,0.04l-0.1,0.14c-0.38,0.57-0.73,1.17-1.02,1.8c-0.06,0.12-0.05,0.28,0.04,0.4c0.12,0.17,0.35,0.2,0.5,0.07
		l0.01-0.01c0.49-0.4,1-0.76,1.52-1.11l0.79-0.53c0.24-0.17,0.51-0.33,0.71-0.59c1.52,0.19,2.68,0.51,3.27,0.99l-3.3,2.76
		C492.75,27.33,496.96,30.29,496.62,35.14"
        />
      </g>
    </svg>
  )
}

export { UTASLogo }

/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'

const PreviewAlert = ({ preview }) => {
  return preview ? (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        zIndex: 99999999,
        bottom: 0,
        left: 0,
        py: 1,
        backgroundColor: '#888',
        textAlign: 'center',
      }}
    >
      <Text
        as="span"
        variant="copy"
        sx={{
          textTransform: 'uppercase',
          letterSpacing: '.333em'
        }}
      >
        <a href="/api/exit-preview">Exit preview mode</a>
      </Text>
    </Box>
  ) : (
    <></>
  )
}

export { PreviewAlert }

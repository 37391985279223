/** @jsx jsx */
import { MenuPage } from 'components/global/menu/MenuPage'
import { Line } from 'components/Line'
import { Fragment } from 'react'
import { jsx, Text, Box, Button } from 'theme-ui'
import Link from 'next/link'
import { LockedWord } from 'components/LockedWord'
import { MenuMap } from './MenuMap'

const MenuItems = ({ menuItems = [], parent }) => {
  return menuItems.map((item, i) => {
    switch (item._type) {
      case 'menuPage':
        return <MenuPage item={item} key={i} i={i} parent={parent} />
      case 'mapPageLink':
        return (
          <MenuMap item={item} key={i} i={i} />
        )
      case 'mapPagePDF':
        return (
          <MenuMap item={item} key={i} i={i} />
        )
      case 'internalLink':
        return (
          <Box
            key={i}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Text
              variant="largeHeaderReverse"
              sx={{
                my: 2,
              }}
            >
              {item.title}
            </Text>
          </Box>
        )
      case 'externalLink':
        return (
          <Box
            key={i}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Text
              variant="largeHeaderReverse"
              sx={{
                my: 2,
              }}
            >
              {item.title}
            </Text>
          </Box>
        )
      default:
        return <Fragment key={i} />
    }
  })
}

export { MenuItems }

{
  /* <Box key={i}>
  <Text variant="largeHeaderReverse">{item.title}</Text>
  <Line color="invertedBlack" />
  </Box> */
}

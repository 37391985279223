/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'

const ColormodeIcon = ({
  iconColor = 'grey',
  show = 'default',
  size = '1em',
  ...props
}) => {
  const [colorMode, setColorMode] = useColorMode()
  const fillColor = colorMode === 'dark' ? 'none' : 'iconColor'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      sx={{
        display: 'inline-block',
        stroke: iconColor,
        fill: fillColor,
        fontSize: size,
        padding:'0px',
        margin:'0px',
      }}
      {...props}
    >
      <circle cx="50" cy="50" r="40"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
    </svg>
  )
}

export { ColormodeIcon }

/** @jsx jsx */
import { jsx, Text, Label, Box } from 'theme-ui'

const InputWrapper = allProps => {
  let { input, label, hideLabel, confirm, other, errors = [], ...props } = allProps

  let id = input ? input._key : null
  let uniqueId = allProps.uniqueId + '-' + id

  if (confirm && input._type == 'emailInput') {
    label = (input.confirmEmailText || 'Email, again') + '*'
    id = 'confirm-' + id
    uniqueId = 'confirm-' + uniqueId
  }

  if (other) {
    label = input.otherLabel + (input.required ? '*' : '') || label
    hideLabel = true
    id = 'other-' + id
    uniqueId = 'other-' + uniqueId
  }

  return (
    <>
      {label ? (
        <Label
          variant={hideLabel ? 'label.hidden' : 'label.visible'}
          htmlFor={uniqueId}
        >
          {label}
        </Label>
      ) : null}
      {errors[id] ? (
        <Text role="alert" variant="label.hidden">
          {errors[id].message || errors[id].type == 'validate'
            ? 'Confirmation must match'
            : ''}
        </Text>
      ) : null}
      <Box
        sx={{
          pt: label && (!hideLabel || other) ? ['13px'] : '0',
          pb: input.removePadding ? 0 : !hideLabel ? ['26px'] : '0',
          width: '100%',
        }}
      >
        {props.children}
      </Box>
    </>
  )
}

export default InputWrapper

/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { Line } from 'components/Line'
import DefaultBlocks from 'components/sanity/DefaultBlocks'
import { useRouter } from 'next/router'
import { useRef, useEffect } from 'react'
import { jsx, Text, Box, Flex } from 'theme-ui'
import { pageMatchesRoute, pageMatchesURL } from 'components/global/menu/helpers/menuRoutes'
import { RenderIf } from 'components/RenderIf'
import { useBreakpointIndex } from '@theme-ui/match-media'

// TODO: may need to rebuild with https://codesandbox.io/s/animate-height-framer-motion-yn59l?file=/src/AnimateHeight.js:560-575
const Accordion = ({
  item,
  i,
  parentRoute = '',
  title = 'open',
  titleVariant = 'largeHeaderReverse',
  children,
  hrHeight = '1px',
  mt = '0px',
}) => {
  const bp = useBreakpointIndex({ defaultIndex: 2 })
  const width = item.content[0]._type === 'partnersBlock' || bp <= 1 ? '100%' : '50%'
  const buttonRef = useRef()
  const router = useRouter()

  const scrollInView = () => {
    if(isOpen) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline:'nearest' })
    }
  }

  const slug = item && item.slug ? item.slug : undefined

  const regExp = new RegExp(`^\/${parentRoute}\/${slug}($|\/){1}`, 'gm');
  const isOpen = pageMatchesRoute(router, regExp)


  const handleClick = () => {
    if (isOpen && parentRoute) {
      router.push(`/${parentRoute}`, undefined, {
        scroll: false,
        shallow: true,
      })
    }
    if (!isOpen && parentRoute && slug)
      router.push(`/${parentRoute}/${slug}`, undefined, {
        scroll: false,
        shallow: true,
      })
  }

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (url === `/${parentRoute}/${slug}`) {
        //setIsOpen(true)
      }
    }
    scrollInView()

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [slug, router.events])

  return (
    <Box
      ref={buttonRef}
      sx={{
        display: 'block',
      }}
    >
      <Button
        aria-expanded={isOpen}
        sx={{
          width: '100%',
          display: 'block',

          '&:focus-visible': {
            fontStyle: 'italic',
          },
        }}
        variant="copyCapsClear"
        onClick={handleClick}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            variant={titleVariant}
            as="span"
            sx={{
              display: 'inline-block',
              width: '100%',
              lineHeight: '1.5em',
            }}
          >
            {item ? item.title : title}
          </Text>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '10px',
              }}
            >
            </Box>
          </Flex>
        </Flex>
      </Button>
      <RenderIf show={isOpen}>
        <Box>
          <Box
            sx={{
              width: width,
              mb:
                item && item.content[0]._type === 'block'
                  ? ['3.33rem']
                  : '',
              mt: item && item.content[0]._type === 'block'
              ? ['1rem']
              : '',
              textAlign: 'justify',
            }}
          >
            {item?.content ? (
              <DefaultBlocks blocks={item.content} reverseColor={true} />
            ) : (
              children
            )}
          </Box>
          
        </Box>
      </RenderIf>
      <Line
            color="alwaysBlack"
            hrHeight={hrHeight}
            sx={{
              mb: item && item.content[0]._type === 'block' ? 1 : mt,
            }}
          />
    </Box>
  )
}

export { Accordion }

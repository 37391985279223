/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { forwardRef } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import theme from 'lib/theme'

const crossSize = '100%'
const crossSx = {
  width: crossSize,
  height: crossSize,
}
const starSize = 2 / 3 * 100;

const Cross = forwardRef(({ onClick, ...props }, ref) => (
  <Button
    onClick={onClick}
    sx={crossSx}
    ref={ref}
    {...props}
  >
    <CrossSVG />
  </Button>
))

/**
 * Render cross as SVG to fill an element
 */
const CrossSVG = ({
  sx,
  bX1 = 6,
  bY1 = 16,
  bX2 = bY1,
  bY2 = bX1,
  width = '100%',
  height = '100%',
  title = 'Dark Mofo',
  color = 'red',
}) => {
  const { colorMode } = useThemeUI()
  const colour = colorMode === 'light' ? theme.colors.black : color === 'red'? theme.colors.red : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      align="center"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      sx={{
        width,
        height,
        display: 'block',
        ...sx,
      }}
    >
      <title>{title}</title>
      <svg
        viewBox="-100 -100 200 200"
        width={`${starSize}%`}
        height={`${starSize}%`}
        x={`${50 - starSize / 2}%`}
        y={`${50 - starSize / 2}%`}
      >
        <path
          d={`
            M 0 0
            V 100
            C ${bX1} ${bY1} ${bX2} ${bY2} 100 0
            M 0 0
            V -100
            C ${-bX1} ${-bY1} ${-bX2} ${-bY2} -100 0
            M 0 0
            H 100
            C ${bY1} ${-bX1} ${bY2} ${-bX2} 0 -100
            M 0 0
            H -100
            C ${-bY1} ${bX1} ${-bY2} ${bX2} 0 100
            Z
          `}
          fill={colour}
        />
      </svg>
      <path
        d="M 50 0
          V 100
          M 0 50
          H 100"
        fill="none"
        stroke={colour}
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  )
}

export { Cross, CrossSVG }

/** @jsx jsx */
import { jsx } from 'theme-ui'

const FilterIcon = ({
  iconColor = 'grey',
  show = 'default',
  size = '1em',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width='1em'
      height='1em'
      viewBox="0 0 100 100"
      sx={{
        display: 'inline-block',
        stroke: iconColor,
        fontSize: size,
      }}
      {...props}
    >
      <line x1="5" y1="5" x2="48" y2="48"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
      <line x1="45" y1="45" x2="90" y2="5"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
      <line x1="5" y1="45" x2="49" y2="91"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
      <line x1="45" y1="90" x2="90" y2="45"
        strokeWidth="1.5"
        vectorEffect="non-scaling-stroke" />
    </svg>
  )
}

export { FilterIcon }

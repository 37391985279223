import { SanityPlayer } from 'components/sanity/videoPlayer/SanityPlayer'
import { useGlobalStaticData } from 'hooks/useGlobalStaticData'
import { useEffect, useRef, useState } from 'react'
import { Container, Flex } from 'theme-ui'
import { skipFromTitle, titleAtTime } from './helpers/timings'
import ResurrectionTitles from './ResurrectionTitles'

const videoStyles = {
  width: '66.666%',
}

const ResurrectionVideo = ({
  onComplete,
  onSkip,
  canSkip,
  playing,
  videoRef = useRef(),
}) => {
  const {
    home: {
      homeVideo,
      homeVideoMobile,
    },
  } = useGlobalStaticData()

  // This is specific to the resurrection svg
  const [ activeTitle, setActiveTitle ] = useState(titleAtTime(0))

  // Track whether video is started
  const [ videoStarted, setVideoStarted ] = useState(false)

  // Define mobile video only once to prevent re-playing on breakpoint switch
  const [ isMobile, setIsMobile ] = useState()

  useEffect(
    () => {
      setIsMobile(window?.innerWidth <= 640)
    },
    [],
  )

  // Check for mobile or desktop video
  const { asset } = isMobile && homeVideoMobile?.asset ? homeVideoMobile : homeVideo;

  const skipClick = canSkip && playing
    ? () => {
      const skipTo = skipFromTitle(activeTitle);
      const video = videoRef.current;

      if (skipTo && video) {
        video.currentTime = skipTo;

        return;
      }

      onSkip();
    }
    : undefined;

  return (
    <Container
      sx={{
        height: '100%',
        maxWidth: '2000px',
        width: '100%',
        position: playing ? 'relative' : 'absolute',
        opacity: playing ? 1 : 0,
        px: ['2px', 3, 6, 6],
        cursor: skipClick ? 'pointer' : 'auto',
      }}
      onClick={skipClick}
    >
      <ResurrectionTitles
        title={activeTitle}
        onComplete={onComplete}
      />
      <Flex sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
        <SanityPlayer
          autoload
          autoplay={false}
          assetDocument={asset}
          showControls={false}
          onFineTimeUpdate={(time) => {
            setActiveTitle(titleAtTime(time))
          }}
          onPlay={() => setVideoStarted(true)}
          onEnded={onComplete}
          videoRef={videoRef}
          style={{
            ...videoStyles,
            opacity: videoStarted ? 'block' : 'none',
          }}
        />
      </Flex>
    </Container>
  )
}

export default ResurrectionVideo

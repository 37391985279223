/** @jsx jsx */
import { jsx } from 'theme-ui'

const EventsTasmaniaLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 220.01"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <path
          d="M102.06,114.92c6.79,0.8,5.1-2.51,9.33-4.65c-8.28,0.73-10.94,0.39-16.8-1.69
      C97.7,111.89,95.72,114.16,102.06,114.92z"
        />
        <path d="M63.36,40.88c-9.42-7.99-13.09-10.43-28.79-16.03C45.39,38.4,46,42.67,46,42.67S55.16,40.84,63.36,40.88z" />
        <path
          d="M160.02,11.34c-15.56,6.83-23.52,20.17-26.94,29.66c5.32,1.46,9.86,2.04,9.86,2.04S146.74,26.52,160.02,11.34z
      "
        />
        <path
          d="M93.47,69.64c-3.16-1.42-6.82-2.08-10.41-1.98l-2.34,0.43c-0.13,0.02-0.43,0.25-0.31,0.31
      c5.42,2.69,10.27,6.89,15.2,13.14c0.07,0.08,0.23,0.03,0.31-0.04c0.36-3.66-0.34-7.32-1.2-10.61l0.08-0.11
      C94.63,70.14,93.95,69.93,93.47,69.64z"
        />
        <path
          d="M109.48,70.77l0.08,0.11c-0.86,3.3-1.56,6.96-1.2,10.61c0.08,0.06,0.24,0.12,0.31,0.04
      c4.94-6.24,9.78-10.45,15.2-13.14c0.12-0.06-0.17-0.29-0.31-0.31l-2.34-0.43c-3.6-0.1-7.26,0.56-10.41,1.98
      C110.32,69.93,109.64,70.14,109.48,70.77z"
        />
        <path
          d="M88.31,106.13c-2.67-21.33-19.89-49.18-19.89-49.18C54.55,51.84,47.7,46.71,47.7,46.71s5.47,16.12,7.46,33.45
      c-9-17.82-25.26-25.67-25.26-25.67s-6.52,8.15-9.37,17.52c8,8.67,16.56,26.94,16.56,26.94s-8.3-7.75-16.97-13.09
      c-2,8-3.7,21.08-0.37,37.75C36.34,105.74,54.15,100.92,88.31,106.13z"
        />
        <path
          d="M49.12,114.2c-18.81,1.82-28.22,14.12-28.22,14.12S31,122,46.69,122.09c16.6,0.09,25.2,9.57,46.58,15.53
      c39.85,11.13,63.02-12.94,65.02-36.28C121.32,149.4,81.61,111.06,49.12,114.2z"
        />
        <path
          d="M116.25,109.59c0,0,26.32-1.19,38.08-12.95c-5.31-8.73,0.38-27.32,6.07-34.53
      c-11.38,4.18-20.86,20.11-20.86,20.11s-4.17-18.93,1.9-31.82c-3.56,0.89-11.76,7.17-11.76,7.17s-3.04,7.21-7.2,23.9
      C118.79,96.13,116.25,109.59,116.25,109.59z"
        />
        <path
          d="M74.87,34.28c0,0,8.44-9.33,24.67-10c17.35-0.71,21.33,3.34,21.33,3.34s-6.45-13.11-12.89-14
      c-7.29-1.01-13.97,1.58-18.89,4.44C83.76,21.16,78.87,24.5,74.87,34.28z"
        />
        <path
          d="M95.99,2.72C85.76-0.61,74.93,0.05,74.93,0.05c-14.45,8.89-12.22,21.84-12,32.45
      C67.59,19.45,80.2,5.83,95.99,2.72z"
        />
        <polygon
          points="51.85,155.01 51.85,151.53 32.47,151.53 32.47,180.94 51.85,180.94 51.85,177.46 36.35,177.46 
      36.35,166.54 51.85,166.54 51.85,163.06 36.35,163.06 36.35,155.01 	"
        />
        <polygon points="75.59,158.9 71.71,158.9 64.91,176.45 58.07,158.9 54.07,158.9 62.93,180.94 66.65,180.94 	" />
        <path
          d="M88.42,158.25c-3.24,0-5.83,1.09-7.77,3.19c-1.99,2.1-2.95,4.98-2.95,8.58c0,3.68,1.01,6.47,3.07,8.46
      c2.06,1.98,4.89,2.99,8.58,2.99c1.42,0,2.79-0.2,4.09-0.53c1.34-0.36,2.59-0.81,3.84-1.33v-4.05h-0.2
      c-0.65,0.57-1.7,1.17-3.24,1.74c-1.54,0.61-3.03,0.93-4.53,0.93c-1.13,0-2.19-0.16-3.12-0.48c-0.93-0.32-1.74-0.81-2.43-1.42
      c-0.73-0.64-1.3-1.49-1.7-2.51c-0.4-0.97-0.61-2.18-0.61-3.52h16.26v-2.02c0-3.19-0.81-5.66-2.43-7.4S91.37,158.25,88.42,158.25z
       M81.46,167.47c0.16-1.74,0.81-3.2,2.02-4.37c1.17-1.17,2.75-1.78,4.73-1.78c1.99,0,3.44,0.52,4.41,1.62
      c0.97,1.05,1.46,2.55,1.5,4.53H81.46z"
        />
        <path
          d="M102.13,158.9v22.05h3.72v-16.46c1.01-0.77,2.06-1.42,3.19-1.94c1.09-0.49,2.15-0.73,3.15-0.73
      c1.01,0,1.82,0.12,2.43,0.4c0.61,0.28,1.09,0.69,1.41,1.21c0.32,0.52,0.53,1.21,0.65,2.1c0.12,0.89,0.2,1.82,0.2,2.83v12.58h3.68
      v-14.32c0-2.71-0.61-4.78-1.86-6.19c-1.26-1.45-3.08-2.18-5.46-2.18c-1.29,0-2.55,0.29-3.8,0.81c-1.25,0.57-2.43,1.29-3.6,2.27
      v-2.43H102.13z"
        />
        <path
          d="M128.39,179.57c1.17,1.21,2.87,1.82,5.14,1.82c0.65,0,1.38-0.08,2.18-0.2c0.81-0.12,1.58-0.24,2.31-0.44v-3.32
      h-0.2c-0.32,0.12-0.77,0.24-1.46,0.44c-0.65,0.16-1.25,0.24-1.86,0.24c-1.05,0-1.82-0.12-2.35-0.36c-0.48-0.24-0.88-0.61-1.17-1.13
      c-0.32-0.57-0.53-1.17-0.57-1.86c-0.04-0.65-0.08-1.54-0.08-2.71v-10.03h7.69v-3.12h-7.69v-6.35h-3.72v6.35h-2.51v3.12h2.51v11.73
      C126.61,176.41,127.21,178.36,128.39,179.57z"
        />
        <path
          d="M151.65,168.69c-0.57-0.13-1.29-0.29-2.14-0.45c-0.85-0.16-1.62-0.32-2.3-0.48c-1.22-0.29-2.1-0.65-2.67-1.14
      c-0.56-0.44-0.84-1.13-0.84-2.02c0-1.05,0.44-1.82,1.41-2.35c0.93-0.52,2.06-0.77,3.44-0.77c1.3,0,2.63,0.25,3.96,0.73
      c1.38,0.49,2.51,1.05,3.48,1.78h0.16v-4.01c-0.89-0.44-1.98-0.84-3.36-1.17c-1.34-0.32-2.67-0.48-4.01-0.48
      c-1.38,0-2.67,0.2-3.8,0.57c-1.13,0.36-2.06,0.85-2.83,1.45c-0.77,0.65-1.33,1.38-1.7,2.14c-0.36,0.81-0.57,1.66-0.57,2.59
      c0,1.45,0.4,2.67,1.21,3.64c0.81,1.01,2.18,1.78,4.13,2.27c0.68,0.2,1.45,0.36,2.3,0.53c0.85,0.16,1.53,0.28,2.06,0.41
      c1.45,0.32,2.39,0.72,2.91,1.17c0.49,0.45,0.73,1.09,0.73,1.94c0,1.13-0.44,1.98-1.38,2.51c-0.93,0.52-2.18,0.81-3.84,0.81
      c-1.29,0-2.67-0.29-4.12-0.81c-1.42-0.53-2.71-1.22-3.89-2.1h-0.2v4.17c0.89,0.48,2.06,0.89,3.4,1.3c1.38,0.36,2.87,0.57,4.53,0.57
      c2.87,0,5.14-0.65,6.8-1.94c1.66-1.29,2.51-2.91,2.51-4.93c0-1.62-0.45-2.92-1.42-3.88C154.69,169.74,153.35,169.09,151.65,168.69z
      "
        />
        <polygon points="24.92,189.99 0,189.99 0,193.47 10.52,193.47 10.52,219.41 14.4,219.41 14.4,193.47 24.92,193.47 	" />
        <path
          d="M37.1,198.49c-0.85-0.61-1.82-1.05-2.99-1.3c-1.13-0.24-2.51-0.4-4.05-0.4c-1.34,0-2.67,0.12-4.05,0.36
      c-1.38,0.24-2.43,0.44-3.16,0.65v3.8h0.2c1.3-0.49,2.51-0.89,3.73-1.13c1.17-0.25,2.27-0.4,3.23-0.4c0.81,0,1.58,0.08,2.31,0.16
      c0.77,0.12,1.38,0.33,1.9,0.65c0.57,0.28,1.01,0.73,1.3,1.3c0.28,0.52,0.44,1.25,0.44,2.06v0.61c-2.18,0.08-4.21,0.24-6.07,0.44
      c-1.9,0.24-3.52,0.61-4.86,1.21c-1.34,0.61-2.35,1.42-3.08,2.43c-0.69,1.05-1.05,2.39-1.05,4.08c0,2.03,0.69,3.73,2.06,5.02
      c1.38,1.33,3.08,1.98,5.06,1.98c1.09,0,2.03-0.08,2.83-0.28c0.81-0.2,1.49-0.49,2.14-0.77c0.52-0.25,1.05-0.57,1.62-0.97
      c0.57-0.41,1.01-0.73,1.34-0.93v2.35h3.68v-14.97c0-1.42-0.2-2.63-0.65-3.56C38.56,199.91,37.95,199.1,37.1,198.49z M35.97,213.99
      c-0.93,0.69-1.94,1.3-2.99,1.82c-1.09,0.48-2.31,0.77-3.6,0.77c-1.54,0-2.71-0.33-3.48-0.97c-0.77-0.65-1.13-1.58-1.13-2.87
      c0-1.09,0.32-1.98,0.97-2.63c0.65-0.65,1.54-1.09,2.59-1.41c0.89-0.24,2.1-0.45,3.65-0.57c1.53-0.12,2.87-0.24,4-0.29V213.99z"
        />
        <path
          d="M56,207.15c-0.57-0.13-1.29-0.29-2.14-0.45c-0.85-0.16-1.62-0.32-2.31-0.49c-1.21-0.28-2.1-0.65-2.67-1.13
      c-0.57-0.44-0.85-1.13-0.85-2.02c0-1.05,0.44-1.82,1.42-2.35c0.93-0.52,2.06-0.77,3.44-0.77c1.3,0,2.63,0.25,3.97,0.73
      c1.38,0.48,2.51,1.05,3.48,1.78h0.16v-4.01c-0.89-0.44-1.98-0.85-3.36-1.17c-1.34-0.32-2.67-0.48-4.01-0.48
      c-1.37,0-2.67,0.2-3.8,0.57c-1.13,0.36-2.06,0.85-2.83,1.45c-0.77,0.65-1.34,1.38-1.7,2.15c-0.36,0.8-0.57,1.66-0.57,2.59
      c0,1.45,0.4,2.67,1.21,3.64c0.81,1.01,2.18,1.78,4.13,2.27c0.69,0.2,1.45,0.36,2.3,0.53c0.85,0.16,1.54,0.28,2.06,0.4
      c1.45,0.32,2.39,0.73,2.91,1.17c0.48,0.45,0.73,1.09,0.73,1.94c0,1.13-0.45,1.98-1.38,2.51c-0.93,0.52-2.18,0.81-3.84,0.81
      c-1.29,0-2.67-0.29-4.12-0.81c-1.42-0.53-2.71-1.22-3.88-2.1h-0.2v4.17c0.89,0.49,2.06,0.89,3.4,1.3c1.38,0.36,2.87,0.57,4.53,0.57
      c2.87,0,5.14-0.65,6.8-1.94c1.66-1.3,2.51-2.91,2.51-4.93c0-1.62-0.44-2.92-1.41-3.88C59.03,208.2,57.7,207.55,56,207.15z"
        />
        <path
          d="M90.59,196.71c-1.42,0-2.75,0.29-3.96,0.85c-1.26,0.57-2.63,1.5-4.13,2.75c-0.48-1.13-1.29-1.98-2.34-2.63
      c-1.05-0.65-2.31-0.97-3.8-0.97c-1.3,0-2.55,0.29-3.68,0.81c-1.17,0.57-2.31,1.29-3.48,2.27v-2.43h-3.72v22.05h3.72v-16.46
      c1.01-0.77,2.02-1.42,3.08-1.94c1.01-0.49,2.02-0.73,3.04-0.73c1.05,0,1.9,0.12,2.47,0.4c0.57,0.28,0.97,0.73,1.29,1.29
      c0.29,0.53,0.45,1.21,0.53,2.1c0.08,0.85,0.12,1.78,0.12,2.75v12.58h3.72v-14.12c0-0.41,0-0.81-0.04-1.22
      c0-0.4-0.04-0.73-0.08-1.05c1.01-0.81,2.06-1.45,3.11-1.98c1.05-0.52,2.06-0.77,3.12-0.77c1.05,0,1.9,0.12,2.47,0.44
      c0.57,0.29,0.97,0.69,1.3,1.25c0.28,0.53,0.44,1.26,0.53,2.14c0.08,0.85,0.12,1.78,0.12,2.71v12.58h3.72v-14.32
      c0-2.63-0.56-4.69-1.7-6.15C94.8,197.48,93.02,196.71,90.59,196.71z"
        />
        <path
          d="M118.22,198.49c-0.85-0.61-1.82-1.05-2.99-1.3c-1.13-0.24-2.51-0.4-4.05-0.4c-1.34,0-2.67,0.12-4.05,0.36
      c-1.38,0.24-2.43,0.44-3.16,0.65v3.8h0.2c1.3-0.49,2.51-0.89,3.72-1.13c1.17-0.25,2.27-0.4,3.24-0.4c0.81,0,1.58,0.08,2.3,0.16
      c0.77,0.12,1.38,0.33,1.9,0.65c0.57,0.28,1.01,0.73,1.3,1.3c0.29,0.52,0.44,1.25,0.44,2.06v0.61c-2.18,0.08-4.21,0.24-6.07,0.44
      c-1.9,0.24-3.52,0.61-4.86,1.21c-1.34,0.61-2.35,1.42-3.07,2.43c-0.69,1.05-1.05,2.39-1.05,4.08c0,2.03,0.69,3.73,2.06,5.02
      c1.38,1.33,3.07,1.98,5.05,1.98c1.1,0,2.02-0.08,2.83-0.28c0.81-0.2,1.49-0.49,2.14-0.77c0.52-0.25,1.05-0.57,1.62-0.97
      c0.57-0.41,1.01-0.73,1.34-0.93v2.35h3.68v-14.97c0-1.42-0.2-2.63-0.65-3.56C119.67,199.91,119.07,199.1,118.22,198.49z
       M117.09,213.99c-0.93,0.69-1.94,1.3-2.99,1.82c-1.09,0.48-2.31,0.77-3.6,0.77c-1.54,0-2.71-0.33-3.48-0.97
      c-0.77-0.65-1.13-1.58-1.13-2.87c0-1.09,0.32-1.98,0.97-2.63c0.65-0.65,1.54-1.09,2.59-1.41c0.89-0.24,2.1-0.45,3.64-0.57
      c1.54-0.12,2.87-0.24,4-0.29V213.99z"
        />
        <path
          d="M137.84,196.71c-1.29,0-2.55,0.29-3.8,0.81c-1.25,0.57-2.43,1.29-3.6,2.27v-2.43h-3.73v22.05h3.73v-16.46
      c1.01-0.77,2.06-1.42,3.19-1.94c1.1-0.49,2.15-0.73,3.16-0.73c1.01,0,1.82,0.12,2.43,0.4c0.61,0.28,1.09,0.69,1.42,1.21
      c0.32,0.52,0.52,1.21,0.65,2.1c0.12,0.89,0.2,1.82,0.2,2.83v12.58h3.68v-14.32c0-2.71-0.61-4.77-1.86-6.19
      C142.05,197.44,140.23,196.71,137.84,196.71z"
        />
        <rect x="151.15" y="197.36" width="3.72" height="22.05" />
        <rect x="150.91" y="189.79" width="4.21" height="3.84" />
        <path
          d="M175.59,198.49c-0.85-0.61-1.82-1.05-3-1.3c-1.13-0.24-2.51-0.4-4.05-0.4c-1.33,0-2.67,0.12-4.04,0.36
      c-1.38,0.24-2.43,0.44-3.16,0.65v3.8h0.2c1.3-0.49,2.51-0.89,3.72-1.13c1.17-0.25,2.27-0.4,3.24-0.4c0.81,0,1.58,0.08,2.31,0.16
      c0.77,0.12,1.38,0.33,1.9,0.65c0.57,0.28,1.01,0.73,1.3,1.3c0.28,0.52,0.44,1.25,0.44,2.06v0.61c-2.19,0.08-4.21,0.24-6.07,0.44
      c-1.9,0.24-3.52,0.61-4.86,1.21c-1.34,0.61-2.35,1.42-3.08,2.43c-0.69,1.05-1.05,2.39-1.05,4.08c0,2.03,0.69,3.73,2.06,5.02
      c1.38,1.33,3.08,1.98,5.05,1.98c1.1,0,2.02-0.08,2.84-0.28c0.81-0.2,1.5-0.49,2.14-0.77c0.52-0.25,1.05-0.57,1.62-0.97
      c0.56-0.41,1.01-0.73,1.34-0.93v2.35h3.68v-14.97c0-1.42-0.2-2.63-0.65-3.56C177.04,199.91,176.43,199.1,175.59,198.49z
       M174.45,213.99c-0.93,0.69-1.94,1.3-2.99,1.82c-1.09,0.48-2.31,0.77-3.6,0.77c-1.54,0-2.71-0.33-3.48-0.97
      c-0.77-0.65-1.13-1.58-1.13-2.87c0-1.09,0.32-1.98,0.97-2.63c0.65-0.65,1.54-1.09,2.59-1.41c0.89-0.24,2.1-0.45,3.65-0.57
      c1.53-0.12,2.87-0.24,4-0.29V213.99z"
        />
        <path
          d="M434.98,209.52c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.94,0.26,2.32,0.78c0.41,0.58,0.44,1.25,0.44,2.21l-4.32,0.81
      c-2.64,0.49-5.25,1.36-5.25,4.5c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.98,4.73-2.4c0,0.9,0.09,1.51,0.2,2.06h3.42v-0.12
      c-0.29-0.7-0.49-1.54-0.49-2.49V209.52z M431.68,214.02c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06l3.78-0.81V214.02z"
        />
        <path
          d="M340.52,209.52c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.94,0.26,2.32,0.78c0.4,0.58,0.43,1.25,0.43,2.21l-4.32,0.81
      c-2.64,0.49-5.24,1.36-5.24,4.5c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.98,4.73-2.4c0,0.9,0.09,1.51,0.2,2.06h3.42v-0.12
      c-0.29-0.7-0.49-1.54-0.49-2.49V209.52z M337.21,214.02c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06l3.77-0.81V214.02z"
        />
        <polygon
          points="328.65,199.28 311.83,199.28 311.83,202.35 318.41,202.35 318.41,219.58 322.03,219.58 322.03,202.35 
      328.65,202.35 	"
        />
        <rect x="415.87" y="199.1" width="3.45" height="3.71" />
        <path
          d="M375.28,204.74c-1.57,0-2.58,0.38-3.4,1.04c-0.64,0.55-1.1,1.13-1.42,1.91c-0.61-1.82-2.12-2.95-4.38-2.95
      c-1.48,0-2.44,0.4-3.19,1.04c-0.52,0.4-0.84,0.9-1.07,1.42v-2.12h-3.42v14.5h3.42v-7.92c0-1.91,0.46-2.87,1.01-3.45
      c0.49-0.49,1.16-0.78,1.97-0.78c0.55,0,1.34,0,2.03,0.67c0.78,0.73,0.81,1.74,0.81,3.08v8.41h3.45v-7.98c0-1.8,0.44-2.75,1.01-3.36
      c0.52-0.55,1.25-0.81,2.06-0.81c0.61,0,1.34,0.06,2.03,0.7c0.73,0.7,0.75,1.74,0.75,3.05v8.41h3.45v-9.19
      C380.41,206.62,378.59,204.74,375.28,204.74z"
        />
        <path
          d="M299.29,210.71c-1.86-0.4-3.19-0.75-3.19-1.88c0-1.13,1.19-1.62,2.47-1.62c1.83,0,2.93,0.87,3.02,2.67
      l3.15-0.52c-0.32-3.45-3.01-4.61-6.18-4.61c-2.9,0-5.71,1.25-5.71,4.35c0,2.93,2.46,3.74,5.04,4.41c2.18,0.55,3.98,0.78,3.98,2.06
      c0,1.22-1.02,1.91-2.96,1.91c-1.92,0-3.22-0.99-3.25-2.87l-3.22,0.38c0.12,3.48,3.04,4.96,6.44,4.96c3.19,0,6.23-1.07,6.23-4.58
      C305.12,211.93,302.02,211.32,299.29,210.71z"
        />
        <rect x="415.87" y="205.08" width="3.45" height="14.5" />
        <path
          d="M395.99,209.52c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.95,0.26,2.32,0.78c0.4,0.58,0.43,1.25,0.43,2.21l-4.32,0.81
      c-2.64,0.49-5.24,1.36-5.24,4.5c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.98,4.73-2.4c0,0.9,0.09,1.51,0.2,2.06h3.42v-0.12
      c-0.29-0.7-0.49-1.54-0.49-2.49V209.52z M392.69,214.02c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06l3.77-0.81V214.02z"
        />
        <path
          d="M349.82,210.71c-1.86-0.4-3.19-0.75-3.19-1.88c0-1.13,1.19-1.62,2.47-1.62c1.83,0,2.93,0.87,3.02,2.67
      l3.16-0.52c-0.32-3.45-3.01-4.61-6.18-4.61c-2.9,0-5.71,1.25-5.71,4.35c0,2.93,2.46,3.74,5.04,4.41c2.18,0.55,3.98,0.78,3.98,2.06
      c0,1.22-1.02,1.91-2.96,1.91c-1.91,0-3.22-0.99-3.25-2.87l-3.22,0.38c0.12,3.48,3.04,4.96,6.44,4.96c3.19,0,6.24-1.07,6.24-4.58
      C355.65,211.93,352.55,211.32,349.82,210.71z"
        />
        <path
          d="M243.65,211.96c-0.38,1.31-0.84,2.87-1.27,4.44c-0.61-2.09-1.07-3.68-1.31-4.44l-2.12-6.88h-3.68l5.16,14.5
      h3.86l5.16-14.5h-3.62L243.65,211.96z"
        />
        <polygon
          points="220.58,219.58 234.35,219.58 234.35,216.57 224.2,216.57 224.2,210.82 233.28,210.82 233.28,207.84 
      224.2,207.84 224.2,202.3 234.35,202.3 234.35,199.28 220.58,199.28 	"
        />
        <path
          d="M407.19,204.74c-2.09,0-3.66,0.78-4.38,2.55v-2.2h-3.42v14.5h3.45v-7.95c0-3.51,1.89-4.21,3.16-4.21
      c0.84,0,1.54,0.06,2.18,0.7c0.75,0.7,0.81,1.62,0.81,2.96v8.5h3.48v-8.76c0-1.74-0.09-3.45-1.22-4.61
      C410.27,205.2,409.08,204.74,407.19,204.74z"
        />
        <path
          d="M262.83,207.14c-1.22-1.53-3.16-2.4-5.45-2.4c-2.2,0-3.94,0.69-5.22,2.03c-1.3,1.39-1.94,3.31-1.94,5.54
      c0,2.35,0.61,4.27,1.94,5.63c1.25,1.27,2.99,2,5.25,2c3.71,0,6.09-1.68,6.85-4.99l-3.34-0.26c-0.32,1.77-1.42,2.67-3.54,2.67
      c-2.41,0-3.62-1.36-3.68-4.06h10.79C264.57,210.8,263.99,208.59,262.83,207.14z M253.72,210.71c0.06-0.7,0.26-1.48,0.73-2.12
      c0.58-0.81,1.57-1.28,2.93-1.28c1.28,0,2.15,0.35,2.79,1.13c0.49,0.61,0.75,1.45,0.81,2.26H253.72z"
        />
        <path
          d="M274.99,204.74c-2.09,0-3.66,0.78-4.39,2.55v-2.2h-3.42v14.5h3.45v-7.95c0-3.51,1.89-4.21,3.17-4.21
      c0.84,0,1.54,0.06,2.18,0.7c0.75,0.7,0.81,1.62,0.81,2.96v8.5h3.48v-8.76c0-1.74-0.09-3.45-1.22-4.61
      C278.07,205.2,276.88,204.74,274.99,204.74z"
        />
        <path
          d="M287.83,201.05h-3.39v4.03h-2.09v2.75h2.09v8.09c0,2.73,1.62,3.8,4.37,3.8c0.75,0,1.48-0.09,1.92-0.17
      l-0.06-2.99c-0.49,0.09-0.99,0.14-1.34,0.14c-1.04,0-1.51-0.2-1.51-1.07v-7.8h2.96v-2.75h-2.96V201.05z"
        />
        <path
          d="M338.04,188.02v-9.19c0-3.77-1.82-5.65-5.13-5.65c-1.57,0-2.58,0.38-3.4,1.04c-0.64,0.55-1.1,1.13-1.42,1.91
      c-0.61-1.83-2.12-2.96-4.38-2.96c-1.48,0-2.44,0.41-3.19,1.04c-0.52,0.41-0.84,0.9-1.07,1.42v-2.11h-3.42v14.5h3.42v-7.92
      c0-1.92,0.46-2.87,1.01-3.45c0.49-0.49,1.16-0.78,1.97-0.78c0.55,0,1.34,0,2.03,0.66c0.78,0.73,0.81,1.74,0.81,3.08v8.41h3.45
      v-7.97c0-1.8,0.44-2.76,1.01-3.36c0.52-0.55,1.25-0.81,2.06-0.81c0.61,0,1.34,0.06,2.03,0.69c0.73,0.7,0.75,1.74,0.75,3.05v8.41
      H338.04z"
        />
        <path
          d="M484.14,173.17c-1.95,0-3.54,0.81-4.32,2.49v-7.94h-3.42v20.3h3.45v-8.06c0-3.45,1.91-4.09,3.16-4.09
      c0.84,0,1.54,0.06,2.21,0.72c0.73,0.7,0.78,1.51,0.78,2.84v8.58h3.48v-8.76c0-1.8-0.09-3.36-1.19-4.55
      C487.27,173.6,486.02,173.17,484.14,173.17z"
        />
        <path
          d="M469.47,175.43c-0.93-1.42-2.35-2.26-4.38-2.26c-3.77,0-6.44,2.93-6.44,7.22c0,4.53,2.61,7.51,6.52,7.51
      c1.97,0,3.4-0.81,4.3-2.38v2.26c0,2.38-1.19,3.66-3.43,3.66c-1.97,0-3.19-0.64-3.31-2.58l-3.33,0.23c0.17,3.57,3.16,4.87,6.49,4.87
      c4.3,0,6.99-2.23,6.99-6.21v-14.24h-3.42V175.43z M465.96,185.35c-2.7,0-3.57-1.62-3.57-4.96c0-3.05,0.93-4.67,3.63-4.67
      c2.41,0,3.57,1.69,3.57,4.67C469.59,183.2,468.66,185.35,465.96,185.35z"
        />
        <path
          d="M424.25,176.3l0.09-3.13h-0.64c-2.41,0.09-4.12,1.19-4.64,2.96v-2.61h-3.42v14.5h3.45v-6.64
      c0-4.01,1.71-5.11,4.44-5.11C423.79,176.27,424.05,176.27,424.25,176.3z"
        />
        <path
          d="M248.82,188.37c4.47,0,7.37-3.04,7.37-7.6c0-4.38-2.81-7.6-7.37-7.6c-4.61,0-7.42,3.19-7.42,7.6
      C241.4,185.5,244.18,188.37,248.82,188.37z M248.82,175.69c2.64,0,3.69,1.63,3.69,5.08c0,3.45-1.02,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C245.08,177.32,246.13,175.69,248.82,175.69z"
        />
        <path
          d="M432.87,173.17c-4.61,0-7.42,3.19-7.42,7.6c0,4.73,2.78,7.6,7.42,7.6c4.47,0,7.37-3.04,7.37-7.6
      C440.24,176.38,437.42,173.17,432.87,173.17z M432.87,185.85c-2.73,0-3.74-1.62-3.74-5.08c0-3.45,1.05-5.08,3.74-5.08
      c2.64,0,3.69,1.63,3.69,5.08C436.55,184.22,435.54,185.85,432.87,185.85z"
        />
        <path
          d="M284.47,175.57c-1.22-1.53-3.16-2.4-5.45-2.4c-2.21,0-3.95,0.7-5.22,2.03c-1.3,1.39-1.94,3.31-1.94,5.54
      c0,2.35,0.61,4.27,1.94,5.63c1.25,1.27,2.99,2,5.25,2c3.71,0,6.09-1.68,6.85-4.99l-3.34-0.26c-0.32,1.77-1.42,2.67-3.54,2.67
      c-2.41,0-3.62-1.36-3.68-4.06h10.79C286.21,179.23,285.63,177.02,284.47,175.57z M275.36,179.15c0.06-0.7,0.26-1.48,0.72-2.12
      c0.58-0.81,1.57-1.28,2.93-1.28c1.28,0,2.15,0.35,2.79,1.13c0.49,0.61,0.75,1.45,0.81,2.27H275.36z"
        />
        <path
          d="M452.5,181.52c0,3.45-1.77,4.15-3.14,4.15c-0.84,0-1.6-0.06-2.29-0.75c-0.7-0.73-0.73-1.74-0.73-3.08v-8.32
      h-3.51v8.5c0,1.77,0.12,3.57,1.25,4.76c1.04,1.1,2.32,1.59,4.18,1.59c2,0,3.48-0.87,4.27-2.46v2.11h3.36v-14.5h-3.39V181.52z"
        />
        <path
          d="M296.71,176.27c0.26,0,0.52,0,0.72,0.03l0.09-3.13h-0.64c-2.41,0.09-4.12,1.19-4.64,2.96v-2.61h-3.42v14.5
      h3.45v-6.64C292.27,177.37,293.98,176.27,296.71,176.27z"
        />
        <path
          d="M312.61,188.02v-8.76c0-1.74-0.09-3.45-1.22-4.61c-0.99-1.01-2.17-1.48-4.06-1.48c-2.09,0-3.66,0.78-4.38,2.55
      v-2.2h-3.42v14.5h3.45v-7.95c0-3.51,1.88-4.21,3.16-4.21c0.84,0,1.54,0.06,2.18,0.69c0.75,0.7,0.81,1.62,0.81,2.96v8.5H312.61z"
        />
        <path
          d="M354.77,183.38l-3.34-0.26c-0.32,1.77-1.42,2.67-3.54,2.67c-2.41,0-3.62-1.36-3.68-4.06H355
      c0.09-2.49-0.49-4.7-1.65-6.15c-1.22-1.53-3.16-2.4-5.45-2.4c-2.2,0-3.94,0.7-5.22,2.03c-1.3,1.39-1.94,3.31-1.94,5.54
      c0,2.35,0.61,4.27,1.94,5.63c1.25,1.27,2.99,2,5.25,2C351.63,188.37,354.01,186.69,354.77,183.38z M344.96,177.02
      c0.58-0.81,1.57-1.28,2.93-1.28c1.28,0,2.15,0.35,2.79,1.13c0.49,0.61,0.75,1.45,0.81,2.27h-7.25
      C344.29,178.45,344.5,177.66,344.96,177.02z"
        />
        <path
          d="M229.11,188.37c2.76,0,5.05-1.1,6.3-3.04l0.23,2.69h3.04v-10.99h-9.51v3.1h5.98
      c-0.23,3.48-2.26,5.31-5.51,5.31c-2.35,0-3.98-0.64-4.96-2.06c-0.9-1.25-1.31-3.02-1.31-5.51c0-2.47,0.49-4.3,1.36-5.51
      c1.02-1.42,2.67-2.06,4.79-2.06c2.99,0,4.56,1.88,5.17,4.24l3.8-0.73c-1.1-4.21-4.49-6.44-8.96-6.44c-6.41,0-10,4.32-10,10.5
      C219.51,184.63,223.07,188.37,229.11,188.37z"
        />
        <path
          d="M267.47,173.52l-2.18,6.87c-0.38,1.31-0.84,2.87-1.27,4.44c-0.61-2.09-1.08-3.69-1.31-4.44l-2.12-6.87h-3.69
      l5.16,14.5h3.86l5.16-14.5H267.47z"
        />
        <path
          d="M393.69,169.49h-3.39v4.03h-2.09v2.75h2.09v8.1c0,2.73,1.62,3.8,4.38,3.8c0.75,0,1.48-0.09,1.91-0.17
      l-0.06-2.99c-0.49,0.09-0.99,0.14-1.33,0.14c-1.05,0-1.51-0.2-1.51-1.07v-7.81h2.96v-2.75h-2.96V169.49z"
        />
        <path
          d="M402.6,179.95c0-3.45,1.92-4.09,3.17-4.09c0.84,0,1.54,0.06,2.21,0.72c0.73,0.7,0.78,1.51,0.78,2.84v8.58h3.48
      v-8.76c0-1.8-0.09-3.36-1.19-4.55c-1.01-1.1-2.26-1.54-4.15-1.54c-1.95,0-3.54,0.81-4.32,2.49v-7.94h-3.42v20.3h3.45V179.95z"
        />
        <path
          d="M361.15,180.07c0-3.51,1.89-4.21,3.17-4.21c0.84,0,1.54,0.06,2.18,0.69c0.75,0.7,0.81,1.62,0.81,2.96v8.5h3.48
      v-8.76c0-1.74-0.09-3.45-1.22-4.61c-0.99-1.01-2.17-1.48-4.06-1.48c-2.09,0-3.66,0.78-4.38,2.55v-2.2h-3.42v14.5h3.45V180.07z"
        />
        <path
          d="M378.34,169.49h-3.39v4.03h-2.09v2.75h2.09v8.1c0,2.73,1.62,3.8,4.37,3.8c0.75,0,1.48-0.09,1.92-0.17
      l-0.06-2.99c-0.49,0.09-0.99,0.14-1.34,0.14c-1.04,0-1.51-0.2-1.51-1.07v-7.81h2.96v-2.75h-2.96V169.49z"
        />
        <path
          d="M326.19,141.6c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.79,3.68l3.36,0.43
      c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.95,0.26,2.32,0.78c0.41,0.58,0.44,1.25,0.44,2.21l-4.32,0.81c-2.64,0.49-5.25,1.36-5.25,4.5
      c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.99,4.73-2.4c0,0.9,0.09,1.51,0.2,2.05h3.42v-0.12c-0.29-0.7-0.49-1.54-0.49-2.49v-7.45
      C332.02,143.22,329.9,141.6,326.19,141.6z M328.71,150.88c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.27-2.06l3.77-0.81V150.88z"
        />
        <path
          d="M270.06,148.39c0-3.45,1.92-4.09,3.16-4.09c0.84,0,1.54,0.06,2.21,0.73c0.73,0.7,0.78,1.51,0.78,2.84v8.58
      h3.48v-8.76c0-1.8-0.09-3.37-1.19-4.56c-1.01-1.1-2.26-1.53-4.15-1.53c-1.95,0-3.54,0.81-4.32,2.49v-7.95h-3.42v20.3h3.45V148.39z"
        />
        <path
          d="M238.35,159.33c-0.61,0-1.22-0.17-1.74-0.32l-0.06,3.04c0.67,0.2,1.6,0.35,2.29,0.35
      c2.49,0,3.74-1.34,4.93-4.73l5.6-15.72h-3.57l-2.23,6.88c-0.38,1.19-0.75,2.38-1.19,3.86c-0.4-1.45-0.81-2.76-1.13-3.71l-2.35-7.02
      h-3.71l5.6,14.5C240.06,158.54,239.63,159.33,238.35,159.33z"
        />
        <polygon
          points="313.53,156.45 313.53,139.22 320.14,139.22 320.14,136.15 303.32,136.15 303.32,139.22 309.91,139.22 
      309.91,156.45 	"
        />
        <rect x="407.37" y="141.95" width="3.45" height="14.5" />
        <rect x="407.37" y="135.98" width="3.45" height="3.71" />
        <path
          d="M374.61,152.45c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.99,4.73-2.4c0,0.9,0.09,1.51,0.2,2.05h3.42v-0.12
      c-0.29-0.7-0.49-1.54-0.49-2.49v-7.45c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.79,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.95,0.26,2.32,0.78c0.41,0.58,0.44,1.25,0.44,2.21l-4.32,0.81
      C377.22,148.45,374.61,149.32,374.61,152.45z M384.19,149.55v1.34c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06L384.19,149.55z"
        />
        <path
          d="M294.96,144.01c-1.22-1.53-3.16-2.4-5.45-2.4c-2.2,0-3.94,0.7-5.22,2.03c-1.3,1.39-1.94,3.31-1.94,5.54
      c0,2.35,0.61,4.26,1.94,5.63c1.25,1.27,2.99,2,5.25,2c3.71,0,6.09-1.68,6.85-4.99l-3.34-0.26c-0.32,1.77-1.42,2.67-3.54,2.67
      c-2.41,0-3.62-1.36-3.68-4.06h10.79C296.7,147.66,296.13,145.46,294.96,144.01z M285.86,147.58c0.06-0.7,0.26-1.48,0.73-2.12
      c0.58-0.81,1.57-1.28,2.93-1.28c1.28,0,2.15,0.35,2.79,1.13c0.49,0.61,0.75,1.45,0.81,2.26H285.86z"
        />
        <path
          d="M264.11,144.7v-2.75h-2.96v-4.03h-3.39v4.03h-2.09v2.75h2.09v8.1c0,2.72,1.62,3.79,4.38,3.79
      c0.75,0,1.48-0.09,1.91-0.17l-0.06-2.98c-0.49,0.09-0.99,0.14-1.34,0.14c-1.04,0-1.51-0.21-1.51-1.08v-7.8H264.11z"
        />
        <path
          d="M442.95,156.45v-8.76c0-1.74-0.09-3.45-1.22-4.61c-0.99-1.01-2.17-1.48-4.06-1.48c-2.09,0-3.66,0.78-4.39,2.55
      v-2.2h-3.42v14.5h3.45v-7.95c0-3.51,1.89-4.21,3.17-4.21c0.84,0,1.54,0.06,2.18,0.7c0.75,0.7,0.81,1.62,0.81,2.96v8.5H442.95z"
        />
        <path
          d="M347.15,152.22c0-3.43-3.1-4.03-5.83-4.64c-1.86-0.41-3.19-0.75-3.19-1.89c0-1.13,1.19-1.62,2.47-1.62
      c1.83,0,2.93,0.87,3.02,2.67l3.15-0.52c-0.32-3.45-3.01-4.61-6.18-4.61c-2.9,0-5.71,1.25-5.71,4.35c0,2.93,2.46,3.74,5.04,4.41
      c2.18,0.55,3.98,0.78,3.98,2.06c0,1.22-1.01,1.92-2.96,1.92c-1.92,0-3.22-0.99-3.25-2.87l-3.22,0.38c0.12,3.48,3.04,4.96,6.44,4.96
      C344.1,156.8,347.15,155.73,347.15,152.22z"
        />
        <path
          d="M359.15,148.04v8.41h3.45v-7.98c0-1.8,0.44-2.75,1.02-3.36c0.52-0.55,1.25-0.81,2.06-0.81
      c0.61,0,1.34,0.06,2.03,0.7c0.73,0.7,0.75,1.74,0.75,3.05v8.41h3.45v-9.19c0-3.77-1.83-5.65-5.13-5.65c-1.57,0-2.58,0.38-3.4,1.04
      c-0.64,0.55-1.1,1.13-1.42,1.91c-0.61-1.83-2.12-2.96-4.38-2.96c-1.48,0-2.44,0.4-3.19,1.04c-0.52,0.4-0.84,0.9-1.07,1.42v-2.12
      h-3.42v14.5h3.42v-7.92c0-1.92,0.47-2.87,1.01-3.45c0.49-0.49,1.16-0.78,1.97-0.78c0.55,0,1.34,0,2.03,0.67
      C359.12,145.69,359.15,146.7,359.15,148.04z"
        />
        <path
          d="M400.49,147.95v8.5h3.48v-8.76c0-1.74-0.09-3.45-1.22-4.61c-0.99-1.01-2.17-1.48-4.06-1.48
      c-2.09,0-3.66,0.78-4.38,2.55v-2.2h-3.42v14.5h3.45v-7.95c0-3.51,1.89-4.21,3.17-4.21c0.84,0,1.54,0.06,2.18,0.7
      C400.43,145.69,400.49,146.62,400.49,147.95z"
        />
        <path
          d="M423.35,154.4c0,0.9,0.09,1.51,0.2,2.05h3.42v-0.12c-0.29-0.7-0.49-1.54-0.49-2.49v-7.45
      c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.79,3.68l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7
      c1.22,0,1.95,0.26,2.32,0.78c0.41,0.58,0.44,1.25,0.44,2.21l-4.32,0.81c-2.64,0.49-5.25,1.36-5.25,4.5c0,2.93,2.35,4.35,5.02,4.35
      C421.03,156.8,422.59,155.81,423.35,154.4z M417.14,152.42c0-1.31,0.9-1.77,2.27-2.06l3.77-0.81v1.34c0,2.12-1.36,3.34-3.8,3.34
      C418.13,154.22,417.14,153.64,417.14,152.42z"
        />
        <path
          d="M220.15,136.15v20.3h3.45v-2c0.84,1.39,2.44,2.35,4.32,2.35c3.86,0,6.55-2.9,6.55-7.6
      c0-4.58-2.64-7.63-6.47-7.63c-1.86,0-3.45,0.78-4.41,2.17v-7.6H220.15z M227.08,144.12c2.73,0,3.66,1.74,3.66,5.08
      c0,3.31-0.9,5.08-3.63,5.08c-2.41,0-3.6-1.77-3.6-5.05C223.51,146.33,224.29,144.12,227.08,144.12z"
        />
        <path
          d="M350.2,121.79c-0.7-0.73-0.73-1.74-0.73-3.08v-8.32h-3.51v8.5c0,1.77,0.12,3.57,1.25,4.76
      c1.04,1.1,2.32,1.59,4.18,1.59c2,0,3.48-0.87,4.27-2.46v2.12h3.36v-14.5h-3.39v8.01c0,3.45-1.77,4.15-3.14,4.15
      C351.65,122.54,350.89,122.48,350.2,121.79z"
        />
        <path
          d="M362.5,110.39v20.3h3.45v-7.57c1.1,1.42,2.67,2.12,4.47,2.12c3.77,0,6.41-3.01,6.41-7.6
      c0-4.73-2.75-7.6-6.49-7.6c-1.8,0-3.37,0.75-4.38,2.29v-1.94H362.5z M369.46,112.56c2.76,0,3.63,1.71,3.63,5.05
      c0,3.34-0.9,5.08-3.69,5.08c-2.82,0-3.54-2.18-3.54-5.11C365.86,114.33,366.96,112.56,369.46,112.56z"
        />
        <path
          d="M423.51,113.14h2.09v8.1c0,2.73,1.62,3.8,4.38,3.8c0.75,0,1.48-0.09,1.91-0.17l-0.06-2.99
      c-0.49,0.09-0.99,0.14-1.34,0.14c-1.04,0-1.51-0.2-1.51-1.07v-7.81h2.96v-2.75h-2.96v-4.03h-3.39v4.03h-2.09V113.14z"
        />
        <path
          d="M460.54,122.83v2.06h3.42v-20.3h-3.42v7.59c-0.96-1.42-2.47-2.14-4.35-2.14c-3.89,0-6.49,3.01-6.49,7.6
      c0,4.73,2.75,7.6,6.55,7.6C458.16,125.23,459.7,124.42,460.54,122.83z M457.03,122.71c-2.82,0-3.6-1.74-3.6-5.08
      c0-3.31,0.87-5.05,3.66-5.05c2.76,0,3.54,2.18,3.54,5.08C460.63,120.88,459.47,122.71,457.03,122.71z"
        />
        <path
          d="M379.59,110.39v20.3h3.45v-7.57c1.1,1.42,2.67,2.12,4.47,2.12c3.77,0,6.41-3.01,6.41-7.6
      c0-4.73-2.75-7.6-6.49-7.6c-1.8,0-3.37,0.75-4.38,2.29v-1.94H379.59z M386.55,112.56c2.76,0,3.63,1.71,3.63,5.05
      c0,3.34-0.9,5.08-3.69,5.08c-2.82,0-3.54-2.18-3.54-5.11C382.95,114.33,384.05,112.56,386.55,112.56z"
        />
        <path
          d="M237.63,110.39h-2.09v2.75h2.09v11.75h3.42v-11.75h2.96v-2.75h-2.96v-2c0-0.96,0.47-1.13,1.45-1.13
      c0.32,0,0.93,0.06,1.39,0.14l0.06-2.78c-0.32-0.09-1.13-0.18-1.83-0.18c-2.96,0-4.49,1.1-4.49,3.92V110.39z"
        />
        <path
          d="M395.9,117.63c0,4.73,2.78,7.6,7.43,7.6c4.47,0,7.36-3.04,7.36-7.6c0-4.38-2.81-7.6-7.36-7.6
      C398.71,110.04,395.9,113.22,395.9,117.63z M403.32,112.56c2.64,0,3.69,1.63,3.69,5.08c0,3.45-1.02,5.08-3.69,5.08
      c-2.73,0-3.75-1.62-3.75-5.08C399.58,114.18,400.62,112.56,403.32,112.56z"
        />
        <path
          d="M226.76,125.23c4.47,0,7.37-3.04,7.37-7.6c0-4.38-2.81-7.6-7.37-7.6c-4.61,0-7.43,3.19-7.43,7.6
      C219.33,122.36,222.11,125.23,226.76,125.23z M226.76,112.56c2.64,0,3.69,1.63,3.69,5.08c0,3.45-1.01,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C223.01,114.18,224.06,112.56,226.76,112.56z"
        />
        <path
          d="M297.66,116.94c0-3.51,1.89-4.21,3.16-4.21c0.84,0,1.54,0.06,2.18,0.7c0.76,0.69,0.82,1.62,0.82,2.96v8.5h3.48
      v-8.76c0-1.74-0.09-3.45-1.22-4.61c-0.99-1.01-2.17-1.48-4.06-1.48c-2.09,0-3.66,0.78-4.38,2.55v-2.2h-3.42v14.5h3.45V116.94z"
        />
        <path
          d="M309.97,120.88c0,2.93,2.35,4.35,5.01,4.35c2.41,0,3.98-0.99,4.73-2.4c0,0.9,0.09,1.51,0.2,2.06h3.42v-0.12
      c-0.29-0.69-0.49-1.53-0.49-2.49v-7.45c0-3.16-2.11-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.44c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.94,0.26,2.32,0.78c0.4,0.58,0.43,1.25,0.43,2.21l-4.32,0.81
      C312.58,116.88,309.97,117.75,309.97,120.88z M319.54,117.98v1.33c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.3,0.9-1.77,2.26-2.06L319.54,117.98z"
        />
        <path
          d="M416.84,118.24c0-4.01,1.71-5.11,4.44-5.11c0.26,0,0.52,0,0.72,0.03l0.09-3.13h-0.64
      c-2.41,0.09-4.12,1.19-4.64,2.96v-2.61h-3.42v14.5h3.45V118.24z"
        />
        <path
          d="M276.72,117.63c0,4.73,2.78,7.6,7.42,7.6c4.47,0,7.37-3.04,7.37-7.6c0-4.38-2.81-7.6-7.37-7.6
      C279.53,110.04,276.72,113.22,276.72,117.63z M284.14,112.56c2.64,0,3.69,1.63,3.69,5.08c0,3.45-1.01,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C280.4,114.18,281.44,112.56,284.14,112.56z"
        />
        <path
          d="M337.2,122.77c-1.91,0-3.22-0.99-3.25-2.87l-3.22,0.38c0.12,3.48,3.04,4.96,6.44,4.96
      c3.19,0,6.24-1.07,6.24-4.58c0-3.42-3.1-4.03-5.83-4.64c-1.86-0.41-3.19-0.75-3.19-1.88c0-1.13,1.19-1.63,2.47-1.63
      c1.83,0,2.93,0.87,3.02,2.67l3.16-0.52c-0.32-3.45-3.01-4.61-6.18-4.61c-2.9,0-5.71,1.25-5.71,4.35c0,2.93,2.46,3.74,5.04,4.41
      c2.18,0.55,3.98,0.78,3.98,2.06C340.17,122.07,339.15,122.77,337.2,122.77z"
        />
        <path
          d="M268.52,113.28c0.32-0.99,0.96-3.16,1.62-5.48h0.06c-0.06,1.77-0.12,4.44-0.12,5.16v11.92h3.42v-20.3h-5.6
      l-3.31,10.47c-0.61,2-1.34,4.61-1.74,6.15h-0.03c-0.38-1.54-0.99-3.66-1.77-6.18l-3.31-10.44h-5.62v20.3h3.42v-11.87
      c0-0.7-0.09-3.45-0.12-5.22h0.06c0.58,1.94,1.31,4.44,1.62,5.42l3.74,11.66h3.89L268.52,113.28z"
        />
        <path
          d="M435.37,123.23c1.25,1.27,2.99,2,5.25,2c3.71,0,6.09-1.68,6.84-4.99l-3.34-0.26
      c-0.32,1.77-1.42,2.67-3.54,2.67c-2.41,0-3.62-1.36-3.69-4.06h10.79c0.09-2.49-0.49-4.7-1.65-6.15c-1.22-1.53-3.16-2.4-5.45-2.4
      c-2.21,0-3.95,0.7-5.22,2.03c-1.3,1.39-1.94,3.31-1.94,5.54C433.43,119.96,434.04,121.87,435.37,123.23z M437.66,113.89
      c0.58-0.81,1.57-1.27,2.93-1.27c1.28,0,2.15,0.35,2.78,1.13c0.49,0.61,0.75,1.45,0.81,2.27h-7.25
      C436.99,115.32,437.19,114.53,437.66,113.89z"
        />
        <rect x="453.81" y="72.84" width="3.45" height="3.71" />
        <path
          d="M429.95,86.68c0-4,1.71-5.1,4.44-5.1c0.26,0,0.52,0,0.73,0.03l0.09-3.13h-0.64c-2.41,0.09-4.12,1.19-4.64,2.95
      v-2.61h-3.42v14.5h3.45V86.68z"
        />
        <path
          d="M472.61,80.88c-1.22-1.53-3.16-2.4-5.45-2.4c-2.21,0-3.95,0.69-5.22,2.02c-1.3,1.39-1.94,3.31-1.94,5.54
      c0,2.35,0.61,4.27,1.94,5.63c1.25,1.27,2.99,2,5.25,2c3.71,0,6.09-1.68,6.84-4.99l-3.34-0.26c-0.32,1.77-1.42,2.67-3.54,2.67
      c-2.41,0-3.63-1.36-3.69-4.06h10.79C474.35,84.53,473.77,82.33,472.61,80.88z M463.5,84.45c0.06-0.7,0.26-1.48,0.73-2.12
      c0.58-0.81,1.57-1.28,2.93-1.28c1.27,0,2.15,0.35,2.78,1.13c0.49,0.61,0.75,1.45,0.81,2.26H463.5z"
        />
        <path
          d="M500,81.57v-2.75h-2.96v-4.03h-3.39v4.03h-2.09v2.75h2.09v8.09c0,2.73,1.62,3.8,4.38,3.8
      c0.75,0,1.48-0.09,1.91-0.17l-0.06-2.99c-0.49,0.09-0.99,0.14-1.34,0.14c-1.04,0-1.51-0.2-1.51-1.07v-7.8H500z"
        />
        <path
          d="M457.25,95.09V78.82h-3.45V94.6c0,1.13-0.29,1.6-0.99,1.6c-0.35,0-0.78-0.06-1.16-0.15l-0.06,3.04
      c0.49,0.12,1.08,0.17,1.57,0.17C455.95,99.26,457.25,97.79,457.25,95.09z"
        />
        <path
          d="M483.59,91.06c-2.87,0-3.6-1.86-3.6-4.99c0-3.05,0.7-4.99,3.6-4.99c2.2,0,2.9,1.48,3.28,3.1l3.22-0.58
      c-0.58-2.96-2.64-5.13-6.5-5.13c-4.64,0-7.33,3.13-7.33,7.6c0,4.76,2.69,7.59,7.33,7.59c3.62,0,6.09-2.02,6.58-4.96l-3.31-0.35
      C486.41,90.16,485.42,91.06,483.59,91.06z"
        />
        <path
          d="M443.73,93.67c4.47,0,7.37-3.04,7.37-7.59c0-4.38-2.81-7.6-7.37-7.6c-4.61,0-7.42,3.19-7.42,7.6
      C436.31,90.8,439.09,93.67,443.73,93.67z M443.73,80.99c2.64,0,3.69,1.62,3.69,5.08c0,3.45-1.01,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C439.98,82.62,441.03,80.99,443.73,80.99z"
        />
        <rect x="361.39" y="78.82" width="3.45" height="14.5" />
        <path
          d="M291.31,81.45c0-0.69-0.09-3.45-0.12-5.22h0.06c0.58,1.94,1.3,4.44,1.62,5.43l3.74,11.66h3.89l3.77-11.6
      c0.32-0.99,0.96-3.16,1.62-5.48h0.06c-0.06,1.77-0.12,4.44-0.12,5.16v11.92h3.42v-20.3h-5.6l-3.31,10.47
      c-0.61,2-1.34,4.61-1.74,6.15h-0.03c-0.38-1.54-0.99-3.65-1.77-6.18l-3.31-10.44h-5.62v20.3h3.42V81.45z"
        />
        <path
          d="M319.89,93.67c4.47,0,7.37-3.04,7.37-7.59c0-4.38-2.81-7.6-7.37-7.6c-4.61,0-7.42,3.19-7.42,7.6
      C312.47,90.8,315.25,93.67,319.89,93.67z M319.89,80.99c2.64,0,3.69,1.62,3.69,5.08c0,3.45-1.01,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C316.15,82.62,317.19,80.99,319.89,80.99z"
        />
        <polygon
          points="270.36,87.9 271.96,86.42 276.19,93.32 280.14,93.32 274.28,84.21 279.96,78.82 275.52,78.82 
      270.36,84.1 270.36,73.02 266.91,73.02 266.91,93.32 270.36,93.32 	"
        />
        <path
          d="M259.65,86.68c0-4,1.71-5.1,4.44-5.1c0.26,0,0.52,0,0.73,0.03l0.09-3.13h-0.64c-2.41,0.09-4.12,1.19-4.64,2.95
      v-2.61h-3.42v14.5h3.45V86.68z"
        />
        <path
          d="M330.77,93.32h3.42V81.57h2.96v-2.75h-2.96v-2c0-0.96,0.46-1.13,1.45-1.13c0.32,0,0.93,0.06,1.39,0.15
      l0.06-2.78c-0.32-0.09-1.13-0.17-1.83-0.17c-2.96,0-4.49,1.1-4.49,3.91v2.03h-2.09v2.75h2.09V93.32z"
        />
        <path
          d="M345.81,93.67c4.47,0,7.37-3.04,7.37-7.59c0-4.38-2.81-7.6-7.37-7.6c-4.61,0-7.43,3.19-7.43,7.6
      C338.38,90.8,341.17,93.67,345.81,93.67z M345.81,80.99c2.64,0,3.69,1.62,3.69,5.08c0,3.45-1.01,5.08-3.69,5.08
      c-2.73,0-3.74-1.62-3.74-5.08C342.07,82.62,343.11,80.99,345.81,80.99z"
        />
        <path
          d="M387.68,89.32c0,2.93,2.35,4.35,5.02,4.35c2.41,0,3.97-0.98,4.73-2.4c0,0.9,0.09,1.51,0.21,2.06h3.42V93.2
      c-0.29-0.7-0.49-1.54-0.49-2.49v-7.45c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.95,0.26,2.32,0.78c0.4,0.58,0.43,1.25,0.43,2.21l-4.32,0.81
      C390.29,85.32,387.68,86.19,387.68,89.32z M397.26,86.42v1.34c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06L397.26,86.42z"
        />
        <path
          d="M239.93,89.32c0,2.93,2.35,4.35,5.02,4.35c2.41,0,3.97-0.98,4.73-2.4c0,0.9,0.09,1.51,0.2,2.06h3.42V93.2
      c-0.29-0.7-0.49-1.54-0.49-2.49v-7.45c0-3.16-2.12-4.78-5.83-4.78c-2.03,0-3.63,0.38-4.73,1.13c-1.1,0.75-1.71,1.97-1.8,3.68
      l3.36,0.43c0.03-1.97,0.96-2.7,2.93-2.7c1.22,0,1.95,0.26,2.32,0.78c0.41,0.58,0.44,1.25,0.44,2.21l-4.32,0.81
      C242.54,85.32,239.93,86.19,239.93,89.32z M249.5,86.42v1.34c0,2.12-1.36,3.34-3.8,3.34c-1.25,0-2.23-0.58-2.23-1.8
      c0-1.31,0.9-1.77,2.26-2.06L249.5,86.42z"
        />
        <path
          d="M373.93,91.2c-1.91,0-3.22-0.99-3.25-2.87l-3.22,0.38c0.12,3.48,3.04,4.96,6.44,4.96
      c3.19,0,6.23-1.07,6.23-4.58c0-3.43-3.1-4.04-5.83-4.65c-1.86-0.4-3.19-0.75-3.19-1.88c0-1.13,1.19-1.62,2.47-1.62
      c1.83,0,2.93,0.87,3.02,2.67l3.16-0.52c-0.32-3.45-3.01-4.61-6.18-4.61c-2.9,0-5.71,1.25-5.71,4.35c0,2.93,2.47,3.74,5.05,4.41
      c2.18,0.55,3.97,0.78,3.97,2.06C376.89,90.51,375.88,91.2,373.93,91.2z"
        />
        <path
          d="M417.33,93.67c3.77,0,6.41-3.01,6.41-7.59c0-4.73-2.75-7.6-6.5-7.6c-1.8,0-3.36,0.75-4.38,2.29v-1.94h-3.45
      v20.3h3.45v-7.57C413.96,92.97,415.53,93.67,417.33,93.67z M412.77,86.01c0-3.25,1.1-5.02,3.6-5.02c2.76,0,3.63,1.71,3.63,5.05
      c0,3.34-0.9,5.08-3.69,5.08C413.5,91.12,412.77,88.94,412.77,86.01z"
        />
        <rect x="361.39" y="72.84" width="3.45" height="3.71" />
        <path
          d="M237.78,83.17c0-6.12-3.59-10.15-9.98-10.15h-7.22v20.3h7.22C234.01,93.32,237.78,89.52,237.78,83.17z
       M224.2,90.39V75.95h3.31c2.29,0,4.15,0.58,5.14,2.03c0.99,1.45,1.27,2.93,1.27,5.19c0,2.23-0.38,3.92-1.33,5.22
      c-0.99,1.31-2.7,2-5.08,2H224.2z"
        />
      </g>
    </svg>
  )
}

export { EventsTasmaniaLogo }

/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import InputSwitchboard from './InputSwitchBoard'

const FormColumns = props => {
  const { input } = props
  let gridColumn = input.stack ? ['span 12', null, 'span 6'] : ['span 6']

  return (
    <Grid
      columns={'repeat(12, [col-start] 1fr)'}
      variant="default"
      sx={{
        boxSizing: 'border-box',
        gridColumnGap: [15, 15, 35],
        gridRowGap: [0, 0, 35],
      }}
    >
      <Box sx={{ gridColumn }}>
        {input.left
          ? input.left.map((child, c) => (
              <Box key={c} sx={{ width: '100%' }}>
                <InputSwitchboard {...props} input={child} />
              </Box>
            ))
          : null}
      </Box>
      <Box sx={{ gridColumn }}>
        {input.right
          ? input.right.map((child, c) => {
              return (
                <Box key={c} sx={{ width: '100%' }}>
                  <InputSwitchboard {...props} input={child} />
                </Box>
              )
            })
          : null}
      </Box>
    </Grid>
  )
}

export default FormColumns

/** @jsx jsx */
import { jsx } from 'theme-ui'

const TheOldWoolstoreLogo = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 398.78"
      sx={{ width: '100%' }}
      {...props}
    >
      <g
        sx={{
          '> *': {
            fill: 'alwaysBlack',
          },
        }}
      >
        <polygon
          points="127.4,92.48 141.57,105.65 146.19,100.68 132.02,87.51 136.18,83.03 132.16,79.3 119.21,93.23 
		123.23,96.97 	"
        />
        <polygon
          points="157.87,89.96 151.67,81.95 157.91,77.13 164.1,85.13 169.49,80.96 154.29,61.32 148.9,65.49 
		154.54,72.77 148.3,77.6 142.67,70.31 137.27,74.49 152.48,94.13 	"
        />
        <polygon
          points="186.65,71.5 184.03,66.7 176.9,70.6 174.65,66.48 181.25,62.87 178.66,58.15 172.06,61.76 
		170.19,58.33 177.31,54.43 174.73,49.7 161.65,56.86 173.57,78.65 	"
        />
        <path
          d="M203.34,61.67c2.57,1.61,5.77,1.93,9.58,0.97c3.88-0.97,6.55-2.77,8.03-5.38c1.48-2.61,1.71-5.95,0.69-10
		c-1.02-4.06-2.8-6.89-5.32-8.49c-2.53-1.6-5.73-1.91-9.6-0.93c-3.86,0.97-6.54,2.77-8.04,5.39c-1.49,2.62-1.74,5.94-0.73,9.95
		C198.98,57.23,200.77,60.06,203.34,61.67z M208.07,43.17c1.62-0.41,2.98-0.14,4.09,0.8c1.1,0.94,1.96,2.62,2.56,5.02
		c0.6,2.39,0.64,4.26,0.12,5.59c-0.52,1.33-1.61,2.21-3.28,2.63c-3.28,0.82-5.5-1.09-6.67-5.75C203.71,46.76,204.77,44,208.07,43.17
		z"
        />
        <polygon points="245.03,57.78 244.6,52.38 234.96,53.14 233.43,33.78 226.67,34.32 228.63,59.08 	" />
        <path
          d="M266.4,55.71c2.48-2.06,3.87-5.15,4.19-9.29c0.3-3.84-0.57-6.86-2.6-9.06c-2.03-2.2-5.05-3.46-9.07-3.77
		l-8.66-0.67l-1.93,24.76l8.1,0.63C260.6,58.63,263.93,57.77,266.4,55.71z M256.61,38.84l1.97,0.15c1.85,0.14,3.19,0.8,4.01,1.96
		c0.83,1.17,1.15,2.88,0.97,5.15c-0.19,2.43-0.81,4.19-1.86,5.3c-1.05,1.11-2.6,1.58-4.66,1.42l-1.51-0.12L256.61,38.84z"
        />
        <path
          d="M172.7,347.35l5.76,2.46l2.52-3.18l6.31,2.69l-0.58,4.01l5.73,2.45l1.81-22.01l-7-2.98L172.7,347.35z
		 M189.43,335.45c-0.12,0.52-0.27,1.39-0.47,2.62c-0.2,1.23-0.35,2.24-0.45,3.02l-0.51,3.51l-4.13-1.76
		c2.09-2.67,3.47-4.46,4.14-5.39C188.69,336.53,189.16,335.86,189.43,335.45z"
        />
        <path
          d="M212.98,346.83c0.59-2.04,0.41-3.8-0.55-5.28c-0.96-1.48-2.72-2.59-5.28-3.34l-7.01-2.04l-5.84,20.04l5.47,1.6
		l1.94-6.65l1.54,0.45c2.46,0.72,4.53,0.66,6.22-0.17C211.16,350.6,212.33,349.06,212.98,346.83z M206.15,346.92
		c-0.62,0.31-1.34,0.35-2.16,0.11l-1-0.29l1.34-4.61l1.41,0.41c1.51,0.44,2.08,1.34,1.68,2.69
		C207.2,346.04,206.77,346.6,206.15,346.92z"
        />
        <path
          d="M218.99,341.07l-10.34,19.49l6.17,1.06l1.72-3.68l6.76,1.16l0.36,4.03l6.14,1.06l-3.31-21.84L218.99,341.07z
		 M218.49,353.58c1.42-3.08,2.35-5.14,2.79-6.2c0.44-1.05,0.74-1.81,0.91-2.28c0.01,0.53,0.06,1.42,0.15,2.66
		c0.09,1.24,0.18,2.26,0.26,3.04l0.32,3.53L218.49,353.58z"
        />
        <path
          d="M244.37,355.71c2.44-1.21,3.72-3.03,3.83-5.44c0.19-4.11-2.58-6.29-8.31-6.56l-6.91-0.32l-0.96,20.85l5.7,0.26
		l0.35-7.59l1.44,0.07l4.12,7.79l6.46,0.3L244.37,355.71z M239.34,352.73l-1.07-0.05l0.22-4.76l1.12,0.05
		c1.95,0.09,2.89,0.85,2.83,2.29C242.36,352,241.33,352.83,239.34,352.73z"
        />
        <polygon
          points="254.08,348.4 255.1,364.63 260.79,364.27 259.76,348.04 264.89,347.72 264.6,343.11 248.64,344.12 
		248.93,348.72 	"
        />
        <path
          d="M276.05,362.45l-1.94-8.86c-0.3-1.38-0.83-3.31-1.57-5.76l0.13-0.03l7.43,13.77l5.3-1.16l0.96-15.57l0.13-0.03
		c0.37,2.24,0.61,3.64,0.72,4.21c0.11,0.57,0.21,1.09,0.32,1.55l1.95,8.94l5.12-1.12l-4.45-20.39l-7.43,1.62l-1.05,15.4l-0.11,0.03
		l-7.46-13.54l-7.42,1.62l4.46,20.39L276.05,362.45z"
        />
        <polygon
          points="312.33,351.69 310.7,347.39 304.32,349.81 302.92,346.13 308.83,343.89 307.23,339.66 301.32,341.9 
		300.15,338.83 306.54,336.41 304.93,332.17 293.22,336.62 300.62,356.13 	"
        />
        <path
          d="M321.68,347.29l-4.48-8.27c-0.58-1.07-1.5-2.58-2.76-4.53l0.12-0.06l13.78,9.26l6.58-3.56l-9.94-18.36
		l-4.47,2.42l4.49,8.3c0.61,1.12,1.47,2.58,2.59,4.36l-0.08,0.04l-13.66-9.1l-6.55,3.54l9.94,18.36L321.68,347.29z"
        />
        <polygon
          points="333.48,321.23 343.12,334.32 347.71,330.94 338.07,317.85 342.21,314.8 339.48,311.09 326.6,320.57 
		329.33,324.28 	"
        />
        <polygon
          points="366.07,314.66 359.85,308.85 364.37,304.01 370.59,309.82 374.5,305.63 359.25,291.39 355.34,295.57 
		360.99,300.85 356.47,305.7 350.81,300.42 346.91,304.6 362.16,318.85 	"
        />
        <path
          d="M369.08,295.82c2.89,2,5.59,2.79,8.11,2.38c2.51-0.41,4.71-1.97,6.59-4.7c1.91-2.76,2.61-5.38,2.09-7.85
		c-0.51-2.47-2.22-4.71-5.1-6.7c-2.9-2-5.59-2.81-8.07-2.42c-2.48,0.39-4.68,1.97-6.58,4.73c-1.9,2.76-2.6,5.38-2.09,7.86
		C364.54,291.62,366.23,293.85,369.08,295.82z M369.93,283.9c0.8-1.15,1.81-1.74,3.03-1.76c1.22-0.02,2.69,0.57,4.4,1.75
		c1.71,1.18,2.76,2.34,3.17,3.47c0.41,1.13,0.21,2.29-0.61,3.48c-1.61,2.34-4.08,2.36-7.4,0.06
		C369.17,288.58,368.31,286.25,369.93,283.9z"
        />
        <polygon
          points="378.71,270.23 393.16,277.69 395.78,272.62 381.33,265.16 383.68,260.59 379.59,258.48 372.25,272.69 
		376.35,274.81 	"
        />
        <polygon
          points="404.6,251.41 400.3,249.79 397.89,256.18 394.2,254.79 396.43,248.88 392.2,247.28 389.97,253.2 
		386.9,252.04 389.3,245.65 385.07,244.06 380.65,255.78 400.18,263.14 	"
        />
        <polygon points="409.5,233.29 405.09,232.16 403.07,240.03 387.27,235.98 385.85,241.5 406.07,246.69 	" />
        <path d="M0.47,156.83C0.17,155.35,0,154.56,0,154.56S0.03,155.44,0.47,156.83z" />
        <path
          d="M500,64.78c0,0-48.51-0.72-101.24,5C362.16,27.11,307.92,0,247.43,0C164.49,0,93.25,50.91,63.24,123.1
		c-30.88,2.14-67.78,9.69-62.72,33.85c-0.01-0.04-0.03-0.08-0.04-0.12c3.28,15.88,22.71,113.24,27.66,212.75
		c0,0,21.6,54.54,78.71,0.9c5.58-5.15,11.16-10.14,16.76-14.98c34.04,27.06,77.06,43.28,123.82,43.28
		c100.38,0,183.63-74.58,197.36-171.23c20.51-0.33,50.01-4.44,47.79-25.5c0.05,0,0.1,0,0.1,0C489.36,93.56,500,64.78,500,64.78z
		 M247.43,396.2c-45.98,0-88.29-15.89-121.83-42.41c0.74-0.64,1.48-1.25,2.22-1.88c32.98,25.92,74.5,41.44,119.61,41.44
		c97.38,0,178.2-72.14,191.88-165.78c1.02-6.95,1.64-14.02,1.89-21.19c0.97-0.14,1.92-0.27,2.86-0.41
		c-0.24,7.31-0.86,14.52-1.88,21.61C428.46,322.78,346.37,396.2,247.43,396.2z M56.5,173.89c2.39-17.96,7.24-35.15,14.24-51.21
		C100.45,54.51,168.45,6.73,247.43,6.73c56.82,0,107.96,24.74,143.25,64c-36.22,4.31-69.64,10.7-99.36,17.88
		c-0.71,0.17-1.44,0.34-2.14,0.51c-0.76,0.19-1.49,0.38-2.24,0.56c-12.34-4.46-25.65-6.9-39.51-6.9c-41.97,0-78.83,22.3-99.37,55.66
		c-0.6,0.35-1.38,0.8-2.35,1.35c-0.63,0.36-1.35,0.76-2.12,1.19c-9.6,5.34-29.88,15.99-52.49,23.98
		C79.78,168.94,67.9,172.25,56.5,173.89z M395.65,70.13c-1.15,0.13-2.28,0.27-3.42,0.41c-35.55-39.91-87.28-65.1-144.8-65.1
		c-79.73,0-148.36,48.38-178.13,117.31c-6.96,16.11-11.76,33.34-14.11,51.34c-0.98,0.13-1.95,0.23-2.92,0.33
		c2.29-18.03,6.98-35.32,13.83-51.5C95.99,52.27,166.01,2.58,247.43,2.58C306.5,2.58,359.54,28.78,395.65,70.13z M402.84,196.04
		c0.01,0.12,0.03,0.21,0.03,0.34c0,1.63-0.59,1.48-1.04,1.48c-0.59,0.15-2.23-0.3-7.72,0.89c-2.52,0.45-5.05,1.48-7.57,2.08
		c-0.45,0.15-0.74-0.74-0.74-1.63c0-1.66,0.9-2.39,1.93-3.6c-0.28,0.48-0.45,1.03-0.45,1.75c0,0.89,0.3,1.78,0.74,1.63
		c2.52-0.59,5.04-1.63,7.57-2.08C400.07,195.93,401.97,196.04,402.84,196.04z M384.91,93.09c0-0.89,0.59-1.93,1.34-1.93
		c0.06-0.01,0.11-0.01,0.16-0.01c0,0.03-0.01,0.06-0.01,0.09c0,5.34,5.79-1.78,5.79,13.06v19.59v19.59v19.59v19.88
		c0,7.83-2.18,9.9-3.63,11.56c1.12-1.68,2.15-4.33,2.15-9.71v-19.88v-19.59v-19.59v-19.59C390.7,91.31,384.91,98.43,384.91,93.09z
		 M365.23,203.4c-0.44,0.44-0.87,0.9-1.32,1.29c-0.94,0.81-1.93,1.49-2.93,2.1c-0.22,0.13-0.45,0.24-0.67,0.36
		c-0.22,0.12-0.45,0.25-0.68,0.36c-1.14,0.56-2.3,1.04-3.5,1.33c-14.54,4.15-23.3-18.4-23.3-52.98c0-9.67,0.88-18.67,2.42-26.71
		c0.06-0.3,0.11-0.61,0.17-0.91c0.05-0.26,0.09-0.52,0.15-0.77c0.24-1.17,0.51-2.29,0.78-3.41c0.13-0.54,0.25-1.08,0.39-1.61
		c0.13-0.49,0.25-0.97,0.38-1.45c2.6-9.48,6.25-17.05,10.57-21.87c-4.06,5.33-7.44,13.16-9.79,22.77c-0.12,0.49-0.24,0.98-0.36,1.48
		c-0.12,0.54-0.26,1.06-0.38,1.61c-0.25,1.18-0.47,2.4-0.69,3.62c-0.05,0.26-0.1,0.51-0.15,0.77c-0.05,0.31-0.11,0.6-0.17,0.91
		c-1.2,7.25-1.86,15.22-1.86,23.68c0,34.58,8.76,57.13,23.3,52.98c0.72-0.17,1.41-0.44,2.11-0.71c0.22-0.09,0.45-0.14,0.67-0.23
		c0.23-0.1,0.45-0.25,0.67-0.36c0.98-0.48,1.93-1.07,2.87-1.75c0.44-0.32,0.89-0.62,1.31-0.99c0.45-0.38,0.89-0.83,1.32-1.26
		c0.68-0.67,1.34-1.38,1.98-2.15c-0.64,0.89-1.31,1.71-1.99,2.49C366.12,202.52,365.67,202.96,365.23,203.4z M170.22,282.92
		c-0.19-0.44-0.38-0.89-0.56-1.35c-0.2-0.49-0.39-0.99-0.58-1.51c-3.4-9.5-5.49-24.07-6.03-42.37c-0.49,11.54-1.57,22.41-3.13,32.35
		c-0.05,0.31-0.1,0.63-0.15,0.94c-0.04,0.27-0.09,0.52-0.13,0.79c-0.2,1.2-0.4,2.39-0.62,3.56c-0.1,0.57-0.21,1.13-0.32,1.69
		c-0.1,0.52-0.19,1.04-0.29,1.55c-3.34,16.94-8.22,30.55-14.12,39.25c-0.62,0.91-1.25,1.76-1.89,2.57c-1.46,1.82-2.96,3.38-4.52,4.6
		c-14.57,11.24-23.25-19.05-23.14-69.36c0.09-39.34,6.91-73.32,16.63-89.07c0.13-0.21,0.26-0.42,0.39-0.63
		c0.72-1.12,1.46-2.14,2.21-3.06c0.69-0.85,1.39-1.6,2.11-2.27c0.64-0.59,1.28-1.12,1.93-1.56c0.48-0.33,0.97-0.62,1.45-0.86
		c0.74-0.37,1.47-0.61,2.2-0.78c0.24-0.05,0.48-0.09,0.72-0.13c0.25-0.04,0.51-0.08,0.76-0.09c10.82-0.46,18.99,21.8,20.09,57.73
		c1.56-32.85,8.84-60.08,18.38-71.13c-10.03,13.72-17.22,45.65-17.22,82.49c0,27.04,3.08,47.35,8.57,57.37
		c0.38,0.7,0.77,1.35,1.18,1.95c0.65,0.95,1.32,1.76,2.02,2.45c0.63,0.51,1.27,1.01,1.91,1.5c0.68,0.4,1.38,0.69,2.1,0.86
		c0.48,0.11,0.96,0.17,1.46,0.18c1.93,0.05,4.01-0.69,6.21-2.28c1.66-1.18,3.24-2.71,4.78-4.48c-1.98,2.6-4.06,4.77-6.26,6.34
		c-1.03,0.74-2.02,1.28-2.99,1.66c-0.27,0.1-0.53,0.21-0.8,0.28c-0.26,0.08-0.52,0.13-0.78,0.18
		C177.16,293.2,173.25,289.83,170.22,282.92z M308.25,99.94c0.62-0.11,1.25-0.23,1.91-0.36c0.57-0.11,1.16-0.23,1.76-0.35
		c0.25-0.05,0.47-0.09,0.73-0.14c12.03-2.49,18.26-4.11,19.6-4.4c1.49-0.29,1.78,0.6,1.92,4.46l-0.01,3.27l0.14,3.26l-0.01,3.26
		l0.14,3.27c0,1.63,0.14,3.56-0.75,3.71c-1.81,0.31-0.41-6.36-1.99-9.69c-0.35-0.73-0.82-1.32-1.53-1.62
		c-0.56-0.23-1.24-0.29-2.09-0.13l-2.52,0.44l-2.38,0.44l-2.01,0.5l-0.36,0.09l-1.16,0.29l-1.22,0.3c-0.16,0.03-0.32,0.06-0.46,0.08
		c-1.43,0.25-1.95,0.26-2.13,1.88c-0.02,0.21-0.04,0.44-0.06,0.71c0,0.06-0.01,0.11-0.01,0.17c0.05-0.01,0.1-0.02,0.15-0.03
		l0.03-0.01l0.73-0.18l1.61-0.4l1.82-0.45l0.55-0.14l1.16-0.22l1.21-0.23l0.39-0.07l2.13-0.38c3.77-0.74,4.32,2.95,4.49,6.33
		c0.03,0.62,0.05,1.21,0.07,1.78c0.03,0.79,0.07,1.49,0.17,2.06c0.04,0.27,0.1,0.5,0.18,0.69c0,1.22-0.09,2.31-0.75,2.42
		c-0.15,0.03-0.27,0-0.38-0.07c-0.05-0.03-0.09-0.1-0.14-0.15c-0.53-0.6-0.52-2.48-0.61-4.57c-0.03-0.66-0.08-1.34-0.15-2
		c-0.08-0.75-0.22-1.46-0.42-2.1c-0.46-1.5-1.32-2.58-2.96-2.61c-0.3-0.01-0.62,0-0.98,0.07l-0.69,0.12l-1.7,0.3l-0.13,0.02
		l-2.37,0.45l-1.07,0.27l-0.73,0.18l-0.58,0.14l-0.28,0.07l-0.79,0.2c-0.01,1.12,0,2.54,0,4.4l-0.05,21.22l-0.05,21.67l-0.05,21.37
		l-0.05,21.52c-0.03,14.25,5.47,6.84,5.46,11.89c0,0.59-0.01,2.08-0.6,2.37c-0.52,0.19-1.08,0.31-1.65,0.42
		c-0.59,0.11-1.2,0.23-1.83,0.35c-0.66,0.13-1.34,0.29-2.01,0.55c-7.43,2.65-8.77,3.69-9.06,3.84c-0.74,0.29-0.89,0-0.89-1.78
		c0.01-4.15,5.35-4.44,5.38-14.53l0.05-22.11l0.05-22.11l0.05-22.11l0.05-21.96c0-1.19,0.17-7.72-0.72-7.72l-2.67,0.74l-2.82,0.59
		l-2.67,0.74l-2.67,0.59c-2.01,0.57-3.09,1.47-3.8,3.36c0.39-0.19,0.81-0.35,1.3-0.49l2.67-0.59l2.67-0.74l2.82-0.59l2.67-0.74
		c0.89,0,0.74,6.53,0.74,7.72v21.96v22.11v22.11v22.11c0,10.09-5.34,10.39-5.34,14.54c0,1.78,0.15,2.08,0.89,1.78
		c0.3-0.15,1.63-1.19,9.05-3.86c1.58-0.61,3.15-0.73,4.57-1.08c-0.04,0.65-0.16,1.4-0.57,1.6c-1.63,0.59-3.56,0.59-5.49,1.33
		c-7.42,2.67-8.76,3.71-9.05,3.86c-0.74,0.3-0.89,0-0.89-1.78c0-4.15,5.34-4.45,5.34-14.54v-22.11v-22.11v-22.11v-21.96
		c0-1.19,0.15-7.72-0.74-7.72l-2.67,0.74l-2.82,0.59l-2.67,0.74l-2.67,0.59c-0.09,0.03-0.17,0.06-0.25,0.08
		c-0.48,2-0.8,4.79-1.29,8.83c-0.16,1.63-0.31,4.01-1.2,4.3c-0.3,0-0.59-1.04-0.59-1.93l0.16-4.45l0.08-2.04l0.08-2.27l0.03-0.68
		l0.13-3.47l0.31-4.3c0-0.73,0.01-1.77,0.06-2.83c0.03-0.54,0.07-1.07,0.13-1.58c0.16-1.33,0.45-2.42,1.01-2.56
		c2.64-0.6,5.28-1.04,8.37-1.56c0.34-0.06,0.71-0.12,1.06-0.18c0.31-0.05,0.61-0.1,0.93-0.16
		C305.32,100.47,306.74,100.22,308.25,99.94z M275.45,262.78c0.63-0.3,1.26-0.59,1.89-0.88l15.71,36.8L275.45,262.78z
		 M276.54,296.13l-9.33-29.38c0.61-0.3,1.21-0.59,1.82-0.88L276.54,296.13z M283.61,259.01c0.63-0.29,1.26-0.57,1.89-0.85
		l16.32,26.39L283.61,259.01z M291.26,255.6c0.64-0.28,1.27-0.55,1.91-0.83l16.97,21.3L291.26,255.6z M299.29,252.17
		c0.68-0.28,1.35-0.57,2.03-0.85l16.51,15.09L299.29,252.17z M308.79,248.29c0.67-0.27,1.35-0.55,2.01-0.81l16.61,10.86
		L308.79,248.29z M320.93,243.61c0.55-0.21,1.11-0.41,1.66-0.62l9.94,5.03L320.93,243.61z M264.92,296.78l-5.11-26.33
		c0.6-0.31,1.2-0.61,1.81-0.91L264.92,296.78z M253.88,273.52l-1.08,22.67l-0.8-21.7C252.63,274.17,253.25,273.85,253.88,273.52z
		 M244.8,278.37l-4.12,19.7l2.31-18.72C243.59,279.02,244.19,278.7,244.8,278.37z M233.32,284.8l-3.61,10.33l2.26-9.54
		C232.41,285.32,232.87,285.06,233.32,284.8z M194.95,280.45c-1.61,1.89-3.28,3.5-5.03,4.73c-14.57,10.5-23.41-16.08-23.3-62.08
		c0.08-35.18,6.7-65.85,16.06-80.48c0.2-0.32,0.41-0.61,0.61-0.91c2.51-3.69,5.21-6.26,8.02-7.44c13.67-5.61,24,19.35,23.9,60.31
		c-0.09,37.58-7.43,68.57-18.6,83.73C196.07,279.04,195.52,279.78,194.95,280.45z M289.94,112.46l-0.3,4.3l-0.15,4.16l-0.15,4.3
		l-0.15,4.45c0,0.37,0.06,0.75,0.14,1.08c-0.15,1.27-0.41,2.49-1.03,2.7c-0.3,0-0.59-1.04-0.59-1.93l0.15-4.45l0.15-4.3l0.15-4.16
		l0.3-4.3c0-2.08,0-6.68,1.19-6.97c0.2-0.05,0.4-0.09,0.6-0.13C289.94,108.81,289.94,111.14,289.94,112.46z M285.02,107.48
		c0.45-0.15,0.74,0.44,0.74,2.38l0.28,5.79l0.13,5.94l0.28,5.94l0.28,5.94c0.14,1.33-0.01,2.52-0.75,2.81
		c-0.74,0.15-1.18-3.41-1.32-4.9c-0.25-1.75-0.54-3.39-0.86-4.91c-0.49-2.3-1.08-4.33-1.73-6.1c-2.51-6.75-6.13-9.59-10.27-8.46
		c-2.01,0.51-4.09,2.13-5.89,4.88c1.11-0.99,2.26-1.65,3.39-1.94c5.94-1.63,10.83,4.9,12.91,19.44c0.09,0.88,0.28,2.47,0.58,3.6
		l0.02,0.33c0.15,1.33,0,2.52-0.74,2.82c-0.74,0.15-1.19-3.41-1.34-4.9c-2.08-14.54-6.98-21.07-12.91-19.44
		c-0.89,0.23-1.79,0.69-2.67,1.34c-2.46,4.44-4.23,11.27-4.25,20.7c-0.08,34.28,27.14,5.26,27.03,53.64
		c-0.03,11.72-2.55,29.17-11.32,39.36c-0.31,0.36-0.63,0.72-0.96,1.06c-2.01,2.1-4.31,3.86-6.96,5.09
		c-4.9,2.36-10.98-1.36-11.27-4.48l-0.43-5.77l0-0.02l-0.58-5.79l-0.21-4.21l-0.07-1.43l-0.43-5.64c-0.14-3.56,0.16-5.79,0.76-6.09
		c1.04-0.44,1.04,1.79,1.32,5.5c0.72,8.02,4.84,25.09,14.05,20.66c1.03-0.47,2.05-1.21,3.04-2.14c3.41-5.87,5.88-14.95,5.88-26.61
		c0-33.98-27.31-3.86-27.31-51.94c0-17.31,4.02-29.11,9.24-34.84c-4.48,6.23-7.76,17.36-7.76,32.98c0,48.08,27.31,17.96,27.31,51.94
		c0,12.33-2.76,21.75-6.48,27.57c2.9-3.16,5.48-8.25,7.08-15.03c1.05-4.45,1.68-9.63,1.69-15.44c0.01-3.18-0.23-5.79-0.65-7.97
		c-3.97-20.19-24.77-2.14-26.41-38.94c-0.07-1.6-0.12-3.28-0.11-5.1c0.01-5.84,0.48-11.06,1.3-15.63c1.2-6.7,3.15-11.99,5.51-15.82
		c0.27-0.43,0.54-0.83,0.82-1.22c2.26-3.23,4.83-5.23,7.45-5.88c8.02-2.06,10.23,3.59,11.12,3.29
		C284.27,109.7,284.28,107.62,285.02,107.48z M244.51,249.07c-0.47,0.3-0.97,0.58-1.53,0.91c-5.05,2.81-10.25,4.58-15.3,7.98
		c-2.68,1.78-5.5,4.14-8.18,5.77c-0.59,0.45-1.04,0.3-1.04-1.19c0.15-2.52,0.9-2.67,2.99-7.12c1.79-3.41,1.95-8.9,1.97-18.1
		l0.06-24.78l0.06-24.78l0.06-24.63l0.06-24.63c0.02-8.74-4.2-7.37-5.33-9.34c-0.18-0.31-0.28-0.69-0.28-1.21
		c0-0.12,0.01-0.21,0.01-0.32c0.05-1.93,0.49-2.37,1.33-2.79c1.34-0.44,3.41-0.44,6.68-1.62c3.86-1.33,5.5-2.66,6.54-2.95
		c0.89-0.44,1.19-0.14,1.18,2.23c-0.01,4.3-4.9,1.18-4.94,17.94l-0.06,23.45l-0.06,23.6l-0.06,23.3l-0.06,23.6
		c-0.03,11.43-0.49,16.47,5.16,13.07c3-1.66,5.49-3.77,7.58-6.07c5.49-8.66,6.71-17.98,7.32-18.28c0.28-0.09,0.44,0.58,0.52,1.16
		c-0.96,3.57-2.77,9.75-6.56,15.61c3.01-3.74,5.06-7.85,6.48-11.49c0.66-1.7,1.2-3.29,1.62-4.68c0.75-2.48,1.17-4.33,1.46-5.08
		l-0.26-3.24c-0.15-3.56,0.15-5.79,0.74-6.09c0.38-0.16,0.62,0.04,0.78,0.52c-0.08,0.98-0.1,2.22-0.04,3.71l0.45,5.64l0.3,5.64
		l0.59,5.79l0.45,5.79c0.3,3.12,6.38,6.83,11.28,4.45c3.15-1.47,5.78-3.66,8.03-6.31c-2.52,3.51-5.65,6.37-9.51,8.17
		c-4.9,2.37-10.98-1.34-11.28-4.45l-0.45-5.79l-0.59-5.79l-0.05-1.03c-0.61,5.06-1.89,10.16-3.1,14.29
		c-1.4,5.25-2.53,7.19-4.01,8.43C245.2,248.6,244.87,248.84,244.51,249.07z M217.82,260.34c1.78-3.41,1.93-8.9,1.93-18.1v-24.78
		v-24.78v-24.63V143.4c0-10.09-5.64-6.68-5.64-10.54c0-2.23,0.45-2.67,1.34-3.12c0.07-0.02,0.16-0.03,0.23-0.05
		c-0.05,0.35-0.08,0.78-0.08,1.32c0,3.86,5.64,0.45,5.64,10.54v24.63v24.63v24.78v24.78c0,9.2-0.15,14.69-1.93,18.1
		c-2.08,4.45-2.82,4.6-2.97,7.12c0,1.48,0.45,1.63,1.04,1.19c2.67-1.63,5.49-4.01,8.16-5.79c5.04-3.41,10.24-5.19,15.28-8.01
		c1.05-0.63,1.92-1.1,2.69-1.79c-1.16,1.98-2.4,2.58-4.17,3.64c-5.05,2.82-10.24,4.6-15.28,8.01c-2.67,1.78-5.49,4.16-8.16,5.79
		c-0.59,0.45-1.04,0.3-1.04-1.19C215,264.94,215.74,264.79,217.82,260.34z M280.78,91.22c-0.43,0.11-0.87,0.21-1.29,0.32
		c-10.17-3.03-20.93-4.68-32.07-4.68c-37.96,0-71.57,18.91-91.96,47.79c-0.46,0.22-0.87,0.42-1.29,0.62
		c-0.38,0.18-0.77,0.37-1.12,0.54c20.47-30.29,55.13-50.25,94.37-50.25c12.03,0,23.61,1.89,34.5,5.36
		C281.55,91.03,281.16,91.12,280.78,91.22z M196.61,300.5c0.22-0.14,0.44-0.28,0.66-0.43c15.11,7.56,32.14,11.84,50.15,11.84
		c53.07,0,97.65-36.95,109.45-86.46c0.24-0.07,0.48-0.14,0.73-0.22c0.23-0.07,0.46-0.14,0.7-0.21
		c-11.66,50.45-56.93,88.18-110.87,88.18c-18.49,0-35.95-4.46-51.41-12.32C196.22,300.75,196.41,300.63,196.61,300.5z
		 M369.01,198.83c-0.79,0.78-1.6,1.49-2.43,2.12c-0.44,0.33-0.87,0.7-1.32,0.98c-0.43,0.27-0.87,0.47-1.31,0.71
		c-0.92,0.49-1.86,0.9-2.83,1.21c-0.22,0.07-0.43,0.16-0.65,0.22c-0.2,0.06-0.39,0.15-0.6,0.2c-0.02,0.01-0.04,0.01-0.06,0.01
		c-14.52,4.04-23.2-18.52-23.12-53.05c0.02-6.56,0.43-12.81,1.18-18.67c0.04-0.33,0.1-0.65,0.14-0.98c0.04-0.27,0.08-0.54,0.12-0.81
		c0.18-1.28,0.36-2.56,0.57-3.79c0.1-0.59,0.23-1.16,0.34-1.74c0.1-0.54,0.2-1.08,0.31-1.61c2.22-10.98,5.71-20.04,10.03-26.17
		c0.43-0.62,0.89-1.16,1.34-1.71c3.16-3.9,6.69-6.36,10.46-6.93c13.66-1.75,23.99,19.65,23.92,52c-0.06,26.78-6.11,47.6-15.58,57.52
		C369.36,198.5,369.18,198.66,369.01,198.83z M112.6,258.93c0,50.3,8.76,80.58,23.3,69.3c1.28-1.01,2.53-2.25,3.74-3.67
		c-1.67,2.24-3.41,4.09-5.22,5.53c-14.54,11.28-23.3-19-23.3-69.3c0-43.55,8.25-80.56,19.61-93.53
		C120.14,181.65,112.6,217.3,112.6,258.93z M190.87,304.28c0.4-0.26,0.79-0.53,1.19-0.8c0.42-0.28,0.84-0.57,1.27-0.85
		c16.18,8.51,34.58,13.36,54.1,13.36c55.82,0,102.59-39.44,113.96-91.91c0.48-0.14,0.96-0.29,1.43-0.43c0.47-0.14,0.93-0.27,1.4-0.4
		c24.76-7.22,47.02-11.99,65.77-15.13c3.43-0.58,6.73-1.09,9.92-1.57c-0.26,7.09-0.88,14.1-1.9,20.98
		c-13.67,92.93-93.91,164.51-190.58,164.51c-44.71,0-85.88-15.35-118.6-41C149.54,333.47,170.32,317.96,190.87,304.28z
		 M445.32,191.32c-0.43,0.02-0.86,0.05-1.29,0.08c-0.95,0.07-1.9,0.18-2.84,0.33c-0.21,0.03-0.43,0.07-0.64,0.11
		c-0.19,0.03-0.38,0.06-0.57,0.1l-0.07,0.01l-6.64,1.33l-1.45,0.29c-0.59,0-1.04-0.3-1.04-1.63c0.11-1.35,0.49-1.51,1.64-2.68
		c-0.07,0.23-0.13,0.48-0.15,0.83c0,1.34,0.45,1.63,1.04,1.63l0.32-0.06l6.24-1.25l0.64-0.13l0.64-0.13l0.31-0.06
		c0.84-0.17,1.69-0.29,2.53-0.39c0.43-0.05,0.86-0.1,1.29-0.13c0.43-0.03,0.86-0.05,1.29-0.08c3.36-0.17,6.73-0.05,10.18-0.44
		c1.61-0.16,2.77-0.22,3.84-1.06c-1.53,2.68-2.96,2.68-5.32,2.92c-2.92,0.33-5.8,0.3-8.65,0.38
		C446.18,191.28,445.75,191.3,445.32,191.32z M431.51,87.36c0,0.01,0,0.01,0,0.02c0,1.78,1.34,1.93,1.93,2.23
		c3.56,0.89,3.56,8.01,3.56,14.84v16.91v16.36v0.71v3.42v4.83v8.82v3.02v3.04v3.74v7.27c0,1.38,0,2.62-0.02,3.79
		c-0.05,4.62-0.32,7.6-1.6,9.31c0.45-0.46,1.12-1.06,2.15-2.29c0.8-0.93,1.24-2.31,1.48-4.16c0.13-0.93,0.2-1.99,0.25-3.19
		c0.05-1.32,0.07-2.8,0.08-4.46c0-0.01,0-0.03,0-0.05c0-0.49,0-0.97,0.01-1.5l0.03-13.85l0.01-3.21l0.01-2.56l0.01-4.67l0.02-9.83
		l0.04-17.07l0.04-16.92c0.02-6.77,0.02-13.8-3.44-14.8c-0.03-0.01-0.05-0.04-0.08-0.04c-0.52-0.26-1.6-0.41-1.87-1.63
		c-0.04-0.18-0.06-0.37-0.06-0.6c0-1.19,0.3-1.93,2.23-1.92c12.76-0.71,16.18-0.26,19-0.4l9.65-1.01c0.44,0,0.59,1.34,0.59,3.12
		c-0.04,16.47-0.93,17.66-1.38,17.66c-1.4,0.12-0.72-5.19-1.24-8.77c-0.09-0.6-0.2-1.15-0.37-1.62c-0.24-0.75-0.61-1.35-1.06-1.84
		c-2.56-2.83-7.94-1.87-10.21-1.75c-4.32,0.19-5.71,0.01-6.17,2.73c0.89-0.13,2.06-0.15,3.62-0.22c2.67-0.15,9.65-1.48,11.28,3.56
		c0.96,2.63,0.37,7.86,0.96,9.72c-0.28,2.23-0.6,2.53-0.82,2.53c-1.63,0.15-0.45-7.12-1.63-10.39c-1.63-5.05-8.61-3.71-11.28-3.56
		c-0.89,0.04-1.64,0.07-2.3,0.1c-0.06,1.29-0.06,2.96-0.07,5.16l-0.02,6.83l-0.02,7.12l-0.02,6.83l-0.02,6.98
		c-0.01,5.04-0.16,5.04,2.51,4.9l1.78-0.14l1.78-0.14l1.78-0.14l1.93,0c0.37-0.02,0.69-0.09,1.01-0.17c1.14-3.48,0.9-7.7,1.88-7.79
		c0.24,0,0.44,0.48,0.57,1.1c-0.26,1.93-0.58,4.43-1.58,6.36c0.69-0.33,1.26-0.8,1.72-1.38c0.69-0.86,1.16-1.94,1.5-3.08
		c0.99-3.38,0.82-7.27,1.76-7.35c0.45,0,0.74,1.63,0.73,2.82L462,135.5l0.12,11.72c0,1.33-0.3,1.93-0.75,2.07
		c-1.28,0.09-0.92-4.97-1.78-8.72c-0.3-1.31-0.77-2.44-1.49-3.15c-0.56-0.54-1.26-0.85-2.19-0.76l-2.52-0.01l-2.52,0.14l-2.38,0.29
		l-2.52,0.14c-0.84,0.09-1.09,1.13-1.17,2.45l1.26-0.07l2.37-0.3l2.52-0.15h2.52c4.66-0.49,3.95,8.53,4.74,11.6l0.01,0.8
		c0,1.33-0.3,1.93-0.74,2.08c-2.08,0.15,0.15-13.21-5.49-12.61h-2.52l-2.52,0.15l-2.18,0.27c0,0.23,0,0.46,0,0.69l-0.02,6.83
		l-0.02,6.83l-0.02,6.83l-0.02,6.98c0,0.33,0,0.61,0,0.93c0,0.15,0,0.29,0,0.43c-0.03,4.84-0.06,7.91,1.19,9.37
		c0.74,0.87,1.91,1.21,3.84,1c4.8-0.35,8.32-2.07,10.95-4.26c2.69-4.08,3.49-8.06,3.94-8.14c0.18,0,0.31,0.28,0.4,0.6
		c-0.73,1.89-1.91,4.61-3.93,7.18c1.76-1.57,3.08-3.34,4.09-5.05c0.61-1.03,1.11-2.03,1.51-2.94c0.01-0.03,0.03-0.06,0.04-0.09
		c1-2.28,1.45-3.98,1.74-4.03c0.44,0,0.59,1.49,0.59,1.78c0,0.67-0.03,1.34-0.08,2.01c-0.38,4.65-1.93,9.18-3.36,12.68
		c-1.21,3.1-2.23,4.32-3.44,4.88c-0.23,0.11-0.47,0.19-0.72,0.26c-0.7,0.18-1.46,0.25-2.38,0.34c-4.29,0.48-8.48,0.16-12.65,0.61
		c-0.43,0.05-0.85,0.1-1.28,0.17c-0.43,0.06-0.85,0.13-1.28,0.21c-0.02,0-0.05,0.01-0.07,0.01l-2.74,0.54l-0.64,0.13l-0.64,0.13
		l-4.14,0.82c-0.59,0-1.04-0.3-1.04-1.63c0.02-0.19,0.04-0.34,0.07-0.49c0.01-0.06,0.03-0.12,0.04-0.18
		c0.05-0.22,0.13-0.4,0.23-0.57c-0.05,0.05-0.09,0.11-0.14,0.16c-0.19,0.21-0.36,0.41-0.52,0.58c1.27-2.26,1.28-6.24,1.28-12.61
		v-16.59v-0.47v-2.48v-2.77v-10.63v-1.18v-3.04v-3.9v-10.12V106.3c0-6.83,0-13.95-3.56-14.84c-0.59-0.3-1.93-0.45-1.93-2.23
		C430.03,88.22,430.25,87.54,431.51,87.36z M421.07,101.58c0.12,0.94,0.22,1.91,0.29,2.95c0.07,1.02,0.12,2.09,0.15,3.21
		c0.02,0.69,0.03,1.4,0.03,2.13c0,1.31-0.07,2.59-0.16,3.86c-0.03,0.37-0.06,0.73-0.09,1.1c-0.04,0.43-0.1,0.85-0.15,1.27
		c-1.05,9.15-4.22,17.03-8.12,20.9l2.8,9.21l2.8,9.21l2.65,9.21c0.88,3.12,2.06,6.24,2.8,9.36c4.86,16.04,8.14,9.96,8.13,13.52
		c0,1.1-1.2,1.75-2.34,2.11c-0.87,0.28-1.71,0.4-1.97,0.4c-0.57,0.07-1.11,0.02-1.63-0.12c-3.89-1.05-6.22-7.68-8.43-14.89
		l-1.77-6.53l-1.91-6.38l-1.91-6.39l-1.76-6.24c-2.36-8.31-1.32-8.02-8.89-7c-1.32,0.19-1.62,1.33-1.67,2.52
		c6.73-0.94,5.86-0.94,8.16,7.08l1.78,6.23l1.93,6.38l1.93,6.38l1.78,6.53c2.52,8.16,5.19,15.58,10.09,14.99
		c0.34,0,1.71-0.21,2.81-0.74c0,0.03,0.01,0.05,0.01,0.08c0,1.93-3.71,2.52-4.3,2.52c-4.9,0.59-7.57-6.83-10.09-14.99l-1.78-6.53
		l-1.93-6.38l-1.93-6.38l-1.78-6.23c-2.11-7.36-1.54-7.97-6.65-7.29c0,0.09,0.01,0.19,0.01,0.28l-0.02,8.01l-0.02,8.16l-0.02,8.16
		l-0.02,8.16c-0.03,13.21,6.81,7.29,6.8,12.48c0,1.63-0.6,1.48-1.04,1.48c-0.24,0.06-0.66,0.02-1.42,0.04
		c-0.72,0.01-1.76,0.07-3.27,0.29c-0.86,0.12-1.84,0.29-3.03,0.55c-2.52,0.44-5.05,1.47-7.57,2.06c-0.45,0.15-0.74-0.74-0.74-1.63
		c0.01-3.86,4.9-2.66,4.93-14.39l0.05-19.88l0.05-19.59l0.05-19.59l0.05-19.59c0.02-9.33-2.26-9.98-3.96-10.51
		c-0.84-0.26-1.53-0.49-1.73-1.73c-0.04-0.24-0.06-0.51-0.06-0.83c0-0.89,0.6-1.93,1.34-1.93c1.19-0.14,0.89,0.45,4.3,0.01
		c2.75-0.2,5.49-0.78,8.08-1.2c0.27-0.04,0.54-0.08,0.81-0.12c0.22-0.03,0.42-0.05,0.63-0.08c0.54-0.07,1.09-0.16,1.62-0.2
		c0.2-0.02,0.77-0.16,1.58-0.23c0.48-0.04,1.04-0.06,1.67-0.03c0.51,0.03,1.06,0.09,1.63,0.21
		C414.51,85.84,419.54,89.34,421.07,101.58z M66.16,371.04c-0.89,0-1.48-6.83-3.27-23.6l-3.71-33.09l-3.56-33.09l-3.71-33.09
		l-3.56-32.95c-1.63-14.1-4.75-11.28-4.75-16.03c0-2.82,0.74-3.71,1.48-3.71c0.05,0,0.09,0.02,0.14,0.02
		c-0.09,0.49-0.14,1.09-0.14,1.83c0,3.39,1.59,2.95,3.1,7.55c0.6,1.84,1.19,4.45,1.65,8.48l0.14,1.3l1.45,13.4l1.73,16.03l0.24,2.22
		l3.71,33.09l3.56,33.09l3.71,33.09c1.56,14.66,2.21,21.72,2.94,23.26C66.97,370.44,66.62,370.95,66.16,371.04z M90.78,351.28
		c-0.3,3.56-0.91,8.45-1.95,8.9c-0.21,0.11-0.39,0.01-0.57-0.49c-0.22-0.64-0.45-1.94-0.76-4.37c-0.13-1-0.27-2.19-0.43-3.6
		l-1.74-15.44l-1.89-15.44l-1.09-9.76l-0.3-2.68l-0.34-3.07l-0.01-0.08l-0.74-6.48l-0.16-1.43l-0.2-1.79l-0.64-5.58
		c-0.15-1.34-0.29-2.08-0.44-2.08c-0.15,0-0.45,0.15-0.6,1.78l-0.28,2.49l-0.13,1.2l-0.11,0.97l-0.19,1.74l0.53,4.56l0.37,3.19
		l0.32,2.74l0.24,2.09l1.78,15.58l1.93,15.43l1.78,15.43c0.62,5.27,0.95,7.44,1.3,8.18c-0.27,1.14-0.61,1.97-1,2.14
		c-0.59,0.3-0.89-0.89-1.78-8.46l-1.78-15.43l-1.93-15.43l-1.78-15.58l-0.81-6.98l-0.42,3.8l-1.82,17.21l-1.97,17.65l-1.82,17.36
		c-0.61,4.89-1.05,6.08-1.79,6.23c-0.14,0-0.28-0.2-0.41-0.57c-0.25-0.7-0.5-2.09-0.78-4.25c-0.49-3.76-1.09-9.85-2.01-18.78
		l-3.63-33.1l-3.48-33.1l-1.77-16.11l-0.78-7.11l-1.03-9.42l-0.05-0.46l-3.48-32.95c-0.4-3.5-0.89-5.95-1.4-7.74
		c-0.45-1.56-0.91-2.6-1.35-3.4c-0.47-0.85-0.9-1.42-1.24-1.98c-0.15-0.25-0.28-0.51-0.39-0.79c-0.18-0.46-0.29-1.01-0.32-1.74
		c-0.01-0.13-0.02-0.24-0.02-0.38c0-0.04,0-0.07,0-0.11c0.02-1.95,0.4-2.94,0.88-3.35c0.19-0.16,0.4-0.24,0.6-0.24
		c0.23,0.02,0.46,0.07,0.69,0.11c0.43,0.07,0.86,0.14,1.29,0.25c0.95,0.23,1.89,0.47,2.84,0.6c0.21,0.03,0.43,0.05,0.64,0.06
		c0.11,0.01,0.22,0.03,0.33,0.03c0.11,0,0.21-0.03,0.32-0.04c1.53-0.1,3.06-0.86,4.58-0.99c0.29,0,0.43,1.14,0.43,2.72
		c0,0.04,0,0.07,0,0.1c-0.01,3.71-2.83,3.85-2.85,12.01l0.43,6.09l2.9,28.35l1.26,11.17l0.46,4.09l0.38,3.37l1.1,9.72l0.29,2.67
		l0.53,5l0.44,4.1l1.79,16.73l2.79,24.86l0.06-0.59l1.19-10.98l1.34-11.13l0.42-3.47l0.26-2.1l0.29-2.4l0.37-3l0.16-2.22l0.08-1.12
		l0.1-1.36l0.4-5.54l-0.59-7.42l-1.78-14.54l-1.63-14.39l-1.63-14.25l-1.63-14.4c-1.78-15.88-6.53-6.38-6.53-14.54
		c0-2.52,0.45-2.67,1.04-2.67c0.15-0.03,0.31-0.05,0.47-0.07c-0.02,0.26-0.02,0.55-0.02,0.89c0,8.16,4.75-1.34,6.53,14.54l1.63,14.4
		l1.63,14.25l1.63,14.39l1.78,14.54l0.59,7.42l-0.7,9.7l-0.04,0.54l-0.09,0.76l-0.13,1.03l-0.61,5.05l-0.28,2.31l-0.22,1.83
		l-0.02,0.19l-1.31,10.94l-1.19,10.98l-0.84,9.03c0.24,1.72,0.55,3.14,0.74,3.14c0.42,0,0.72-1.49,1.01-4.07
		c0.01-0.13,0.03-0.24,0.04-0.37l0.05-0.48l1.02-10.65l1.21-10.98l0.48-3.95l0.15-1.23l0.25-2.01l0.28-2.25l0.15-1.27l0.05-0.42
		l0.4-3.18l0.13-1.02l0.16-1.25l0.68-5.52L77.77,272l-0.58-7.42l-1.75-14.55l-1.6-14.4l-1.6-14.25l-1.6-14.4
		c-1.52-13.84-5.34-8.42-6.28-12.2c-0.11-0.45-0.18-1.02-0.2-1.77c-0.01-0.18-0.01-0.36-0.01-0.56c0-0.01,0-0.02,0-0.04
		c0.01-2.52,0.45-2.67,1.04-2.67c2.23-0.44,5.79-0.13,6.68-0.28c3.27-0.88,5.05-2.21,6.54-2.51c0.3-0.15,0.3,1.19,0.29,2.67
		c0,0.48-0.04,0.89-0.11,1.28c-0.02,0.13-0.05,0.26-0.08,0.39c0.16-0.22,0.35-0.35,0.52-0.43c0.12-0.06,0.24-0.11,0.34-0.14
		c0.06-0.02,0.13-0.02,0.19-0.04c0,0.04,0,0.08-0.01,0.12c-0.02,0.18-0.03,0.37-0.03,0.59c0,5.49,3.86,2.67,3.86,13.21
		c0,2.37-0.45,6.53-0.59,9.05l-0.89,7.42L81,228.35l-0.74,7.42l-0.39,4l0.05,0.47c0.37,3.74,0.53,5.58,0.65,6.07
		c0.02,0.09,0.05,0.16,0.07,0.16c0.3-0.15,0.6-2.97,1.06-6.52l0.76-7.57l0.76-7.42l0.91-7.27l0.91-7.42
		c0.08-1.34,0.25-3.14,0.39-4.89c0.12-1.54,0.23-3.05,0.23-4.16c0.02-8.37-2.41-8.33-3.41-10.71c-0.02-0.05-0.05-0.09-0.06-0.14
		c-0.17-0.46-0.28-1.02-0.33-1.72c-0.01-0.21-0.03-0.41-0.03-0.65c0.01-2.08,0.9-2.37,1.34-2.52c0.74-0.24,1.53-0.27,2.34-0.31
		c0.65-0.03,1.3-0.08,1.96-0.27c2.82-1.03,3.72-2.37,4.76-2.66c0.3-0.15,0.59,0.3,0.59,1.04c-0.02,6.83-1.63-1.64-4.66,25.07
		l-1.36,10.38l-1.21,10.53l-1.21,10.53l-1.06,10.39c-0.61,5.19-0.61,7.27-0.62,9.5c-0.01,2.52,0.43,8.01,0.86,11.43l1.31,12.17
		c0.44,3.86,1.02,8.02,1.31,12.02l1.37,11.43l0.09,0.74l0.08,0.78l0.12,1.14l0.62,5.8l0.3,2.78l0.04,0.41l0.03-0.31l0.21-2.05
		l0.44-4.3l0.09-0.85l0.11-1.1l1.7-16.52l2.97-28.64l3.12-28.79l3.12-28.49c0.3-2.82,0.45-5.34,0.45-8.16
		c0-10.39-4.3-6.68-4.3-11.58c0-1.93,0.15-3.71,0.74-3.86c0.28-0.14,0.57-0.22,0.85-0.3c-0.08,0.66-0.11,1.46-0.11,2.3
		c0,4.9,4.3,1.19,4.3,11.58c0,2.82-0.15,5.34-0.45,8.16l-3.12,28.49l-3.12,28.79l-2.97,28.64l-2.06,19.96l-0.11,1.1l-0.09,0.85
		l-0.44,4.25l-0.2,1.97l-0.07,0.67c-0.04,0.36-0.07,0.7-0.11,1.05c-0.09,0.88-0.18,1.72-0.27,2.49c0.34,2.17,0.58,2.99,0.88,2.89
		c0.12-0.08,0.25-0.46,0.37-1.04c0.13-0.61,0.27-1.48,0.41-2.56c0.07-0.51,0.14-1.07,0.21-1.67c0.07-0.61,0.15-1.25,0.22-1.94
		c0.09-0.81,0.18-1.66,0.28-2.57l0.16-1.49l0.09-0.84l0.12-1.09l2.67-25.36l3.04-28.63l3.18-28.78l3.18-28.48
		c0.07-0.65,0.1-1.25,0.16-1.88c0.18-2.09,0.3-4.11,0.31-6.28c0.02-9.92-3.9-7.01-4.24-10.99c-0.02-0.19-0.03-0.38-0.03-0.6
		c0-0.34,0.01-0.67,0.02-1c0.05-1.51,0.24-2.74,0.73-2.86c1.49-0.74,2.97-0.44,4.6-1.18c1.93-0.89,4.02-2.81,5.5-3.4
		c0.44-0.3,0.74-0.44,0.74,2.08c-0.01,5.34-1.63-0.45-3.91,19.58l-4.39,38.57l-4.4,39.02l-4.25,38.87l-0.5,4.49l-0.12,1.04
		l-0.09,0.79l-0.45,4.04l-0.22,1.95l-0.19,1.7L90.78,351.28z"
        />
        <path
          d="M81.29,215.52c0.15-2.52,0.59-6.68,0.59-9.05c0-10.54-3.86-7.72-3.86-13.21c0-0.24,0.01-0.45,0.04-0.64
		c-0.79,1.83-2.05,3.56-2.06,9.04c-0.01,2.97,0.14,4.45,0.43,7.27l0.87,7.87l0.87,7.72l0.87,8.02l0.13,1.11l0.34-3.43l0.89-7.27
		L81.29,215.52z"
        />
        <path
          d="M400.11,94.71c-0.02,0.46-0.05,0.91-0.05,1.47l-0.02,9.79l-0.02,9.65l-0.02,9.79l-0.02,9.8
		c-0.01,2.97,4.15,1.79,7.27,1.35c0.6-0.09,1.36-0.33,2.18-0.81c1.74-3.35,3.1-8.73,3.1-17.42
		C412.52,100.11,406.51,94.21,400.11,94.71z"
        />
        <path
          d="M355.23,98.73c-1.65,0.26-3.18,1.04-4.61,2.18c-3.9,6.08-6.64,15.68-8.04,26.79c-0.08,0.59-0.14,1.2-0.21,1.8
		c-0.07,0.67-0.16,1.32-0.22,2c-0.14,1.43-0.26,2.87-0.35,4.33c-0.02,0.34-0.03,0.69-0.05,1.03c-0.02,0.39-0.04,0.79-0.06,1.19
		c-0.12,2.4-0.2,4.82-0.21,7.27c-0.07,28.19,6.95,53.43,18.47,53.79c0.21,0.01,0.43,0,0.64-0.01c0.21-0.01,0.43-0.02,0.64-0.04
		c0.44-0.06,0.88-0.14,1.33-0.26c0.49-0.11,0.97-0.3,1.45-0.5c0.43-0.18,0.85-0.41,1.27-0.66c0.43-0.25,0.85-0.53,1.27-0.85
		c0.24-0.18,0.47-0.38,0.7-0.59c5.43-8.65,9.05-24.36,9.05-44.18C376.31,123.51,368.15,96.95,355.23,98.73z"
        />
        <path
          d="M153.26,270.34c0.07-0.66,0.13-1.32,0.2-1.99c0.14-1.41,0.26-2.85,0.37-4.3c0.02-0.31,0.06-0.61,0.08-0.92
		c0.03-0.37,0.05-0.73,0.08-1.1c0.49-6.79,0.75-13.95,0.75-21.43c0-35-6.14-66.07-16.28-69.37c-0.21-0.07-0.42-0.12-0.64-0.17
		c-0.21-0.04-0.41-0.09-0.63-0.11c-0.96-0.09-1.96,0.08-2.99,0.5c-0.23,0.09-0.46,0.17-0.69,0.29c-0.28,0.14-0.56,0.32-0.83,0.5
		c-0.46,0.3-0.92,0.66-1.36,1.07c-0.12,0.21-0.23,0.42-0.34,0.63c-7.24,13.58-11.4,42.47-11.47,72.04
		c-0.1,43.78,7.97,80.15,21.2,69.8c0.6-0.45,1.19-0.99,1.77-1.63c4.67-8.61,8.49-23.46,10.56-42.07
		C153.13,271.5,153.2,270.93,153.26,270.34z"
        />
        <path
          d="M185.47,146.66c-0.91,0.41-1.78,1.01-2.63,1.77c-7.22,12.38-11.24,38.56-11.3,65.32
		c-0.09,40.07,7.84,72.59,21.07,63.27c0.74-0.5,1.47-1.17,2.18-1.92c6.94-12.08,11.89-36.21,11.89-65.52
		C206.69,173.08,198.38,141.17,185.47,146.66z"
        />
        <path
          d="M359.25,94.15c-2.89,0.45-5.46,2.36-7.69,5.36c1.59-1.4,3.3-2.34,5.15-2.63
		c12.91-1.78,21.07,24.78,21.07,53.28c0,21.03-4.09,37.39-10.07,45.64c7.23-6.93,12.42-24.69,12.48-48.32
		C380.27,118.98,372.17,92.4,359.25,94.15z"
        />
        <path
          d="M407.15,90.51c-0.35-0.1-0.7-0.18-1.05-0.23c-0.76-0.11-1.54-0.12-2.32-0.04c-1.82,0.11-3.02,0.07-3.49,2.69
		c0.3-0.03,0.61-0.05,0.95-0.07c6.53-0.74,12.76,5.05,12.76,23.6c0,10.07-1.83,15.71-3.95,18.87c2.95-2.12,6.41-7.61,6.44-21.46
		c0.01-2.78-0.14-5.24-0.4-7.47c-0.07-0.62-0.14-1.25-0.23-1.83c-0.09-0.59-0.2-1.14-0.31-1.69c-1.38-6.98-4.18-10.67-7.47-12.04
		C407.78,90.72,407.47,90.6,407.15,90.51z"
        />
        <path
          d="M140,165.86c-0.24,0.02-0.48,0.07-0.73,0.12c-0.23,0.05-0.46,0.08-0.7,0.16c-0.4,0.13-0.8,0.29-1.2,0.5
		c-0.8,0.4-1.58,0.98-2.33,1.69c-0.67,0.64-1.33,1.39-1.96,2.25c-0.43,0.59-0.86,1.22-1.27,1.91c0.36-0.4,0.72-0.77,1.08-1.11
		c0.53-0.48,1.07-0.87,1.62-1.2c0.17-0.1,0.33-0.21,0.5-0.3c0.93-0.48,1.84-0.72,2.72-0.78c0.22-0.02,0.44-0.03,0.66-0.02
		c0.22,0.01,0.44,0.04,0.66,0.08c10.68,1.7,17.18,33.56,17.18,69.57c0,8.8-0.38,17.16-1.05,24.98c-0.03,0.36-0.06,0.71-0.09,1.07
		c-0.03,0.3-0.05,0.61-0.08,0.92c-0.13,1.41-0.27,2.78-0.42,4.15c-0.07,0.64-0.14,1.27-0.22,1.9c-0.07,0.58-0.13,1.18-0.2,1.75
		c-2.14,17.06-5.78,30.64-10.17,38.75c4.84-6.73,8.99-19.83,11.58-36.97c0.08-0.55,0.16-1.12,0.24-1.68
		c0.09-0.61,0.18-1.21,0.27-1.84c0.17-1.28,0.33-2.59,0.48-3.91c0.03-0.28,0.07-0.56,0.1-0.85c0.04-0.34,0.08-0.68,0.12-1.03
		c1.02-9.33,1.61-19.56,1.64-30.44C158.52,197.95,151.52,164.88,140,165.86z"
        />
        <path
          d="M189.37,141.68c-2.16,0.96-4.14,2.95-5.93,5.77c1.11-1.18,2.28-2.08,3.51-2.64
		c12.91-5.49,21.22,26.42,21.22,62.92c0,29.67-5.08,54.06-12.15,65.98c8.17-10.33,14.34-36.51,14.42-69.06
		C210.53,168.14,202.3,136.22,189.37,141.68z"
        />
        <polygon points="312.22,63.3 312.22,56.75 306.55,53.47 300.88,56.75 300.88,63.3 306.55,66.57 	" />
        <polygon points="117.98,132.15 117.98,125.6 112.31,122.33 106.63,125.6 106.63,132.15 112.31,135.43 	" />
      </g>
    </svg>
  )
}

export { TheOldWoolstoreLogo }

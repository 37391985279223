import { CrossSVG } from "components/global/Cross";
import FlexCentre from "components/utils/FlexCentre";
import { crossSize } from "lib/theme";
import { Box } from "theme-ui";

const SplashCross = () => {
  return (
    <FlexCentre
      sx={{
        zIndex: 4,
      }}
    >
      <Box sx={{
        direction: 'row',
        height: crossSize,
        width: crossSize,
      }}>
        <CrossSVG />
      </Box>
    </FlexCentre>
  )
}

export default SplashCross;

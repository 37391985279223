/** @jsx jsx */
/*  eslint-disable react/display-name, react-hooks/rules-of-hooks */
import BlockContent from '@sanity/block-content-to-react'
import { useDarkContext } from 'components/context/DarkContext'
import Form from 'components/global/Form'
import { Line } from 'components/Line'
import { Accordion } from 'components/modules/Accordion'
import Link from 'next/link'
import { jsx, Box, Text, useThemeUI } from 'theme-ui'
import { Partners } from 'components/modules/Partners'
import { mapLinkTypes } from './helpers/links'

const BlockRenderer = ({ node, children }) => {
  const { theme } = useThemeUI()
  const { style = 'normal', reverseColor } = node
  const variant = reverseColor ? 'Reverse' : ''
  switch (style) {
    case 'normal':
      return (
          <Text
            as="p"
            variant={`copy${variant}`}
            sx={{
              mb: [theme.lineHeights[6]],
            }}
          >
            {children}
          </Text>
      )
    case 'smallCopy':
      return (
        <div>
          <Text variant={`smallAccessCopy${variant}`} as="p">
            {children}
          </Text>
        </div>

      )
    case 'smallCaps':
      return (
        <Text
          as="span"
          variant={`smallAccessCopyCaps`}
          sx={{
            mb: [theme.lineHeights[6]],
            color: 'alwaysBlack',
          }}
        >
          {children}
        </Text>
      )
    case 'largeCopy':
      return (
        <div>
          <Text as="h3" variant={`largeCopy${variant}`}>
            {children}
          </Text>
          <Line hrHeight='1px' color={'alwaysBlack'} />
        </div>
      )
    case 'eventCopy':
      return (
        <div>
          <Text
            as="h2"
            variant={`copyCapsWide`}
            sx={{
              mb: [theme.lineHeights[6]],
              pt:'1em',
            }}
          >
            {children}
          </Text>
        </div>
      )
    case 'subTitle':
      return (
        <Text
          as="h2"
          variant={`copy${variant}`}
          sx={{
            mb: 0,
            fontWeight: 'bold',
            pt: '1em',
          }}
        >
          {children}
        </Text>
      )
    case 'pttr':
      return (
        <Text
          as="p"
          variant={`copy${variant}`}
          sx={{
            mb: 0,
            textAlign: 'left',
            textAlignLast: 'left',
          }}
        >
          {children}
        </Text>
      )
    default:
      return (
        <Text as="p" variant={`copy${variant}`}>
          {children}
        </Text>
      )
  }
}

const ButtonBlockRenderer = () => {
  return <Box>BUTTONS</Box>
}

const PartnersRender = () => {
  return (
    <Box>
      <Partners />
    </Box>
  )
}

const QuoteRenderer = props => {
  const { quotee, quote, reverseColor } = props.node
  const { theme } = useThemeUI()
  const variant = reverseColor ? 'Reverse' : ''
  return (
    <Box
      sx={{
        pl: 6,
        fontSize: [6],
        mb: [theme.lineHeights[6]],
      }}
    >
      <Text variant={`copy${variant}`} as="p">
        {quote}
      </Text>
      <Text variant={`smallCopy${variant}`} as="h5">
        – {quotee}
      </Text>
    </Box>
  )
}

const FormRender = props => {
  const { form, title, reverseColor, _key } = props.node
  const variant = reverseColor ? 'Reverse' : ''
  return (
    <Box>
      <Text variant={`copy${variant}`} as="p">
        {title}
      </Text>
      <Form form={form} uniqueId={_key} />
    </Box>
  )
}

const AccordionRender = props => {
  const { _key, reverseColor, accordionContent, parentRoute } = props.node
  if (accordionContent === undefined) return <></>
  const accordions = accordionContent?.map((item, i) => {
    return (
      <Accordion
        i={`${_key}${i}`}
        key={i}
        item={item}
        reverseColor={reverseColor}
        parentRoute={parentRoute}
        titleVariant="copyCapsEventListing"
      />
    )
  })

  return (
    <>
      {accordions}
    </>
  )
}

const serializers = reverseColor => {
  return {
    types: {
      block: BlockRenderer,
      accordionBlock: AccordionRender,
      buttonBlock: ButtonBlockRenderer,
      quote: QuoteRenderer,
      formBlock: FormRender,
      partnersBlock: Partners,
    },
    list: props => {
      const { theme } = useThemeUI()
      return props.type === 'number' ? (
        <ol
          sx={{
            margin: 0,
            padding: 0,
            mb: [theme.lineHeights[6]],
            listStyle: 'inside numerical',
          }}
        >
          {props.children}
        </ol>
      ) : (
        <ul
          sx={{
            margin: 0,
            padding: 0,
            paddingLeft:'0em',
            mb: [theme.lineHeights[6]],
            listStyle: 'none',
            'li:before': {
              content: '"•"',
              marginRight: '.333em',
            },
          }}
        >
          {props.children}
        </ul>
      )
    },
    listItem: props => {
      return (
        <Text
          variant={`copy${reverseColor ? 'Reverse' : ''}`}
          as="li"
        >
          {props.children}
        </Text>
      )
    },
    marks: {
      internal: props => {
        return (
          <Text as="span" sx={{ display: 'inline-block' }}>
            {props.children}
          </Text>
        )
      },
      header: props => {
        return (
          <Text as="h4" variant="copyTitle">
            {props.children}
          </Text>
        )
      },
      smallCopyInText: props => {
        return (
          <Text
            as="span"
            variant={`smallCopy${reverseColor ? 'Reverse' : ''}`}
            sx={{
              '::selection': { backgroundColor: 'red', color: 'black' },
            }}
          >
            {props.children}
          </Text>
        )
      },
      internalLink: props => {
        if (props.mark?.internalPage?.slug?.current) {
          const slug = props.mark.internalPage.slug.current
          const subPage = props.mark.subPage

          const type =
            props.mark.internalPage._type === 'location'
              ? 'map'
              : props.mark.internalPage._type

          const isEventLink = type === 'event'
          const isLocationLink = type === 'map'
          const isFormLink = type === 'form'

          const href =
            isEventLink || isLocationLink || isFormLink
              ? `/${mapLinkTypes(type)}/${slug}`
              : `/${slug}${subPage ? `/${subPage}` : ''}`

          const { menuDisplay, setMenuDisplay } = useDarkContext()
          const handleClick = () => {
            if (menuDisplay && isEventLink) setMenuDisplay(false)
          }

          return (
            <Link href={href} scroll={false} passHref>
              <Text
                as="a"
                variant="link"
                onClick={handleClick}
                sx={{
                  color: 'inherit',
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  textDecoration: 'none',
                  borderBottom: '1px solid currentColor',
                  '::selection': {
                    color: 'red',
                    backgroundColor: 'black',
                  },
                }}
              >
                {props.children}
              </Text>
            </Link>
          )
        }
        return props.children
      },
      externalLink: props => {
        return (
          <Text
            as="a"
            target="_blank"
            variant="link"
            rel="noopener noreferrer"
            href={props.mark.externalUrl}
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              textDecoration: 'none',
              borderBottom: '1px solid currentColor',
              '::selection': {
                color: 'red',
                backgroundColor: 'black',
              },
            }}
          >
            {props.children}
          </Text>
        )
      },
      mapLink: props => {
        return (
          <Link href={'/map'} scroll={false} passHref>
            <Text
              as="a"
              variant="link"
              sx={{
                color: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                textDecoration: 'none',
                borderBottom: '1px solid currentColor',
                '::selection': {
                  color: 'red',
                  backgroundColor: 'black',
                },
              }}
            >
              {props.children}
            </Text>
          </Link>
        )
      },
      line: props => {
        return (
          <Line color="alwaysBlack" hrHeight='1px' />
        )
      },
      gap: props => {
        return (
          <div sx={{ height: '1rem' }} />
        )
      },
      bold: props => {
        return (
          <Text
            sx={{
              fontWeight: 'bold'
            }}
          >
            {props.children}
          </Text>
        )
      },
      italic: props => {
        return (
          <Text
            sx={{
              fontStyle: 'italic'
            }}
          >
            {props.children}
          </Text>
        )
      },
      span: props => {
        return (
          <span sx={{
            display: "inline-block",
          }}>
            {props.children}
          </span>
        )
      },
      tagLink: props => {
        const tag = props?.mark?.tag
        const {
          setFilterByOpen,
          menuDisplay,
          setMenuDisplay,
          tagFilterBy,
          setTagFilterBy,
        } = useDarkContext()

        const handleClick = () => {
          if (menuDisplay) setMenuDisplay(false)
          setFilterByOpen(true)
          const newTagFilterBy = tagFilterBy
          Object.keys(newTagFilterBy).forEach(t => {
            newTagFilterBy[t] = false
          })
          newTagFilterBy[tag] = true
          setTagFilterBy(newTagFilterBy)
        }

        return (
          <Link href={'/program'} scroll={false} passHref>
            <Text
              as="a"
              variant="link"
              onClick={handleClick}
              sx={{
                color: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                textDecoration: 'none',
                borderBottom: '1px solid currentColor',
              }}
            >
              {props.children}
            </Text>
          </Link>
        )
      },
      fileLink: props => {
        return (
          <Text
            as="a"
            target="_blank"
            variant="link"
            href={
              props.mark.file.asset.url +
              (props.mark.file.asset.originalFilename
                ? '?dl=' + props.mark.file.asset.originalFilename
                : '')
            }
            rel="noopener noreferrer"
            sx={{
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              textDecoration: 'none',
              borderBottom: '1px solid currentColor',
            }}
          >
            {props.children}
          </Text>
        )
      },
    },
  }
}

export const DefaultBlocks = ({
  blocks,
  reverseColor = false,
  parentRoute = undefined,
  required = false,
}) => {
  const mappedBlocks = blocks?.map(block => {
    const newBlock = block
    newBlock.reverseColor = reverseColor
    if (parentRoute) newBlock.parentRoute = parentRoute
    return newBlock
  })

  return (
    <BlockContent
      blocks={mappedBlocks}
      serializers={serializers(reverseColor)}
    />
  )
}

export default DefaultBlocks

/**
 * Return a string in the correct date formats
 * 
 * @param {*} event 
 * @returns 
 */
 function searchTerm(
  event
) {
  var searchEvent = event.title + " " + (event.subTitle ? event.subTitle : "")
  event.tags?.map(tag => searchEvent = searchEvent + " " + tag.label)
  event.locations?.map(location => searchEvent = searchEvent + " " + location.name)

  return normalizeSearch(searchEvent)
}

export { searchTerm }

/**
 * Return a string in the correct date formats
 * 
 * @param {*} string 
 * @returns 
 */
 function normalizeSearch(
  string
) {
  var result = string.toLowerCase()
  result = result.replace(/\u0110/g, "d")
  result = result.replace(/\u0111/g, "d")
  result = result.replace(/\u0142/g, "l")
  result = result.replace(/\u00f8/g, "o")
  result = result.replace(/\u00df/g, "s")
  result = result.replace(/\u00f0/g, "o")
  result = result.replace(/\u0153/g, "o")
  result = result.replace(/\u00e6/g, "a")
  result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  return result
}

export { normalizeSearch }
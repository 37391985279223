/** @jsx jsx */
import { Button } from 'components/buttons/Button'
import { useDarkContext } from 'components/context/DarkContext'
import { jsx, Text, useColorMode } from 'theme-ui'
import { SavedIcon } from 'components/global/icons/SavedIcon'

const FavouriteButton = ({
  id,
  title,
  tnew,
  variant = 'smallCopySmallCapsClear',
  color = 'black',
  fillColor = 'red',
  elem,
  ...props
}) => {
  const { favourites, toggleFavourite } = useDarkContext()
  const [colorMode] = useColorMode()
  const iconColor = color

  return (
    <Button
      onClick={() => {
        dataLayer.push({
          event: `custom.toggle.${favourites[id] ? 'off' : 'on'}`,
          group: 'Planner',
          label: title,
          parentLabel: undefined,
          info: {
            position: props.index,
          },
          data: {
            artist: elem?.subTitle,
            performance: elem?.title,
            venue: elem?.locations && elem.locations[0].name,
            time: elem?.listEventTimes,
            eventType: elem?.ticketedEvent ? 'ticketed' : 'non-ticketed',
            date: elem?.dates && new Date(elem.dates[0]).valueOf(),
            mode: colorMode,
          },
        })
        /* eslint-enable no-undef */
        toggleFavourite(id, title, tnew)
      }}
      title='Save'
      sx={{
        padding:'0px',
      }}
      {...props}
    >
        <SavedIcon saved={favourites[id]} iconColor={iconColor} fillColor={fillColor} size = '1.2em'/> 
      {props.children}
    </Button>
  )
}

export { FavouriteButton }

{
  /* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8.962"
          height="10.434"
          viewBox="0 0 8.962 10.434"
        >
          <path
            id="Path_33"
            data-name="Path 33"
            d="M0,0V9L3.977,6.353,7.962,9V0Z"
            transform="translate(0.5 0.5)"

            strokeWidth="1"
          />
        </svg> */
}

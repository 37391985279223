/**
 * Return `true` if the current route matches the item's current slug
 * 
 * @param {*} url 
 * @param {*} item 
 * @returns 
 */
 function pageMatchesURL(
    url,
    regExp
  ) {
      return url.match(regExp);
  }
  
  /**
   * Alias of `pageMatchesURL` for lazily accessing `router.asPath`
   * 
   * @param {*} router 
   * @param {*} item 
   * @returns 
   */
  function pageMatchesRoute(router, regExp) {
    return pageMatchesURL(router.asPath.replace(/\?(.*)/g, ""), regExp)
  }

  export {pageMatchesRoute, pageMatchesURL}